import { useRoute } from "vue-router";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";

import log from "@controllers/Logger";
import CoveryController from "@front/core/controllers/CoveryController.js";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { addScript } from "@front/core/helpers/functionsHelper";
import { getBettingUrl } from "@helpers/bettingHelpers";
import { useGiftsStore } from "@store/gifts";
import { useUserBalance } from "@store/user/userBalance";
import { useUserInfo } from "@store/user/userInfo";
import { useUserStatuses } from "@store/user/userStatuses";
import { useCashboxStore } from "@store/cashboxStore";
import { sendTransactionToCovery } from "@api/requests/metrics";
import i18nInit from "@i18n";

import { ActionsTransaction } from "@api/DTO/cashbox";
import toast from "@plugins/Toast";

function customRoutes(route) {
    let routesForCashBox = {
        successUrl: window.location.href,
        failureUrl: window.location.href,
        pendingUrl: window.location.href,
        supportUrl: window.location.href,
    };

    if (route.meta.popup) {
        const bettingUrl = getBettingUrl();
        if (bettingUrl) {
            routesForCashBox = {
                successUrl: bettingUrl,
                failureUrl: bettingUrl,
                pendingUrl: bettingUrl,
                supportUrl: "/support",
            };
        }
    }

    return routesForCashBox;
}

const USER_STATUSES_FOR_COVERY = {
    257: "id 257 - Bronze",
    258: "id 258 - Silver",
    259: "id 259 - Gold",
    255: "id 255 - Platinum",
    759: "id 759 - Diamond",
};


export async function sendCoveryPayment(formData, methodConfig, action) {
    try {
        let card_last_4 = "",
            card_bin = null;
        if (action === ActionsTransaction.DEPOSIT) {
            if (formData.savedProfileId) {
                card_last_4 = methodConfig.savedProfiles[0].title.slice(-4);
                card_bin = Number(methodConfig.savedProfiles[0].title.slice(6));
            } else if (formData.encCreditcardNumber) {
                card_last_4 = formData.encCreditcardNumber.trim().slice(-4);
                card_bin = Number(formData.encCreditcardNumber.trim().slice(0, 6));
            }
        }
        const { getUserInfo: userInfo } = storeToRefs(useUserInfo());
        const { getUserGroups } = storeToRefs(useUserStatuses());

        const [ expiryYear, expiryMonth ] = formData.expiry_date ? formData.expiry_date.split("/") : [];

        let data = {
            country: userInfo.value.country,
            firstname: userInfo.value.first_name,
            lastname: userInfo.value.last_name,
            email: userInfo.value.email,
            device: { fingerprint: CoveryController.deviceFingerprint() },
            merchant_user_id: userInfo.value.email,
            card_expiration_month: expiryMonth && Number(expiryMonth),
            card_expiration_year: expiryYear && Number(expiryYear),
            card_holder_name: formData.cardHolder,
            ...(card_last_4 ? { card_last_4 } : {}),
            ...(card_bin ? { card_bin } : {}),
            ...(card_last_4 && card_last_4 ? { payment_account_id: `${ card_bin }...${ card_last_4 }` } : {}),
            website_url: typeof window === "object" && window.location.href,
            transaction_id: "777",
            date_time: dayjs().utc().format(),

            transaction_currency: formData.currency,
            transaction_amount: Number(formData.amount),
            payment_system: methodConfig.brand,

        };

        const {
            email, voucherNumber, accountNumber,
            address, destinationAccount, customer_purse,
            id,
        } = formData;

        if (!card_bin) {
            data.payment_account_id = email || voucherNumber || accountNumber ||
                address || destinationAccount || customer_purse || id;
        }


        let dataFiltered = Object.fromEntries(
            Object.entries(data)
                .filter(([ , val ]) => {
                    return Boolean(val);
                }),
        );

        if (action === ActionsTransaction.DEPOSIT) {
            dataFiltered.type = "transaction";
        } else if (action === ActionsTransaction.CASHOUT) {
            dataFiltered.type = "payout";
        }

        const customPlayerStatus = getUserGroups.value.find((idItem) => USER_STATUSES_FOR_COVERY[idItem]);
        return await sendTransactionToCovery({
            ...dataFiltered,
            custom_player_status: USER_STATUSES_FOR_COVERY[customPlayerStatus] || "null",
        });
    } catch (err) {
        log.error("SEND_COVERY_PAYMENT_ERROR", err);
        throw err;
    }
}

const TRANSACTION_STATE = {
    SUCCESS: "success",
    CANCELED: "canceled",
};

const LAST_SHOWN_TRANSACTION = "lastShownTransaction";

export async function showCanceledDepToast() {
    const { getIsLogged } = storeToRefs(useUserInfo());

    if (!getIsLogged.value) {
        return;
    }
    const { historyDeposits } = storeToRefs(useCashboxStore());
    const lastShownTransaction = JSON.parse(localStorage.getItem(LAST_SHOWN_TRANSACTION)) || {};
    const { loadPlayerPaymentsHistory } = useCashboxStore();
    await loadPlayerPaymentsHistory();
    const { t } = i18nInit.instance;

    const lastDepTransaction = [ ...historyDeposits.value ].pop();
    if (!lastDepTransaction) {
        return;
    }
    const olderMoreThan5Minutes = new Date() - new Date(lastDepTransaction.finished_at) > 5 * 60 * 1000;

    if (
        lastDepTransaction.state === TRANSACTION_STATE.CANCELED &&
        !olderMoreThan5Minutes &&
        lastDepTransaction.id !== lastShownTransaction?.id) {
        toast.show({
            type: "warning",
            image: "warning",
            text: t("CASHBOX.PAYMENT_STATUS.CANCELED", { action: lastDepTransaction.action }),
            id: Date.now(),
        });
        localStorage.setItem(LAST_SHOWN_TRANSACTION, JSON.stringify(lastDepTransaction));
    }
}

export function submitAnalyticsCashoutSuccess(config, action) {
    const $gtm = useGtm();
    if (action === ActionsTransaction.CASHOUT) {
        $gtm.customTrackEvent({
            ...$gtm.CASHBOX_EVENTS.WITHDRAW.WITHDRAW_SUCCESS,
            eventLabel: config.brand,
        });
        $gtm.customTrackEvent({
            ...$gtm.CASHBOX_EVENTS.WITHDRAW.NEW.WITHDRAW_SUCCESS,
            eventLabel: config.brand,
        });
    }
}

export function submitAnalyticsPaymentError(config, action) {
    const $gtm = useGtm();
    if (action === ActionsTransaction.DEPOSIT) {
        $gtm.customTrackEvent({
            ...$gtm.CASHBOX_EVENTS.DEPOSIT.DEPOSIT_UNSUCCESS,
            eventLabel: config.brand,
        });
    } else {
        $gtm.customTrackEvent({
            ...$gtm.CASHBOX_EVENTS.WITHDRAW.WITHDRAW_UNSUCCESS,
            eventLabel: config.brand,
        });

        $gtm.customTrackEvent({
            ...$gtm.CASHBOX_EVENTS.WITHDRAW.NEW.WITHDRAW_UNSUCCESS,
            eventLabel: config.brand,
        });
    }
}

export function addCashboxLib(callback = () => {
}) {
    if (typeof PaymentsAPI === "undefined") {
        addScript(
            `https://payments-lib.cdn.s7s.ai/v1/index.js?timesamp=${ Date.now() }`,
            {
                async: false,
                defer: false,
            },
            callback,
        );
    } else {
        return callback();
    }
}

export function useSetConfigToCashboxAPI() {
    const router = useRoute();

    function setConfigToCashboxAPI() {
        window.PaymentsAPI.config({
            redirectRoutes: customRoutes(router),
            onClosePopup(data) {
                // eslint-disable-next-line no-console
                console.log("onClosePopup", { data });
            },
        });
    }

    return {
        setConfigToCashboxAPI,
    };
}

export function useLoadCashboxData() {
    const giftsStore = useGiftsStore();
    const userBalance = useUserBalance();
    const { loadPlayerPaymentsHistory, loadPaymentMethods } = useCashboxStore();

    function loadCashboxData() {
        return Promise.all([
            loadPaymentMethods(),
            loadPlayerPaymentsHistory(),
            userBalance.loadUserBalance(),
            giftsStore.loadDepositGiftsData(),
            giftsStore.loadGiftsData(),
            giftsStore.loadFSGiftsData(),
        ]);
    }

    return { loadCashboxData };
}
