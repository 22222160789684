<template>
    <div class="footer gap-m" :style="contentVisibility">
        <div class="footer-menu">
            <div class="footer-menu__wrapper gap-m">
                <FooterMenu/>
                <LanguageForm v-if="isMobile" class="footer-menu__lang"/>

                <div class="footer-contact gap-row-s">
                    <div v-if="$t('TEL')">
                        <p class="footer-contact__title">
                            {{ $t("FOOTER.TEL_TITLE") }}
                        </p>
                        <a :href="`tel:${$t('TEL')}`" class="contacts-links__link">{{ $t("TEL") }}</a>
                    </div>
                    <div>
                        <p class="footer-contact__title">
                            {{ $t("FOOTER.EMAIL_TITLE") }}
                        </p>
                        <a
                            :href="'mailto:' + config.contacts.email"
                            class="contacts-links__link"
                        >{{ config.contacts.email }}</a>
                    </div>
                    <FooterSocial/>
                </div>
                <div>
                    <LanguageForm v-if="!isMobile" class="footer-menu__lang"/>
                    <div class="gap-row-s">
                        <FooterRocketPartnerLink/>
                        <PartnerMedia/>
                    </div>
                </div>
            </div>
        </div>
        <ProducersBlock/>
        <FooterPayments/>
        <hr>
        <TrustBlock/>
        <FooterContacts/>
    </div>
</template>

<script setup>
import { computed } from "vue";
import FooterSocial from "@modules/Footer/components/FooterSocial/FooterSocial.vue";
import FooterMenu from "@modules/Footer/components/FooterMenu/FooterMenu.vue";
import FooterPayments from "@modules/Footer/components/FooterPayments/FooterPayments.vue";
import FooterContacts from "./components/FooterCopyright/FooterCopyright.vue";
import ProducersBlock from "@modules/Footer/components/ProducersBlock/ProducersBlock.vue";
import TrustBlock from "@modules/Footer/components/TrustBlock/TrustBlock.vue";
import LanguageForm from "@modules/Profile/components/LanguageForm/LanguageForm.vue";
import FooterRocketPartnerLink from "@modules/Footer/components/FooterRocketPartnerLink/FooterRocketPartnerLink.vue";

import config from "@theme/configs/config";
import { useEnvironments } from "@store/environments";
import PartnerMedia from "@modules/Footer/components/PartnerMedia.vue";
import { storeToRefs } from "pinia";
import { useRootStore } from "@store/root";

const { isMobile } = storeToRefs(useRootStore());

const { useMocker } = useEnvironments();
const contentVisibility = computed(() => {
    return {
        "content-visibility": useMocker ? "visible" : "auto",
    };
});

</script>

<style lang="scss" src="./style.scss"></style>
