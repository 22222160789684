<template>
    <div v-if="timerEnabled">
        {{ t("SESSION_TIME") }} <span class="session-time">{{ sessionTime }}</span>
    </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref, reactive, onBeforeUnmount, computed, watch, toRefs } from "vue";
import { useLicense } from "@helpers/license";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";

const { getIsLogged: isLogged } = storeToRefs(useUserInfo());
const { isLicenseEE } = toRefs(useLicense());
const { t } = useI18n();
const timerId = ref();
const sessionTime = ref(null);
const time = reactive({
    seconds: 0,
    minutes: 0,
    hours: 0,
});
const sessionStarted = ref(false);
const timerEnabled = computed(() => isLogged.value && sessionStarted.value && isLicenseEE.value);

const SESSION_STORAGE_KEY = "sessionTime";

function padTime(val) {
    return val < 10 ? `0${ val }` : val;
}

// @TODO https://upstars.atlassian.net/browse/ALPA-2709
function updateTimer() {
    time.seconds++;
    if (time.seconds === 60) {
        time.seconds = 0;
        time.minutes++;
    }

    if (time.minutes === 60) {
        time.minutes = 0;
        time.hours++;
    }

    sessionTime.value = `${ padTime(time.hours) }:${ padTime(time.minutes) }:${ padTime(time.seconds) }`;
}

const startSession = () => {
    if (sessionStarted.value || !isLogged.value) {
        return;
    }
    sessionStarted.value = true;
    if (sessionStorage.getItem(SESSION_STORAGE_KEY)) {
        const { seconds, minutes, hours } = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
        time.seconds = seconds;
        time.minutes = minutes;
        time.hours = hours;
    }
    timerId.value = setInterval(updateTimer, 1000);
    window.addEventListener("beforeunload", () => {
        clearInterval(timerId.value);
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(time));
    });
};


const clearSession = () => {
    sessionTime.value = null;
    time.seconds = 0;
    time.minutes = 0;
    time.hours = 0;
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(time));

    sessionStarted.value = false;
    clearInterval(timerId.value);
};

watch(isLogged, (value) => {
    if (value) {
        startSession();
    } else {
        clearSession();
    }
});

onMounted(() => {
    startSession();
});

onBeforeUnmount(() => {
    clearInterval(timerId.value);
});
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.session-time {
    min-width: 3rem;
}
</style>
