import type { UserGroup } from "@src/types/user";
import { useCommon } from "@store/common";
import { useUserStatuses } from "@store/user/userStatuses";
import { rocketWheelGiftTitle } from "@views/LootBoxPage/constants";
import { defineStore, storeToRefs } from "pinia";
import { v4 as uuid } from "uuid";
import { computed, ref } from "vue";

import type { IGift, IGiftFreeSpins } from "@api";
import { GiftState } from "@api";
import { IndexedDBEvents } from "@controllers/indexedDB/consts";
import { useNotificationDB } from "@controllers/indexedDB/notificationsDB";
import { currencyView } from "@helpers/currencyHelper";
import { dayMonthYear, formatDate } from "@helpers/formatDate";
import { giftTitleTranslate } from "@helpers/giftTitleTranslate";
import { isServer } from "@helpers/ssrHelpers";
import i18nInit from "@i18n";
import { routeNames } from "@router/routeNames";

import type {
    IConfigNotice,
    INoticePaymentsChanges,
    INoticePersonalNotifications,
    INoticePlayerJoinedLeaderboard,
    INoticePlayerKickedFromLeaderboard,
    INoticeTournamentStarted,
    INotification,
    TournamentNotice,
} from "@src/types/WSnotices";

import {
    EventsNotice,
    WSNotificationName,
} from "@src/types/WSnotices";

function parseNoticeText(data): { content: INotification["content"] | undefined } | { title: string } {
    try {
        const parseData = JSON.parse(data.title) as INotification["content"];
        return { content: parseData };
    } catch (err) {
        return { title: data.title };
    }
}

function prepareNoticeText(data: INoticePersonalNotifications): INotification {
    return {
        id: data.id,
        type: WSNotificationName.PERSONAL_NOTIFICATIONS,
        text: data.text,
        ...parseNoticeText(data),
    };
}

function prepareNoticeTournament(data: INoticeTournamentStarted): INotification {
    const { t } = i18nInit.instance;

    return {
        id: data.id,
        text: t(
            "NOTIFY_BAR.MESSAGES." + data.event?.toUpperCase(),
            { end_at: formatDate(data.end_at) },
        ),
        title: data.title,
        identifier: data.id,
        type: WSNotificationName.TOURNAMENT_NOTIFICATIONS,
    };
}

function preparePlayerJoined(data: INoticePlayerJoinedLeaderboard): INotification {
    const { t } = i18nInit.instance;

    return {
        id: data.id,
        text: t(
            "NOTIFY_BAR.MESSAGES." + data.event?.toUpperCase(),
            { award_place: data.award_place },
        ),
        title: data.title,
        identifier: data.id,
        type: WSNotificationName.TOURNAMENT_NOTIFICATIONS,
    };
}

function preparePlayerKicked(data: INoticePlayerKickedFromLeaderboard): INotification {
    const { t } = i18nInit.instance;

    return {
        id: data.id,
        text: t(
            "NOTIFY_BAR.MESSAGES." + data.event?.toUpperCase(),
            {
                previous_place: data.previous_place,
                end_at: formatDate(data.end_at),
            },
        ),
        title: data.title,
        identifier: data.id,
        type: WSNotificationName.TOURNAMENT_NOTIFICATIONS,
    };
}

function prepareNoticePaymentsChanges(data: INoticePaymentsChanges): INotification {
    const { t } = i18nInit.instance;
    const { getSubunitsToUnitsByCode: subUnits } = useCommon();

    const amount = currencyView(data.amount_cents, data.currency, null, subUnits(data.currency));

    return {
        id: data.id,
        text: t("NOTIFY_BAR.MESSAGES." + data.action?.toUpperCase(), { amount }),
        title: data.action,
        type: WSNotificationName.TOURNAMENT_NOTIFICATIONS,
    };
}

function prepareGiftsNotice(data: IGift): INotification {
    const { t } = i18nInit.instance;
    const { getSubunitsToUnitsByCode: subUnits } = useCommon();
    const amount = currencyView(data.amount_cents, data.currency, null, subUnits(data.currency));

    return {
        id: data.id,
        type: WSNotificationName.BONUSES_CHANGES,
        text: t("NOTIFY_BAR.MESSAGES.BONUS.ISSUED", {
            title: giftTitleTranslate(data.title),
            untilText: data.activatable_until ?
                t("NOTIFY_BAR.MESSAGES.BONUS.UNTIL_TEXT", {
                    activatable_until: dayMonthYear(data.activatable_until),
                }) :
                "",
            amount,
        }),
        content: {
            button: {
                title: t("NOTIFY_BAR.MESSAGES.BONUS.BUTTON"),
                toName: routeNames.profileGifts,
            },
        },
    };
}

function prepareGiftsFSNotice(data: IGiftFreeSpins): INotification {
    const { t } = i18nInit.instance;
    return {
        id: data.id,
        type: WSNotificationName.FREESPINS_CHANGES,
        text: t("NOTIFY_BAR.MESSAGES.FS.ISSUED", {
            title: giftTitleTranslate(data.title),
            freespins_total: data.freespins_total,
            activatable_until: dayMonthYear(data.activatable_until),
        }),
        content: {
            button: {
                title: t("NOTIFY_BAR.MESSAGES.FS.BUTTON"),
                toName: routeNames.profileGifts,
            },
        },
    };
}

function addTournamentNotification(notice: TournamentNotice) {
    if (prepareNoticeCollection[notice.event]) {
        return prepareNoticeCollection[notice.event](notice);
    }
    return;
}

const prepareNoticeCollection = {
    [EventsNotice.TOURNAMENT_STARTED]: prepareNoticeTournament,
    [EventsNotice.PLAYER_JOINED_LEADERBOARD]: preparePlayerJoined,
    [EventsNotice.PLAYER_KICKED_FROM_LEADERBOARD]: preparePlayerKicked,
};

const eventsHandlers = {
    [WSNotificationName.TOURNAMENT_NOTIFICATIONS]: addTournamentNotification,
    [WSNotificationName.PAYMENTS_CHANGES]: prepareNoticePaymentsChanges,
    [WSNotificationName.PERSONAL_NOTIFICATIONS]: prepareNoticeText,
    [WSNotificationName.BONUSES_CHANGES]: prepareGiftsNotice,
    [WSNotificationName.FREESPINS_CHANGES]: prepareGiftsFSNotice,
};

const { notificationDB } = useNotificationDB();

export const useNoticesStore = defineStore("notices", () => {
    const { getUserGroups } = storeToRefs(useUserStatuses());

    const headerNotices = ref<IConfigNotice[]>([]);
    const notifications = ref<INotification[]>([]);

    const enabledNotices = computed(() => {
        return getUserGroups.value.some((group: UserGroup) => {
            return [ 916, 21 ].includes(+group);
        });
    });

    if (!isServer) {
        notificationDB?.getAllData().then((data) => notifications.value = data || []);
        notificationDB?.eventTarget.addEventListener && notificationDB?.eventTarget.addEventListener(IndexedDBEvents.deleteData, () => {
            notificationDB?.getAllData().then((data) => notifications.value = data || []);
        });
    }

    function addHeaderNoticeConfig(noticeConfig): void {
        const newState = headerNotices.value.filter(({ id }) => {
            return id !== noticeConfig.id;
        });
        headerNotices.value = [ ...newState, noticeConfig ];
    }

    function deleteHeaderNotice(idNotice): void {
        const newState = headerNotices.value.filter(({ id }) => {
            return id !== idNotice;
        });
        headerNotices.value = [ ...newState ];
    }

    function addRealTimeNotification({ data }, type: WSNotificationName): void {
        if (eventsHandlers[type]) {
            const isRocketWheelNotification = data.title.includes(rocketWheelGiftTitle);
            if (isRocketWheelNotification) {
                return;
            }
            if (
                data.stage !== GiftState.issued &&
                [ WSNotificationName.BONUSES_CHANGES, WSNotificationName.FREESPINS_CHANGES ].includes(type)
            ) {

                return;
            }

            const dataWithId = {
                id: uuid(),
                ...data,
            };

            notifications.value = [ ...notifications.value, { ...dataWithId, type } ];
            notificationDB?.saveData(dataWithId.id, { ...data, type });
        }

    }

    const getAllNotifications = computed(() => {
        return notifications.value.map((notice: INotification) => {
            if (eventsHandlers[notice.type]) {
                return eventsHandlers[notice.type](notice);
            }
        }).filter((item) => item);
    });

    function deleteNotification(noticeId: string): void {
        notifications.value = notifications.value.filter(({ id }) => {
            return id !== noticeId;
        });
        notificationDB?.deleteData(noticeId);
    }

    function clearUserNotification() {
        notifications.value = [];
        notificationDB?.clearAllData();
    }

    return {
        enabledNotices,

        headerNotices,
        notifications,
        getAllNotifications,

        addHeaderNoticeConfig,
        deleteHeaderNotice,

        addRealTimeNotification,
        deleteNotification,
        clearUserNotification,
    };
});
