<template>
    <div class="articles-list--wrapper">
        <div class="articles-list--header d-flex justify-content--space-between">
            <div class="articles-list--title font__header">
                {{ title }}
            </div>
            <div v-if="showAllLink" class="show-all-link">
                <FeButton
                    :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                    @click="handleLinkClick(showAllLink)"
                >
                    {{ $t("ARTICLES.BUTTON") }}
                </FeButton>
            </div>
        </div>
        <div class="articles-list gap-m">
            <div
                v-for="article in shorthandArticles"
                :key="article.url"
                class="article"
                @click="() => handleLinkClick(article.url)">
                <router-link
                    v-slot="{ navigate }"
                    :to="article.url"
                    v-bind="$props"
                    custom>

                    <a
                        :href="article.url"
                        v-bind="$attrs"
                        class="article-header"
                        @click="navigate">
                        <p class="font__caption-1">{{ article.date }}</p>
                        <p class="article-title font__header">{{ article.title }}</p>
                    </a>
                    <FeImage
                        :src="article.img"
                        class="article-image"
                        :alt="article.imgAlt || 'Rocketplay Casino Blog'"/>
                </router-link>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { computed, withDefaults } from "vue";
import { useRouter } from "vue-router";
import FeImage from "../../ui/FeImage/FeImage.vue";

interface IArticles {
    date: string;
    title: string;
    url: string;
    img: string;
    imgAlt?: string;
}

interface IProps {
    title: string;
    articles: IArticles[];
    showAllLink: string;
    maxView?: number;
}

const props = withDefaults(defineProps<IProps>(), {
    maxView: 6,
});

const shorthandArticles = computed(() => props.articles.slice(0, props.maxView));

const $router = useRouter();

const handleLinkClick = (url: string): void => {
    $router.push(url);
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.articles-list {
    display: grid;

    grid-template-columns: auto;
    justify-items: center;
    @include media(S) {
        grid-template-columns: 1fr 1fr;
    }

    @include media(L) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &--header {
        margin: 1.5rem 0;
    }

    .article {
        background: var(--color-layer-alt-1);
        border-radius: var(--border-radius-m);
        padding: 1rem;
        cursor: pointer;

        .article-header {
            margin-bottom: 1rem;
            text-decoration: none;
            color: inherit;
            font-family: inherit;
            display: block;
        }
    }

    .article-image {
        aspect-ratio: auto 392 / 200;
    }

    .article-title {
        text-transform: none;
        color: var(--color-primary-1);
    }
}
</style>
