<template>
    <div
        id="sidebar"
        v-click-outside="closeSideBar"
        v-touch:swipe.stop="closeSideBar"
        :class="classes"
        class="sidebar"
        @click="closeSideBar"
    >
        <div class="sidebar__header">
            <div class="sidebar__header-title">
                {{ $t("SIDEBAR.HEADER_TITLE") }}
            </div>
            <div class="sidebar__header-close">
                <FeIcon :icon="FeIconConfig.icons.fill.close"/>
            </div>
        </div>
        <UserBalance
            v-if="isLogged && open"
            :is-dark="true"
            :show-user-balance-on-click="false"
            class="sidebar__user-balance"
            show-progress
        >
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                @click="openCashbox">
                {{ $t("HEADER.CASHBOX") }}
            </FeButtonNew>
            <FeButton v-else @click="openCashbox">
                {{ $t("HEADER.CASHBOX") }}
            </FeButton>
            <template #bottom-info>
                <div class="gap-s">
                    <CompPointBalance v-if="featureFlags.enableConpoints"/>
                    <LotteryTicketsBalance/>
                </div>
            </template>
        </UserBalance>
        <div class="sidebar__scroll">
            <div class="sidebar__menu" @click.stop>
                <FeAccordion v-if="isLogged && noticeNewCounter > 0">
                    <template #activator="{toggleHandler, showContent}">
                        <div
                            :class="{'sidebar__menu-item--active': showContent}"
                            class="sidebar__menu-item sidebar__menu-item--notice"
                        >
                            <SidebarMenuItem
                                :active="showContent"
                                :count="noticeNewCounter"
                                :has-child="noticeNewCounter > 0"
                                :icon="iconNotice"
                                :show-child="showContent"
                                title="Notifications"
                                @click="toggleHandler"
                            />
                        </div>
                    </template>
                </FeAccordion>
                <FeAccordion
                    v-for="item in menu.filter(i => !i.hide)"
                    :key="item.title"
                    :has-content="item.hasOwnProperty('children')"
                    class="sidebar__menu-item"
                >
                    <template #activator="{toggleHandler, showContent}">
                        <SidebarMenuItem
                            :active="showContent || isActiveBtn( item, 'menu' )"
                            :badge="item.badge"
                            :click-fn="item.clickFn"
                            :count="item.count"
                            :external-url="item.externalUrl"
                            :has-child="item.hasOwnProperty('children')"
                            :icon="item.icon"
                            :linkto="item.url"
                            :title="item.title"
                            @click="clickHandler(item, toggleHandler)"
                        />
                    </template>
                    <div
                        v-if="item.children"
                        class="sidebar__menu sidebar__submenu"
                    >
                        <div
                            v-for="child in item.children"
                            :key="child.title"
                            class="sidebar__menu-item list-complete-item"
                        >
                            <SidebarMenuItem
                                :active="isActiveBtn(child, 'submenu')"
                                :count="child.count"
                                :icon="item.isIcons ? child.image : child.icon"
                                :linkto="child.url"
                                :title="child.title"
                                @click="clickHandler(child)"
                            />
                        </div>
                    </div>
                </FeAccordion>
                <div class="sidebar__menu__bottom">
                    <hr class="sidebar__menu__separator">
                    <FeAccordion
                        v-for="item in bottomMenu.filter(i => !i.hide)"
                        :key="item.title"
                        :has-content="item.hasOwnProperty('children')"
                        class="sidebar__menu-item"
                    >
                        {{ item.title }}
                        <template #activator="{toggleHandler, showContent}">
                            <SidebarMenuItem
                                :active="showContent || isActiveBtn( item )"
                                :badge="item.badge"
                                :external-url="item.externalUrl"
                                :has-child="item.hasOwnProperty('children')"
                                :icon="item.icon"
                                :linkto="item.url"
                                :show-child="showContent"
                                :title="item.title"
                                @click="clickHandler(item, toggleHandler)"
                            />
                        </template>
                    </FeAccordion>
                </div>
                <hr class="sidebar__menu__separator">
                <div class="sidebar__lang gap-column-s">
                    <p class="sidebar__lang-text">
                        {{ $t("HEADER.LANGUAGE") }}
                    </p>
                    <LanguageForm/>
                </div>
            </div>
        </div>
        <div v-if="!isLogged" class="sidebar__bottom">
            <EnterBtns :show-bonus-banner="false"/>
        </div>
        <LocalTime v-if="isMobile" class="local-time"/>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useJackpots } from "@store/jackpots";
import { routeNames } from "@router/routeNames";

import FeButton from "@ui/FeButton/FeButton.vue";
import FeAccordion from "@ui/FeAccordion/FeAccordion.vue";
import EnterBtns from "@components/EnterBtns/EnterBtns.vue";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import UserBalance from "@components/UserBalance/UserBalance.vue";
import bodyDisableScroll from "@front/core/mixins/bodyDisableScroll.js";

import iconNotice from "@theme/images/icon-notice.svg";
import SidebarMenuItem from "@modules/SideBar/SidebarMenuItem.vue";
import iconClose from "@theme/images/icon-close.svg";

import CompPointBalance from "@modules/CompPoints/components/CompPointBalance.vue";
import LotteryTicketsBalance
    from "@modules/Lottery/LotteryPage/components/LotteryTicketsBalance/LotteryTicketsBalance.vue";
import { TOP_MENU_TYPE } from "@modules/StaticPage/constants.js";
import { STATUS_PROMO } from "@store/tournaments/constants.ts";
import LocalTime from "@components/LocalTime/LocalTime.vue";
import LanguageForm from "@modules/Profile/components/LanguageForm/LanguageForm.vue";
import featureFlags from "@theme/configs/featureFlags";
import { useClickOnSpecialSlotMenu } from "@src/helpers/specialLinkMenuHelper";
import { SPECIAL_GAME_LINK } from "@theme/configs/games";
import { getTitleFromIdentifier } from "@helpers/getTitleFromIdentifier";
import { BADGE_COLORS } from "@ui/FeNavBadge/badgeColorsConsts";
import { useLootboxesStore } from "@store/lootboxes";
import { useLotteriesStore } from "@store/lotteries";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { useCMS } from "@store/CMS";
import { useUserInfo } from "@store/user/userInfo";
import { useRootStore } from "@store/root";
import FeButtonNew from "@ui/New/FeButton.vue";


function fnForMenu(item, fn) {
    return fn(item.url) && item.code !== "games";
}

function fnForSubmenu(item, fn) {
    return item.slug && fn(item.slug) || item.code && fn(item.code);
}

const menuElements = {
    MENU: "menu",
    SUBMENU: "submenu",
};

const mapFn = {
    [menuElements.MENU]: fnForMenu,
    [menuElements.SUBMENU]: fnForSubmenu,
};

export default {
    name: "MobileSidebar",

    components: {
        FeButtonNew,
        SidebarMenuItem,
        FeButton,
        FeIcon,
        EnterBtns,
        UserBalance,
        FeAccordion,
        CompPointBalance,
        LotteryTicketsBalance,
        LocalTime,
        LanguageForm,
    },

    data() {
        return {
            FeIconConfig,
            open: false,
            iconNotice,
            iconClose,
            featureFlags,
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
            userNotice: "getNotice",
            userCurrency: "getUserCurrency",
        }),
        ...mapState(useTournamentsStore, {
            tournamentsList: "getTournamentsList",
            customTournamentsList: "getCustomTournamentsList",
        }),
        ...mapState(useLotteriesStore, {
            lotteriesList: "getLotteriesList",
        }),
        ...mapState(useJackpots, {
            activeJackpots: "jackpotsActiveList",
        }),
        ...mapState(useLootboxesStore, [ "isUserIncludeInLootbox" ]),
        ...mapState(useCMS, {
            pages: "getUnhiddenStaticPages",
        }),
        tournamentsCount() {
            return [
                ...this.tournamentsList,
                ...this.customTournamentsList,
                ...this.lotteriesList ]
                .filter(({ status }) => {
                    return status === STATUS_PROMO.ACTIVE;
                }).length;
        },
        itemsJackpots() {
            return this.activeJackpots.map((item) => {
                return {
                    title: getTitleFromIdentifier(item.identifier),
                    url: `/${item.identifier}`,
                    isLogin: false,
                    badge: {
                        background: BADGE_COLORS.ERROR,
                        color: BADGE_COLORS.WHITE,
                        text: this.$t("HEADER.BADGE.NEW"),
                    },
                    icon: FeIconConfig.icons.outline.mystic_jackpot,
                };
            });
        },
        menu() {
            const newMenu = [
                ...[ this.specialSlotLink ],
                {
                    title: this.$t("HEADER.TOURNAMENTS"),
                    code: "tournamentsList",
                    url: { name: routeNames.tournamentsAll },
                    icon: FeIconConfig.icons.outline.tournaments,
                    count: this.tournamentsCount,
                },
                ...(featureFlags.enableMysticJackpots && this.itemsJackpots || []),
                ...(this.isUserIncludeInLootbox ? [ {
                    title: this.$t("HEADER.ROCKET_WHEEL"),
                    code: "spin-wheel",
                    url: { name: routeNames.spinWheelPage },
                    isLogin: true,
                    icon: FeIconConfig.icons.outline.rocket_wheel,
                } ] : []),
                ...[ {
                    title: this.$t("HEADER.ACHIEVEMENTS"),
                    code: "achievements",
                    url: { name: routeNames.achievements },
                    icon: FeIconConfig.icons.outline.mission,
                } ],
                ...this.loggedTopMenuPages,
                {
                    url: { name: routeNames.vipClub },
                    title: this.$t("HEADER.ROCKETPLAY_VIP"),
                    code: routeNames.vipClub,
                    isLogin: false,
                    icon: FeIconConfig.icons.outline.vipclub,
                },
            ];
            return newMenu.filter((item) => {
                return this.isLogged || !this.isLogged && !item.isLogin;
            });
        },
        topMenuPages() {
            const filteredPages = this.pages.filter((page) => {
                return page.categories.includes(TOP_MENU_TYPE);
            });
            return filteredPages.map((page) => {
                return {
                    ...page,
                    code: page.id,
                    icon: FeIconConfig.icons.outline[page.id],
                };
            });
        },
        loggedTopMenuPages() {
            return this.topMenuPages.filter((item) => {
                return this.isLogged || !this.isLogged && !item.isLogin;
            });
        },
        bottomMenu() {
            const bottomMenu = [
                {
                    title: this.$t("COMMON.PROFILE"),
                    code: "users",
                    url: { name: routeNames.profile },
                    isLogin: true,
                    icon: FeIconConfig.icons.outline.profile,
                },
                {
                    title: this.$t("HEADER.SUPPORT"),
                    code: "chat",
                    isLogin: false,
                    icon: FeIconConfig.icons.outline.support,
                    click: () => {
                        this.$bus.$emit("chat.toggle");
                        this.$bus.$emit("sidebar.close");
                    },
                },
            ];
            return bottomMenu.filter((item) => {
                return this.isLogged || !this.isLogged && !item.isLogin;
            });
        },

        classes() {
            return {
                "sidebar--open": this.open,
            };
        },

        notice() {
            let notice = [ ...this.userNotice ];
            return notice.sort((current) => {
                return current.status !== "new" || current.next;
            });
        },

        noticeNewCounter() {
            return this.notice.filter((el) => {
                return el.status === "new";
            }).length;
        },

        specialSlotLink() {
            const { clickOnSpecialSlotMenu } = useClickOnSpecialSlotMenu();
            return {
                title: this.$t("HEADER.SPECIAL_GAME_NAME"),
                icon: FeIconConfig.icons.outline.rocket_x,
                url: SPECIAL_GAME_LINK,
                badge: {
                    background: BADGE_COLORS.RRIMARY,
                    color: BADGE_COLORS.BLACK,
                    text: this.$t("HEADER.BADGE.SPECIAL_GAME"),
                },
                clickFn: clickOnSpecialSlotMenu,
            };
        },
    },

    mounted() {
        this.$bus.$on("sidebar.open", () => {
            this.open = true;
            bodyDisableScroll(this.open);
        });

        this.$bus.$on("sidebar.close", () => {
            this.open = false;
            bodyDisableScroll(this.open);
        });
    },


    methods: {
        closeSideBar(direction) {
            if (typeof direction !== "string" && this.open || direction === "right") {
                this.$bus.$emit("sidebar.close");
                bodyDisableScroll(false);
            }
        },
        openCashbox() {
            this.$router.push({ name: routeNames.cashboxDashboard });
        },
        checkUrl(url) {
            const name = url?.name || url;
            if (name) {
                let rg = new RegExp(name);
                return rg.test(this.$route.path) || this.$route.name === name;
            }
            return false;
        },
        isActiveBtn(item, element = "") {
            return mapFn[element] ? mapFn[element](item, this.checkUrl) : this.checkUrl(item.url);
        },
        clickHandler(item, toggleFn = () => {
        }) {
            if (Object.prototype.hasOwnProperty.call(item, "children")) {
                toggleFn();
            } else if (typeof item.click === "function") {
                item.click();
            } else {
                this.closeSideBar();
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
