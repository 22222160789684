import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";

import { mockLevels } from "@modules/Quest/constants/robot-s/mock";

import robot from "@modules/Quest/images/robot-new.svg";
import questRobotSLogo from "./img/quest-robot-s--logo.png";
import questRobotSLogoX2 from "./img/quest-robot-s--logo@2x.png";
import questRobotSDesktop from "./img/quest-robot-s-desktop.jpg";
import questRobotSDesktopX2 from "./img/quest-robot-s-desktop@2x.jpg";
import questRobotSMobile from "./img/quest-robot-s-mob.jpg";
import questRobotSMobileX2 from "./img/quest-robot-s-mob@2x.jpg";

const config: IBaseQuestConfig = {
    mockLevels,
    imageConfig: {
        logo: {
            main: questRobotSLogo,
            retina: questRobotSLogoX2,
        },
        desktop: {
            main: questRobotSDesktop,
            retina: questRobotSDesktopX2,
        },
        mobile: {
            main: questRobotSMobile,
            retina: questRobotSMobileX2,
        },
        bookmarkIcon: robot
    }
};

export default config;
