import { computed, toRefs } from "vue";

import { MODAL_PROFILE_DATA } from "@config/modalsNames";
import { useCommon } from "@store/common";
import { useUserInfo } from "@store/user/userInfo";

import i18n from "@i18n";
import Modal from "@plugins/Modal";
import { EnumContextFields, EnumFormFields } from "@src/types/common";
import { defineAsyncComponent } from "vue";
import { storeToRefs } from "pinia";

const popupFormByContext = defineAsyncComponent(() => {
    return import( "@modules/Popups/PopupFormByContext/PopupFormByContext.vue");
});

export function useContextFieldData(context: EnumContextFields) {
    const { getUserInfo } = storeToRefs(useUserInfo());
    const { playerFieldsInfo } = storeToRefs(useCommon());

    const fieldsOfContext = playerFieldsInfo.value?.contexts[context] || [];
    const userFieldEmptyForContext = computed(() => {
        if (getUserInfo.value.id) {
            return fieldsOfContext.filter((field: EnumFormFields) => !getUserInfo.value[field]);
        }
        return [];
    });

    return {
        userFieldEmptyForContext,
        fieldsOfContext,
    };
}

export function useShowPersonalInfoPopup(context: EnumContextFields) {
    const { userFieldEmptyForContext } = useContextFieldData(context);

    if (!userFieldEmptyForContext.value.length) {
        return false;
    }

    const { t } = i18n.instance || i18n.init();

    Modal.show({
        name: MODAL_PROFILE_DATA,
        component: popupFormByContext,
        mobileFriendly: true,
        blockCloseOverlay: true,
        props: {
            title: t("GAME_ITEM.POPUP_EDIT_PROFILE"),
            showFields: userFieldEmptyForContext.value,
            context,
        },
    });

    return true;
}
