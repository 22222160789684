import { useCMSFetchService } from "@store/CMS";

import { filterStaticPages } from "@helpers/staticPages";
import StaticPage from "@modules/StaticPage/StaticPage.vue";
import { routeNames } from "@router/routeNames";

interface IStaticPage {
    categories: string[];
    id: string;
    path: string;
    name: string;
}

export function addStaticPageToRouter(router) {
    const { loadStaticPages } = useCMSFetchService();

    return loadStaticPages({ reload: true }).then((allPages: IStaticPage[]) => {
        filterStaticPages(allPages)
            .forEach((page) => {
                router.addRoute(routeNames.mainLayout,
                    {
                        path: page.path,
                        name: page.id,
                        component: StaticPage,
                        props: () => {
                            return { ...page };
                        },
                    },
                );
            });
    });
}
