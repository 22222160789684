<template>
    <div
        v-if="showCookiesAccept && !isHidePopupOnPage"
        key="cookies"
        class="cookies-accepted__wrapper"
        :data-test="dataTestAttrs.cookiesAccepted.cookiesAcceptedPopup"
    >
        <InlineNotification>
            <i18n-t :keypath="keyForText" tag="span">
                <template #link>
                    <router-link :to="routesStatic.cookiePolicy">
                        {{ $t("COOKIE_ACCEPT.LINK_TEXT") }}
                    </router-link>
                </template>
            </i18n-t>

            <template #actions>
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :theme="themes.PrimarySmall"
                    :data-test="dataTestAttrs.cookiesAccepted.cookiesAcceptButton"
                    full-width-mobile
                    @click="hideCookie"
                >
                    {{ $t("COOKIE_ACCEPT.BUTTON_ACCEPT") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="FeButtonConfig.themes.PrimaryExtraSmall"
                    :data-test="dataTestAttrs.cookiesAccepted.cookiesAcceptButton"
                    @click="hideCookie"
                >
                    {{ $t("COOKIE_ACCEPT.BUTTON_ACCEPT") }}
                </FeButton>
            </template>
        </InlineNotification>
    </div>
</template>

<script>
import { mapState } from "pinia";
import cookie from "@front/core/controllers/CookieController.js";

import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import dataTestAttrs from "./dataTestAttrs";
import InlineNotification from "@modules/InlineNotification/InlineNotification.vue";
import { routesStatic } from "@router/routesStatic";
import { useMultilang } from "@store/multilang";
import { routeNames } from "@router/routeNames";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

const cookiesKey = "cookies_accepted";
const routsHidePopup = [
    routeNames.singUpPage,
];
export default {
    name: "CookiesAccepted",

    components: {
        FeButtonNew,
        FeButton,
        InlineNotification,
    },

    data() {
        return {
            FeButtonConfig,
            showCookiesAccept: false,
            dataTestAttrs,
            routesStatic,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useMultilang, {
            userGeoIp: "getUserGeo",
        }),
        keyForText() {
            if (this.userGeoIp === "AU") {
                return "COOKIE_ACCEPT.TEXT_AU.TEXT";
            }
            return "COOKIE_ACCEPT.TEXT_MAIN";
        },
        isHidePopupOnPage() {
            return routsHidePopup.includes(this.$route.name);
        },
    },
    created() {
        setTimeout(() => {
            this.showCookiesAccept = !cookie.get(cookiesKey);
        }, 0);
    },

    methods: {
        hideCookie() {
            cookie.set(cookiesKey, true, { expires: 31536e3, path: "/" });
            this.showCookiesAccept = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.cookies-accepted {
    width: 100%;


    a {
        @include link-secondary($font-weight--bold);
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .5rem 1rem;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
    }


    :deep(.inline-notification) {
        border-radius: var(--border-radius-xs);

        @include media(M) {
            border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
        }

        .inline-notification__container {
            grid-template-columns: 1fr;
            width: 100%;

            @include media(M) {
                margin: 0 auto;
                width: auto;
            }
        }
    }

    &__wrapper {
        padding: 1rem;

        @include media(M) {
            padding: 0;
        }
    }
}

</style>
