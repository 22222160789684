export default {
    AU: "AUD",
    CA: "CAD",
    NZ: "NZD",
    US: "USD",
    IE: "EUR",
    DE: "EUR",
    JP: "JPY",
    NO: "NOK",
    BR: "BRL",
    AT: "EUR",
    CH: "EUR",
};
