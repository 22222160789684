import { UserAccountLicense } from "@api/DTO";
import { useMultilang } from "@store/multilang";
import { useUserInfo } from "@store/user/userInfo";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const ALLOWED_DOMAINS = [ "rocketplay.com" ];

export function useLicense() {
    const isMainDomain = typeof window !== "undefined"
        ? ALLOWED_DOMAINS.includes(window.location.hostname)
        : false;

    const { getUserInfo } = storeToRefs(useUserInfo());

    const { getUserGeo: userGeoIp } = storeToRefs(useMultilang());

    const userLicense = computed<UserAccountLicense>(() => {
        if (!isMainDomain) {
            return UserAccountLicense.CW;
        }

        return getUserInfo.value.license_name as UserAccountLicense;
    });
    const usedLicense = computed(() => {
        if (!isMainDomain) {
            return UserAccountLicense.CW;
        }
        return userLicense.value || userGeoIp.value;
    });

    const isLicenseEE = computed(() => {
        if (!isMainDomain) {
            return false;
        }
        return usedLicense.value === UserAccountLicense.EE;
    });

    return {
        userGeoIp: userGeoIp.value,
        userLicense,

        usedLicense,
        isLicenseEE,
    };
};
