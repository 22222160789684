import { EventBus as bus } from "@bus";
import { MODAL_CASHBOX_LIST } from "@config/modalsNames";
import Modal from "@plugins/Modal";
import { routeNames } from "@router/routeNames";
import { useContextStore } from "@store/context";
import { useUIStore } from "@store/ui";
import { useUserInfo } from "@store/user/userInfo";
import { storeToRefs } from "pinia";
import { defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";

const WebviewIOSEscape = defineAsyncComponent(() => {
    return import( "@modules/Popups/WebviewIOSEscape/WebviewIOSEscape.vue");
});

const LOGIN_TIMEOUT_TO_SHOW = 1000 * 60 * 5; // 5 minutes
const LOGIN_TIMEOUT_TO_REPEAT = 1000 * 60; // 1 minute

const LOCAL_STORAGE_SHOWED_KEY = "ios_escape_showed";

function getIsShowed(): boolean {
    const isShowed = sessionStorage.getItem(LOCAL_STORAGE_SHOWED_KEY);
    return Boolean(isShowed);
}

function setIsShowed() {
    sessionStorage.setItem(LOCAL_STORAGE_SHOWED_KEY, "1");
}

const LOCAL_STORAGE_LOGIN_TIMER_START = "ios_escape_timer";

function getTimerStart(): number {
    const saved = sessionStorage.getItem(LOCAL_STORAGE_LOGIN_TIMER_START);
    if (!saved) {
        const time = Date.now();
        sessionStorage.setItem(LOCAL_STORAGE_LOGIN_TIMER_START, String(time));
        return time;
    }
    return Number(saved);
}

async function showEscapePopup() {
    setIsShowed();
    Modal.show({
        name: "ios-webview-exit",
        component: WebviewIOSEscape,
        new: true,
        bottomSide: true,
    });
}

function loggedShowStrategy(router: VueRouter) {
    const start = getTimerStart();

    let showed = false;

    function showPopupCondition() {
        const { modals } = storeToRefs(useUIStore());

        const cashboxOpen = modals.value.some((modal) => MODAL_CASHBOX_LIST.includes(modal.name));
        if (!cashboxOpen && router.currentRoute.name !== routeNames.gameItem) {
            if (!showed) {
                showEscapePopup();
                showed = true;
            }
        } else {
            // repeat try to show escape popup if open condition failed
            setTimeout(showPopupCondition, LOGIN_TIMEOUT_TO_REPEAT);
        }
    }

    // show escape popup after specific time after registration (or if user reenter in browser)
    setTimeout(showPopupCondition, Math.max(Date.now() + LOGIN_TIMEOUT_TO_SHOW - start, 0));

    // in case user logout show escape popup immediately
    bus.$once("user.logout", () => {
        if (!showed) {
            showEscapePopup();
            showed = true;
        }
    });
}

export async function useEscapePopupStrategy() {
    const router = useRouter();
    const contextStore = useContextStore();
    const isShowed = getIsShowed();
    const { getIsLogged } = storeToRefs(useUserInfo());

    const context = await contextStore.getContext();

    // escape popup can be displayed only once for IOS in meta webview
    if (isShowed || !context.isMetaWebview || !context.isIOS) {
        return;
    }

    if (getIsLogged.value) {
        loggedShowStrategy(router);
    } else {
        await showEscapePopup();
    }
}
