<template>
    <FeCard
        :theme="cardConfig.themes.Dark"
        class="payment-item text--align-center gap-s padding-s">
        <PaymentsLogo :payment="methodData.id" class="payment-item__logo"/>


        <div class="payment-item__info gap-row-s">
            <div
                v-if="methodData.type"
                class="payment-item__info__row"
            >
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.TYPE") }}</span>
                <strong>{{ methodData.type }}</strong>
            </div>

            <div v-if="withdrawType" class="payment-item__info__row">
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.FEE") }}</span>
                <strong>{{ methodData.fee }}</strong>
            </div>

            <div v-if="methodData.processingTime" class="payment-item__info__row">
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.PROCESS_TIME") }}</span>
                <strong>{{ methodData.processingTime }}</strong>
            </div>

            <div
                v-if="methodData[type] && methodData[type].min"
                class="payment-item__info__row">
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.MIN_TRANSACTION") }}</span>
                <i18n-t
                    v-if="typeof methodData[type].min !== 'number'"
                    keypath="PAYMENTS_PAGE.METHOD_ITEM.MIN_TRANSACTION_DATA"
                    tag="strong"
                >
                    <template #count>
                        {{ $t(methodData[type].min) }}
                    </template>
                    <template #currency>
                        {{ methodData.currency || $t("CURRENCY") }}
                    </template>
                </i18n-t>
                <strong v-else>
                    {{
                        $t("PAYMENTS_PAGE.METHOD_ITEM.MIN_TRANSACTION_DATA",
                           {
                               count: methodData[type].min,
                               currency: methodData.currency || $t("CURRENCY"),
                           },
                        )
                    }}
                </strong>
            </div>

            <div
                v-if="methodData[type] && methodData[type].max"
                class="payment-item__info__row">
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.MAX_TRANSACTION") }}</span>
                <i18n-t
                    v-if="true"
                    keypath="PAYMENTS_PAGE.METHOD_ITEM.MAX_TRANSACTION_DATA"
                    tag="strong"
                >
                    <template #count>
                        {{ $t(methodData[type].max) }}
                    </template>
                    <template #currency>
                        {{ methodData.currency || $t("CURRENCY") }}
                    </template>
                </i18n-t>
                <strong v-else>
                    {{
                        $t("PAYMENTS_PAGE.METHOD_ITEM.MAX_TRANSACTION_DATA",
                           {
                               count: methodData[type].max,
                               currency: methodData.currency,
                           },
                        )
                    }}
                </strong>
            </div>

            <div
                v-if="methodData.currencyMethod"
                class="payment-item__info__row"
            >
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.CURRENCY") }}</span>
                <strong>{{ methodData.currencyMethod }}</strong>
            </div>
            <div
                v-if="methodData.minDep"
                class="payment-item__info__row"
            >
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.MIN_DEP") }}</span>
                <strong>{{ $t(methodData.minDep) }}</strong>
            </div>
            <div
                v-if="methodData.network"
                class="payment-item__info__row"
            >
                <span>{{ $t("PAYMENTS_PAGE.METHOD_ITEM.NETWORK") }}</span>
                <strong>{{ methodData.network }}</strong>
            </div>
        </div>

        <div
            v-if="getIsLogged"
            class="payment-item__action">
            <template v-if="featureFlags.enableNewUIComponents">
                <FeButtonNew
                    v-if="withdrawType"
                    @click="handleClickWithdrawButton"
                >
                    {{ $t("PAYMENTS_PAGE.METHOD_ITEM.WITHDRAW_BUTTON") }}
                </FeButtonNew>
                <FeButtonNew
                    v-else
                    @click="handleClickDepositButton"
                >
                    {{ $t("PAYMENTS_PAGE.METHOD_ITEM.DEPOSIT_BUTTON") }}
                </FeButtonNew>
            </template>
            <template v-else>
                <FeButton
                    v-if="withdrawType"
                    @click="handleClickWithdrawButton"
                >
                    {{ $t("PAYMENTS_PAGE.METHOD_ITEM.WITHDRAW_BUTTON") }}
                </FeButton>
                <FeButton
                    v-else
                    @click="handleClickDepositButton"
                >
                    {{ $t("PAYMENTS_PAGE.METHOD_ITEM.DEPOSIT_BUTTON") }}
                </FeButton>
            </template>
        </div>
        <template v-else>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                @click="handleClickSingUp">
                {{ $t("COMMON.REGISTRATION") }}
            </FeButtonNew>
            <FeButton
                v-else
                @click="handleClickSingUp">
                {{ $t("COMMON.REGISTRATION") }}
            </FeButton>
        </template>
    </FeCard>
</template>

<script setup>
import { routeNames } from "@router/routeNames";
import { WITHDRAWAL_METHOD } from "../paymentIptionsData";
import I18nInit from "@i18n";
import FeCard, * as cardConfig from "@ui/FeCard/FeCard.vue";
import FeButton from "@ui/FeButton/FeButton.vue";
import PaymentsLogo from "@modules/Payments/components/PaymentsLogo/PaymentsLogo.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";

const props = defineProps({
    methodData: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
    },
});

const { t: $t } = I18nInit.instance;
const { getIsLogged } = storeToRefs(useUserInfo());
const $router = useRouter();
const $gtm = useGtm();

const withdrawType = computed(() => {
    return props.type === WITHDRAWAL_METHOD;
});

function handleClickSingUp() {
    $router.push({ name: routeNames.registration });
    $gtm.customTrackEvent({
        ...$gtm.REGISTRATION_EVENTS.CLICK,
        location: "payments",
    });
}

function handleClickDepositButton() {
    $router.push({ name: routeNames.cashboxDeposit });
    $gtm.customTrackEvent({
        ...$gtm.CASHBOX_EVENTS.DEPOSIT.NEW.CLICK_OPEN_DEPOSIT_MAIN,
        location: "PaymentsMethodItem",
    });
}

function handleClickWithdrawButton() {
    $router.push({ name: routeNames.cashboxWithdraw, params: { action: "withdraw" } });
    $gtm.customTrackEvent({
        ...$gtm.CASHBOX_EVENTS.WITHDRAW.NEW.CLICK_BUTTON_OPEN_POPUP,
        location: "PaymentsMethodItem",
    });
}

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.payment-item {
    max-width: 100%;
    align-content: space-between;

    &__info {
        &__row {
            @include font-size--Caption2;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:first-child {
                @include font-size--Caption2($font-weight--normal);
                color: var(--color-text-body);
            }
        }
    }

    &__action {
        display: flex;
        justify-content: center;
    }

    &__logo {
        margin: 0 auto;
    }
}
</style>
