<template>
    <div
        v-if="userComPointBalance"
        class="comp-points-balance"
        @click="handleClickOpenCashbox"
    >
        <div class="gap-column-xs align-items-center">
            <span class="comp-points-balance__title">{{ $t("COMP_PINTS.COINS_BALANCE") }}</span>
            <Tooltip class="comp-points-balance__tooltip">
                <template #activator>
                    <div @click.stop>
                        <FeIcon :icon="FeIconConfig.icons.fill.info1" class="comp-points-balance__icon-info"/>
                    </div>
                </template>
                <span>{{ $t("COMP_PINTS.INFO_TEXT") }}</span>
            </Tooltip>
        </div>
        <div class="gap-column-xs align-items-center">
            <FeImage
                :src="iconCoin"
                :src-retina="iconCoinX2"
                class="comp-points-balance__icon"
            />
            <div class="comp-points-balance__count">
                {{ userComPointBalance }}
            </div>
        </div>
    </div>
</template>

<script>
import FeImage from "@ui/FeImage/FeImage.vue";
import iconCoin from "@modules/CompPoints/images/icon-coin.png";
import iconCoinX2 from "@modules/CompPoints/images/icon-coin@2.png";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import Tooltip from "@components/Tooltip/Tooltip.vue";
import { openExchangeCalculator } from "@helpers/helperCompointExOpen";
import { mapState } from "pinia";
import { useCompPointsStore } from "@store/compPoints/compPointsStore";

export default {
    name: "CompPointBalance",


    components: {
        Tooltip,
        FeIcon,
        FeImage,
    },

    data() {
        return {
            FeIconConfig,
            iconCoin,
            iconCoinX2,
        };
    },

    computed: {
        ...mapState(useCompPointsStore, {
            userComPointBalance: "getUserCompPoints",
        }),
        handleClickOpenCashbox() {
            return openExchangeCalculator.bind({}, this.$store);
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.comp-points-balance {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
        width: 1rem;
        height: 1rem;
    }

    &__title {
        @include font-size--Caption2($weight: $font-weight--normal);
        color: var(--color-text-body);
    }

    &__count {
        @include font-size--Caption1;
    }

    &__tooltip {
        position: relative;
        height: 100%;
    }

    &__icon-info {
        width: 1rem;
        height: 1rem;
        display: flex;
    }
}
</style>
