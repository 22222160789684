<template>
    <div class="gap-row-s">
        <div
            v-for="jackpotData in jackpotsActive"
            :key="jackpotData.id"
            class="jackpot-slider gap-row-s padding-s"
        >
            <div class="jackpot-slider__header">
                <div>
                    <p
                        v-t="'JACKPOTS.SLIDER.TITLE'"
                        class="jackpot-slider__title h3"
                    />
                    <p class="jackpot-slider__prize-pool h5">
                        {{ prizePool(jackpotData) }}
                    </p>
                </div>

                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    class="jackpot-slider__btn"
                    @click="goToGames(jackpotData)"

                >
                    {{ $t("JACKPOTS.SLIDER.ACTION_BUTTON") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    class="jackpot-slider__btn"
                    @click="goToGames(jackpotData)"

                >
                    {{ $t("JACKPOTS.SLIDER.ACTION_BUTTON") }}
                </FeButton>
            </div>

            <div class="jackpot-slider__sliders-wrap">
                <CarouselScroll
                    :show-navigation="false"
                    class="bonus-page__carousel"
                >
                    <JackpotSliderItem
                        v-for="level in jackpotData.levels"
                        :key="level.id"
                        :jackpot-currency="jackpotData.currency"
                        :jackpot-identifier="jackpotData.identifier"
                        :level-data="level"
                    />
                </CarouselScroll>
            </div>

            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                class="jackpot-slider__btn"
                full-width-mobile
                @click="goToGames(jackpotData)"
            >
                {{ $t("JACKPOTS.SLIDER.ACTION_BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                class="jackpot-slider__btn"
                full-width-mobile
                @click="goToGames(jackpotData)"
            >
                {{ $t("JACKPOTS.SLIDER.ACTION_BUTTON") }}
            </FeButton>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useJackpots } from "@store/jackpots";
import { jackpotPrizePool } from "@src/helpers/jackpotsHelpers";

import FeButton from "@ui/FeButton/FeButton.vue";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import { routeNames } from "@router/routeNames";
import JackpotSliderItem from "@components/JackpotsCustom/JackpotSliderItem.vue";
import { useCommon } from "@store/common";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";

const jackpotsStore = useJackpots();
const jackpotsActive = computed(() => {
    return jackpotsStore.jackpotsActiveList;
});

const $router = useRouter();
const { getSubunitsToUnitsByCode } = useCommon();

function prizePool(jackpotData) {
    return jackpotPrizePool(jackpotData, getSubunitsToUnitsByCode);
}

function goToGames(jackpotData) {
    $router.push({
        name: routeNames.categoryGames,
        params: { slug: jackpotData.identifier },
    });
}
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.jackpot-slider {
    $three-slide-column: calc(100% / 3 - 1rem);

    grid-template-columns: minmax(0, 1fr);
    --carousel-item-width: 15rem;

    @include media(M) {
        --carousel-item-width: #{$three-slide-column};
    }

    @include media(XM) {
        --carousel-item-width: 15rem;
    }

    @include media(L) {
        --carousel-item-width: #{$three-slide-column};
    }

    background: var(--color-secondary-gradient);
    border-radius: var(--border-radius-s);

    &__header {
        text-align: center;

        @include media(M) {
            text-align: start;
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-auto-flow: column;
        }

        .jackpot-slider__btn {
            display: none;

            @include media(M) {
                display: block;
            }
        }
    }

    &__title {
        @include font-size--Header3;
        margin-bottom: .5rem;
        color: var(--color-text-alt);
    }

    &__prize-pool {
        @include font-size--Header1($font-weight--black);
        color: var(--color-primary-1);
    }

    & > .jackpot-slider__btn {
        @include media(M) {
            display: none;
        }
    }
}

</style>
