import image1 from "@theme/images/BrandImages/Levels/level_1.png";
import image2 from "@theme/images/BrandImages/Levels/level_2.png";
import image3 from "@theme/images/BrandImages/Levels/level_3.png";
import image4 from "@theme/images/BrandImages/Levels/level_4.png";
import image5 from "@theme/images/BrandImages/Levels/level_5.png";
import image6 from "@theme/images/BrandImages/Levels/level_6.png";
import image7 from "@theme/images/BrandImages/Levels/level_7.png";
import image8 from "@theme/images/BrandImages/Levels/level_8.png";
import image9 from "@theme/images/BrandImages/Levels/level_9.png";
import image10 from "@theme/images/BrandImages/Levels/level_10.png";
import image11 from "@theme/images/BrandImages/Levels/level_11.png";
import image12 from "@theme/images/BrandImages/Levels/level_12.png";

export const LEVELS = {
    level_1: {
        gift_descriptions: [],
        image: image1,
    },
    level_2: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_2.points" },
            { type: "fs", name: "LEVELS.level_2.fs" },
        ],
        image: image2,
    },
    level_3: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_3.points" },
            { type: "cashback", name: "LEVELS.level_3.cashback" },
            { type: "fs", name: "LEVELS.level_3.fs" },
        ],
        image: image3,
    },
    level_4: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_4.points" },
            { type: "cashback", name: "LEVELS.level_4.cashback" },
            { type: "fs", name: "LEVELS.level_4.fs" },
        ],
        image: image4,
    },
    level_5: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_5.points" },
            { type: "cashback", name: "LEVELS.level_5.cashback" },
            { type: "fs", name: "LEVELS.level_5.fs" },
        ],
        image: image5,
    },
    level_6: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_6.points" },
            { type: "cashback", name: "LEVELS.level_6.cashback" },
            { type: "fs", name: "LEVELS.level_6.fs" },
        ],
        image: image6,
    },
    level_7: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_7.points" },
            { type: "cashback", name: "LEVELS.level_7.cashback" },
            { type: "fs", name: "LEVELS.level_7.fs" },
        ],
        image: image7,
    },
    level_8: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_8.points" },
            { type: "cashback", name: "LEVELS.level_8.cashback" },
            { type: "bonus", name: "LEVELS.level_8.fs" },
        ],
        image: image8,
    },
    level_9: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_9.points" },
            { type: "cashback", name: "LEVELS.level_9.cashback" },
            { type: "bonus", name: "LEVELS.level_9.fs" },
        ],
        image: image9,
    },
    level_10: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_10.points" },
            { type: "cashback", name: "LEVELS.level_10.cashback" },
            { type: "bonus", name: "LEVELS.level_10.fs" },
        ],
        image: image10,
    },
    level_11: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_11.points" },
            { type: "cashback", name: "LEVELS.level_11.cashback" },
            { type: "bonus", name: "LEVELS.level_11.fs" },
        ],
        image: image11,
    },
    level_12: {
        gift_descriptions: [
            { type: "points", name: "LEVELS.level_12.points" },
            { type: "cashback", name: "LEVELS.level_12.cashback" },
            { type: "bonus", name: "LEVELS.level_12.fs" },
        ],
        image: image12,
    },
};
