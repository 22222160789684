<template>
    <div v-if="enabledNotices" class="notify-button">
        <div v-if="countMessages" class="notify-button__counter">
            {{ countMessages }}
        </div>
        <FeIcon
            v-if="countMessages"
            :icon="icons.outline.notificationsOn"
            class="notify-button__icon"

        />
        <FeIcon
            v-else
            :icon="icons.outline.notificationsOff"
            class="notify-button__icon"
        />
    </div>
</template>

<script lang="ts">
export default { name: "NotifyBarButton" };
</script>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useNoticesStore } from "@store/notices";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";

const { getAllNotifications, enabledNotices } = storeToRefs(useNoticesStore());

const countMessages = computed(() => {
    const countMess = getAllNotifications.value.length;
    return countMess < 9 ? countMess : "9+";
});

</script>

<style scoped lang="scss">
@import "~@theme/styles";

.notify-button {
    padding: .5rem;

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    &__counter {
        width: 1rem;
        height: 1rem;
        border-radius: 5rem;
        border: 0;

        @include font-size--SmallCaption($font-weight--bold);
        background-color: var(--color-primary-1);
        color: var(--color-layer-body);
        text-align: center;

        position: absolute;
        right: -0.5rem;
        top: -0.25rem;
    }
}
</style>
