import { REFERRER, STAG_PARTNER_KEY, DEFAULT_STAGS_COUNTRY_REFER } from "@theme/configs/stagConsts";
import type { IStagByReferName } from "@src/types/configs";

import { storeToRefs } from "pinia";

import { isServer } from "@helpers/ssrHelpers";
import { referrerHelper } from "@helpers/referrerHelper";
import log from "@controllers/Logger";
import cookie from "@front/core/controllers/CookieController.js";
import { useCommon } from "@store/common";
import { useMultilang } from "@store/multilang";

interface IGetStagParams {
    referrer?: string;
    stagsByReferName: IStagByReferName;
    path: string;
    country: string;
}

interface IStagInfo {
    stagId: string;
    stagVisit: string;
}

function getStagByReferrerName({ referrer, stagsByReferName, path, country } = {} as IGetStagParams): string {
    if (referrer) {
        const referSearchEnginesMatch = Object.values(REFERRER).find((refItem) => referrer.includes(refItem));

        if (!referSearchEnginesMatch) {
            return "";
        }

        if (!stagsByReferName) {
            stagsByReferName = {
                pages: null,
                countries: DEFAULT_STAGS_COUNTRY_REFER,
            };
        }

        const stagReferPathValue = stagsByReferName?.pages?.[referSearchEnginesMatch]?.[path];
        const stagReferGeoValue = stagsByReferName?.countries?.[country]?.[referSearchEnginesMatch];
        const stagReferOthersGeoValue = stagsByReferName?.countries?.others?.[referSearchEnginesMatch];

        return stagReferPathValue || stagReferGeoValue || stagReferOthersGeoValue || "";
    }

    return "";
}

function setStag(stag: string): void {
    try {
        cookie.set(STAG_PARTNER_KEY, stag, { expires: 30 * 86400, path: "/" });
    } catch (error) {
        log.error("STAG_ERROR_SET_COOKIE", error);
    }
}

function getStag(): string | undefined {
    return cookie.get(STAG_PARTNER_KEY);
}

function getStagInfo(): IStagInfo | null {
    const stag = getStag();

    if (stag) {
        const [ stagId, stagVisit ] = stag.split("_");
        return { stagId, stagVisit };
    }

    return null;
}


function sendLogUserEmptyStag(referrer: string): void {
    log.error("STAG_ERROR_EMPTY", `Referrer: ${referrer}`);
}

async function init(): Promise<void> {
    if (isServer) {
        return;
    }

    if (getStag()) {
        return;
    }

    const referrer = referrerHelper();

    const url = new URL(window.location.href);
    const path = url.pathname;

    const queryParams = new URLSearchParams(url.search);
    const stagQuery = queryParams.get("stag");

    if (stagQuery) {
        setStag(stagQuery);
    } else {
        const { getUserGeo } = storeToRefs(useMultilang());
        const loadStagByReferName = await useCommon().loadStagByReferName();

        const stagReferrer = getStagByReferrerName({
            referrer,
            path,
            stagsByReferName: loadStagByReferName!,
            country: getUserGeo.value,
        });

        if (stagReferrer) {
            setStag(stagReferrer);
        }
    }

    if (referrer && !getStag()) {
        sendLogUserEmptyStag(referrer);
    }
}

export default {
    init,
    getStag,
    getStagInfo,
    getStagByReferrerName,
};
