<template>
    <div class="jackpot-slider-item padding-s">
        <strong class="jackpot-slider-item__name text--align-center">
            {{ levelData.name }}
        </strong>
        <FeImage
            class="jackpot-slider-item__img"
            :src="iconLevel.img"
            :src-retina="iconLevel.imgRetina"
        />
        <div class="jackpot-slider-item__prize text--align-center">
            {{ prize }}
        </div>

        <div class="jackpot-slider-item__conditions">
            <span class="gap-column-xs justify-space-between">
                <span v-t="'JACKPOTS.SLIDER.LEVELS.CONDITIONALS.MIN_BET'"/>
                <strong v-t="`JACKPOTS.SLIDER.LEVELS.${jackpotIdentifier}.${levelData.identifier}.MIN_BET`"/>
            </span>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { currencyView } from "@helpers/currencyHelper";

import FeImage from "@src/ui/FeImage/FeImage.vue";
import { mapLevelIconLarge } from "@components/JackpotsCustom/jackpotsConfig";
import { useCommon } from "@store/common";

const props = defineProps({
    levelData: Object,
    jackpotCurrency: String,
    jackpotIdentifier: String,
});


const iconLevel = computed(() => {
    return mapLevelIconLarge[props.levelData.identifier];
});

const { getSubunitsToUnitsByCode } = useCommon();

const prize = computed(() => {
    return currencyView(
        props.levelData.amount_cents,
        props.jackpotCurrency,
        null,
        getSubunitsToUnitsByCode(props.jackpotCurrency),
    );
});

</script>
<style scoped lang="scss">
@import "~@theme/styles";

.jackpot-slider-item {
    background: var(--color-tertiary-6);
    border-radius: var(--border-radius-s);
    color: var(--color-text-body);

    display: grid;
    grid-auto-columns: 100%;
    row-gap: 1rem;
    justify-items: center;

    &__img {
        width: 6rem;
        height: 6rem;
    }

    &__prize {
        @include font-size--Header2;
        color: var(--color-primary-1);
    }

    &__conditions {
        width: 100%;
        @include font-size--Caption2($font-weight--normal);
        justify-content: space-between;

        strong {
            color: var(--color-text-alt);
        }
    }
}

</style>
