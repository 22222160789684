<template>
    <div class="trust-block gap-row-s">
        <p v-t="'FOOTER.AWARDS'" class="trust-block__title text--align-center h4"/>
        <div class="trust-block__list">
            <TrustBlockIcon
                v-for="item in config.awardsNominations"
                :key="item.icon"
                :item="item"
            />
        </div>
        <p v-t="'FOOTER.OVERVIEW_CERTIFICATES'" class="trust-block__title text--align-center h4"/>
        <CarouselScroll class="trust-block__carousel">
            <TrustBlockIcon
                v-for="item in config.trust"
                :key="item.icon"
                :item="item"
            />
        </CarouselScroll>
        <hr>
        <div class="trust-block__list">
            <TrustBlockIcon
                v-for="item in licensesIcons"
                :key="item.icon"
                :item="item"
            />
            <FooterDMCA/>
        </div>
    </div>
</template>

<script setup>
import { computed, toRefs } from "vue";

import FooterDMCA from "@modules/Footer/components/FooterDMCA.vue";
import config from "@theme/configs/config";
import CarouselScroll from "@src/ui/CarouselScroll/CarouselScroll.vue";
import TrustBlockIcon from "@modules/Footer/components/TrustBlock/TrustBlockIcon.vue";
import icon18 from "@modules/Footer/components/TrustBlock/img/icon-18.svg";
import icon21 from "@modules/Footer/components/TrustBlock/img/icon-21.svg";
import { useLicense } from "@helpers/license";
import gamblingHelpOnline from "./img/gambling-help-online.svg";
import { isServer } from "@helpers/ssrHelpers";

const { isLicenseEE } = toRefs(useLicense());

const iconOldest = computed(() => {
    return {
        icon: isLicenseEE.value ? icon21 : icon18,
    };
});

const gamblingHelpOnlineLogo = computed(() => {
    if (!isServer) {
        const hostname = window.location.hostname;
        const match = hostname.match(/(rocketplay\d*)/);
        return match && match[0] !== "rocketplay" ? [ {
            icon: gamblingHelpOnline,
            blank: true,
            to: "https://www.gamblinghelponline.org.au/",
            alt: "Gambling Help Online",
        } ] : [];
    }
    return [];
});

const licensesIcons = computed(() => {
    return [ config.gcbValidator, iconOldest.value, ...config.licenses, ...gamblingHelpOnlineLogo.value ];
});

</script>

<style scoped lang="scss" src="./style.scss"></style>
