import type { IFileCMS, IPageItemCMS } from "@api/DTO/CMS";
import type { ILootboxesFileConfig, ILootboxItemConfig } from "@api/DTO/lootboxes";
import { loadMockLootboxWheelConfigs, loadPageContentFromWheelCmsReq } from "@api/requests/lootbox";
import { STATUS_LOOTBOX_ISSUED } from "@components/LootBox/configs";

import { http } from "@api/http";
import log from "@controllers/Logger";
import type { ILootbox } from "@src/types/lootboxes";
import { EnumLootboxState } from "@src/types/lootboxes";
import type { UserGroup } from "@src/types/user";
import { useUserStatuses } from "@store/user/userStatuses";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useLootboxesStore = defineStore("lootboxes", () => {
    const lootboxesList = ref<ILootbox[]>([]);
    const fakeIdPrizeWin = ref<number>();
    const mockSectionsRocketWheelConfigs = ref<ILootboxesFileConfig>({});
    const userGroupForWheel = ref<UserGroup>();
    const pageContentByGroup = ref<IPageItemCMS>();

    const lootboxListIssued = computed<ILootbox[]>(() => {
        return [ ...lootboxesList.value.filter(({ stage }: { stage: EnumLootboxState }) => {
            return stage === STATUS_LOOTBOX_ISSUED;
        }) ];
    });

    const countActiveLootbox = computed(() => lootboxListIssued.value.length);

    const isUserIncludeInLootbox = computed<boolean>(() => {
        return !!getMockWheelUser.value.length;
    });

    async function loadLootboxesList({ reload }: { reload?: boolean } = {}): Promise<ILootbox[]> {
        if (!reload && lootboxesList.value.length) {
            return lootboxesList.value;
        }
        try {
            const { data } = await http().get(`/api/player/lootboxes`);
            lootboxesList.value = data;
            return data;
        } catch (err) {
            log.error("LOAD_LOOTBOXES_LIST_ERROR", err);
            throw err;
        }
    }

    async function loadPrizeOfLootbox(lootboxId: string | number) {
        try {
            const { data } = await http().post(`/api/player/lootboxes/${ lootboxId }/activation`);
            return data;
        } catch (err) {
            log.error("LOAD_PRIZE_OF_LOOTBOX_ERROR", err);
        }
    }

    function updateLootboxList({ data: newData }: { data: ILootbox }): void {
        lootboxesList.value = [
            ...lootboxesList.value.filter(({ id }: { id: ILootbox["id"] }) => {
                return newData.id !== id;
            }),
            newData,
        ];
    }

    const getMockWheelUser = computed<ILootboxItemConfig[]>(() => {
        const userStatuses = useUserStatuses();
        const userGroups: UserGroup[] = userStatuses.getUserGroups;

        const mockSectionsRocketWheelConfig = Object.entries(mockSectionsRocketWheelConfigs.value)
            .find(([ key ]) => userGroups.includes(Number(key)));

        const [ groupNumberFinedInConfig, mockSections ] = mockSectionsRocketWheelConfig || [];
        userGroupForWheel.value = groupNumberFinedInConfig;
        if (groupNumberFinedInConfig) {
            loadPageContentFormCMS();
        }

        return mockSections || [];

    });

    async function loadMockSectionsRocketWheel() {
        const fileMockSectionsRocketWheel = await loadMockLootboxWheelConfigs();
        if (fileMockSectionsRocketWheel) {
            mockSectionsRocketWheelConfigs.value = fileMockSectionsRocketWheel as ILootboxesFileConfig;
        }
    }

    async function loadPageContentFormCMS() {
        if (userGroupForWheel.value) {
            const pageContent = await loadPageContentFromWheelCmsReq(userGroupForWheel.value);
            if (pageContent) {
                pageContentByGroup.value = pageContent;
            }
        }
    }

    function clearLootboxesUserData(): void {
        lootboxesList.value = [];
    }

    return {
        lootboxesList,
        fakeIdPrizeWin,

        lootboxListIssued,
        countActiveLootbox,
        isUserIncludeInLootbox,

        loadLootboxesList,
        loadPrizeOfLootbox,

        userGroupForWheel,
        getMockWheelUser,
        pageContentByGroup,
        loadMockSectionsRocketWheel,

        updateLootboxList,
        clearLootboxesUserData,
    };
});
