<template>
    <FeIcon
        class="close-button"
        :icon="icon"
        :lazy="false"
        @click="click"
    />
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";

export default {
    name: "CloseButton",

    components: {
        FeIcon,
    },

    props: {
        icon: {
            type: String,
            default: FeIconConfig.icons.fill.close,
        },
    },
    emits: [ "click" ],

    data() {
        return {
            FeIconConfig,
        };
    },
    methods: {
        click($event) {
            this.$emit("click", $event);
        },
    },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
