import { loadLanguageAsync } from "@i18n/loadLanguageAsync";
import { useMultilang } from "@store/multilang";
import { storeToRefs } from "pinia";

export default function languageHook(router) {
    router.beforeEach((to, from, next) => {
        const { getUserLocale } = storeToRefs(useMultilang());
        loadLanguageAsync({ lang: getUserLocale.value }).then(() => {
            next();
        });
    });
}
