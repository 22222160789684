import { MODAL_SING_UP, MODAL_LOGIN_FORM } from "@config/modalsNames";
import { defineAsyncComponent } from "vue";
import { useUIStore } from "@store/ui";

const registrationForm = defineAsyncComponent(() => {
    return import("@modules/Auth/RegistrationForm/RegistrationForm.vue");
});
const loginPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/Login/LoginPopup.vue");
});
const remindPasswordPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/RemindPassword/RemindPasswordPopup.vue");
});

const newPasswordPasswordPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/components/NewPassword/NewPasswordPopup.vue");
});

function clearHashTag() {
    setTimeout(() => {
        history.pushState("", document.title, window.location.pathname + window.location.search);
    }, 1000);
}

function showLoginPopup() {
    clearHashTag();
    const { setShowModal } = useUIStore();

    setShowModal({
        name: MODAL_LOGIN_FORM,
        component: loginPopup,
        mobileFriendly: true,
        fullScreen: true,
    });
}

async function showRegistrationPopup() {
    clearHashTag();
    const { setShowModal } = useUIStore();

    setShowModal({
        name: MODAL_SING_UP,
        component: registrationForm,
        mobileFriendly: true,
        fullScreen: true,
    });
}

function remindPopup() {
    clearHashTag();
    const { setShowModal } = useUIStore();

    setShowModal({
        name: "remind-pass-form",
        component: remindPasswordPopup,
        mobileFriendly: true,
        fullScreen: true,
    });
}

function showNewPasswordPopup() {
    clearHashTag();
    const { setShowModal } = useUIStore();

    setShowModal({
        name: "new-password-form",
        component: newPasswordPasswordPopup,
        mobileFriendly: true,
        fullScreen: true,
    });
}

export default (router) => {
    router.beforeEach((to, from, next) => {
        switch (to.hash) {
            case "#sign-in":
                showLoginPopup();
                break;
            case "#sign-up":
                showRegistrationPopup();
                break;
            case "#remind":
                remindPopup();
                break;
            case "#new-password":
            case "#users/password/edit":
            case "#profile/password/edit":
                showNewPasswordPopup();
                break;
            default:
        }

        next();
    });
};

