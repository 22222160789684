<template>
    <div
        class="month-hidden"
        @click="handleClickDay"
    >
        <p class="calendar-item__month">
            <span>1</span>
        </p>
        <div
            class="calendar-item"
            :class="classesCollection"
        >
            <FeIcon
                :icon="icons.fill.secretGifts"
                class="calendar-item__icon-secret-prize"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";

interface IProps {
    dayConfig: any;
    activeIndex: number;
}

const props = defineProps<IProps>();

const emit = defineEmits([ "choose-day" ]);

const classesCollection = computed(() => {
    return [
        { "calendar-item__active": props.activeIndex === props.dayConfig.index },
        { "calendar-item__today": props.dayConfig.today },
    ];
});

function handleClickDay() {
    emit("choose-day", props.dayConfig);
}

</script>

<style scoped lang="scss">
.calendar-item {
    display: flex;
    width: 4rem;
    height: 4rem;
    padding: 0.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background-color: var(--color-tertiary-3);
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    text-wrap: nowrap;

    span {
        white-space: normal;
        word-break: normal;
    }

    &__active,
    &:hover {
        background-color: var(--color-tertiary-2);
    }

    &__completed {
        color: var(--color-primary-1);

        &-status {
            color: var(--color-successfully);
            position: absolute;
            right: -.125rem;
            top: -.125rem;
            border: 1px solid var(--color-layer-body);
            border-radius: var(--border-radius-m);
            width: 1rem;
            height: 1rem;
            background-color: var(--color-layer-body);

            .fe-icon {
                width: 100%;
                height: 100%;
                line-height: 0;
            }
        }
    }

    &__today {
        color: var(--color-primary-1);


        &:not(.calendar-item__active) {
            .calendar-item__icon-secret-prize {
                :deep(svg path) {
                    fill: url(#a);
                }
            }
        }

        &.calendar-item__active {
            background: var(--color-primary-gradient);
            color: var(--color-button-text-primary);

            .calendar-item__icon-secret-prize {
                color: var(--color-layer-body);
            }
        }
    }

    &__month {
        color: transparent;
        margin-bottom: .5rem;
        text-transform: capitalize;
    }

    &__first-day-month {
        .calendar-item__month {
            opacity: 1 !important;
        }
    }

    &__icon-secret-prize {
        width: 2rem;
        height: 2rem;
        color: var(--color-primary-1);
    }
}

.month-hidden {
    .calendar-item__month {
        opacity: 0;
        transition: opacity .2s;

    }
}
</style>
