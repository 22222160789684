import { createGtm } from "@gtm-support/vue-gtm";
import { getUtmParamsFromCookies } from "@front/core/controllers/utmParams";
import stagController from "@controllers/StagController";
import ABTestController from "@controllers/ABTest/ABTestController";
import featureFlags from "@src/thems/alpa/configs/featureFlags";
import { useUserInfo } from "@store/user/userInfo";
import { storeToRefs } from "pinia";
import log from "@controllers/Logger";

import {
    PLACES,
    COMMON_EVENT,
    REGISTRATION_EVENTS,
    PERSONAL_INFO_POPUP,
    CASHBOX_EVENTS,
    COMMON_EVENTS,
    LOGIN_EVENTS,
    LOOTBOX_WHEEL,
    GAME_EVENTS,
    FORM_EVENTS,
} from "@controllers/MetriksController/gtmConstants";
import { useContextStore } from "@store/context";
import { useMultilang } from "@store/multilang";
import { useCommon } from "@store/common";
import { useRootStore } from "@store/root";

const submitPageView = ($gtm) => {
    const { getIsLogged } = storeToRefs(useUserInfo());
    const { getPlatform } = storeToRefs(useRootStore());
    return (additional = {}) => {
        if (getIsLogged.value) {
            const { getUserInfo: userInfo } = storeToRefs(useUserInfo());

            $gtm.customTrackEvent({
                event: "Pageviewrocketplay",
                userId: userInfo.value.user_id,
                Currency: userInfo.value.currency,
                currencyCode: userInfo.value.currency,
                Country: userInfo.value.locale,
                userAuth: true,
                Affiliate: "not set",
                ...additional,
                Platform: getPlatform.value && getPlatform.value.userAgent,
            });
        } else {
            const { getUserLocale, getUserGeo } = storeToRefs(useMultilang());
            const { getDefaultCurrency } = useCommon();

            $gtm.customTrackEvent({
                event: "Pageviewrocketplay",
                userId: "not set",
                Currency: getDefaultCurrency,
                currencyCode: getUserGeo.value,
                Country: getUserLocale.value,
                Affiliate: "not set",
                userAuth: false,
                Platform: getPlatform.value && getPlatform.value.userAgent,
                ...additional,
            });
        }
    };
};

/* eslint-disable */
const eccomerceSubmitPageView = (submitPageView) => ({
    id,
    amount,
}) => {
    const { isMobile } = storeToRefs(useRootStore());
    submitPageView({
        ecommerce: {
            purchase: {
                actionField: {
                    action: "purchase",
                    affiliation: isMobile ? "mobile" : "desktop",
                    id,
                    revenue: amount,
                },
                products: [
                    {
                        brand: "not set",
                        category: "deposit",
                        id,
                        name: "deposit",
                        price: amount,
                        quantity: 1,
                    },
                ],
            },
        },
    });
};

let $gtm = {
    customTrackEvent: (data, eventPlace) => {
    },
    REGISTRATION_EVENTS,
    CASHBOX_EVENTS,
    PLACES,
    LOGIN_EVENTS,
    COMMON_EVENTS,
    LOOTBOX_WHEEL,
    PERSONAL_INFO_POPUP,
    GAME_EVENTS,
    FORM_EVENTS,
};

export function useGtm() {
    return $gtm;
}

const GTMController = {
    init(app, router, gtmId) {
        const { isBotUA } = useContextStore();
        if (isBotUA) {
            return app.config.globalProperties.$gtm = $gtm;
        }

        app.use(createGtm({
            id: gtmId,
            defer: true,
            debug: DEV && FORCE_RUN_ANALYTICS,
            vueRouter: router,
            loadScript: !DEV || FORCE_RUN_ANALYTICS,
        }));

        $gtm = app.config.globalProperties.$gtm;

        if ($gtm) {
            $gtm.REGISTRATION_EVENTS = REGISTRATION_EVENTS;
            $gtm.CASHBOX_EVENTS = CASHBOX_EVENTS;
            $gtm.PLACES = PLACES;
            $gtm.LOGIN_EVENTS = LOGIN_EVENTS;
            $gtm.COMMON_EVENTS = COMMON_EVENTS;
            $gtm.LOOTBOX_WHEEL = LOOTBOX_WHEEL;
            $gtm.GAME_EVENTS = GAME_EVENTS;
            $gtm.PERSONAL_INFO_POPUP = PERSONAL_INFO_POPUP;
            $gtm.FORM_EVENTS = FORM_EVENTS;
            $gtm.customTrackEvent = (data, eventPlace) => {
                try {
                    if (data?.event === COMMON_EVENT) { // rest lock sending `rocketplay` events
                        return;
                    }
                    const { getUserInfo: userInfo } = storeToRefs(useUserInfo());
                    $gtm.trackEvent({
                        eventPlace: eventPlace || router.currentRoute.meta?.gtmPlace || PLACES.DEFAULT,
                        utmParams: getUtmParamsFromCookies(),
                        partnerStag: stagController.getStag(),
                        userId: userInfo.value.id,
                        ...(featureFlags.enableABReg ? { experiment: `exp_8375 | ${ ABTestController.variant }` } : {}),
                        ...data,
                    });
                } catch (err) {
                    log.error("CUSTOM_TRACK_EVENT_ERROR", err);
                }

            };

            $gtm.submitPageView = submitPageView($gtm);
            $gtm.eccomerceSubmitPageView = eccomerceSubmitPageView($gtm.submitPageView);

            router.afterEach(() => {
                return $gtm.submitPageView();
            });
        }
    },
};

export default GTMController;
