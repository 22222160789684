<template>
    <NotifyMessageBlank :id="data.id">
        <template #title>
            {{ notify.title }}
        </template>
        <template #default>
            <div v-html="notify.text" />
        </template>
        <template #button>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondarySmall"
                @click="gotoTournament"
            >
                {{ $t("NOTIFY_BAR.MESSAGES.BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                @click="gotoTournament"
            >
                {{ $t("NOTIFY_BAR.MESSAGES.BUTTON") }}
            </FeButton>
        </template>
    </NotifyMessageBlank>
</template>

<script lang="ts" setup>
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

import NotifyMessageBlank from "@modules/Notify/NotifyMessageBlank.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";

import { routeNames } from "@router/routeNames";
import type { INotification } from "@src/types/WSnotices";

interface IProps {
    data: INotification;
}

const props = defineProps<IProps>();
const notify = computed(() => {
    return props.data;
});

function gotoTournament() {
    useRouter().push({
        name: routeNames.tournamentPage, params: {
            slug: String(props.data.identifier),
        },
    });
}
</script>

