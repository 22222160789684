import { defineAsyncComponent } from "vue";
import { useUIStore } from "@store/ui";

const compPointsExchange = defineAsyncComponent(() => {
    return import("@modules/CompPoints/components/CompPointsExchangePopup.vue");
});

export async function openExchangeCalculator() {
    const { setShowModal } = useUIStore();

    setShowModal({
        name: "comp-points-exchange-popup",
        component: compPointsExchange,
        mobileFriendly: true,

    });
}

