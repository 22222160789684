<template>
    <div
        :class="{
            'modal-common__large': largeHeight,
            'modal-common__without-footer': !showFooter || !hasBottomSideUnderScroll
        }"
        class="modal-common"
    >
        <FeToolbar
            v-if="!hideCommonToolbar"
            :title="title"
        >
            <template #left>
                <slot name="toolbar-left">
                    <Logo
                        v-if="fullScreen"
                        class="modal-common__logo"
                        use-mobile-logo
                        @click="closeHandler"
                    />
                </slot>
            </template>

            <template #right>
                <FeIcon
                    v-if="!hideCloseButton"
                    :icon="FeIconConfig.icons.fill.close"
                    class="toolbar-steps__icon-close"
                    @click="handleClickCloseButton"
                />
            </template>
        </FeToolbar>


        <FeLoader
            v-if="showLoader"
            class="modal-common__loader"
        />
        <template v-else>
            <slot name="top-side"/>
            <div v-if="showAnimation" class="modal-common__animation"/>
            <div v-if="fullScreen" ref="scrollBar" class="modal-common__body">
                <div class="gap-row-s">
                    <slot :update-scroll-bar="updateScrollBar"/>
                </div>
            </div>
            <div
                v-else
                ref="scrollBar"
                class="modal-common__body"
            >
                <div class="gap-row-s">
                    <slot :update-scroll-bar="updateScrollBar"/>
                </div>
                <div v-if="$slots['bottom-side']">
                    <slot name="bottom-side"/>
                </div>
            </div>
            <div
                v-if="hasBottomSideUnderScroll && showFooter"
                class="modal-common__under-scroll"
            >
                <slot name="bottom-side-under-scroll"/>
            </div>
        </template>
    </div>
</template>

<script>
import { nextTick } from "vue";
import FeLoader from "@ui/FeLoader/FeLoader.vue";
import FeToolbar from "@ui/FeToolbar/FeToolbar.vue";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import { EventBus as bus } from "@bus";
import Logo from "@components/Logo/Logo.vue";
import { mapState } from "pinia";
import { useUIStore } from "@store/ui";

export default {
    name: "ModalCommonSlot",

    components: {
        FeLoader,
        FeToolbar,
        FeIcon,
        Logo,
    },

    inject: {
        closeHandler: { type: Function },
        fullScreen: { type: Boolean },
        fullScreenMobile: { type: Boolean, default: false },
        hasOverflowVisible: { type: Boolean, default: false },
    },

    props: {
        title: {
            type: String,
        },
        showLoader: {
            type: Boolean,
        },
        largeHeight: {
            type: Boolean,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        hideCommonToolbar: {
            type: Boolean,
        },
        hideCloseButton: {
            type: Boolean,
        },
    },
    emits: [ "clickCloseButton" ],

    setup(_, { slots }) {
        return { slots };
    },

    data() {
        return {
            FeIconConfig,
            showAnimation: this.hasOverflowVisible,
            modalName: "",
        };
    },

    computed: {
        hasBottomSideUnderScroll() {
            return this.slots["bottom-side-under-scroll"];
        },
        ...mapState(useUIStore, [
            "modals",
        ]),
        gtmTitle() {
            const title = this.title || this.modalName;
            return title.replace(/ /g, "_").toUpperCase();
        },
    },

    mounted() {
        this.modalName = this.modals[0]?.name || "";
        this.$gtm.customTrackEvent({
            event: "popup_open",
            popup_name: this.gtmTitle,
        });
        bus.$on("scrollToError", this.moveToEl);
    },

    beforeUnmount() {
        this.$gtm.customTrackEvent({
            event: "popup_close",
            popup_name: this.gtmTitle,
        });
        bus.$off("scrollToError", this.moveToEl);
    },

    methods: {
        updateScrollBar() {
            if (this.$refs.scrollBar.scrollTop) {
                this.$refs.scrollBar.scrollTop = 0;
            }
        },

        async moveToEl(selector = "[class*='__error-message']") {
            await nextTick();

            const firstError = this.$refs.scrollBar?.querySelector(selector);

            if (firstError) {
                if (this.fullScreen) {
                    firstError.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                    firstError.scrollIntoView({ block: "center" });
                }
            }
        },

        handleClickCloseButton() {
            this.closeHandler({ clickOnCloseButton: true });
            this.$emit("clickCloseButton");
        },
    },
};
</script>

<style lang="scss" scoped src="./styles.scss"></style>
