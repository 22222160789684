<template>
    <div class="payments-methods gap-s">
        <p class="payments-methods__title text--align-center text--upper h2">
            {{ title }}
        </p>

        <div class="payments-methods__list gap-s">
            <PaymentsMethodItem
                v-for="methodItem in filteredData"
                :key="methodItem.id"
                :method-data="methodItem"
                :type="type"
            />
        </div>
    </div>
</template>

<script>

import PaymentsMethodItem from "./PaymentsMethodItem.vue";

import { paymentsMethods } from "../paymentIptionsData.js";

export default {
    name: "PaymentsMethods",

    components: {
        PaymentsMethodItem,
    },

    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
        },
        methodData: {
            type: Array,
            default: () => {
                return paymentsMethods;
            },
        },
    },

    computed: {
        filteredData() {
            return this.methodData.filter((item) => {
                return Boolean(item[this.type]);
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.payments-methods {
    &__list {
        display: grid;
        grid-template-columns: 1fr;

        @include media(S) {
            grid-template-columns: 1fr 1fr;
        }
    }
}
</style>
