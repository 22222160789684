import { COUNTRIES } from "@theme/configs/constsLocales";

export const STATE_POSTS_AU: Record<string, number[][]> = {
    "New South Wales": [
        [ 1000, 2599 ],
        [ 2619, 2899 ],
        [ 2921, 2999 ],
    ],

    "Australian Capital Territory": [
        [ 200, 299 ],
        [ 2600, 2618 ],
        [ 2900, 2920 ],
    ],

    "Victoria": [
        [ 3000, 3999 ],
        [ 8000, 8999 ],
    ],

    "Queensland": [
        [ 4000, 4999 ],
        [ 9000, 9999 ],
    ],

    "South Australia": [
        [ 5000, 5999 ],
    ],

    "Western Australia": [
        [ 6000, 6797 ],
        [ 6800, 6999 ],
    ],

    "Tasmania": [
        [ 7000, 7999 ],
    ],

    "Northern Territory": [
        [ 800, 999 ],
    ],
};

export const STATE_POSTS_CA_START: Record<string, string> = {
    "A": "Newfoundland and Labrador",
    "B": "Nova Scotia",
    "C": "Prince Edward Island",
    "E": "New Brunswick",
    "G": "Quebec",
    "H": "Quebec",
    "J": "Quebec",
    "K": "Ontario",
    "L": "Ontario",
    "M": "Ontario",
    "N": "Ontario",
    "P": "Ontario",
    "R": "Manitoba",
    "S": "Saskatchewan",
    "T": "Alberta",
    "V": "British Columbia",
    "X": "Northwest Territories",
    "Y": "Yukon Territory",
};

function getStateForAU(postCode: string) {
    const postCodeNumber = +postCode;
    const res = Object.entries(STATE_POSTS_AU).find(([ key, ranges ]) => {
        return ranges.some((rangeItem) => postCodeNumber >= rangeItem[0] && postCodeNumber <= rangeItem[1]);
    });

    return res && res[0];
}

function getStateForCA(postCode: string) {
    const firstLetter = postCode[0].toUpperCase();

    return STATE_POSTS_CA_START[firstLetter];
}

const getStatesCollectionByCountry = {
    [COUNTRIES.CANADA]: getStateForCA,
    [COUNTRIES.AUSTRALIA]: getStateForAU,
};

export function getStateByCounty(countryUser: string, postCode: string) {
    if (!countryUser || !getStatesCollectionByCountry[countryUser]) {
        return null;
    }

    return getStatesCollectionByCountry[countryUser](postCode);
}
