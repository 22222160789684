<template>
    <div class="lobby-row">
        <div v-if="title" class="lobby-row__cover mobile-layout-width">
            <router-link v-if="url" :to="url" class="lobby-row__cover-title">
                <p class="h2">
                    {{ title }}
                </p>
                <FeImage
                    v-if="categoryIcons[category]"
                    :src="categoryIcons[category]"
                    class="lobby-row__icon"
                    alt="Lobby row icon"/>
            </router-link>
            <p v-else-if="seeAllFn" class="h2">
                {{ title }}
            </p>
            <p v-else class="h3">
                {{ title }}
            </p>
            <div v-if="url" class="lobby-row__cover-link">
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :theme="themes.SecondarySmall"
                    @click="$router.push(url)"
                >
                    {{ $t("GAME_ROW.BUTTON") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                    @click="$router.push(url)"
                >
                    {{ $t("GAME_ROW.BUTTON") }}
                </FeButton>
            </div>

            <div
                v-else-if="seeAllFn"
                class="lobby-row__cover-link"
            >
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :theme="themes.SecondarySmall"
                    @click="seeAllFn"
                >
                    {{ $t("GAME_ROW.BUTTON") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                    @click="seeAllFn"
                >
                    {{ $t("GAME_ROW.BUTTON") }}
                </FeButton>
            </div>
        </div>
        <div class="lobby-row__carousel">
            <CarouselScroll :transparent-border="hasTransparentBorder">
                <slot/>
            </CarouselScroll>
        </div>
    </div>
</template>

<script>
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import FeImage from "@ui/FeImage/FeImage.vue";

import { SlugCategoriesGames } from "@theme/configs/categoryesGames";

import topIcon from "@theme/icons/categories/top.svg";
import tournamentsIcon from "@theme/icons/categories/tournament-games.svg";
import bonusWagerIcon from "@theme/icons/categories/bonus-wagering.svg";
import jackpotIcon from "@theme/icons/categories/jackpots.svg";
import newIcon from "@theme/icons/categories/new.svg";
import { mapState } from "pinia";
import { useRootStore } from "@store/root";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";

export default {
    name: "LobbyRow",

    components: {
        CarouselScroll,
        FeButton,
        FeImage,
        FeButtonNew,
    },

    props: {
        title: {
            type: String,
        },

        category: {
            type: [ String, null ],
            default: null,
        },

        url: {
            type: Object,
        },

        hasTransparentBorder: {
            type: Boolean,
            default: true,
        },

        seeAllFn: {
            type: [ Function, null ],
            default: null,
        },
    },

    data() {
        return {
            FeButtonConfig,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),

        categoryIcons() {
            return {
                [SlugCategoriesGames.SLUG_CATEGORY_TOP]: topIcon,
                [SlugCategoriesGames.SLUG_CATEGORY_NEW]: newIcon,
                [SlugCategoriesGames.SLUG_CATEGORY_TOUR_GAMES]: tournamentsIcon,
                [SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING]: bonusWagerIcon,
                [SlugCategoriesGames.SLUG_CATEGORY_JACKPOT]: jackpotIcon,
            };
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
