<template>
    <div>
        <component
            :is="chatsComponents[enabledChat]"
            v-if="chatsComponents[enabledChat] && propsForChildComponents"
            v-bind="propsForChildComponents"
            v-on="handlers"
        />
    </div>
</template>

<script setup lang="ts">
import { useMultilang } from "@store/multilang";
import { useUserInfo } from "@store/user/userInfo";
import type { IUserInfo } from "@src/types/user";
import { computed, onMounted, onBeforeUnmount, watch } from "vue";
import { storeToRefs } from "pinia";

import type { IFreshChatParams } from "@front/core/modules/FreshChat/types";
import { CHAT_LIVECHAT, DEFAULT_CHAT, RESERVE_CHAT, useEnabledChatStore } from "@store/chats/enabledChatStore";
import { pinia } from "@store/pinia";

import vipStatusHelper from "@helpers/vipStatusHelper";
import { EventBus as bus } from "@bus";
import { defineAsyncComponent } from "vue";
import { useFreshchatStore } from "@store/chats/freshchatStore";

const userStore = useUserInfo();
const { getUserInfo: userInfo, getIsLogged } = storeToRefs(userStore);

const liveChat = defineAsyncComponent(() => {
    return import("@front/core/modules/LiveChat/index.alpa.vue");
});
const freshChat = defineAsyncComponent(() => {
    return import("@front/core/modules/FreshChat/index.alpa");
});

interface IInitialSessionVariables {
    Project: string;
    Language: string,
}

interface PropsLiveChats {
    defaultGroup: string;
    initialSessionVariables: IInitialSessionVariables,
}

const GUEST_GROUP = "26";
const GROUPS = {
    GENERAL: "1",
    VIP: "2",
};

const chatsComponents = {
    [RESERVE_CHAT]: liveChat,
    [DEFAULT_CHAT]: freshChat,
};

const { enabledChat } = storeToRefs(useEnabledChatStore());
const freshchatStore = useFreshchatStore(pinia);
const multilang = useMultilang();

const isLogged = computed<boolean>(() => {
    return getIsLogged.value;
});

function sendFreshchatRestoreId(restoreId) {
    return userStore.sendFreshChatRestoreId(restoreId);
}

function freshChatSetMessageCount(data) {
    return freshchatStore.setNewMessageCount(data);
}

defineEmits([ "update:unreadCount", "update:restoreId", "scroll" ]);

const handlers = computed(() => {
    if (enabledChat.value === DEFAULT_CHAT) {
        return {
            "update:unreadCount": freshChatSetMessageCount,
            "update:restoreId": sendFreshchatRestoreId,
            "scroll": () => {
            },
        };
    }

    return {};
});

const initialSessionVariables = computed<IInitialSessionVariables>(() => {
    return {
        Project: "Alpa",
        Language: multilang.getUserLocale.slice(0, 2),
    };
});

const propsForChildComponents = computed<{ data: IFreshChatParams } | PropsLiveChats>(() => {
    if (enabledChat.value === RESERVE_CHAT) {
        return {
            defaultGroup: GUEST_GROUP,
            initialSessionVariables: initialSessionVariables.value,
        } as PropsLiveChats;
    }

    return {
        data: freshchatStore.freshChatData,
    };
});


const detectLiveChatUserGroup = computed<string>(() => {
    if (isLogged.value) {
        const { status } = vipStatusHelper(userInfo.value);
        return status ? GROUPS.VIP : GROUPS.GENERAL;
    }
    return GUEST_GROUP;
});

const isEnableLiveChat = computed<boolean>(() => {
    return enabledChat.value === CHAT_LIVECHAT;
});


function setLiveChatData(userInfoProps: IUserInfo): void {
    const { linkProfile, userName, status } = vipStatusHelper(userInfoProps);

    bus.$emit("chat.set.data", {
        customerName: userName.replace(/\[.*\] /, ""),
        customerEmail: userInfoProps.email,
        group: detectLiveChatUserGroup.value,
        sessionVariables: {
            Id: userInfoProps.id,
            Link: linkProfile,
            Status: status?.name,
            ...initialSessionVariables.value,
        },
    });
}

function resetLiveChatData(): void {
    bus.$emit("chat.set.data", {
        customerName: " Visitor ",
        customerEmail: " ",
        group: detectLiveChatUserGroup.value,
        sessionVariables: {
            Id: " ",
            Link: " ",
            Status: " ",
            ...initialSessionVariables.value,
        },
    });
}

onMounted(() => {
    if (isEnableLiveChat.value) {
        bus.$on("user.logout", resetLiveChatData);
        bus.$on("user.login", setLiveChatData);
    }
});

onBeforeUnmount(() => {
    if (isEnableLiveChat.value) {
        bus.$off("user.logout", resetLiveChatData);
        bus.$off("user.login", setLiveChatData);
    }
});


watch(userInfo, (val) => {
    if (!isEnableLiveChat.value) {
        return;
    }

    if (val.id) {
        setLiveChatData(val);
    } else {
        resetLiveChatData();
    }
});

</script>
