import { routeNames } from "@router/routeNames";

export default {
    methods: {
        navigateGame(game) {
            if (this.isLogged) {
                this.$router.push({ name: routeNames.gameItem, params: { name: game.slug } });
            } else {
                this.$router.push({ name: routeNames.registration });
            }
        },

        navigateDemo(game) {
            this.$router.push({ name: routeNames.gameItem, params: { name: game.slug } });
        },
    },
};
