<template>
    <ModalCommonSlot
        :title="$t(popupDataByConfig.titlePopup)"
        class="text--align-center"
        @click-close-button="addToGroupWhoShowedPopup"
    >
        <div class="new-provider-popup__banner gap-column-s">
            <FeImage
                :src="popupDataByConfig.logo"
                class="new-provider-popup__banner-logo"
            />
            <FeImage
                :src="popupDataByConfig.image.main"
                :src-retina="popupDataByConfig.image.retina"
                class="new-provider-popup__banner-thumbs"
            />
        </div>

        <p class="h3" v-html="$t(popupDataByConfig.title)"/>
        <p v-t="popupDataByConfig.description"/>

        <template #bottom-side-under-scroll>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                class="new-provider-popup__banner-btn"
                full-width-mobile
                @click="handleClickButton"
            >
                {{ $t("POPUP_NEW_PROVIDER.BUTTON_NAME") }}
            </FeButtonNew>
            <FeButton
                v-else
                class="new-provider-popup__banner-btn"
                full-width-mobile
                @click="handleClickButton"
            >
                {{ $t("POPUP_NEW_PROVIDER.BUTTON_NAME") }}
            </FeButton>
        </template>
    </ModalCommonSlot>
</template>

<script lang="ts" setup>
import { type IPopupProviderConfig, POPUP_PROVIDERS_CONFIGS } from "@modules/Popups/PopupProviderNew/popupConfigs";
import { ProvidersNames } from "@src/types/game";
import { useUserStatuses } from "@store/user/userStatuses";
import { routeNames } from "@router/routeNames";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

import FeButton from "@ui/FeButton/FeButton.vue";
import ModalCommonSlot from "@components/ModalCommonSlot/ModalCommonSlot.vue";
import FeImage from "@ui/FeImage/FeImage.vue";

const userStatusesStore = useUserStatuses();

interface IProps {
    closeHandler: () => void;
    groupIdWhoWasShowed: number;
    nameProvider: ProvidersNames;
}

const props = defineProps<IProps>();

const $router = useRouter();

const popupDataByConfig = computed(() => {
    return POPUP_PROVIDERS_CONFIGS[props.nameProvider] as IPopupProviderConfig;
});

const bgBanner = computed(() => {
    return `url(${popupDataByConfig.value.bannerBackground})`;
});

function addToGroupWhoShowedPopup() {
    userStatusesStore.addUserToGroup(props.groupIdWhoWasShowed);
}

function handleClickButton() {
    $router.push({ name: routeNames.producersGames, params: { slug: props.nameProvider } });
    props.closeHandler();
    addToGroupWhoShowedPopup();
}
</script>

<style lang="scss" scoped>
.new-provider-popup {
    &__banner {
        width: 100%;
        height: 6rem;
        background-image: v-bind(bgBanner);
        border-radius: var(--border-radius-xs);

        padding: .5rem 0 .5rem 1rem;
        align-items: center;
        justify-content: space-between;

        &-logo {
            height: 4rem;
        }

        &-thumbs {
            height: 5rem;
        }

        &-btn {
            margin: 0 auto;
        }
    }
}
</style>
