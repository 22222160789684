<template>
    <div class="user-buttons">
        <div class="user-buttons__support" @click="openChat">
            <FeIcon
                :icon="FeIconConfig.icons.outline.support"
                class="user-buttons__support-icon"
            />
            <div
                v-if="counterMessage"
                class="user-buttons__support-counter"
            >
                {{ counterMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import { mapState } from "pinia";
import { useLivechatStore } from "@store/chats/livechatStore";
import { useFreshchatStore } from "@store/chats/freshchatStore";

export default {
    name: "SupportButton",

    components: {
        FeIcon,
    },

    data() {
        return {
            FeIconConfig,
        };
    },

    computed: {
        ...mapState(useLivechatStore, {
            chatMessageCountLiveChat: "getNewMessageCount",
        }),
        ...mapState(useFreshchatStore, {
            chatMessageCountFreshChat: "getMessagesCount",
        }),

        counterMessage() {
            const count = (this.chatMessageCountFreshChat || this.chatMessageCountLiveChat);
            if (count > 1) {
                return "1+";
            }
            return count;
        },
    },

    methods: {
        openChat() {
            this.$bus.$emit("chat.toggle");
        },
    },
};
</script>

<style lang="scss">
@import "~@theme/styles";

.user-buttons {
    display: flex;
    margin-left: auto;

    &__support {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: transform .1s ease;
        padding: .5rem;
        background: transparent;
        border: none;

        &:hover {
            transform: scale(1.2);
        }

        &-counter {
            position: absolute;
            right: -0.5rem;
            top: -0.25rem;
            @include pin;
        }
    }
}
</style>
