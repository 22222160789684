<template>
    <Popup
        bottom
        class="search-in-header__wrapper"
        full-width
        offset-x
        offset-y
        popup-wrapper-fixed
        right
        use-backdrop>
        <template #activator="{ showHandler: showSearchHandler }">
            <div :class="classes" class="games-list-toolbar" @click.stop>
                <div class="games-list-toolbar__search">
                    <GamesListToolbarSearch
                        :additional-button="additionalButton"
                        @open="showSearchHandler"/>
                </div>
            </div>
        </template>

        <template #default="{ hideHandler: hideSearchHandler }">
            <SearchPopup
                :close-handler="hideSearchHandler"
                class="search-in-header"
                @query="searchQueryHandler"/>
        </template>
    </Popup>
</template>

<script>
import Popup from "@components/Popup/Popup.vue";
import GamesListToolbarSearch from "@components/GamesListToolbarSearch/GamesListToolbarSearch.vue";
import SearchPopup from "@components/GamesListToolbarSearch/components/SearchPopup/SearchPopup.vue";

export default {
    name: "SearchGames",

    components: {
        Popup,
        GamesListToolbarSearch,
        SearchPopup,
    },

    props: {
        showProducers: {
            type: Boolean,
            default: true,
        },
        additionalButton: {
            type: Boolean,
        },
    },
    emits: [ "searchToggle" ],

    data() {
        return {
            searchOpen: false,
            searchQuery: "",
        };
    },

    computed: {
        classes() {
            return {
                "games-list-toolbar--search-open": this.searchOpen,
            };
        },
    },

    methods: {
        searchToggle(isOpen) {
            this.searchOpen = isOpen;
            this.$emit("searchToggle", isOpen);
        },
        searchQueryHandler(value) {
            this.searchQuery = value;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
