<template>
    <div
        class="profile-level"
        :class="classes"
    >
        <FlipCard>
            <template #default="{ rotateHandler }">
                <div
                    class="profile-level__front gap-xs"
                    @click="rotateHandler"
                >
                    <div class="gap-xs">
                        <FeImage
                            class="profile-level__image"
                            :src="levelImage"
                            :alt="levelTitleTranslate"
                        />
                        <p class="profile-level__name h3">
                            {{ levelTitleTranslate }}
                        </p>
                    </div>

                    <div class="profile-level__rewards gap-xs">
                        <div
                            v-for="(reward, index) in rewards"
                            :key="index"
                            class="profile-level__reward"
                        >
                            <FeIcon
                                :icon="BONUS_TYPE_CONFIG[reward.type]"
                                class="profile-level__reward-image"
                            />
                            <div class="profile-level__reward-desc">
                                {{ $t(reward.name) }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #back="{ rotateHandler }">
                <div
                    class="profile-level__back"
                    @click="rotateHandler"
                >
                    <FeIcon :icon="icons.fill.close" class="profile-level__image-exit"/>
                    <FeText
                        :size="FeTextConfig.size.H3"
                        :align="FeTextConfig.align.Center"
                    >
                        {{ level.name }}
                    </FeText>
                    <div class="profile-level__back-content" v-html="level.content"/>
                </div>
            </template>
        </FlipCard>
    </div>
</template>

<script>
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeImage from "@ui/FeImage/FeImage.vue";
import FlipCard from "@ui/FlipCard/FlipCard.vue";
import FeText, * as FeTextConfig from "@ui/FeText/FeText.vue";

import iconProfileLock from "@theme/images/profile/lock.svg";
import exit from "@theme/images/icon-close.svg";

const BONUS_TYPE_CONFIG = {
    cashback: icons.outline.cashback,
    fs: icons.outline.fs,
    points: icons.outline.points,
    bonus: icons.outline.fs,
};

export default {
    name: "ProfileLevel",

    components: {
        FeIcon,
        FeImage,
        FlipCard,
        FeText,
    },

    props: {
        level: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },

        accentLevel: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            icons,
            exit,
            FeTextConfig,
            BONUS_TYPE_CONFIG,
        };
    },

    computed: {
        rewards() {
            if (!this.level.gift_descriptions) {
                return [];
            }

            return this.level.gift_descriptions;
        },
        levelImage() {
            if (this.active) {
                return this.level.image;
            }
            return iconProfileLock;
        },
        classes() {
            return {
                [`status-${ this.rewards.level }`]: this.rewards.level >= 0,
                "profile-level--active": this.active,
                "profile-level--accent": this.accentLevel,
            };
        },

        levelTitleTranslate() {
            const keyToSnakeCase = String(this.level.name).replace(/\s/g, "_");
            return this.$te(`LEVELS.TITLES.${ keyToSnakeCase }`) ?
                this.$t(`LEVELS.TITLES.${ keyToSnakeCase }`) :
                this.level.name;
        },
    },

    methods: {},
};
</script>

<style lang="scss" src="./style.scss"></style>
