<template>
    <div class="progressbar_wrapper" :class="classes">
        <slot name="top" />
        <div class="progressbar__main" main>
            <div v-if="big" class="progressbar__insade">
                <slot name="inside" />
            </div>
            <div class="progressbar__active" active :style="{width: progress + '%'}" />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: "ProgressBar",

    props: {
        big: {
            type: Boolean,
            default: false,
        },
        progress: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        classes() {
            return this.big ? "progressbar--big" : "";
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
