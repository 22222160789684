<template>
    <CarouselScroll
        class="vip-club-carousel-statuses"
        :active-index="positionCardInCollection"
    >
        <VipClubCarouselStatusItem
            v-for="item in stepsData"
            :key="item.step"
            :step-data="item"
            :all-steps="stepsData"

        />
    </CarouselScroll>
</template>

<script setup lang="ts">
import { VIP_CLUB_STATUSES } from "@components/VipClub/consts";
import { userVipClubStatus } from "@components/VipClub/vipClubHelpers";
import { computed } from "vue";

import type { IStatusStepItem } from "@components/VipClub/types";
import VipClubCarouselStatusItem from "@components/VipClub/VipClubCarouselPreferItem.vue";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";

interface IProps {
    stepsData: IStatusStepItem[];
}

const props = defineProps<IProps>();

const positionCardInCollection = computed(() => {
    return props.stepsData.findIndex(({ step }) => step === VIP_CLUB_STATUSES[userVipClubStatus.value]);
});

</script>
<style scoped lang="scss">
@import "~@theme/styles";

.vip-club-carousel-statuses {
    --carousel-item-width: 18rem;
    --carousel-gap: 1rem;

    @include media(L) {
        --carousel-item-width: 21rem;
    }

    :deep(.carousel-scroll__item) {
        height: 100%;
    }
}
</style>
