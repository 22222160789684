<template>
    <VipClubStatus v-if="vipStatusWithRegular"/>
    <div v-else class="gap-row-s">
        <p class="text--color--alt" v-html="$t('VIP_CLUB.STATUS_CARD.TEXT_FOR_OTHER_USER.DESC')"/>
        <SingUpDepositButton class="banner-button-action"/>
    </div>
</template>

<script setup lang="ts">
import SingUpDepositButton from "@components/EnterBtns/SingUpDepositButton.vue";
import VipClubStatus from "@components/VipClub/VipClubStatus.vue";
import { userVipClubStatus } from "@components/VipClub/vipClubHelpers";
import { computed } from "vue";
import { VIP_CLUB_IDS } from "@components/VipClub/consts";

const vipStatusWithRegular = computed(() => userVipClubStatus.value || VIP_CLUB_IDS.REGULAR);

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.banner-block-status {
    max-width: 26rem;
}

.banner-button-action {
    width: max-content;
    margin: 0 auto;
    @include media(M) {
        margin: 0;
    }
}
</style>
