<template>
    <FeButtonNew
        v-if="featureFlags.enableNewUIComponents"
        :theme="themes.PrimaryLarge"
        full-width-mobile
        :data-test="dataTestButton"
        @click="signUpAction"
    >
        {{ titleButton }}
    </FeButtonNew>
    <FeButton
        v-else
        :theme="FeButtonConfig.themes.PrimaryMedium"
        full-width-mobile
        :data-test="dataTestButton"
        @click="signUpAction"
    >
        {{ titleButton }}
    </FeButton>
</template>

<script lang="ts" setup>
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import featureFlags from "@theme/configs/featureFlags";

interface IProps {
    titleRegBtn?: string;
}

const props = defineProps<IProps>();

const { t } = useI18n();
const $router = useRouter();
const { getIsLogged } = storeToRefs(useUserInfo());

function signUpAction() {
    return $router.push({ name: routeNames.cashboxDeposit });
}

const titleButton = computed(() => {
    return getIsLogged.value ?
        t("COMMON.DEPOSIT")
        : props.titleRegBtn || t("REGISTRATION.REG_NOW");
});

const dataTestButton = computed(() => {
    return getIsLogged.value ? "deposit-block__deposit--button" : "deposit-block__sing-up--button";
});

</script>

<style lang="scss" scoped></style>
