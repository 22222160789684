<template>
    <div class="confirm-emp__container">
        <FeCard
            v-if="displayEmpMessage"
            :theme="FeCardConfig.themes.White"
            :padding="FeCardConfig.PaddingSizes.paddingS"
            class="confirm-emp gap-row-xs"
        >
            <FeImage :src="image"/>
            <p>{{ $t("EMP_MESSAGE.TEXT") }}</p>

            <div class="confirm-emp__actions gap-row-s">
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    @click="handleApprove">
                    {{ $t("EMP_MESSAGE.BUTTON_APPROVE") }}
                </FeButtonNew>
                <FeButton v-else @click="handleApprove">
                    {{ $t("EMP_MESSAGE.BUTTON_APPROVE") }}
                </FeButton>
                <router-link :to="{name: routeNames.oopsPage}">
                    {{ $t("EMP_MESSAGE.BUTTON_DECLINE") }}
                </router-link>
            </div>
        </FeCard>
    </div>
</template>

<script>
import FeCard, * as FeCardConfig from "@ui/FeCard/FeCard.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import iconAttention from "@assets/graphic-icons/attention.png";
import iconAttentionDark from "@assets/graphic-icons/attention-dark.png";
import cookie from "@front/core/controllers/CookieController.js";
import { routeNames } from "@router/routeNames";
import { mapState } from "pinia";
import { useMultilang } from "@store/multilang";
import { useUIStore } from "@store/ui";
import { useUserInfo } from "@store/user/userInfo";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";

const approveEmpKey = "EMP_user_approved";

export default {
    name: "PopupConfirmEMP",

    components: {
        FeButtonNew,
        FeImage,
        FeCard,
        FeButton,
    },

    data() {
        return {
            FeCardConfig,
            iconAttention,
            FeButtonConfig,
            routeNames,
            showEmpCard: false,
        };
    },

    computed: {
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useUserInfo, { userIsLogged: "getIsLogged" }),
        ...mapState(useUIStore, [ "isThemeDark" ]),
        ...mapState(useMultilang, {
            userGeo: "getUserGeo",
        }),

        isOopsPage() {
            return this.$route.name === routeNames.oopsPage;
        },

        displayEmpMessage() {
            return !this.isOopsPage && this.showEmpCard && !this.userIsLogged && this.userGeo === "AU";
        },
        image() {
            return this.isThemeDark ? iconAttentionDark : iconAttention;
        },
    },
    created() {
        setTimeout(() => {
            this.showEmpCard = !cookie.get(approveEmpKey);
        }, 0);
    },

    methods: {
        handleApprove() {
            cookie.set(approveEmpKey, true, { expires: 31536e6 });
            this.showEmpCard = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import "./style.scss";

.confirm-emp {
    p {
        margin: 0;
    }

    &__message {
        padding-bottom: $confirm-emp__messager--padding-bottom;
    }

    &__container {
        width: $confirm-emp__container--width;
        max-width: $confirm-emp__container--max-width;
        text-align: center;
    }

    &__actions {
        a {
            display: inline-block;
            width: max-content;
            margin: 0 auto;
            color: var(--color-text-link-default);
            text-decoration: none;
        }
    }

    img {
        display: none;
        width: $confirm-emp-img--width;

        @include media(M) {
            display: block;
            margin: 0 auto;
        }
    }

}
</style>
