import { REFERRER_COOKIE_NAME } from "@theme/configs/constsCookies";

import cookie from "@front/core/controllers/CookieController.js";
import { isServer } from "@helpers/ssrHelpers";

export const referrerHelper = (): string | undefined => {
    if (isServer) {
        return;
    }

    return document.referrer || cookie.get(REFERRER_COOKIE_NAME);
}
