<template>
    <NotifyMessageBlank :id="data.id" :type="data.type">
        <template v-if="data.content.icon" #icon>
            <FeImage :src="data.content.icon" />
        </template>
        <template v-if="data.content.title" #title>
            {{ data.content.title }}
        </template>
        <template #default>
            <div v-html="data.text" />
        </template>
        <template v-if="data.content.button" #button>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondarySmall"
                @click="handleClickButton"
            >
                {{ data.content.button.title }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondaryExtraSmall"
                @click="handleClickButton"
            >
                {{ data.content.button.title }}
            </FeButton>
        </template>
    </NotifyMessageBlank>
</template>

<script lang="ts" setup>
import featureFlags from "@theme/configs/featureFlags";
import FeImage from "@ui/FeImage/FeImage.vue";
import { themes } from "@ui/New/ButtonConfig";
import { toRefs } from "vue";

import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import NotifyMessageBlank from "@modules/Notify/NotifyMessageBlank.vue";
import type { INotification, INotificationContent } from "@src/types/WSnotices";
import { useRouter } from "vue-router";
import FeButtonNew from "@ui/New/FeButton.vue";

interface INotificationWithContent extends INotification {
    content: INotificationContent;
}

interface IProps {
    data: INotificationWithContent;
}

const $router = useRouter();
const props = defineProps<IProps>();
const { data } = toRefs<IProps>(props);


function handleClickButton() {
    const { toName, path } = data.value.content.button;
    $router.push(toName ? { name: toName } : { path: path });
}

</script>
