import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";

import { mockLevels } from "@modules/Quest/constants/m/mock";
import { POINT_STATUS_ACTIVE, POINT_STATUS_COMPLETED, POINT_STATUS_LOCKED } from "@modules/Quest/constants/PointStatuses";
import character from "@modules/Quest/images/character.svg";

// @ts-ignore
import pointActive from "./img/point_active_1x.png";
// @ts-ignore
import pointActiveL from "./img/point_active_2x.png";
// @ts-ignore
import pointLocked from "./img/point_locked_1x.png";
// @ts-ignore
import pointLockedL from "./img/point_locked_2x.png";
// @ts-ignore
import pointUnlocked from "./img/point_unlocked_1x.png";
// @ts-ignore
import pointUnlockedL from "./img/point_unlocked_2x.png";
// @ts-ignore
import questMDesktopX2 from "./img/questM-desktop-2x.jpg";
// @ts-ignore
import questMDesktop from "./img/questM-desktop.jpg";
// @ts-ignore
import questMLogo from "./img/questM-logo-main-1x.png";
// @ts-ignore
import questMLogoX2 from "./img/questM-logo-main-2x.png";
// @ts-ignore
import questMLogoMobile from "./img/questM-logo-mobile.png";
// @ts-ignore
import questMLogoMobile2x from "./img/questM-logo-mobile@-2x.png";
// @ts-ignore
import questMMobX2 from "./img/questM-mob-2x.jpg";
// @ts-ignore
import questMMob from "./img/questM-mob.jpg";

const config: IBaseQuestConfig = {
    mockLevels,
    imageConfig: {
        logoSecondary: {
            main: questMLogoMobile,
            retina: questMLogoMobile2x,
        },
        logo: {
            main: questMLogo,
            retina: questMLogoX2,
        },
        desktop: {
            main: questMDesktop,
            retina: questMDesktopX2,
        },
        mobile: {
            main: questMMob,
            retina: questMMobX2,
        },
        points: {
            [POINT_STATUS_ACTIVE]: {
                sm: pointActive,
                lg: pointActiveL,
            },
            [POINT_STATUS_COMPLETED]: {
                sm: pointUnlocked,
                lg: pointUnlockedL,
            },
            [POINT_STATUS_LOCKED]: {
                sm: pointLocked,
                lg: pointLockedL,
            },
        },
        bookmarkIcon: character
    }
};

export default config;
