<template>
    <div class="payments-logo">
        <FeImage
            class="payments-logo__image"
            :src="paymentsSprite"
            :class="classes"
            :alt="payment"
        />
    </div>
</template>

<script>

import paymentsSprite from "./payments-logo.svg";
import FeImage from "@ui/FeImage/FeImage.vue";

export default {
    name: "PaymentsLogo",

    components: {
        FeImage,
    },

    props: {
        payment: {
            type: String,
        },
        flow: {
            type: String,
        },
    },

    data: () => {
        return {
            paymentsSprite,
        };
    },

    computed: {
        classes() {
            return [
                this.payment,
                this.flow && `${ this.payment }--${ this.flow.toLowerCase() }`,

            ];
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
