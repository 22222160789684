<template>
    <div :class="classes" class="games-list">
        <component
            :is="componentCard(game)"
            v-for="(game, index) in cardsGames"
            :key="game.slug || index"
            :category="categoryGames"
            :game="game"
        />
    </div>
</template>

<script>
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";

import GameItem from "@modules/GameHall/components/GameItem/GameItem.vue";
import GameItemRandom from "@modules/GameHall/components/GameItemRandom/GameItemRandom.vue";
import CashbackCard from "@modules/GameHall/components/CashbackCard/CashbackCard.vue";


const insertToArray = (arr, index, ...newItems) => {
    return [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted items
        ...newItems,
        // part of the array after the specified index
        ...arr.slice(index),
    ];
};


const INDEX_LOCATION_RANDOM_CARD = 10;
const GAME_CARD_TYPE_RANDOM = "randomGameCard";
const GAME_CARD_TYPE_CASHBOX = "cashboxCard";

export default {
    name: "GameItems",

    components: {
        GameItem,
    },

    props: {
        games: {
            type: Array,
            default: () => {
                return [];
            },
        },
        gap: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        categoryGames: {
            type: String,
        },
    },

    data() {
        return {
            GameItem,
        };
    },

    computed: {
        classes() {
            return {
                "games-list--gap": this.gap,
                "games-list--full-width": this.fullWidth,
            };
        },

        cardsGames() {
            if (this.categoryGames) {
                let indexLocation = INDEX_LOCATION_RANDOM_CARD;
                const regexCategories = /^(special|new|top|recommended)/;
                if (regexCategories.test(this.categoryGames)) {
                    indexLocation = this.games.length;
                }
                return [
                    ...(this.categoryGames === SlugCategoriesGames.SLUG_CATEGORY_LIVE ? [ { type: GAME_CARD_TYPE_CASHBOX } ] : []),
                    ...insertToArray(this.games, indexLocation, {
                        type: GAME_CARD_TYPE_RANDOM,
                    }),
                ];
            }
            return this.games;
        },
    },

    methods: {
        componentCard({ type }) {
            if (type === GAME_CARD_TYPE_RANDOM) {
                return GameItemRandom;
            }

            if (type === GAME_CARD_TYPE_CASHBOX) {
                return CashbackCard;
            }
            return GameItem;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
