import { ProvidersNames } from "@src/types/game";

import bannerBg from "./img/banner-bg.jpg";
import newProviderLogo from "./img/new-provider-logo.svg";
import newProviderThumbs from "./img/new-provider-thumbs.png";
import newProviderThumbsX2 from "./img/new-provider-thumbs@2x.png";

import bannerBgPragmaticplay from "./img/banner-bg-pragmaticplay.jpg";
import pragmaticplayLogo from "./img/pragmaticplay-logo.svg";
import pragmaticplayThumbs from "./img/thumbs-pragmaticplay.png";
import pragmaticplayThumbsX2 from "./img/thumbs-pragmaticplay@2x.png";

export interface IPopupProviderConfig {
    titlePopup: string;
    title: string;
    description: string;
    logo: string;
    bannerBackground: string;
    image: {
        retina: string;
        main: string;
    };
    button: {
        title: string;
    };
}

export const POPUP_PROVIDERS_CONFIGS: Partial<Record<ProvidersNames, IPopupProviderConfig>> = {
    [ProvidersNames.evolution]: {
        titlePopup: "POPUP_NEW_PROVIDER.TITLE_POPUP",
        title: "POPUP_NEW_PROVIDER.TITLE",
        description: "POPUP_NEW_PROVIDER.DESC",
        button: {
            title: "POPUP_NEW_PROVIDER.BUTTON",
        },
        bannerBackground: bannerBg,
        logo: newProviderLogo,
        image: {
            retina: newProviderThumbs,
            main: newProviderThumbsX2,
        },
    },
    [ProvidersNames.pragmaticplay]: {
        titlePopup: "POPUP_NEW_PROVIDER.PRAGMATICPLAY.TITLE_POPUP",
        title: "POPUP_NEW_PROVIDER.PRAGMATICPLAY.TITLE",
        description: "POPUP_NEW_PROVIDER.PRAGMATICPLAY.DESC",
        bannerBackground: bannerBgPragmaticplay,
        button: {
            title: "POPUP_NEW_PROVIDER.PRAGMATICPLAY.BUTTON_NAME",
        },
        logo: pragmaticplayLogo,
        image: {
            retina: pragmaticplayThumbs,
            main: pragmaticplayThumbsX2,
        },
    },
};
