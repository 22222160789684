export const mockLevels = {
    1: {
        position: {
            left: 130,
            top: 189,
        },
        bets: {
            EUR: 0,
        },
    },
    2: {
        position: {
            left: 81,
            top: 274,
        },
        bets: {
            EUR: 500,
        },
    },
    3: {
        position: {
            left: 179,
            top: 274,
        },
        bets: {
            EUR: 1000,
        },
    },
    4: {
        position: {
            left: 130,
            top: 361,
        },
        bets: {
            EUR: 2000,
        },
    },
    5: {
        position: {
            left: 130,
            top: 477,
        },
        bets: {
            EUR: 3000,
        },
    },
    6: {
        position: {
            left: 130,
            top: 652,
        },
        bets: {
            EUR: 5000,
        },
    },
    7: {
        position: {
            left: 81,
            top: 737,
        },
        bets: {
            EUR: 10000,
        },
    },
    8: {
        position: {
            left: 179,
            top: 737,
        },
        bets: {
            EUR: 25000,
        },
    },
    9: {
        position: {
            left: 130,
            top: 824,
        },
        bets: {
            EUR: 50000,
        },
    },
    10: {
        position: {
            left: 130,
            top: 940,
        },
        bets: {
            EUR: 75000,
        },
    },
    11: {
        position: {
            left: 130,
            top: 1122,
        },
        bets: {
            EUR: 100000,
        },
    },
    12: {
        position: {
            left: 81,
            top: 1207,
        },
        bets: {
            EUR: 130000,
        },
    },
    13: {
        position: {
            left: 179,
            top: 1207,
        },
        bets: {
            EUR: 150000,
        },
    },
    14: {
        position: {
            left: 130,
            top: 1294,
        },
        bets: {
            EUR: 175000,
        },
    },
    15: {
        position: {
            left: 130,
            top: 1410,
        },
        bets: {
            EUR: 200000,
        },
    },
    16: {
        position: {
            left: 130,
            top: 1594,
        },
        bets: {
            EUR: 300000,
        },
    },
    17: {
        position: {
            left: 81,
            top: 1678,
        },
        bets: {
            EUR: 400000,
        },

    },
    18: {
        position: {
            left: 179,
            top: 1678,
        },
        bets: {
            EUR: 500000,
        },
    },
    19: {
        position: {
            left: 130,
            top: 1765,
        },
        bets: {
            EUR: 600000,
        },
    },
    20: {
        position: {
            left: 130,
            top: 1882,
        },
        bets: {
            EUR: 700000,
        },
    },
    21: {
        position: {
            left: 25,
            top: 2051,
        },
        bets: {
            EUR: 800000,
        },
    },
    22: {
        position: {
            left: -8,
            top: 2157,
        },
        bets: {
            EUR: 900000,
        },
    },
    23: {
        position: {
            left: 130,
            top: 2212,
        },
        bets: {
            EUR: 1000000,
        },
    },
    24: {
        position: {
            left: 269,
            top: 2157,
        },
        bets: {
            EUR: 1500000,
        },
    },
    25: {
        position: {
            left: 238,
            top: 2051,
        },
        bets: {
            EUR: 2000000,
        },
    },
};
