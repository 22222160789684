import { useCMSFetchService } from "@store/CMS";
import { useCommonFetchService } from "@store/common";
import { useEnvironmentsFetchService } from "@store/environments";
import { useLotteriesFetchService } from "@store/lotteries";
import { useMultilangFetchService } from "@store/multilang";
import { useSettingsFetchService } from "@store/settings";
import type { Pinia } from "pinia";

import { useBannersFetchService } from "@store/banners";
import { useGamesCategoryFetchService } from "@store/games/gamesCategory";
import { useGamesProvidersFetchService } from "@store/games/gamesProviders";
import { useGamesCommonFetchService } from "@store/games/gamesStore";
import { useJackpotsFetchService } from "@store/jackpots";
import { useLevelsFetchService } from "@store/levels/levelsStore";
import { useLogoStoreFetchService } from "@store/logoStore";
import { useRootStoreFetchService } from "@store/root";
import { useTournamentsFetchService } from "@store/tournaments/tournamentsStore";
import { useUserInfoFetchService } from "@store/user/userInfo";

export function usePrefetchData(pinia?: Pinia) {
    const { loadJackpots } = useJackpotsFetchService(pinia);
    const { loadGamesProviders } = useGamesProvidersFetchService(pinia);
    const { loadLevelData } = useLevelsFetchService(pinia);
    const { loadLotteriesList } = useLotteriesFetchService(pinia);
    const { loadTournaments } = useTournamentsFetchService(pinia);
    const { loadEnvironments } = useEnvironmentsFetchService(pinia);
    const { loadGamesCategories } = useGamesCommonFetchService(pinia);
    const { initCollection } = useGamesCategoryFetchService(pinia);
    const { loadMultilang } = useMultilangFetchService(pinia);
    const { loadStaticPages, loadCMSSnippets } = useCMSFetchService(pinia);
    const { initSettingsStore } = useSettingsFetchService(pinia);
    const {
        loadCountries,
        loadCurrencies,
        loadProjectInfo,
        loadCryptoExchangeRates,
    } = useCommonFetchService(pinia);
    const { loadUserInfo } = useUserInfoFetchService(pinia);
    const { loadCMSPages } = useBannersFetchService(pinia);
    const { loadLogoConfig } = useLogoStoreFetchService(pinia);
    const { initRootStore } = useRootStoreFetchService(pinia);

    return () => {
        return Promise.all([
            loadJackpots(),
            loadGamesProviders(),
            loadLevelData(),
            loadLotteriesList(),
            loadTournaments(),
            loadEnvironments(),
            loadMultilang(),
            loadStaticPages({ reload: true }),
            loadCMSSnippets({ reload: true }),
            loadCryptoExchangeRates(),
            loadCountries(),
            loadCurrencies(),
            loadProjectInfo(),
            loadCMSPages(),
            loadGamesCategories().then(initCollection),
            initSettingsStore(),
            loadUserInfo(),
            loadLogoConfig(),
            initRootStore(),
        ]);
    };
}
