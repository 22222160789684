import {
    COUNTRY_BY_HOST,
    ALLOW_DOMAINS,
    DOMAIN_FOR_CANADA,
} from "@theme/configs/constsLocales";

export function canonicalHelper(hostname: string, pathname: string): string {
    const defaultCanonicalHostname = DOMAIN_FOR_CANADA;

    const computedHostname = ALLOW_DOMAINS.find((allowedHostname: string) => {
        return COUNTRY_BY_HOST[hostname] === COUNTRY_BY_HOST[allowedHostname];
    }) || defaultCanonicalHostname;

    return `https://${computedHostname}${pathname}`;
}
