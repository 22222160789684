import bronze from "@components/VipClub/img/bronze.png";
import bronzeX2 from "@components/VipClub/img/bronze@2x.png";
import diamond from "@components/VipClub/img/diamond.png";
import diamondX2 from "@components/VipClub/img/diamond@2x.png";
import gold from "@components/VipClub/img/gold.png";
import goldX2 from "@components/VipClub/img/gold@2x.png";
import platinum from "@components/VipClub/img/platinum.png";
import platinumX2 from "@components/VipClub/img/platinum@2x.png";
import silver from "@components/VipClub/img/silver.png";
import silverX2 from "@components/VipClub/img/silver@2x.png";

export enum VIP_CLUB_IDS {
    BRONZE = 257,
    BRONZE_OLD = 793,
    REGULAR = 1117,
    SILVER = 258,
    GOLD = 259,
    PLATINUM = 255,
    DIAMOND = 759,
}

export const VIP_CLUB_STATUSES = {
    [VIP_CLUB_IDS.BRONZE]: "Bronze",
    [VIP_CLUB_IDS.BRONZE_OLD]: "Bronze",
    [VIP_CLUB_IDS.SILVER]: "Silver",
    [VIP_CLUB_IDS.GOLD]: "Gold",
    [VIP_CLUB_IDS.PLATINUM]: "Platinum",
    [VIP_CLUB_IDS.DIAMOND]: "Diamond",
};

interface IMageConfig {
    src: string;
    srcRetina: string;
}

const IMG_BRONZE: IMageConfig = {
    src: bronze,
    srcRetina: bronzeX2,
};

const IMG_SILVER: IMageConfig = {
    src: silver,
    srcRetina: silverX2,
};

const IMG_GOLD: IMageConfig = {
    src: gold,
    srcRetina: goldX2,
};

const IMG_PLATINUM: IMageConfig = {
    src: platinum,
    srcRetina: platinumX2,
};

const IMG_DIAMOND: IMageConfig = {
    src: diamond,
    srcRetina: diamondX2,
};

export const IMG_STATUSES: Record<number, IMageConfig> = {
    [VIP_CLUB_IDS.BRONZE]: IMG_BRONZE,
    [VIP_CLUB_IDS.REGULAR]: IMG_BRONZE,
    [VIP_CLUB_IDS.SILVER]: IMG_SILVER,
    [VIP_CLUB_IDS.GOLD]: IMG_GOLD,
    [VIP_CLUB_IDS.PLATINUM]: IMG_PLATINUM,
    [VIP_CLUB_IDS.DIAMOND]: IMG_DIAMOND,
};

export const IMG_BY_NAME_STATUSES: Record<string, IMageConfig> = {
    Bronze: IMG_BRONZE,
    Silver: IMG_SILVER,
    Gold: IMG_GOLD,
    Platinum: IMG_PLATINUM,
    Diamond: IMG_DIAMOND,
};

export const DIAMOND_STATUS = "diamond";
