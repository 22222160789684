<template>
    <div class="header__bottom">
        <EnterBtns v-if="!isLogged" class="header__bottom--top" />

        <div class="header__bottom-nav">
            <div v-if="isLogged" class="header__bottom-nav-cashbox">
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :data-test="dataTestAttrs.depositButton"
                    @click="handleOpenDeposit">
                    {{ cashboxTitle }}
                </FeButtonNew>
                <FeButton v-else :data-test="dataTestAttrs.depositButton" @click="handleOpenDeposit">
                    {{ cashboxTitle }}
                </FeButton>
            </div>
            <div v-for="(item, index) in bottomNavList" :key="item.title" class="fe-relative">
                <a
                    v-if="item.externalUrl"
                    :ref="'nav-item-' + item.title"
                    :key="index"
                    :href="item.externalUrl"
                    class="header__bottom-nav-item"
                >
                    <FeIcon
                        class="header__bottom-nav-icon"
                        :icon="item.icon"
                    />
                    {{ item.title }}
                    <span
                        v-if="item.counter"
                        class="header__bottom-nav-item-counter"
                        :class="{ 'active' : item.active}"
                    >{{ item.counter }}</span>
                    <FeNavBadge
                        v-show="item.badge"
                        class="header__bottom-nav-badge"
                        :badge="item.badge || {}"
                    />
                </a>
                <router-link
                    v-else
                    v-slot="{ navigate, isExactActive }"
                    :to="item.to || { name: item.routeName }"
                    custom
                >
                    <a
                        v-touch:tap="navigate"
                        class="header__bottom-nav-item"
                        :class="getActiveClass(item, isExactActive)"
                    >
                        <FeIcon
                            class="header__bottom-nav-icon"
                            :icon="item.icon"
                        />
                        {{ item.title }}
                        <span
                            v-show="item.counter"
                            class="header__bottom-nav-item-counter"
                            :class="{ 'active' : item.active}"
                        >{{ item.counter }}</span>
                    </a>
                </router-link>
            </div>
            <div
                class="fe-relative"
                :data-test="dataTestAttrs.openSidebar"
                @click.stop="openSideBar"
            >
                <span class="header__bottom-nav-item">
                    <FeIcon
                        class="header__bottom-nav-icon"
                        :icon="FeIconConfig.icons.outline.bottomBarMenu"
                    />
                    {{ $t("HEADER.MENU") }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { routeNames } from "@router/routeNames.js";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import EnterBtns from "@components/EnterBtns/EnterBtns.vue";
import FeButton from "@ui/FeButton/FeButton.vue";
import FeNavBadge from "@ui/FeNavBadge/FeNavBadge.vue";

import { useGetActiveClass } from "@helpers/getActiveClassNavMenu";
import dataTestAttrs from "./data-test-attrs.ts";
import { mapState } from "pinia";
import { useUserInfo } from "@store/user/userInfo";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";

export default {
    name: "BottomMenu",

    components: {
        FeButtonNew,
        FeIcon,
        EnterBtns,
        FeButton,
        FeNavBadge,
    },

    props: {
        pathForDepositButton: {
            type: String,
            default: routeNames.cashboxDeposit,
        },
        cashboxTitle: {
            type: String,
            default() {
                // eslint-disable-next-line vue/no-deprecated-props-default-this
                return this.$t("COMMON.CASHBOX");
            },
        },
        navMenuList: {
            type: Array,
        },
    },

    setup() {
        const { getActiveClass } = useGetActiveClass();
        return {
            getActiveClass,
        };
    },

    data() {
        return {
            FeIconConfig,
            itemLobby: {
                title: this.$t("HEADER.GAMES"),
                icon: FeIconConfig.icons.outline.bottomBarCasino,
                routeName: routeNames.main,
                action: () => {
                    this.$router.push({ name: this.main });
                },
            },
            itemPokies: {
                title: this.$t("HEADER.POKIES"),
                icon: FeIconConfig.icons.outline.bottomBarPokies,
                routeName: routeNames.categoryPokiesAll,
                to: { name: routeNames.categoryPokiesAll },
            },
            itemLive: {
                title: this.$t("HEADER.LIVE"),
                icon: FeIconConfig.icons.outline.bottomBarTable,
                routeName: routeNames.categoryLiveAll,
                to: { name: routeNames.categoryLiveAll },
            },
            dataTestAttrs,
        };
    },

    computed: {
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),

        bottomNavList() {
            return this.navMenuList ||
                [
                    this.itemLobby,
                    this.itemPokies,
                    this.itemLive,
                ];
        },
    },

    methods: {
        openSideBar() {
            this.$bus.$emit("sidebar.open");
        },

        handleOpenDeposit() {
            this.$router.push({ name: this.pathForDepositButton });

            if (this.$gtm && this.$gtm.customTrackEvent) {
                this.$gtm.customTrackEvent({
                    ...this.$gtm?.CASHBOX_EVENTS?.DEPOSIT?.NEW?.CLICK_OPEN_DEPOSIT_MAIN,
                    location: "mobile_BottomMenu",
                });
            }
        },
    },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
