import { FullStory } from "@front/core/controllers/MetriksController/FullStory.ts";
import hotjar from "./HotJar";
import config from "@theme/configs/config";
import ValdemoroController from "./ValdemoroController";
import GTMController from "./GTMController";
import { useContextStore } from "@store/context";
import { useSettings } from "@store/settings";
import * as Sentry from "@sentry/vue";

export default {
    init(app, {
        user,
        router,
    }) {
        const { isBotUA: isBot } = useContextStore();
        const { valdemoroSrc } = useSettings();

        if (config.tagManagerId) {
            GTMController.init(app, router, config.tagManagerId);
        }

        if (!isBot && (!DEV || FORCE_RUN_ANALYTICS)) {
            if (config.fullStoryId) {
                FullStory.init(config.fullStoryId, user);

                FullStory.getCurrentSessionURL((url) => Sentry.setTag("fullstory_session_url", url));
            }

            if (config.hotjar_id) {
                hotjar.init(config.hotjar_id, 6);
            }

            if (valdemoroSrc) {
                ValdemoroController.init(valdemoroSrc);
            }
        }
    },
};
