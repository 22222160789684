import cookies from "@front/core/controllers/CookieController.js";

export const UTM_PARAMS = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
];

export function setUtmParamsToCookies(query = {}, expires) {
    if (typeof document === "undefined") {
        return;
    }

    const now = new Date();
    now.setTime(now.getTime() + expires);

    const isSetCookies = Object.keys(query).some((queryItem) => {
        return UTM_PARAMS.find((utm) => {
            return utm === queryItem;
        });
    });

    if (isSetCookies) {
        UTM_PARAMS.forEach((key) => {
            const cookieValue = query[key] || "";
            cookies.set(key, cookieValue, {
                "expires": now.toGMTString(),
                "path=/": true,
            });
        });
    }
}

export function getUtmParamsFromCookies() {
    const utmParams = {};
    UTM_PARAMS.forEach((key) => {
        utmParams[key] = cookies.get(key);
    });

    return utmParams;
}
