import Modal from "@plugins/Modal";
import { routeNames } from "@router/routeNames";
import { MODAL_LOGIN_FORM, MODAL_REMIND_PASS_FORM, MODAL_SING_UP } from "@config/modalsNames";
import { defineAsyncComponent } from "vue";

const registrationForm = defineAsyncComponent(() => {
    return import("@modules/Auth/RegistrationForm/RegistrationForm.vue");
});

const loginPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/Login/LoginPopup.vue");
});

const remindPasswordPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/RemindPassword/RemindPasswordPopup.vue");
});
const newPasswordPasswordPopup = defineAsyncComponent(() => {
    return import("@modules/Auth/components/NewPassword/NewPasswordPopup.vue");
});
const emailVerify = defineAsyncComponent(() => {
    return import("@modules/Auth/EmailVerify/EmailVerify.vue");
});

function controlOpenMainPage(from, next) {
    if (!from.name) {
        return next({ name: routeNames.main });
    }
}

export async function signUpPopup(to, from, next) {
    Modal.show({
        name: MODAL_SING_UP,
        component: registrationForm,
        mobileFriendly: true,
        fullScreen: true,
    });
    controlOpenMainPage(from, next);

    return next(false);
}

export function logInPopup(to, from, next) {
    Modal.show({
        name: MODAL_LOGIN_FORM,
        component: loginPopup,
        mobileFriendly: true,
        fullScreen: true,
    });
    controlOpenMainPage(from, next);

    return next(false);
}

export function remindPasswordInPopup(to, from, next) {
    Modal.show({
        name: MODAL_REMIND_PASS_FORM,
        component: remindPasswordPopup,
        mobileFriendly: true,
        fullScreen: true,
    });
    controlOpenMainPage(from, next);
    return next(false);
}

export function newPasswordPasswordInPopup(to, from, next) {
    Modal.show({
        name: "new-password-form",
        component: newPasswordPasswordPopup,
        mobileFriendly: true,
        fullScreen: true,
        props: {
            ...to.query,
        },
    });

    controlOpenMainPage(from, next);

    return next(false);
}

export function emailVerifyPopup(to, from, next) {
    Modal.show({
        name: "new-password-form",
        component: emailVerify,
        mobileFriendly: true,
        showClose: true,
    });
    controlOpenMainPage(from, next);

    return next(false);
}
