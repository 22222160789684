import log from "@controllers/Logger";

let localBus = null;

export function navigator(name, state, data) {
    if (!name) {
        log.error("NAVIGATOR: cant find navigator", name);
        return;
    }

    if (!state) {
        log.error(`NAVIGATOR: "${ name }" cant find state`);
        return;
    }

    localBus.$emit(`navigator.${ name }.change.state`,
        state,
        data,
    );
}

export default {
    init(bus) {
        localBus = bus;
    },
};
