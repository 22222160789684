<template>
    <transition name="fade">
        <div v-if="isShow" class="global-loader">
            <FeLoader :size="10" />
        </div>
    </transition>
</template>

<script>
import FeLoader from "@ui/FeLoader/FeLoader.vue";

export default {
    name: "PreloaderController",

    components: {
        FeLoader,
    },

    data() {
        return {
            loadingList: [],
        };
    },

    computed: {
        isShow() {
            return this.loadingList.length > 0;
        },
    },

    mounted() {
        this.$bus.$on("preloader.show", this.show);
        this.$bus.$on("preloader.close", this.close);
    },

    beforeUnmount() {
        this.$bus.$off("preloader.show", this.show);
        this.$bus.$off("preloader.close", this.close);
    },

    methods: {
        show(name, id) {
            this.loadingList.push({ name, id });
        },

        close(name, id = null) {
            this.loadingList = this.loadingList.filter((loadingItem) => {
                if (id) {
                    return loadingItem.name !== name && loadingItem.id !== id;
                }
                return loadingItem.name !== name;
            });
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
