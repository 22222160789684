import type { IGame } from "@src/types/game";
import { useMultilang } from "@store/multilang";
import { COUNTRIES } from "@theme/configs/constsLocales";
import { storeToRefs } from "pinia";

// tslint:disable-next-line:max-line-length
function findGameBySeoTittleAndProducerWithDuplicate(gamesCollection: IGame[], { producer, seoTitle }): IGame | undefined {
    return gamesCollection.find(({ seo_title: seoTitleItem, provider: providerItem }) => {
        return producer === providerItem && seoTitleItem === seoTitle;
    });
}

function findGameOfPlaysonBySeoTitle(gamesCollection: IGame[], { producer, seoTitle }): IGame | undefined {
    const { getUserGeo: userGeo } = storeToRefs(useMultilang());
    const targetProducerByGeo = userGeo.value === COUNTRIES.AUSTRALIA ? "redgenn" : "infin";
    return gamesCollection.find(({ seo_title: seoTitleItem, identifier: identifierItem }) => {
        const [ producerItem ] = identifierItem.split("/");
        return targetProducerByGeo === producerItem && seoTitleItem === seoTitle;
    });
}

export function findGameBySeoTittleAndProducer(gamesCollection: IGame[], { producer, seoTitle }): IGame | undefined {
    if (producer === "playson") {
        return  findGameOfPlaysonBySeoTitle(gamesCollection, { producer, seoTitle });
    }
    return findGameBySeoTittleAndProducerWithDuplicate(gamesCollection, { producer, seoTitle });
}
