import { ProvidersNames } from "@src/types/game";

export const KEY_IS_SHOWED_POPUP_IN_SESSION = "isShowedPopupNewProvider";

interface IGroupProviderConfigs {
    targetGroup: number;
    nameProvider: ProvidersNames;
    groupWasShowed: number;
    nameGroup?: string;
}

export const PROVIDERS_GROUPS: IGroupProviderConfigs[] = [
    {
        targetGroup: 851,
        nameProvider: ProvidersNames.evolution,
        groupWasShowed: 859,
        nameGroup: "Popup Evo Ca",
    },
    {
        targetGroup: 1030,
        nameProvider: ProvidersNames.pragmaticplay,
        groupWasShowed: 1056,
        nameGroup: "",
    },
];
