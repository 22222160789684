<template>
    <LobbyRow
        v-if="gameRow.length"
        :category="category"
        :has-transparent-border="hasTransparentBorder"
        :title="getCategoryName"
        :url="urlCategoryGames"
        class="game-row"
    >
        <GameItem
            v-for="(item, index) in gameRow"
            :key="item.id"
            :game="item"
            :is-large="largeItemIndex === index"
            :type-image="TypeImage.GAME_ROW_THUMB"
            @open-demo="navigateDemo(item)"
            @open-game="navigateGame(item)"
        />
        <GameItemRandom
            v-if="enableRandomGame"
            :catecory-games="gamesForRow"
            :category="category"
        />
        <GameItemEmpty v-else-if="category" :url="urlCategoryGames"/>
    </LobbyRow>
</template>

<script>
import LobbyRow from "@components/LobbyRow/LobbyRow.vue";
import GameItem from "@modules/GameHall/components/GameItem/GameItem.vue";
import GameItemEmpty from "@modules/GameHall/components/GameItemEmpty/GameItemEmpty.vue";
import GameItemRandom from "@modules/GameHall/components/GameItemRandom/GameItemRandom.vue";
import gameNavigateMixin from "@modules/GameHall/mixins/gameNavigateMixin.js";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";
import { getGameTemplates } from "@helpers/gameHelpers";
import { routeNames } from "@router/routeNames";
import { TypeImage } from "@theme/configs/imgResize";
import { mapState, mapActions } from "pinia";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";
import { useUserInfo } from "@store/user/userInfo";
import { useRootStore } from "@store/root";

const CATEGORY_ENABLE_RANDOM = [
    SlugCategoriesGames.SLUG_CATEGORY_TOP,
    SlugCategoriesGames.SLUG_CATEGORY_NEW,
    SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED,
];

export default {
    name: "GameRow",

    components: {
        LobbyRow,
        GameItem,
        GameItemEmpty,
        GameItemRandom,
    },

    mixins: [ gameNavigateMixin ],

    props: {
        games: {
            type: Array,
        },

        title: {
            type: String,
        },

        titleHide: {
            type: Boolean,
        },

        category: {
            type: [ String, Object ],
        },

        url: {
            type: Object,
        },

        urlHide: {
            type: Boolean,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        hasTransparentBorder: {
            type: Boolean,
            default: true,
        },
        largeItemIndex: {
            type: [ Number, null ],
        },

        showSkeletonAfterLoad: {
            type: Boolean,
        },
    },

    data() {
        return {
            loadedData: false,
            TypeImage,
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
        ...mapState(useGamesCategory, {
            getCategoryGames: "getCollection",
        }),
        ...mapState(useGamesCommon, [ "getGameCategoryNameBySlug" ]),

        gameRow() {
            if (!this.loadedData) {
                return getGameTemplates();
            }

            let START = 0,
                LIMIT = 15;

            return this.gamesForRow.slice(START, LIMIT);
        },

        enableRandomGame() {
            return CATEGORY_ENABLE_RANDOM.includes(this.category);
        },

        gamesForRow() {
            if (this.games) {
                return this.games;
            }

            return this.getCategoryGames(this.category, 1);
        },

        urlCategoryGames() {
            if (this.urlHide) {
                return {};
            }

            if (this.url) {
                return this.url;
            }
            return { name: routeNames.categoryGames, params: { slug: this.category } };
        },

        getCategoryName() {
            if (this.titleHide) {
                return "";
            }

            if (this.title) {
                return this.title;
            }
            return this.translateCategory(this.getGameCategoryNameBySlug(this.category));
        },
    },

    mounted() {
        this.loadingData();
    },

    methods: {
        ...mapActions(useGamesCategory, {
            loadCategoryGames: "loadGamesCategory",
        }),

        translateCategory(name) {
            return this.$te(`GAME_NAV.CATEGORIES.${name}`) ? this.$t(`GAME_NAV.CATEGORIES.${name}`) : name;
        },

        async loadingData() {
            if (this.category) {
                await this.loadCategoryGames(this.category);
            }
            this.loadedData = true;
        },
    },
};
</script>
