<template>
    <div class="header-notice">
        <transition-group
            name="fade"
            mode="out-in"
            class="message-container"
            tag="div"
        >
            <div
                v-for="itemNotice in filteredHeaderNotices"
                :key="itemNotice.id"
                class="header-notice__content main-layout-width"
            >
                <FeIcon
                    class="header-notice__icon"
                    :icon="itemNotice.icon || icons.fill.attention2"
                />
                <span v-html="itemNotice.message"/>
                <FeLink
                    v-if="itemNotice.link"
                    :theme="FeLinkConfig.themes.Primary"
                    class="header-notice__link"
                    @click="submitNoticeFn(itemNotice.link.fn, itemNotice.id)"
                >
                    {{ itemNotice.link.text }}
                </FeLink>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from "pinia";

import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeLink, * as FeLinkConfig from "@ui/FeLink/FeLink.vue";
import { useNoticesStore } from "@store/notices";

const EMAIL_NOTICE_ID = "notices-header__email-confirm";

export default {
    name: "HeaderNotice",

    components: {
        FeIcon,
        FeLink,
    },

    data() {
        return {
            FeLinkConfig,
            icons,
            waiting: false,
        };
    },

    computed: {
        ...mapState(useNoticesStore, {
            headerNotices: "headerNotices",
        }),
        filteredHeaderNotices() {
            return this.headerNotices.filter(({ id }) => id !== EMAIL_NOTICE_ID);
        },
    },

    methods: {
        async submitNoticeFn(propFn) {
            try {
                if (!this.waiting) {
                    this.waiting = true;
                    await propFn();
                    this.waiting = false;
                }
            } catch (err) {
                this.waiting = false;
            }
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.header-notice {
    @include font-size--Caption2($font-weight--normal);
    background: var(--color-secondary-3);
    color: var(--color-text-alt);

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 0.5rem 0;
        box-sizing: border-box;
    }

    &__icon {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
    }

    &__link :deep(a) {
        margin-left: .25rem;
        @include font-size--Caption2($font-weight--normal);
    }
}
</style>
