<template>
    <div
        :class="bannerClasses"
        class="vip-adv__banner gap-m align-content-center"
    >
        <div v-if="isFinishLastDay" class="gap-row-m">
            <VipAdventuresBadges :is-finish-day="isFinishLastDay"/>
            <h1
                class="font-weight--black"
                v-html="bannerTexts.title"
            />

            <div class="gap-row-xs">
                <div>
                    <p
                        class="font__caption-1--normal"
                        v-html="bannerTexts.subTitle"
                    />
                </div>

                <p v-html="bannerTexts.description"/>

                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    full-width-mobile
                    @click="$router.push({name: routeNames.tournamentsAll})"
                >
                    {{ $t("ADVENTURE.BANNER_FINISHED.BANNER") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="themesButton.PrimarySmall"
                    full-width-mobile
                    @click="$router.push({name: routeNames.tournamentsAll})"
                >
                    {{ $t("ADVENTURE.BANNER_FINISHED.BANNER") }}
                </FeButton>
            </div>
        </div>

        <div v-else class="gap-row-m">
            <VipAdventuresBadges :is-finish-day="isFinishLastDay"/>
            <div>
                <strong v-html="bannerTexts.title"/>
                <h1
                    class="color-primary font-weight--black"
                    v-html="bannerTexts.prizePool"
                />
            </div>

            <p v-html="bannerTexts.description"/>
        </div>

        <FeImage
            :src="bannerImages.default"
            :src-retina="bannerImages.retina"
            alt="vip adventure gifts"
            class="vip-adv__banner-img"
        />
    </div>
</template>

<script lang="ts" setup>

import { routeNames } from "@router/routeNames";
import { useRootStore } from "@store/root";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useVipAdventures } from "@store/user/vipAdventures";
import { useI18n } from "vue-i18n";
import type { TranslateResult } from "vue-i18n";
import { formatDateVipAdv } from "@modules/VipAdventures/consts";
import FeButton, { themes as themesButton } from "@ui/FeButton/FeButton.vue";
import VipAdventuresBadges from "@modules/VipAdventures/VipAdventuresLabels.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import advBanerImg from "./img/adv-baner-img.png";
import advBanerImgX2 from "./img/adv-baner-img@2x.png";

const { t } = useI18n();
const { isMobile } = storeToRefs(useRootStore());

const isFinishLastDay = computed<boolean>(() => {
    const vipAdventuresStore = useVipAdventures();

    const lastDay = vipAdventuresStore.vipAdventuresDays[vipAdventuresStore.vipAdventuresDays.length - 1];
    return Boolean(lastDay?.isCompleted) ||
        vipAdventuresStore.toDay.isAfter(
            dayjs(lastDay?.day, formatDateVipAdv).add(1, "day"),
            "day",
        );
});

const bannerClasses = computed<Record<string, boolean>[]>(() => {
    return [
        {
            "vip-adv__banner--is-finish": isFinishLastDay.value,
            "vip-adv__banner--is-mobile": isMobile.value,
        },
    ];
});

const bannerImages = computed<{ default: string, retina: string }>(() => {
    return {
        default: advBanerImg,
        retina: advBanerImgX2,
    };
});

const bannerTexts = computed<{
    title: TranslateResult;
    description: TranslateResult
    prizePool?: TranslateResult;
    subTitle?: TranslateResult
}>(() => {
    if (isFinishLastDay.value) {
        return {
            title: t("ADVENTURE.BANNER_FINISHED.TITLE"),
            subTitle: t("ADVENTURE.BANNER_FINISHED.SUB_TITLE"),
            description: t("ADVENTURE.BANNER_FINISHED.DESC"),
        };
    }

    return {
        title: t("ADVENTURE.BANNER.SUB_TITLE"),
        prizePool: t("ADVENTURE.BANNER.PRIZE_POOL"),
        description: t("ADVENTURE.BANNER.DESC"),
    };
});

</script>


<style lang="scss" scoped>
@import "~@theme/styles";

.vip-adv__banner {
    grid-auto-flow: row;
    text-align: center;
    justify-items: center;

    p {
        color: var(--color-text-alt);
    }

    &-img {
        width: 10rem;
        grid-row: 1/2;

        @include media(M) {
            width: 19rem;
        }
    }

    @include media(M) {
        grid-auto-flow: column;
        text-align: start;

        h1 {
            text-align: start;
        }
    }

    &--is-finish {
        padding: 1rem;
        border-radius: 1rem;
        background: var(--color-secondary-gradient);

        .fe-button {
            margin: 0 auto;
        }

        @include media(M) {
            border-radius: 1.5rem;
            padding: 1.5rem;
            .fe-button {
                margin: 0;
            }
        }
    }
}
</style>
