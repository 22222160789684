import { Currencies } from "@api/DTO";
import type { ICountries, ICryptoExchangeRates, ICurrencies, IProjectInfo } from "@api/DTO/info";
import {
    loadCountriesReq,
    loadCryptoExchangeRatesReq,
    loadCurrenciesReq,
    loadProjectInfoReq,
} from "@api/requests/info";
import currencyOfCountry from "@helpers/currencyOfCountry";
import { useJackpots } from "@store/jackpots";
import { useMultilang } from "@store/multilang";
import config from "@theme/configs/config";
import { ENABLE_CURRENCIES } from "@theme/configs/currencies";
import { defineStore, storeToRefs, type Pinia } from "pinia";
import { computed, ref } from "vue";

import { http } from "@api/http";
import { loadStagByReferNameReq, loadSurveyConfigReq } from "@api/requests/configs";
import log from "@controllers/Logger";
import userAgentController, { type IPlatformState } from "@front/core/controllers/UserAgentController";
import type { IPlayerFieldsInfo } from "@src/types/common";
import type { IStagByReferName, ISurveyConfig } from "@src/types/configs";
import { useUserInfo } from "@store/user/userInfo";
import { CONFIG_DEFAULT_COLLECTIONS_MENU_SLUGS, MenuCollectionsMame, SlugCategoriesGames } from "@theme/configs/categoryesGames";

export const useCommon = defineStore("common", () => {
    const jackpotsStore = useJackpots();
    const platform = ref<IPlatformState>();

    if (typeof window !== "undefined") {
        userAgentController().then((platformData) => {
            platform.value = platformData;
        });
    }

    const isMobile = computed<boolean | undefined>(() => {
        return platform.value && platform.value.isMobile;
    });

    const playerFieldsInfo = ref<IPlayerFieldsInfo>();

    async function loadPlayerFieldsInfo({ reload } = { reload: false }): Promise<IPlayerFieldsInfo> {
        if (playerFieldsInfo.value && !reload) {
            return playerFieldsInfo.value;
        }
        try {
            const { data } = await http().get("/api/info/player_fields");
            playerFieldsInfo.value = data;

            return data;
        } catch (err) {
            log.error("LOAD_PLAYER_FIELDS_INFO", err);
            throw err;
        }
    }

    function hasFieldsInContext(context, field) {
        return playerFieldsInfo.value?.contexts[context]?.includes(field);
    }

    function getFieldsType(context, field) {
        return playerFieldsInfo.value?.fields?.find((fieldItem) => fieldItem.field === field);
    }

    const slugMenuGamesCategories = ref<Record<string, SlugCategoriesGames[]>>();

    async function loadCategoriesFileConfig() {
        if (slugMenuGamesCategories.value) {
            return slugMenuGamesCategories.value;
        }

        try {
            const { data } = await http().get<
                Record<string, SlugCategoriesGames[]>
            >("/api/fe/config/menu-categories-games",
            );
            slugMenuGamesCategories.value = data;

            return data;
        } catch (err) {
            log.error("LOAD_CATEGORIES_PAGE_FILE_CONFIG_ERROR", err);
            throw err;
        }
    }

    const isTurnOnJPMystic = computed<boolean>(() => {
        return jackpotsStore.jackpotsActiveList.some(({ identifier }) => {
            return identifier === SlugCategoriesGames.SLUG_CATEGORY_MYSTIC_JACKPOTS;
        });
    });

    function getMenuCategoriesBySlug(slug: MenuCollectionsMame): SlugCategoriesGames[] {
        return (
            slugMenuGamesCategories.value && slugMenuGamesCategories.value[slug] ||
            CONFIG_DEFAULT_COLLECTIONS_MENU_SLUGS[slug]
        ).filter((slug) => {
            if (slug === SlugCategoriesGames.SLUG_CATEGORY_MYSTIC_JACKPOTS) {
                return isTurnOnJPMystic.value;
            }
            return true;
        });
    }

    const stagsByReferName = ref<IStagByReferName>();

    async function loadStagByReferName() {
        try {
            if (stagsByReferName.value) {
                return stagsByReferName.value;
            }

            const data = await loadStagByReferNameReq();
            stagsByReferName.value = data;
            return data;
        } catch (err) {
            throw err;
        }
    }

    const surveyConfig = ref<ISurveyConfig>();

    async function loadSurveyConfig() {
        try {
            const data = await loadSurveyConfigReq();
            surveyConfig.value = data;
            return data;
        } catch (err) {
            log.error("LOAD_SURVEY_CONFIG_ERROR", err);
        }
    }

    const countries = ref<ICountries[]>([]);

    async function loadCountries({ reload } = { reload: false }) {
        if (!reload && countries.value.length) {
            return countries.value;
        }

        const data = await loadCountriesReq();
        if (data) {
            countries.value = data;
        }
    }

    const getCountries = computed(() => {
        const { getUserGeo } = storeToRefs(useMultilang());

        const countriesPrepared = [ ...countries.value ];
        if (getUserGeo && countriesPrepared.length > 0) {
            countriesPrepared.unshift(countriesPrepared.splice(countriesPrepared.findIndex(({ code }) => {
                return code === getUserGeo.value;
            }), 1)[0]);
            return countriesPrepared;
        }
        return countriesPrepared;
    });

    const currencies = ref<ICurrencies[]>([]);
    const defaultCurrency = ref<Currencies>(config.currencyDefault as Currencies);

    const getDefaultCurrency = computed(() => {
        const { getUserGeo: codeByGeoIp } = storeToRefs(useMultilang());
        const defaultCurrencyOfCountry = currencyOfCountry(codeByGeoIp.value) as string;
        return defaultCurrencyOfCountry || defaultCurrency.value;
    });

    async function loadCurrencies() {
        const data = await loadCurrenciesReq();
        if (data) {
            currencies.value = data.filter(({ code }) => {
                return ENABLE_CURRENCIES.some((currencyCodeEnabled) => {
                    return currencyCodeEnabled === code;
                });
            });
        }
    }

    const getAllCurrencies = computed<ICurrencies[]>(() => {
        return currencies.value;
    });

    const isCryptoCurrency = (currency: Currencies): boolean => {
        return !currencies.value.find(({ code }) => code === currency)?.fiat;
    };

    const getCurrencyFiat = computed(() => {
        return currencies.value.filter(({ fiat }) => fiat).map(({ code }) => code);
    });

    const getCurrencyCrypto = computed(() => {
        return currencies.value.filter(({ fiat }) => !fiat).map(({ code }) => code);
    });

    const getSubunitsToUnitsByCode = (codeProp?: Currencies) => {
        const userInfoStore = useUserInfo();
        const currencyInfo = currencies.value.find(({ code }) => {
            return code === (codeProp || userInfoStore.getUserInfo?.currency);
        });
        return currencyInfo?.subunits_to_unit;
    };

    const infoProject = ref<IProjectInfo>();

    async function loadProjectInfo(): Promise<void> {
        infoProject.value = await loadProjectInfoReq();
    }

    const cryptoExchangeRates = ref<ICryptoExchangeRates>();

    async function loadCryptoExchangeRates(): Promise<void> {
        cryptoExchangeRates.value = await loadCryptoExchangeRatesReq();
    }

    return {
        isMobile,

        loadPlayerFieldsInfo,
        playerFieldsInfo,

        hasFieldsInContext,
        getFieldsType,

        loadCategoriesFileConfig,
        getMenuCategoriesBySlug,

        stagsByReferName,
        loadStagByReferName,

        surveyConfig,
        loadSurveyConfig,

        loadCountries,
        getCountries,

        loadCurrencies,
        currencies,
        getAllCurrencies,
        isCryptoCurrency,
        getCurrencyFiat,
        getCurrencyCrypto,
        getSubunitsToUnitsByCode,
        getDefaultCurrency,

        loadProjectInfo,
        infoProject,

        cryptoExchangeRates,
        loadCryptoExchangeRates,
    };

});

export function useCommonFetchService(pinia?: Pinia) {
    const {
        loadCountries,
        loadCurrencies,
        loadProjectInfo,
        loadCryptoExchangeRates,
    } = useCommon(pinia);

    return {
        loadCountries,
        loadCurrencies,
        loadProjectInfo,
        loadCryptoExchangeRates,
    };
}
