import log from "@controllers/Logger";
import { http } from "../http";
export async function exchangeToMoney(exchange) {
    try {
        const { data } = await http().post<void>(
            "/api/comp_points/exchange/money",
            {
                exchange,
            });
        return data;
    } catch (err) {
        log.error("EXCHANGE_TO_MONEY_ERROR", err);
        throw err;
    }
}

export async function loadRatesMoney() {
    try {
        const { data } = await http().get<void>("/api/comp_points/rates/money");
        return data;
    } catch (err) {
        log.error("EXCHANGE_TO_MONEY_ERROR", err);
        throw err;
    }
}

export async function loadUserCompPoints() {
    try {
        const {data} = await http().get("/api/player/comp_points");
        return data;
    } catch (err) {
        log.error("LOAD_USER_COMP_POINTS", err);
    }
}
