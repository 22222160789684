import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";
import m from "./m";
import robotM from "./robot-m";
import robotS from "./robot-s";
import s from "./s";
import xs from "./xs";

const QUEST_SIZE_XS = "xs";
const QUEST_SIZE_S = "s";
const QUEST_SIZE_M = "m";
const QUEST_SIZE_ROBOT_S = "robot-s";
const QUEST_SIZE_ROBOT_M = "robot-m";

const configs = {
    [QUEST_SIZE_XS]: xs,
    [QUEST_SIZE_S]: s,
    [QUEST_SIZE_M]: m,
    [QUEST_SIZE_ROBOT_S]: robotS,
    [QUEST_SIZE_ROBOT_M]: robotM,
};

export default function(type: string): IBaseQuestConfig {
    return configs[type];
}
export const DEFAULT_QUEST_SIZE = QUEST_SIZE_M;
export const QUEST_SIZE_ROBOT_LIST = [ QUEST_SIZE_ROBOT_S, QUEST_SIZE_ROBOT_M ];
