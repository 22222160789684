<template>
    <FeCard
        v-if="termsFilesByLicense.length"
        :theme="themesFeCard.Dark"
        class="terms-download gap-m padding-xs"
    >
        <div class="terms-download__info">
            {{ t("TERMS_AND_CONDITIONS.EFFECTIVE_FROM") }} {{ from }}
            <br>
            {{ t("TERMS_AND_CONDITIONS.LAST_UPDATED") }} {{ lastUpdated }}
        </div>
        <template v-if="featureFlags.enableNewUIComponents">
            <FeButtonNew
                v-for="file in termsFilesByLicense"
                :key="file.url"
                :theme="themes.SecondaryMedium"
                data-test="terms-and-conditions__download--button"
                @click="openFile(file)"
            >
                {{ t("TERMS_AND_CONDITIONS.BUTTON_DOWNLOAD") }}
            </FeButtonNew>
        </template>
        <template v-else>
            <FeButton
                v-for="file in termsFilesByLicense"
                :key="file.url"
                :theme="themesButton.SecondarySmall"
                data-test="terms-and-conditions__download--button"
                @click="openFile(file)"
            >
                {{ t("TERMS_AND_CONDITIONS.BUTTON_DOWNLOAD") }}
            </FeButton>
        </template>
    </FeCard>
</template>

<script setup>
import { computed, toRefs } from "vue";
import i18nInit from "@i18n";
import FeButtonNew from "@ui/New/FeButton.vue";

const { t } = i18nInit.instance || i18nInit.init();

import FeCard, { themes as themesFeCard } from "@ui/FeCard/FeCard.vue";
import FeButton, { themes as themesButton } from "@ui/FeButton/FeButton.vue";
import { useLicense } from "@helpers/license";
import { storeToRefs } from "pinia";
import { useBannerStore } from "@store/banners";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

defineProps({
    from: {
        type: String,
    },
    lastUpdated: {
        type: String,
    },
});

const { usedLicense } = toRefs(useLicense());
const { termsFiles } = storeToRefs(useBannerStore());
const termsFilesByLicense = computed(() => {
    const filesForLicense = termsFiles.value.filter((file) =>
        file.categories.includes(usedLicense.value),
    );
    return filesForLicense.length ?
        filesForLicense :
        termsFiles.value.filter((file) => file.categories.length === 1);
});

const openFile = (file) => {
    window.open(file.url, "_blank");
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.terms-download {
    @include font-size--Body;
    color: var(--color-text-body);
    grid-auto-flow: row;

    @include media(S) {
        justify-content: space-between;
        flex-direction: row;
        grid-auto-flow: column;
    }
}

</style>
