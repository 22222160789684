import { useUserBalance } from "@store/user/userBalance";
import i18n from "@i18n";

export function parseContent(content, variables) {
    if (!variables) {
        return;
    }
    const { userCurrency } = useUserBalance();

    const resolvedVariables = Object.entries(variables).reduce((acc, [ key, value ]) => {
        acc[key] = value[userCurrency] || value.EUR;
        return acc;
    }, {});

    return i18n.instance.rt(content, resolvedVariables);
}
