<template>
    <div class="bookmark-item">
        <FeImage
            :src="props.image"
            class="bookmark-item__logo"
            @click="clickBookmark"
        />
        <slot />
    </div>
</template>

<script setup lang="ts">
import FeImage from "@ui/FeImage/FeImage.vue";

interface IProps {
    image: string;
}

const props = defineProps<IProps>();

const emit = defineEmits<{(e: "bookmark-click"): void }>();

function clickBookmark() {
    emit("bookmark-click");
}
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.bookmark-item {
    position: relative;
    transition: all .5s;
    border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
    padding: .5rem;
    background: var(--color-secondary-gradient);
    cursor: pointer;
    display: flex;
    overflow: hidden;
    width: fit-content;

    &__logo {
        width: 3rem;
        height: 3rem;

        @include media(XM) {
            width: 5rem;
            height: 5rem;
        }
    }
}
</style>
