<template>
    <div
        v-if="isLogged"
        class="comp-points gap-s"
    >
        <CompPointBalanceInformer :position-tooltip="positionTooltip"/>

        <FeButtonNew
            v-if="featureFlags.enableNewUIComponents"
            :theme="themes.SecondaryMedium"
            full-width-mobile
            class="comp-points__new-btn"
            @click="handleClickOpenCashbox"
        >
            <FeIcon :icon="FeIconConfig.icons.fill.exchange"/>
            {{ $t("COMP_PINTS.BUTTON_SWAP") }}
        </FeButtonNew>
        <FeButton
            v-else
            :icon="FeIconConfig.icons.fill.exchange"
            :theme="FeButtonConfig.themes.SecondarySmall"
            full-width-mobile
            @click="handleClickOpenCashbox"
        >
            {{ $t("COMP_PINTS.BUTTON_SWAP") }}
        </FeButton>
    </div>
</template>

<script>
import * as FeIconConfig from "@ui/FeIcon/FeIcon";

import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import CompPointBalanceInformer from "@modules/CompPoints/components/CompPointBalanceInformer.vue";
import { openExchangeCalculator } from "@helpers/helperCompointExOpen";
import { mapState } from "pinia";
import { useCompPointsStore } from "@store/compPoints/compPointsStore";
import { useUserInfo } from "@store/user/userInfo";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";
import FeIcon from "@ui/FeIcon/FeIcon.vue";

export default {
    name: "CompPoints",

    components: {
        FeIcon,
        FeButtonNew,
        FeButton,
        CompPointBalanceInformer,
    },

    props: {
        infoMode: {
            type: Boolean,
            default: true,
        },
        positionTooltip: {
            type: String,
        },
    },

    data() {
        return {
            FeIconConfig,
            FeButtonConfig,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
        ...mapState(useCompPointsStore, {
            userComPointBalance: "getUserCompPoints",
        }),
        handleClickOpenCashbox() {
            return openExchangeCalculator.bind({}, this.$store);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.comp-points {
    justify-content: center;
    justify-items: center;

    &__new-btn {
        :deep(.label) {
            display: flex;
            gap: 0.5rem;
        }
    }
}
</style>
