import dataTestsAttrs from "./data-test-attrs";
import { ref } from "vue";

export const baseFieldProps = {
    modelValue: {
        type: [ String, Number, Object ],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: "",
    },
    dataTest: {
        type: String,
    },
    autocomplete: {
        type: String,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "text",
    },
    label: {
        type: String,
    },
    name: {
        type: String,
    },
    rules: {
        type: [ String, Array, Object ],
    },
    vid: {
        type: String,
    },
    description: {
        type: String,
    },
    maxlength: {
        type: Number,
    },
    step: {
        type: Number,
    },
    inputmode: {
        type: String,
        default: "text",
    },
    pattern: {
        type: String,
    },
};

export function useBaseActions(emit) {
    function handleClick() {
        emit("click");
    }

    function handleInput(val) {
        emit("update:modelValue", val);
    }

    function handleChange(val) {
        emit("change", val);
    }

    return {
        handleClick,
        handleInput,
        handleChange,
    };
}

export function useBaseField(emit) {
    const dataTestsAttrsRef = ref(dataTestsAttrs);

    return {
        dataTestsAttrsRef,
        ...useBaseActions(emit)
    };
}
