import { useUserInfo } from "@store/user/userInfo";
import { storeToRefs } from "pinia";
import { MODAL_USER_PERSONAL_INFO } from "@config/modalsNames";
import { defineAsyncComponent } from "vue";
import { useCommon } from "@store/common";
import { useUIStore } from "@store/ui";

const userPersonalInfo = defineAsyncComponent(() => {
    return import("@modules/Popups/UserPersonalInfo/UserPersonalInfo.vue");
});

export function userHasEmptyField({ mobile_phone, first_name, last_name, date_of_birth, country, city, address, postal_code }) {
    return !mobile_phone || !first_name || !last_name || !date_of_birth || !country || !city || !address || !postal_code;
}

export const showPreCashboxPopup = () => {
    const { getUserInfo: userInfo } = storeToRefs(useUserInfo());
    const { getCurrencyFiat } = useCommon();
    const { setShowModal } = useUIStore();

    if (
        Boolean(userInfo.value.id) &&
        userHasEmptyField(userInfo.value) &&
        getCurrencyFiat.includes(userInfo.value.currency)
    ) {
        setShowModal({
            name: MODAL_USER_PERSONAL_INFO,
            component: userPersonalInfo,
            mobileFriendly: true,
            fullScreen: true,
        });
        return true;
    }
    return false;
};
