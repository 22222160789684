import { VIP_CLUB_IDS } from "@components/VipClub/consts";
import bronze from "@components/VipClub/img/bronze.png";
import bronzeX2 from "@components/VipClub/img/bronze@2x.png";
import diamond from "@components/VipClub/img/diamond.png";
import diamondX2 from "@components/VipClub/img/diamond@2x.png";
import gold from "@components/VipClub/img/gold.png";
import goldX2 from "@components/VipClub/img/gold@2x.png";
import platinum from "@components/VipClub/img/platinum.png";
import platinumX2 from "@components/VipClub/img/platinum@2x.png";
import regular from "@components/VipClub/img/regular.png";
import regularX2 from "@components/VipClub/img/regular@2x.png";
import silver from "@components/VipClub/img/silver.png";
import silverX2 from "@components/VipClub/img/silver@2x.png";

export const VIP_CLUB_STATUSES = {
    [VIP_CLUB_IDS.BRONZE]: "Bronze",
    [VIP_CLUB_IDS.REGULAR]: "Regular",
    [VIP_CLUB_IDS.SILVER]: "Silver",
    [VIP_CLUB_IDS.GOLD]: "Gold",
    [VIP_CLUB_IDS.PLATINUM]: "Platinum",
    [VIP_CLUB_IDS.DIAMOND]: "Diamond",
};

interface IImageConfig {
    src: string;
    srcRetina?: string;
}

const IMG_REGULAR: IImageConfig = {
    src: regular,
    srcRetina: regularX2,
};

const IMG_BRONZE: IImageConfig = {
    src: bronze,
    srcRetina: bronzeX2,
};

const IMG_SILVER: IImageConfig = {
    src: silver,
    srcRetina: silverX2,
};

const IMG_GOLD: IImageConfig = {
    src: gold,
    srcRetina: goldX2,
};

const IMG_PLATINUM: IImageConfig = {
    src: platinum,
    srcRetina: platinumX2,
};

const IMG_DIAMOND: IImageConfig = {
    src: diamond,
    srcRetina: diamondX2,
};

export const IMG_STATUSES: Record<number, IImageConfig> = {
    [VIP_CLUB_IDS.BRONZE]: IMG_BRONZE,
    [VIP_CLUB_IDS.REGULAR]: IMG_REGULAR,
    [VIP_CLUB_IDS.SILVER]: IMG_SILVER,
    [VIP_CLUB_IDS.GOLD]: IMG_GOLD,
    [VIP_CLUB_IDS.PLATINUM]: IMG_PLATINUM,
    [VIP_CLUB_IDS.DIAMOND]: IMG_DIAMOND,
};

export const IMG_BY_NAME_STATUSES: Record<string, IImageConfig> = {
    Regular: IMG_REGULAR,
    Bronze: IMG_BRONZE,
    Silver: IMG_SILVER,
    Gold: IMG_GOLD,
    Platinum: IMG_PLATINUM,
    Diamond: IMG_DIAMOND,
};

export interface IStatus {
    id: number;
    name: string;
    img: IImageConfig;
    order: number;
}

interface IStatuses {
    [key: string]: IStatus;
}

export const STATUSES: IStatuses = {
    REGULAR: {
        id: VIP_CLUB_IDS.REGULAR,
        name: "Rising star",
        img: IMG_REGULAR,
        order: 0,
    },
    BRONZE: {
        id: VIP_CLUB_IDS.BRONZE,
        name: "Bronze",
        img: IMG_BRONZE,
        order: 1,
    },
    SILVER: {
        id: VIP_CLUB_IDS.SILVER,
        name: "Silver",
        img: IMG_SILVER,
        order: 2,
    },
    GOLD: {
        id: VIP_CLUB_IDS.GOLD,
        name: "Gold",
        img: IMG_GOLD,
        order: 3,
    },
    PLATINUM: {
        id: VIP_CLUB_IDS.PLATINUM,
        name: "Platinum",
        img: IMG_PLATINUM,
        order: 4,
    },
    DIAMOND: {
        id: VIP_CLUB_IDS.DIAMOND,
        name: "Diamond",
        img: IMG_DIAMOND,
        order: 5,
    },
};

export const DIAMOND_STATUS = "diamond";

export const VIP_PROGRESS_CURRENCIES = {
    EUR: "EUR",
    USD: "USD",
    CAD: "CAD",
    AUD: "AUD",
    NOK: "USD",
    PLN: "USD",
    NZD: "NZD",
    JPY: "USD",
    BRL: "BRL",
    INR: "INR"
};

export const MOCKED_PROGRESS_SUM = {
    USD: {
        betSum: 6000000,
        depSum: 600000,
    },
    EUR: {
        betSum: 6000000,
        depSum: 600000,
    },
    AUD: {
        betSum: 9000000,
        depSum: 900000,
    },
    CAD: {
        betSum: 9000000,
        depSum: 900000,
    },
    NZD: {
        betSum: 9000000,
        depSum: 900000,
    },
    BRL: {
        betSum: 30000000,
        depSum: 3000000,
    }
};
