import {
    STAG_KEY,
    AFFB_ID_KEY,
    AFFB_ID_DEFAULT
} from "@theme/configs/stagConsts";
import {
    DEFAULT_COUNTRY,
    DEFAULT_LOCALE_BY_COUNTRY,
} from "@theme/configs/constsLocales";

import type { LocaleName, Locales } from "@api/DTO/multilang";
import type { LocationQuery } from "vue-router";

import { defineStore, type Pinia } from "pinia";
import { computed, ref } from "vue";
import i18n from "@i18n";

import stagController from "@controllers/StagController";
import { loadLocalesReq, updateLocalesReq } from "@api/requests/multilang";
import { getLocale } from "@helpers/localeInCookies";
import redirectToLang from "@helpers/redirectToLang";

export const useMultilang = defineStore("multilang", () => {
    const locales = ref<Locales>([]);
    const locale = ref<LocaleName>("");
    const geo = ref<string>("");
    const country = ref<string>("");

    const getDefaultLang = computed(() => {
        return DEFAULT_LOCALE_BY_COUNTRY[country.value] || DEFAULT_LOCALE_BY_COUNTRY.default;
    });

    const getUserLocale = computed(() => {
        return getLocale() || locale.value || getDefaultLang.value;
    });

    const getUserGeo = computed(() => {
        return geo.value || DEFAULT_COUNTRY;
    });

    function setLocale(localeName: LocaleName) {
        i18n.instance.locale = localeName;
        locale.value = localeName;
    }

    async function loadLocales(queryParams: LocationQuery = {}) {
        if (getUserLocale.value) {
            setLocale(getUserLocale.value);
        }

        const query = new URLSearchParams();

        const { affb_id } = queryParams;
        query.set(AFFB_ID_KEY, String(affb_id || AFFB_ID_DEFAULT));

        const stag = stagController.getStag();
        if (stag) {
            query.set(STAG_KEY, String(stag));
        }

        return loadLocalesReq(query.toString()).then((data: Locales) => {
            locales.value = data;
            if (!getUserLocale.value) {
                setLocale(getDefaultLang.value);
            }
        });
    }

    async function updateLocale({ lang }: { lang: LocaleName }) {
        return updateLocalesReq({ locale: lang }).then(() => {
            redirectToLang(lang, getDefaultLang.value, locales.value);
        });
    }

    return {
        locales,
        geo,
        locale,
        country,

        getDefaultLang,
        getUserLocale,
        getUserGeo,

        loadLocales,
        updateLocale,
    };
});

export function useMultilangFetchService(pinia?: Pinia) {
    useMultilang(pinia);

    function loadMultilang() {
        return Promise.resolve();
    };

    return {
        loadMultilang,
    };
}
