<template>
    <VipClubCarouselStatuses
        v-if="userVipClubStatus"
        :steps-data="statusesCollection.vipUsers"
    />

    <div
        v-else
        class="status-block gap-row-s"
    >
        <div class="grid-column--2--m gap-s padding-s">
            <div
                class="gap-row-s align-items-center align-content-center justify-items-center">
                <FeImage
                    :src="bronzeXXLImg"
                    :src-retina="bronzeXXLImgX2"
                    alt="bronze"
                    class="status-block__img"
                />
                <h3 v-t="'VIP_CLUB.COMPARES.DEFAULT.STATUS_NAME'"/>
            </div>
            <VipClubItemTablePreference :list-prefer="listForTable"/>
        </div>
        <div class="conditions-block card-block card-block--bg-tertiary-4 gap-s padding-s">
            <div class="gap-row-xs">
                <strong v-t="'VIP_CLUB.COMPARES.DEFAULT.FIRST_INFO.TITLE'"/>
                <p class="text--color--alt" v-html="$t('VIP_CLUB.COMPARES.DEFAULT.FIRST_INFO.DESC')"/>
            </div>
            <SingUpDepositButton class="margin-center" title-reg-btn="register now"/>

        </div>

    </div>
</template>

<script setup lang="ts">
import FeImage from "@ui/FeImage/FeImage.vue";
import { computed } from "vue";

import SingUpDepositButton from "@components/EnterBtns/SingUpDepositButton.vue";
import VipClubCarouselStatuses from "@components/VipClub/VipClubCarouselStatuses.vue";
import VipClubItemTablePreference from "@components/VipClub/VipClubItemTablePreference.vue";

import { userVipClubStatus } from "@components/VipClub/vipClubHelpers";
import type { IPreferItem, IStatusStepItem } from "@components/VipClub/types";

import bronzeXXLImg from "./img/bronze-xxl.png";
import bronzeXXLImgX2 from "./img/bronze-xxl@2x.png";

export interface IProps {
    statusesCollection: {
        defaultUsers: IPreferItem[];
        vipUsers: IStatusStepItem[];
    };
}

const props = defineProps<IProps>();

const listForTable = computed<IPreferItem[]>(() => {
    return !userVipClubStatus.value ? props.statusesCollection.defaultUsers : [];
});


</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.conditions-block {
    grid-auto-flow: row;
    align-items: center;

    @include media(M) {
        grid-auto-flow: column;

    }
}
</style>
