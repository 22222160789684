<template>
    <span id="select-language" class="language">
        <FeSelect
            v-if="locales.length > 1"
            :name="label"
            :value="modelValue"
            :list="locales"
            :data-test="dataTest"
            @select:change-option="$emit('change', $event)"
        >
            <template v-if="guessCountryCode" #left>
                <Flag :code="guessCountryCode" />
            </template>
            <template #default="{item}">
                <option
                    :key="item.code"
                    :value="item.code"
                    style="text-transform: uppercase"
                >
                    {{ item.code }}
                </option>
            </template>
        </FeSelect>
    </span>
</template>

<script>
import { mapState } from "pinia";

import { baseFieldProps, useBaseField } from "./BaseField.ts";
import FeSelect from "@ui/FeSelect/FeSelect.vue";
import Flag from "@components/CountryFlag/CountryFlag.vue";
import { useMultilang } from "@store/multilang";

export default {
    name: "LanguageSelect",

    components: {
        FeSelect,
        Flag,
    },

    props: {
        ...baseFieldProps,
    },

    emits: [ "change" ],

    setup(_, { emit }) {
        const {
            dataTestsAttrsRef,
            handleInput,
            handleChange,
        } = useBaseField(emit);

        return {
            dataTestsAttrsRef,
            handleInput,
            handleChange,
        };
    },

    computed: {
        ...mapState(useMultilang, {
            locales: "locales",
        }),

        guessCountryCode() {
            return this.modelValue?.split("-")?.[1]?.toLowerCase() || this.modelValue;
        },
    },
};
</script>

<style lang="scss">
.language {
    min-width: 6rem;

    &.fe-tooltip--position-top {
        width: auto;
    }

    .select-lang {
        .select-data-field {
            font-size: 2rem;
            line-height: 1.5rem;
            text-transform: uppercase;

            option {
                text-transform: uppercase;
            }
        }
    }
}

</style>
