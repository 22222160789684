import { useRootStore } from "@store/root";
import { useUIStore } from "@store/ui";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { MODAL_CASHBOX_LIST, MODAL_USER_PERSONAL_INFO } from "@config/modalsNames";
import { routeNames } from "@router/routeNames";

import { useCommon } from "@store/common";
import { useUserInfo } from "@store/user/userInfo";
import { getSurveyWidgetAsync, ISurveyWidgetType } from "./script";

export function useFreshchatSurvey() {
    const { getUserInfo } = storeToRefs(useUserInfo());
    const { isMobile } = storeToRefs(useRootStore());

    const $route = useRoute();
    const { modals } = storeToRefs(useUIStore());

    const timerComplete = ref<boolean>(false);
    const isGamePage = computed<boolean>(() => $route.name === routeNames.gameItem);
    const userIsLogged = computed<boolean>(() => !!getUserInfo.value.id);
    const isOpenedCashbox = computed<boolean>(() => {
        return modals.value.some((modal) => [ ...MODAL_CASHBOX_LIST, MODAL_USER_PERSONAL_INFO ].includes(modal.name));
    });

    const isShowSurvey = computed<boolean>(() => {
        return timerComplete.value
            && !isGamePage.value
            && userIsLogged.value
            && !isOpenedCashbox.value;
    });

    async function initSurvey() {
        const widget = await getSurveyWidgetAsync();
        const { loadSurveyConfig } = useCommon();
        const { survey_id, survey_collector_id, is_enable_widget } = await loadSurveyConfig();

        if (typeof window.SurveyWidget === "undefined" || !survey_id || !survey_collector_id || !is_enable_widget) {
            return;
        }

        widget.init({
            type: isMobile.value
                ? ISurveyWidgetType.COMPACT
                : ISurveyWidgetType.DEFAULT,
            include_unsubscribe: true,
            delay: 5,
            survey_id,
            survey_collector_id,
            contact: {
                work_email: getUserInfo.value.email,
                first_name: getUserInfo.value.first_name || "",
                last_name: getUserInfo.value.last_name || "",
                phone_number: getUserInfo.value.mobile_phone || "",
            },
        });
    }

    watch(() => isShowSurvey.value, (val) => {
        if (val) {
            initSurvey();
        }
    });

    let timerId: ReturnType<typeof setTimeout> | null = null;
    onMounted(() => {
        timerId = setTimeout(() => {
            timerComplete.value = true;
        }, 5 * 60 * 1000);
    });
    onBeforeUnmount(() => {
        if (timerId) {
            clearTimeout(timerId);
        }
    });
}
