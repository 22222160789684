<template>
    <div class="timer">
        <template v-if="text[0] && days && days !== '00'">
            <span class="timer__item day">
                <span class="timer__count">{{ days }}</span>
                <span class="timer__desc">{{ text[0] }}</span>
            </span>
            <span v-if="dots" class="timer__dots day">:</span>
        </template>

        <template v-if="text[1]">
            <span class="timer__item hour">
                <span class="timer__count">{{ hours }}</span>
                <span class="timer__desc">{{ text[1] }}</span>
            </span>
            <span v-if="dots" class="timer__dots hour">:</span>
        </template>

        <template v-if="text[2]">
            <span class="timer__item minute">
                <span class="timer__count">{{ minutes }}</span>
                <span class="timer__desc">{{ text[2] }}</span>
            </span>
            <span v-if="dots" class="timer__dots minute">:</span>
        </template>

        <template v-if="text[3]">
            <span class="timer__item second">
                <span class="timer__count">{{ seconds }}</span>
                <span class="timer__desc">{{ text[3] }}</span>
            </span>
        </template>
    </div>
</template>

<script>

import timerMixin from "@components/Timer/timerMixin.js";

export default {
    name: "Timer",

    mixins: [ timerMixin ],
};
</script>

<style>
.timer,
.timer__item {
    display: flex;
}

.timer {
    gap: .25rem;
    margin-right: .25rem;
}
</style>
