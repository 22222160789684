import { getBaseUrl } from "@helpers/BaseUrlHelper";
import NavigatorController from "@plugins/Navigator";
import { createVue3App } from "@src/app";
import { useContextStore } from "@store/context";
import { UAParser as uaParser } from "ua-parser-js";
import { isFrozenUA } from "ua-parser-js/helpers";
import Vue3Mask from "vue3-mask";
import Vue3OutsideEvents from "vue3-outside-events";
import Vue3TouchEvents from "vue3-touch-events";

import log from "@controllers/Logger";
import metriks from "@controllers/MetriksController";
import initSentry from "@controllers/SentryController";
import { initSw } from "@helpers/serviceWorker";
import { useUserInfo } from "@store/user/userInfo";
import "intersection-observer";
import { storeToRefs } from "pinia";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { scrollend } from "scrollyfills";

log.init({}, (obj: any) => {
    fetch("/log", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(obj),
    });
});
window.addEventListener("load", () => {
    fetch("/performance", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(window.performance.timing),
    });
    if (window.navigator?.connection) {
        const { rtt, downlink, effectiveType } = window.navigator.connection;
        fetch("/network-performance", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
                rtt,
                downlink,
                effectiveType,
            }),
        });
    }
});

interface IMyWindow extends Window {
    __INITIAL_STATE__: any;
}

declare var window: IMyWindow;

(async () => {
    const uaHints = isFrozenUA(navigator.userAgent) ?
        await uaParser().withClientHints() :
        uaParser(navigator.userAgent);
    const { app, router, EventBus } = await createVue3App({
        ua: navigator.userAgent,
        initialState: window.__INITIAL_STATE__,
        piniaInitialState: window.__pinia,
        uaHints,
        baseUrl: getBaseUrl(window.location.pathname),
    });
    app.use(Vue3Mask);
    app.use(Vue3OutsideEvents);
    app.use(Vue3TouchEvents);

    if (!DEV) {
        initSentry(app, router);
    }

    const { getUserInfo: userInfo } = storeToRefs(useUserInfo());
    NavigatorController.init(EventBus);

    const { getContext } = useContextStore();
    getContext().then(() => {
        metriks.init(app, {
            router,
            user: userInfo.value,
        });
        initSw();
    });

    router.isReady().then(() => {
        return app.mount("#app");
    });

})();
