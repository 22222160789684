import { COUNTRIES } from "@theme/configs/constsLocales";

export const STAG_KEY = "stag";
export const STAG_PARTNER_KEY = "partner-stag";
export const AFFB_ID_KEY = "affb_id";
export const AFFB_ID_DEFAULT = 122;

export enum REFERRER {
    GOOGLE = "google",
    BING = "bing",
    YAHOO = "yahoo",
}

export const DEFAULT_STAGS_COUNTRY_REFER: Record<string, Record<REFERRER, string>> = {
    [COUNTRIES.CANADA]: {
        [REFERRER.GOOGLE]: "97251_60214a36eb3e175502a40cb1",
        [REFERRER.BING]: "97251_602150e2eb3e175502a4173f",
        [REFERRER.YAHOO]: "97251_602150ef191a418c96f34f3f",
    },
    [COUNTRIES.AUSTRALIA]: {
        [REFERRER.GOOGLE]: "97251_6021512b191a418c96f34f9f",
        [REFERRER.BING]: "97251_60215136eb3e175502a417bd",
        [REFERRER.YAHOO]: "97251_6021513f6ed54bdde36083a7",
    },
    [COUNTRIES.GERMANY]: {
        [REFERRER.GOOGLE]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.BING]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.YAHOO]: "97251_668428ca8d2506ff26be17e2",
    },
    [COUNTRIES.AUSTRIA]: {
        [REFERRER.GOOGLE]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.BING]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.YAHOO]: "97251_668428ca8d2506ff26be17e2",
    },
    [COUNTRIES.ITALY]: {
        [REFERRER.GOOGLE]: "97251_66842959e6d8dce5434cf652",
        [REFERRER.BING]: "97251_66842959e6d8dce5434cf652",
        [REFERRER.YAHOO]: "97251_66842959e6d8dce5434cf652",
    },
    [COUNTRIES.AUSTRIA]: {
        [REFERRER.GOOGLE]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.BING]: "97251_668428ca8d2506ff26be17e2",
        [REFERRER.YAHOO]: "97251_668428ca8d2506ff26be17e2",
    },
    others: {
        [REFERRER.GOOGLE]: "97251_668429b0e6d8dce5434d02d5",
        [REFERRER.BING]: "97251_668429b0e6d8dce5434d02d5",
        [REFERRER.YAHOO]: "97251_668429b0e6d8dce5434d02d5",
    }
};
