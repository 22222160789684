<template>
    <div class="producer-image">
        <img
            class="producer-image__image"
            :class="`type--${ type }`"
            :data-class="producer"
            :src="`/img/sprites/products-sprite-${ type || colorTheme }.svg?${ environment.version }`"
            :alt="producerAlt"
        >
    </div>
</template>
<script>
import { mapState } from "pinia";
import { useEnvironments } from "@store/environments";
import { useUIStore } from "@store/ui";

export const THEMES = {
    LIGHT: "theme-light",
    DARK: "theme-dark",
    GRAY: "theme-gray",
};

export default {
    name: "ProducerImage",

    props: {
        producer: {
            type: String,
        },
        type: {
            type: String,
            default: "",
        },
    },

    computed: {
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),
        ...mapState(useUIStore, {
            colorTheme: "colorTheme",
        }),
        producerAlt() {
            return `${this.producer} ${this.$t("GAME_NAV.CATEGORIES.POKIES").toLowerCase()}`;
        },
    },
};
</script>
<style lang="scss" src="./style.scss"></style>
