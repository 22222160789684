import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";
import { mockLevels } from "@modules/Quest/constants/robot-m/mock";

// @ts-ignore
import questRobotMDesktopX2 from "@modules/Quest/constants/robot-m/img/quest-robot-m-desktop-2x.jpg";
// @ts-ignore
import questRobotMDesktop from "@modules/Quest/constants/robot-m/img/quest-robot-m-desktop.jpg";
// @ts-ignore
import questRobotMMobileX2 from "@modules/Quest/constants/robot-m/img/quest-robot-m-mob-2x.jpg";
// @ts-ignore
import questRobotMMobile from "@modules/Quest/constants/robot-m/img/quest-robot-m-mob.jpg";
// @ts-ignore
import questRobotMLogoX2 from "./img/quest-robot-m--logo-x2.png";
// @ts-ignore
import questRobotMLogo from "./img/quest-robot-m--logo.png";

import robot from "@modules/Quest/images/robot-new.svg";

const config: IBaseQuestConfig = {
    mockLevels,
    imageConfig: {
        logo: {
            main: questRobotMLogo,
            retina: questRobotMLogoX2,
        },
        desktop: {
            main: questRobotMDesktop,
            retina: questRobotMDesktopX2,
        },
        mobile: {
            main: questRobotMMobile,
            retina: questRobotMMobileX2,
        },
        bookmarkIcon: robot

    }
};

export default config;
