import { storeToRefs } from "pinia";
import { ref } from "vue";

import { KEY_IS_SHOWED_POPUP_IN_SESSION, PROVIDERS_GROUPS } from "@modules/Popups/PopupProviderNew/constants";
import PopupProviderNew from "@modules/Popups/PopupProviderNew/PopupProviderNew.vue";
import Modal from "@plugins/Modal";
import { ProvidersNames } from "@src/types/game";
import { useGamesProviders } from "@store/games/gamesProviders";
import { useUserStatuses } from "@store/user/userStatuses";

export function usePopupNewProvider() {
    const userStatusesStore = useUserStatuses();
    const gamesProvidersStore = useGamesProviders();
    const { getUserGroups } = storeToRefs(userStatusesStore);
    const { collections } = storeToRefs(gamesProvidersStore);

    function checkToShowPopup(): void {
        const isShowedPopupInSession = ref<boolean>(JSON.parse(sessionStorage.getItem(KEY_IS_SHOWED_POPUP_IN_SESSION) || "false"));

        const usersInGroupForShowPopup = PROVIDERS_GROUPS.find(({ targetGroup }) =>
            getUserGroups.value.includes(targetGroup));

        if (!isShowedPopupInSession.value && usersInGroupForShowPopup) {
            const { groupWasShowed, nameProvider } = usersInGroupForShowPopup;

            if (!getUserGroups.value.includes(groupWasShowed)) {
                showPopup(groupWasShowed, nameProvider);
            }

            if (!collections[nameProvider]) {
                gamesProvidersStore.loadGamesProviders();

            }
        }
    }

    function showPopup(groupWasShowed: number, nameProvider: ProvidersNames): void {
        Modal.show({
            name: "popup-provider-new",
            component: PopupProviderNew,
            mobileFriendly: true,
            props: {
                groupIdWhoWasShowed: groupWasShowed,
                nameProvider,
            },
        });

        sessionStorage.setItem(KEY_IS_SHOWED_POPUP_IN_SESSION, JSON.stringify(true));
    }

    return {
        checkToShowPopup,
    };
}
