<template>
    <div
        :class="gameItemClass"
        :data-test="dataTest"
        class="game-item gap-row-xs"
        @mouseenter="activeHover = true"
        @mouseleave="activeHover = false"
    >
        <div
            class="game-item__preview"
            :style="{ 'background-image': 'url(' + defaultImgSrc + ')' }"
        >
            <div class="game-item__preview-container">
                <div class="game-item__preview-content">
                    <div class="game-item__preview-left">
                        <div
                            v-if="specialBadge"
                            :class="specialBadge.name"
                            class="game-item__special-badge"
                        >
                            <FeImage
                                :src="specialBadge.img"
                                class="game-item__special-badge-image"
                                :alt="specialBadge.name"
                            />
                        </div>
                        <div v-if="game.badges" class="game-item__badges">
                            <FeBadge
                                v-for="(badge, index) in game.badges"
                                :key="index"
                                :data-test="`game-item__badge--${badge.type}`"
                                :theme="badge.theme"
                            >
                                <FeImage
                                    :src="ICON_BADGES_BY_TYPE[badge.type]"
                                    class="game-item__badges-image"
                                    :alt="badge.type"
                                />
                            </FeBadge>
                        </div>
                    </div>
                    <div class="game-item__preview-right"/>

                    <template v-if="game.preview">
                        <FeImage
                            v-if="isAnimatedThumb"
                            :alt="gameAlt"
                            :src="game.preview"
                            class="game-item__thumb"
                            :type="typeImage"
                            @on-load="onLoad"
                        />
                        <FePicture
                            v-else
                            :alt="gameAlt"
                            :src="game.preview"
                            :type="typeImage"
                            class="game-item__thumb"
                            @on-load="onLoad"
                        />
                    </template>

                    <div v-if="jackpotTotalGameItem" class="game-item__jackpot text--color--alt">
                        <template v-if="isLarge">
                            <p class="game-item__jackpot__producer">
                                {{ game.provider }}
                            </p>
                            <p class="game-item__jackpot__game">
                                {{ game.name }}
                            </p>
                        </template>
                        <div class="game-item__jackpot__data">
                            <FeIcon :icon="iconJackpot" class="game-item__jackpot__icon"/>
                            <span class="game-item__jackpot__total">{{ jackpotTotalGameItem }}</span>
                        </div>
                    </div>
                </div>

                <transition name="overlay-animation">
                    <div v-if="activeHover" class="game-item__overlay">
                        <div
                            v-if="noSupportCurrency"
                            class="game-item__currency-not-support text--align-center d-flex
                            justify-content--center align-items--center flex-direction--column"
                        >
                            <FeIcon :icon="icons.fill.lock" class="icon-lock"/>
                            <p
                                v-t="'GAME_HALL_BOTTOM.GAME_NOT_SUPPORT_CURRENCY_TITLE'"
                                class="font__caption-1 text--upper text--color--alt game-item__currency-not-support-title"
                            />
                            <p
                                v-t="'GAME_HALL_BOTTOM.GAME_NOT_SUPPORT_CURRENCY'"
                                class="font__caption-2--normal"
                            />
                        </div>
                        <template v-else>
                            <div class="game-item__overlay-footer">
                                <div
                                    v-if="isShowFavoriteButton && !isFavoriteGame"
                                    class="game-item__favorite-btn"
                                    @click.stop="toggleToFavorite"
                                >
                                    <FeIcon
                                        :icon="isFavoriteGame ?
                                            icons.fill.favoriteActive :
                                            icons.outline.favoriteDisable"
                                    />
                                </div>
                                <FeButtonNew
                                    v-if="featureFlags.enableNewUIComponents"
                                    :theme="themes.PrimaryLarge"
                                    class="game-item__btn-play"
                                    data-test="game-item__action--open-game"
                                    :icon="icons.fill.play"
                                    @click.stop.prevent="openGame"
                                />
                                <FeButton
                                    v-else
                                    :theme="FeButtonConfig.themes.PrimarySmall"
                                    class="game-item__btn-play"
                                    data-test="game-item__action--open-game"
                                    @click.stop.prevent="openGame"
                                >
                                    <FeIcon :icon="icons.fill.play"/>
                                </FeButton>

                                <router-link
                                    v-if="game.has_demo_mode"
                                    v-slot="{href}"
                                    :to="{
                                        name: routeNames.gameItem,
                                        params: { ...paramsForUrlGame }
                                    }"
                                >
                                    <a
                                        v-t="'GAME_ITEM.DEMO'"
                                        :href="href"
                                        class="game-item__open-demo"
                                        data-test="game-item__action--open-demo"
                                        @click.prevent="handleClickDemoOpen"
                                    />
                                </router-link>
                            </div>
                        </template>
                    </div>
                </transition>


                <div
                    v-if="isShowFavoriteButton && isFavoriteGame"
                    class="game-item__favorite-btn"
                    @click.stop="toggleToFavorite"
                >
                    <FeIcon
                        :icon="isFavoriteGame ?
                            icons.fill.favoriteActive :
                            icons.outline.favoriteDisable"
                    />
                </div>
            </div>
        </div>
        <p
            class="game-item__bottom font__small-caption"
            @click.prevent="openGame"
        >
            <span class="font__small-caption--bold text--color--alt">{{ game.title }}</span>
            <br>
            <span class="font__small-caption text-color--body">{{ providerName }}</span>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { GameMode } from "@modules/gamesConsts";
import { useCommon } from "@store/common";
import { useGamesProviders } from "@store/games/gamesProviders";
import featureFlags from "@theme/configs/featureFlags";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { computed, ref } from "vue";

import { routeNames } from "@router/routeNames";
import { ICON_BADGES_BY_TYPE } from "./config/badge";

import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeImage from "@ui/FeImage/FeImage.vue";
import FePicture from "@ui/FePicture/FePicture.vue";
import FeBadge from "@ui/FeBadge/FeBadge.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import defaultImg from "@theme/images/BrandImages/logo-sceleton.png";
import logoIconBlack from "@theme/images/BrandImages/logo-icon-black.svg";
import dropAndWin from "./img/drop-and-win.svg";

import { currencyView } from "@helpers/currencyHelper";
import type { IGameItem, IParamsUrlGame } from "@helpers/gameHelpers";
import { paramsUrlGamePage } from "@helpers/gameHelpers";
import { useGamesFavorite } from "@store/games/gamesFavorite";
import { useGameFavoriteHelper } from "@modules/Game/useGameFavotire";
import { useShowPersonalInfoPopup } from "@helpers/useShowPersonalInfoPopup";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { useUserInfo } from "@store/user/userInfo";

import { TypeImage } from "@theme/configs/imgResize";
import type { EnumContextFields } from "@src/types/common";
import type { EnumCurrencies } from "@src/types/currencies";
import { useI18n } from "vue-i18n";
import { useGamesCommon } from "@store/games/gamesStore";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";


const { t } = useI18n();
const $gtm = useGtm();
const $router = useRouter();
const $route = useRoute();
const { getIsLogged, getUserCurrency } = storeToRefs(useUserInfo());

const { getUserInfo: userInfo } = storeToRefs(useUserInfo());

const SPECIAL_CATEGORIES_NAMES = {
    EXCLUSIVE: "exclusive",
    PRAGMATIC_DROP_WINS: "pragmatic-drop-wins",
    PRAGMATIC_DROP_WINS_LIVE: "pragmatic-drop-wins-live",
};

const SPECIAL_CATEGORIES_BADGE_LIST = [
    SPECIAL_CATEGORIES_NAMES.EXCLUSIVE,
    SPECIAL_CATEGORIES_NAMES.PRAGMATIC_DROP_WINS,
    SPECIAL_CATEGORIES_NAMES.PRAGMATIC_DROP_WINS_LIVE,
];

const SPECIAL_CATEGORIES_IMG = {
    [SPECIAL_CATEGORIES_NAMES.EXCLUSIVE]: logoIconBlack,
    [SPECIAL_CATEGORIES_NAMES.PRAGMATIC_DROP_WINS]: dropAndWin,
    [SPECIAL_CATEGORIES_NAMES.PRAGMATIC_DROP_WINS_LIVE]: dropAndWin,
};

interface IProps {
    game: IGameItem;
    isLarge?: boolean;
    category?: string;
    typeImage?: TypeImage;
}

const props = withDefaults(defineProps<IProps>(), {
    typeImage: TypeImage.GAME_THUMB,
});

const defaultImgSrc = ref<string>(defaultImg);
const activeHover = ref<boolean>(false);

const isLogged = computed<boolean>(() => {
    return getIsLogged.value;
});
const userCurrency = computed<EnumCurrencies>(() => {
    return getUserCurrency.value;
});

const isAnimatedThumb = computed<boolean>(() => {
    return props.game?.categories?.includes("animated");
});

const { gamesJackpots } = storeToRefs(useGamesCommon());

const { getSubunitsToUnitsByCode: subUnits } = useCommon();
const { isCryptoCurrency } = useCommon();

const userCurrencyIsCrypto = computed<boolean>(() => {
    return isCryptoCurrency(userCurrency.value);
});

const noSupportCurrency = computed<boolean>(() => {
    return isLogged.value &&
        !props.game?.currencies[userCurrency.value]?.id &&
        !userCurrencyIsCrypto.value;
});

const gameAlt = computed<string>(() => {
    return `${props.game.name} ${t("GAME_NAV.CATEGORIES.POKIES").toLowerCase()}`;
});

const dataTest = computed<string>(() => {
    const formattedGameName = props.game.title.toLowerCase().replace(/\s/g, "_");
    return `game-item game-item--${formattedGameName}`;
});

const jackpotTotalGameItem = computed<string | null>(() => {
    const jackpotCents = gamesJackpots.value?.[userCurrency.value]?.[props.game.slug];
    if (!jackpotCents) {
        return null;
    }
    return currencyView(jackpotCents, userCurrency.value, null, subUnits(), 2);
});

const iconJackpot = computed<string>(() => {
    return props.isLarge ? icons.outline.jackpot : icons.fill.jackpot777;
});

const { gameIdByCurrency } = storeToRefs(useGameFavoriteHelper(props.game));
const { favoriteGameId } = storeToRefs(useGameFavoriteHelper(props.game));
const { isFavoriteGame } = storeToRefs(useGameFavoriteHelper(props.game));

const paramsForUrlGame = computed<IParamsUrlGame>(() => {
    return paramsUrlGamePage(props.game);
});

const isLoading = computed(() => {
    return (!paramsForUrlGame.value.name && !paramsForUrlGame.value.producer);
});

const gameItemClass = computed<Record<string, boolean | undefined>>(() => {
    return {
        ...(props.game
            ? {
                "game-item--no-logged": !isLogged.value,
                "game-item--large": props.isLarge,
            }
            : {}),
        loading: isLoading.value,
    };
});

const specialBadge = computed<null | { name: string; img: string }>(() => {
    const specialBadgeCategory = SPECIAL_CATEGORIES_BADGE_LIST.find((specialCat) =>
        props.game.categories.includes(specialCat));
    if (specialBadgeCategory) {
        return {
            name: specialBadgeCategory,
            img: SPECIAL_CATEGORIES_IMG[specialBadgeCategory],
        };
    }
    return null;
});

const { gamesProvidersCollectionData } = storeToRefs(useGamesProviders());

const providerName = computed(() => {
    return gamesProvidersCollectionData.value[props.game.provider]?.name || props.game.provider;
});

const isShowFavoriteButton = computed<boolean>(() => {
    return isLogged.value && gameIdByCurrency.value;
});

const { addGameToFavorites, deleteGameFromFavorites } = useGamesFavorite();

async function run(mode: string): Promise<void> {
    if (mode !== GameMode.Demo && await useShowPersonalInfoPopup(props.game.provider as EnumContextFields)) {
        return;
    }

    $router.push({
        name: routeNames.gameItem,
        params: {
            ...paramsForUrlGame.value,
            exitUrl: $route.path,
        },
        query: { mode },
    });

    const { name: game_name, producer: provider } = paramsUrlGamePage(props.game);

    $gtm.customTrackEvent({
        ...$gtm.GAME_EVENTS[mode.toUpperCase()],
        Affiliate: "not set",
        userId: userInfo.value?.id || null,
        game_name,
        provider,
    });
}

function openGame(): void {
    if (isLogged.value) {
        run(GameMode.Real);
        $gtm.customTrackEvent({
            ...$gtm.REGISTRATION_EVENTS.CLICK,
            location: "Game item 'Play' button",
        });
    } else {
        $router.push({ name: routeNames.registration });
    }
}

const isLoadingFavoriteReq = ref<boolean>(false);

async function toggleToFavorite(): Promise<void> {
    if (isLoadingFavoriteReq.value) {
        return;
    }
    isLoadingFavoriteReq.value = true;
    if (isFavoriteGame.value && favoriteGameId.value) {
        await deleteGameFromFavorites(favoriteGameId.value);
    } else {
        await addGameToFavorites(gameIdByCurrency.value);
    }
    isLoadingFavoriteReq.value = false;
}

function onLoad(): void {
    defaultImgSrc.value = "";
}

function handleClickDemoOpen() {
    run(GameMode.Demo);
}

</script>

<style lang="scss" src="./style.scss"></style>
<style lang="scss" scoped>
.game-item__preview {
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-tertiary-4);
}

.game-item {
    &.loading {
        pointer-events: none;
        cursor: wait;
    }
}

.overlay-animation {
    &-leave-active {
        transition: all 0.1s;
    }

    &-enter-active {
        transition: all 0.2s;
    }

    &-enter-from,
    &-leave-to {
        top: 100%;
    }
}
</style>
