<template>
    <a
        href="https://rocketplaypartners.com/"
        target="_blank"
        rel="noindex, nofollow"
        class="rocket-partner-link"
    >
        {{ $t("FOOTER.PARTNER_LINK") }}
        <FeImage
            :src="logoRocketPartners"
            :src-retina="logoRocketPartners2x"
            class="rocket-partner-link__img"
        />
    </a>
</template>

<script>
import FeImage from "@ui/FeImage/FeImage.vue";

import logoRocketPartners from "@modules/Footer/components/FooterRocketPartnerLink/img/logoRocketPartners.png";
import logoRocketPartners2x from "@modules/Footer/components/FooterRocketPartnerLink/img/logoRocketPartners@2x.png";

export default {
    name: "FooterRocketPartnerLink",

    components: {
        FeImage,
    },

    data() {
        return {
            logoRocketPartners,
            logoRocketPartners2x,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.rocket-partner-link {
    display: block;
    @include font-size--Body($font-weight--normal);
    text-decoration: none;
    text-transform: unset;

    &, &:visited {
        color: var(--color-text-alt);
    }

    &__img {
        display: block;
        height: 3rem;
        margin: 0 auto;
        @include media(XM) {
            margin: unset;
        }
    }
}
</style>
