import { STATUSES } from "@components/VipClub/constsNew";
import { VIP_CLUB_STATUSES } from "@components/VipClub/consts";
import type { IUserLevelInfo } from "@src/types/levels";
import type { IUserStatus, UserGroup } from "@src/types/user";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

import { addPlayerToGroup } from "@api";
import { useUserInfo } from "@store/user/userInfo";

import { TEST_GROUP_ID, VIP_STATUSES } from "@store/user/consts";

import { useLevelsStore } from "@store/levels/levelsStore";

const ID_GROUP_FOR_MULTI_ACC = 100;

export const useUserStatuses = defineStore("userStatuses", () => {
    const userStore = useUserInfo();
    const { getUserInfo } = storeToRefs(userStore);
    const getUserLevelInfo = computed<IUserLevelInfo | {}>(() => {
        const levelsStore = useLevelsStore();
        const { id } = getUserInfo.value?.statuses?.find((group) => {
            return !Number(group.id);
        }) || {};

        if (!id) {
            return {};
        }

        return levelsStore.getLevelsById(id);
    });

    const getUserStatuses = computed<IUserStatus[]>(() => {
        return getUserInfo.value.statuses;
    });
    const getUserGroups = computed<UserGroup[]>(() => {
        return getUserStatuses.value.map((group: IUserStatus) => {
            return Number(group.id) || group.id;
        });
    });

    const isUserTester = computed<boolean>(() => {
        return getUserGroups.value.includes(TEST_GROUP_ID);
    });

    const isMultiAccount = computed<boolean>(() => {
        return getUserGroups.value.includes(ID_GROUP_FOR_MULTI_ACC);
    });

    const isVip = computed<boolean>(() => {
        return VIP_STATUSES.some((status) => getUserGroups.value.includes(status));
    });

    const isDiamond = computed<boolean>(() => {
        return getUserGroups.value.includes(STATUSES.DIAMOND.id);
    });

    const userVipStatus = computed<string>(() => {
        const statusGroup = VIP_STATUSES.find(status => getUserGroups.value.includes(status));
        return VIP_CLUB_STATUSES[statusGroup];
    });

    async function addUserToGroup(groupForAdding: string | number) {
        if (!getUserGroups.value.includes(groupForAdding)) {
            await addPlayerToGroup(groupForAdding);
            userStore.addUserGroup({ id: groupForAdding, name: "" });
        }
    }

    return {
        getUserLevelInfo,
        getUserStatuses,
        getUserGroups,
        isUserTester,
        isMultiAccount,
        isVip,
        isDiamond,
        userVipStatus,

        addUserToGroup,
    };
});
