export const MODAL_LOGIN_FORM = "modal-login-form";
export const MODAL_SING_UP = "modal-sign-up";
export const MODAL_CASHBOX_DASHBOARD = "modal-cashbox-dashboard";
export const MODAL_CASHBOX_STEP_WITHDRAW = "modal-cashbox-step-withdraw";
export const MODAL_CASHBOX_STEP_DEPOSIT = "modal-cashbox-step-deposit";
export const MODAL_CASHBOX_STEP_DEP_ONE_CLICK = "modal-cashbox-deposit-one-click";
export const MODAL_CASHBOX_DEPOSIT_UTORG = "modal-cashbox-deposit-utorg";
export const MODAL_REMIND_PASS_FORM = "remind-pass-form";
export const MODAL_CURRENCY_SELECT = "currency-select-model";
export const MODAL_USER_PERSONAL_INFO = "modal-user-personal-info";
export const MODAL_PROFILE_DATA = "modal-profile-data";

export const MODAL_LIMITS = "modal-limits";

export const MODAL_CASHBOX_LIST = [
    MODAL_CASHBOX_DASHBOARD,
    MODAL_CASHBOX_STEP_DEPOSIT,
    MODAL_CASHBOX_STEP_WITHDRAW,
    MODAL_CASHBOX_STEP_DEP_ONE_CLICK,
];
