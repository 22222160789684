import iconLevelBronzeX2 from "@components/JackpotsCustom/img/jackpots-level-bronze@X2.png";
import iconLevelBronze from "@components/JackpotsCustom/img/jackpots-level-bronze@X2.png";
import iconLevelGolden from "@components/JackpotsCustom/img/jackpots-level-gold.png";
import iconLevelGoldenX2 from "@components/JackpotsCustom/img/jackpots-level-gold@X2.png";
import iconLevelSilver from "@components/JackpotsCustom/img/jackpots-level-silver.png";
import iconLevelSilverX2 from "@components/JackpotsCustom/img/jackpots-level-silver@X2.png";

import iconLevelBronzeL from "@components/JackpotsCustom/img/jackpots-level-bronze-L.png";
import iconLevelBronzeLX2 from "@components/JackpotsCustom/img/jackpots-level-bronze-L@X2.png";
import iconLevelGoldenL from "@components/JackpotsCustom/img/jackpots-level-gold-L.png";
import iconLevelGoldenLX2 from "@components/JackpotsCustom/img/jackpots-level-gold-L@X2.png";
import iconLevelSilverL from "@components/JackpotsCustom/img/jackpots-level-silver-L.png";
import iconLevelSilverLX2 from "@components/JackpotsCustom/img/jackpots-level-silver-L@X2.png";

import iconPlace1 from "@components/JackpotsCustom/img/jackpots-wins-place-1.png";
import iconPlace1X2 from "@components/JackpotsCustom/img/jackpots-wins-place-1@X2.png";
import iconPlace2 from "@components/JackpotsCustom/img/jackpots-wins-place-2.png";
import iconPlace2X2 from "@components/JackpotsCustom/img/jackpots-wins-place-2@X2.png";
import iconPlace3 from "@components/JackpotsCustom/img/jackpots-wins-place-3.png";
import iconPlace3X2 from "@components/JackpotsCustom/img/jackpots-wins-place-3@X2.png";
import type { IMapImages } from "@src/types/common";

export const mapLevelIcon: { [key: string]: IMapImages } = {
    ["level_1"]: {
        img: iconLevelGolden,
        imgRetina: iconLevelGoldenX2
    },
    ["level_2"]: {
        img: iconLevelSilver,
        imgRetina: iconLevelSilverX2
    },
    ["level_3"]: {
        img: iconLevelBronze,
        imgRetina: iconLevelBronzeX2
    }
};

export const mapLevelIconLarge: { [key: string]: IMapImages } = {
    ["level_1"]: {
        img: iconLevelGoldenL,
        imgRetina: iconLevelGoldenLX2,
    },
    ["level_2"]: {
        img: iconLevelSilverL,
        imgRetina: iconLevelSilverLX2,
    },
    ["level_3"]: {
        img: iconLevelBronzeL,
        imgRetina: iconLevelBronzeLX2,
    },
};

export const LEVEL_SIZES = {
    SMALL: "small",
};

export const mapWinnersIcons: { [key: string]: IMapImages } = {
    ["place1"]: {
        img: iconPlace1,
        imgRetina: iconPlace1X2,
    },
    ["place2"]: {
        img: iconPlace2,
        imgRetina: iconPlace2X2
    },
    ["place3"]: {
        img: iconPlace3,
        imgRetina: iconPlace3X2
    },
    ["Gold"]: {
        img: iconLevelGoldenL,
        imgRetina: iconLevelGoldenL,
    },
    ["Silver"]: {
        img: iconLevelSilverL,
        imgRetina: iconLevelSilverL
    },
    ["Bronze"]: {
        img: iconLevelBronzeL,
        imgRetina: iconLevelBronzeL
    }
};
