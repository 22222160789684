import { routeNames } from "@router/routeNames";
import { PromoType } from "@store/tournaments/constants";
import { PLACES } from "@controllers/MetriksController/gtmConstants";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";
import {
    emailVerifyPopup,
    logInPopup,
    newPasswordPasswordInPopup,
    remindPasswordInPopup,
    signUpPopup,
} from "@hooks/signUpPopup";
import Modal from "@plugins/Modal";

import MainBase from "@layouts/Main/Main.vue";
import { FeatureFlagConfiguration } from "@front/core/modules/FeatureFlagConfiguration";

const LobbyPage = () => {
    return import("@modules/GameHall/LobbyPage/LobbyPage.vue");
};

const GameHall = () => {
    return import("@modules/GameHall/MainSection.vue");
};

const ProducerPage = () => {
    return import("@modules/GameHall/ProducerPage.vue");
};

const CategoryPage = () => {
    return import("@modules/GameHall/CategoryPage.vue");
};

const GameBase = () => {
    return import("@layouts/Game/Game.vue");
};
const Game = () => {
    return import("@modules/Game/Game.vue");
};


const EmailConfirmation = () => {
    return import("@modules/Auth/components/EmailVerify/EmailConfirmation.vue");
};

const Profile = () => {
    return import("@modules/Profile/Profile.vue");
};
const ProfileMain = () => {
    return import("@modules/Profile/ProfileMain/ProfileMain.vue");
};
const ProfileLevels = () => {
    return import("@modules/Profile/ProfileLevels/ProfileLevels.vue");
};
const ProfileGifts = () => {
    return import("@modules/Gifts/List/List.vue");
};
const ProfileLimits = () => {
    return import("@modules/Profile/ProfileLimits/ProfileLimits.vue");
};
const ProfileSecurity = () => {
    return import("@modules/Profile/ProfileSecurity/ProfileSecurity.vue");
};
const ProfileVerification = () => {
    return import("@modules/Profile/ProfileVerification/ProfileVerification.vue");
};
const ProfileGamesHistoryPage = () => {
    return import("@modules/Profile/ProfileGamesHistory/ProfileGamesHistory.vue");
};

const TournamentPage = () => {
    return import("@modules/Tournament/TournamentPage/TournamentPage.vue");
};

const LotteryPage = () => {
    return import("@modules/Lottery/LotteryPage/LotteryPage.vue");
};
const PromoList = () => {
    return import("@modules/PromoList/PromoList.vue");
};

const PromoDashboard = () => {
    return import("@modules/PromoDashboard/PromoDashboard.vue");
};

const LimitConfirm = () => {
    return import("@modules/Limits/components/LimitConfirm/LimitConfirm.vue");
};

const SupportPage = () => {
    return import("@modules/Support/Support.vue");
};

const ErrorEMP = () => {
    return import("@modules/ErrorEMP/ErrorEMP.vue");
};

const FavoriteGames = () => {
    return import("@modules/FavoriteGames/FavoriteGames.vue");
};

const QuestPage = () => {
    return import("@modules/Quest/Quest.vue");
};

const BenefitsCrypto = () => {
    return import("@views/BenefitsCrypto/BenefitsCrypto.vue");
};

const Achievements = () => {
    return import("@modules/Achievements/Achievements.vue");
};

const LootBoxPage = () => {
    return import("@views/LootBoxPage/LootBoxPage.vue");
};

const compPointsExchange = () => {
    return import("@modules/CompPoints/components/CompPointsExchangePopup.vue");
};

const PopupLayout = () => {
    return import("@layouts/Popup/Popup.vue");
};
const BettingPage = () => {
    return import("@views/BettingPage/BettingPage.vue");
};

const SimpleLayout = () => {
    return import("@layouts/SimpleLayout.vue");
};
const SingUpPage = () => {
    return import("@views/SimplePages/SingUpPage.vue");
};

const VipClub = () => {
    return import("@views/VipClub/VipClub.vue");
};

const LoginPage = () => {
    return import("@views/SimplePages/LoginPage.vue");
};
const EmptyLayout = () => {
    return import("@layouts/EmptyLayout.vue");
};


const EMPTY_COMPONENT = {
    render() {
        return null;
    },
};


const fullWidthPages = [
    routeNames.promoPage,
    routeNames.tournamentPage,
    routeNames.lotteryPage,
    routeNames.questPage,
];

export function styleHyphenFormat(name) {
    const matched = name?.match(/\d+|[a-zA-Z]?[a-zA-Z]+/g) || [];
    return matched.join("-").toLowerCase();
}


function propsFoGameGages(route) {
    const { producer, name } = route.params;
    return {
        gameSlug: `${producer}${name}`,
        name,
    };
}

export default [
    {
        path: "/",
        component: MainBase,
        name: routeNames.mainLayout,
        props: (route) => {
            const routerMatchedNames = [];
            const props = {};

            route.matched.forEach((router) => {
                routerMatchedNames.push(router.name);
            });

            if (routerMatchedNames.includes(routeNames.main)) {
                props.showBanner = true;
            }

            if (routerMatchedNames.some((rN) => {
                return fullWidthPages.includes(rN);
            })) {
                props.fullWidth = true;
            }

            return props;
        },
        children: [
            {
                path: "",
                component: GameHall,
                children: [
                    {
                        path: "",
                        name: routeNames.main,
                        component: LobbyPage,
                    },
                    {
                        path: "/pokies",
                        component: EmptyLayout,
                        children: [
                            {
                                path: "all",
                                name: routeNames.categoryPokiesAll,
                                component: CategoryPage,
                                props: { slug: SlugCategoriesGames.SLUG_CATEGORY_POKIES },
                            },
                            {
                                path: ":slug",
                                name: routeNames.categoryPokies,
                                component: CategoryPage,
                                props: true,
                            },
                        ],
                    },

                    {
                        path: "/favorite-games",
                        component: EmptyLayout,
                        children: [
                            {
                                path: "",
                                name: routeNames.favoriteGames,
                                component: FavoriteGames,
                            },
                        ],
                    },
                    {
                        path: "/live",
                        component: EmptyLayout,
                        children: [
                            {
                                path: "all",
                                name: routeNames.categoryLiveAll,
                                component: CategoryPage,
                                props: { slug: SlugCategoriesGames.SLUG_CATEGORY_LIVE },
                            },
                            {
                                path: ":slug",
                                name: routeNames.categoryTable,
                                component: CategoryPage,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: "/categories/:slug",
                        name: routeNames.categoryGames,
                        component: CategoryPage,
                        props: true,
                    },
                    {
                        path: "/producers/:slug",
                        name: routeNames.producersGames,
                        component: ProducerPage,
                        meta: { type: "Producer" },
                        props: true,
                    },
                    {
                        path: "/producers/:slug/:page",
                        name: routeNames.producersGamesByPage,
                        component: ProducerPage,
                        props: true,
                    },
                    {
                        path: "/cashbox",
                        component: EMPTY_COMPONENT,
                        name: routeNames.cashbox,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                    },

                    {
                        path: "/dashboard",
                        name: routeNames.cashboxDashboard,
                        component: EMPTY_COMPONENT,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                    },
                    {
                        path: "/deposit",
                        name: routeNames.cashboxDeposit,
                        component: EMPTY_COMPONENT,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                        alias: "/accounts/:id/deposit",
                    },
                    {
                        path: "/deposit-bonus",
                        name: routeNames.cashboxDepositDepositBonus,
                        component: EMPTY_COMPONENT,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                    },
                    {
                        path: "/withdraw",
                        name: routeNames.cashboxWithdraw,
                        component: EMPTY_COMPONENT,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                    },
                    {
                        path: "/deposit-fast",
                        name: routeNames.cashboxDepositOneClick,
                        component: EMPTY_COMPONENT,
                        meta: {
                            requiresAuth: true,
                            popup: true,
                            isCashbox: true,
                        },
                    },
                    {
                        path: "/exchange-compoints",
                        name: routeNames.exchangeCompoints,
                        component: EMPTY_COMPONENT,
                        beforeEnter: async () => {
                            Modal.show({
                                name: "modal-exchange-comppoints",
                                component: compPointsExchange,
                                mobileFriendly: true,
                                showClose: false,
                            });
                        },
                        meta: {
                            requiresAuth: true,
                        },
                    },

                ],
            },
            {
                path: "/tournaments",
                component: PromoDashboard,
                children: [
                    {
                        path: "",
                        name: routeNames.tournaments,
                        component: PromoList,
                        props: {
                            typeTournaments: PromoType.TOURNAMENT,
                        },
                    },
                    {
                        path: "all",
                        name: routeNames.tournamentsAll,
                        component: PromoList,
                        props: {
                            typeTournaments: PromoType.ALL,
                        },
                    },
                    {
                        path: "producers",
                        name: routeNames.tournamentsProducers,
                        component: PromoList,
                        props: {
                            typeTournaments: PromoType.PRODUCERS,
                        },
                    },
                    {
                        path: "lotteries",
                        name: routeNames.tournamentsLottery,
                        component: PromoList,
                        props: {
                            typeTournaments: PromoType.LOTTERY,
                        },
                    },
                    {
                        path: "actions",
                        name: routeNames.tournamentsAction,
                        component: PromoList,
                        props: {
                            typeTournaments: PromoType.ACTION,
                        },
                    },
                    {
                        path: "achievements",
                        name: routeNames.achievements,
                        component: Achievements,
                        props: {
                            typeTournaments: PromoType.ACTION,
                        },
                    },
                ],
            },
            {
                path: "tournaments/:slug",
                name: routeNames.tournamentPage,
                component: TournamentPage,
            },
            {
                path: "lottery/:slug",
                name: routeNames.lotteryPage,
                component: LotteryPage,
            },
            {
                path: "/profile/password/edit",
                alias: "/users/password/edit",
                redirect: {
                    name: routeNames.newPassword,
                },
            },
            {
                path: "/profile/confirmation",
                alias: "/users/confirmation",
                name: "confirmation-player",
                redirect: {
                    name: routeNames.emailConfirmation,
                },
            },
            {
                path: "/profile/limits/confirm",
                name: routeNames.userLimitConfirm,
                component: LimitConfirm,
            },
            {
                path: "/profile",
                component: Profile,
                meta: { requiresAuth: true },
                alias: [ "/users" ],
                children: [
                    {
                        path: "",
                        component: ProfileMain,
                        name: routeNames.profile,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "level",
                        component: ProfileLevels,
                        name: routeNames.profileLevels,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "gifts",
                        component: ProfileGifts,
                        name: routeNames.profileGifts,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "verification",
                        component: ProfileVerification,
                        name: routeNames.profileVerification,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "limits",
                        component: ProfileLimits,
                        name: routeNames.profileLimits,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "security",
                        component: ProfileSecurity,
                        name: routeNames.profileSecurity,
                        meta: { requiresAuth: true },
                    },
                    {
                        path: "games-history",
                        component: ProfileGamesHistoryPage,
                        name: routeNames.profileGamesHistory,
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: "/support",
                name: routeNames.supportPage,
                component: SupportPage,
            },
            {
                path: "action/:slug?",
                name: routeNames.questPage,
                component: QuestPage,
            },
            {
                path: "benefits-of-crypto",
                name: routeNames.benefitCrypto,
                component: BenefitsCrypto,
            },

            {
                path: "rocket-wheel",
                name: routeNames.spinWheelPage,
                component: LootBoxPage,
            },
            {
                path: "rocketplay-vipclub",
                name: routeNames.vipClub,
                component: VipClub,
            },
        ],
    },
    {
        path: "/play",
        component: GameBase,
        name: routeNames.gameBase,
        props: propsFoGameGages,
        children: [
            {
                path: ":producer/:name",
                component: Game,
                meta: { notAllowedFor451: true, gtmPlace: PLACES.IN_GAME },
                name: routeNames.gameItem,
                props: (route) => {
                    const { producer, name } = route.params;
                    return {
                        gameSlug: `${producer}${name}`,
                        name,
                    };
                },
            },
        ],
    },
    {
        path: "/oops",
        name: routeNames.oopsPage,
        component: ErrorEMP,
    },

    {
        path: "/accounts/history",
        redirect: {
            name: routeNames.main,
        },
    },
    {
        path: "/sign-in",
        component: EMPTY_COMPONENT,
        name: routeNames.login,
        meta: {
            notAllowedFor451: true,
            requiresGuest: true,
            popup: true,
        },
        beforeEnter: logInPopup,
    },
    {
        path: "/sign-up",
        component: EMPTY_COMPONENT,
        name: routeNames.registration,
        meta: {
            requiresGuest: true,
            popup: true,
            notAllowedFor451: true,
        },
        beforeEnter: signUpPopup,
    },
    {
        path: "/remind",
        component: EMPTY_COMPONENT,
        name: routeNames.remind,
        beforeEnter: remindPasswordInPopup,
        meta: {
            requiresGuest: true,
            popup: true,
            notAllowedFor451: true,
        },
    },
    {
        path: "/new-password",
        component: EMPTY_COMPONENT,
        name: routeNames.newPassword,
        beforeEnter: newPasswordPasswordInPopup,
        meta: {
            popup: true,
            metaUrl: "/new-password",
            notAllowedFor451: true,
        },
    },
    {
        path: "/email-verify",
        component: EMPTY_COMPONENT,
        name: routeNames.emailVerify,
        beforeEnter: emailVerifyPopup,
        meta: {
            popup: true,
            notAllowedFor451: true,
        },
    },
    {
        path: "/confirmation",
        component: EmailConfirmation,
        props: (route) => {
            return { confirmationToken: route.query.confirmation_token };
        },
        name: routeNames.emailConfirmation,
        meta: {
            popup: true,
        },
    },
    {
        path: "/sport",
        component: EmptyLayout,
        children: [
            {
                path: "",
                component: SimpleLayout,
                children: [
                    {
                        path: "registration",
                        name: routeNames.bettingReg,
                        component: SingUpPage,
                        meta: {
                            requiresGuest: true,
                            popup: true,
                            notAllowedFor451: true,
                        },
                    },
                    {
                        path: "login",
                        name: routeNames.bettingLogin,
                        component: LoginPage,
                        meta: {
                            requiresGuest: true,
                            popup: true,
                            notAllowedFor451: true,
                        },
                    },
                ],
            },

            {
                path: "",
                component: PopupLayout,
                meta: {
                    popup: true,
                },
                children: [
                    {
                        path: ":param?",
                        component: BettingPage,
                        name: routeNames.bettingMain,
                        meta: {
                            popup: true,
                        },
                    },
                ],
            },

        ],
    },
    {
        path: "/registration",
        component: SimpleLayout,
        children: [
            {
                path: "",
                component: SingUpPage,
                name: routeNames.singUpPage,
                meta: {
                    requiresGuest: true,
                    popup: true,
                    notAllowedFor451: true,
                },
            },
        ],
    },
    {
        path: "/login",
        component: SimpleLayout,
        children: [
            {
                path: "",
                component: LoginPage,
                name: routeNames.loginPage,
                meta: {
                    requiresGuest: true,
                    popup: true,
                    notAllowedFor451: true,
                },
            },
        ],
    },
    {
        path: "/ffc",
        component: FeatureFlagConfiguration,
        name: routeNames.featureFlagConfiguration,
    },
];
