export default {
    mergeRoutes(base, diff) {
        diff.forEach((route) => {
            let baseRoute = this.getBaseRoute(base, route.path);

            if (baseRoute && route.delete) {
                this.delete(base, baseRoute);
            } else if (baseRoute) {
                this.merge(route, baseRoute);
            } else {
                this.add(base, route);
            }
        });
        return base;
    },

    getBaseRoute(base, path) {
        return base.find((item) => {
            return item.path === path;
        });
    },

    delete(base, route) {
        let index = base.indexOf(route);

        if (index > -1) {
            base.splice(index, 1);
        }
    },

    merge(route, baseRoute) {
        for (let key in route) {
            if (Object.prototype.hasOwnProperty.call(route, key)) {
                switch (key) {
                    case "path":
                    case "name":
                        break;
                    case "children":
                        this.mergeChildrenRoutes(route, baseRoute);
                        break;
                    case "component":
                        baseRoute.component = route.component;
                        break;
                    default:
                        this.mergeOtherProperty(key, route, baseRoute);
                        break;
                }
            }
        }
    },

    mergeChildrenRoutes(route, baseRoute) {
        if (baseRoute.children) {
            this.mergeRoutes(baseRoute.children, route.children);
        } else {
            baseRoute.children = route.children;
        }
    },

    mergeOtherProperty(key, route, baseRoute) {
        switch (typeof route[key]) {
            case "object":
                if (baseRoute[key]) {
                    baseRoute[key] = Object.assign(baseRoute[key], route[key]);
                } else {
                    baseRoute[key] = route[key];
                }
                break;
            default:
                baseRoute[key] = route[key];
                break;
        }
    },

    add(base, route) {
        base.push(route);
    },
};
