<template>
    <a
        v-show="showLicense"
        href="//www.dmca.com/Protection/Status.aspx?ID=73aef96b-efa2-4771-9e42-f985d0e6d97c"
        title="DMCA.com Protection Status"
        class="dmca-badge"
        rel="noindex, nofollow"
        target="_blank"
    >
        <img
            src="https://images.dmca.com/Badges/_dmca_premi_badge_1.png?ID=73aef96b-efa2-4771-9e42-f985d0e6d97c"
            alt="DMCA.com Protection Status"
        >
    </a>
</template>

<script setup>
import { onMounted, computed } from "vue";
import {
    DOMAIN_FOR_AUSTRALIA,
    DOMAIN_FOR_CANADA,
    DOMAINS_FOR_AUSTRALIA,
    DOMAINS_FOR_CANADA,
} from "@theme/configs/constsLocales";

const hostForDisplay = {
    [DOMAIN_FOR_CANADA]: true,
    [DOMAINS_FOR_AUSTRALIA.r9]: true,
    [DOMAINS_FOR_CANADA.r10]: true,
    [DOMAINS_FOR_AUSTRALIA.r12]: true,
    [DOMAINS_FOR_AUSTRALIA.r13]: true,
    [DOMAINS_FOR_AUSTRALIA.r14]: true,
    [DOMAINS_FOR_AUSTRALIA.r15]: true,
    [DOMAIN_FOR_AUSTRALIA]: true,
    ["local.rocketplay.com"]: true,
};

const showLicense = computed(() => {
    return typeof window !== "undefined" && hostForDisplay[location.hostname];
});

onMounted(() => {
    if (showLicense.value) {
        let licenceScript = document.createElement("script");
        licenceScript.type = "text/javascript";
        licenceScript.src = "https://images.dmca.com/Badges/DMCABadgeHelper.min.js";
        licenceScript.async = true;
        document.body.appendChild(licenceScript);
    }
});

</script>

<style scoped lang="scss">
.dmca-badge {
    display: flex;
    width: 7rem;
    height: 4rem;
    align-content: center;
    justify-content: center;

    & img {
        height: 4rem;
    }
}
</style>
