<template>
    <div
        :class="{
            'nav-roll__wrapper--has-dropdown': dropDownLinks.length
        }"
        class="nav-roll__wrapper"
    >
        <div
            ref="navRollContainer"
            class="nav-roll gap-m"
        >
            <NavRollItem
                v-for="(item, i) in navList"
                :key="item.title + i"
                :hidden-items="hiddenItems"
                :item="item"
                :class="itemClasses(item)"
            />
            <Popup
                v-if="dropDownLinks.length"
                :left="leftAlignDropdown"
                activator-fixed
                bottom
                class="nav-roll__dropdown-wrapper"
                width-auto
            >
                <template #activator>
                    <div
                        ref="dropdownActivator"
                        class="nav-roll-item nav-roll-item__more-button"
                    >
                        <FeIcon :icon="FeIconConfig.icons.fill.more"/>
                        <span v-if="showMoreTitle">{{ $t("COMMON.MORE") }}</span>
                    </div>
                </template>
                <template #default>
                    <div class="nav-roll__dropdown">
                        <NavRollItem
                            v-for="(item, i) in dropDownLinks"
                            :key="i + item.title"
                            :classes="[ 'nav-roll__dropdown-item' ]"
                            :hidden-items="hiddenItems"
                            :item="item"
                        />
                    </div>
                </template>
            </Popup>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, onBeforeUnmount, nextTick } from "vue";
import Popup from "@components/Popup/Popup.vue";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import NavRollItem from "@components/NavRoll/NavRollItem.vue";

const props = defineProps({
    navList: {
        type: Array,
    },
    showMoreTitle: {
        type: Boolean,
    },
    leftAlignDropdown: {
        type: Boolean,
        default: false,
    },
    itemsLocation: {
        type: String,
        required: true,
    },
});

const navRollContainer = ref(null);
const hiddenItems = ref([]);
const isProviders = ref(false);

const sanitizeTitle = (title) => `${ title.split(" ").join("-").toLowerCase() }-${ props.itemsLocation }`;

const dropDownLinks = computed(() => props.navList.filter((item) => hiddenItems.value.includes(sanitizeTitle(item.title))));

const observer = ref(null);

function setObserve() {
    if (!navRollContainer.value) {
        return;
    }

    isProviders.value = navRollContainer.value.parentElement.classList.contains("games-list-toolbar__nav-roll");

    const options = {
        root: navRollContainer.value,
        rootMargin: props.showMoreTitle ? "0px -115px 0px 0px" : "0px -60px 0px 0px",
        threshold: 1,
    };

    const callback = (entries) => {
        entries.forEach((entry) => {
            const entriesKey = entry.target.getAttribute("data-key-item");
            if (!entry.isIntersecting) {
                hiddenItems.value.push(entriesKey || "");
            } else {
                hiddenItems.value = hiddenItems.value.filter((id) => id !== entriesKey);
            }
        });
    };
    observer.value = new IntersectionObserver(callback, options);

    props.navList.forEach(({ title }) => {
        const element = document.querySelector(`.nav-item-${ sanitizeTitle(title) }`);
        if (element) {
            element.setAttribute("data-key-item", sanitizeTitle(title));
            observer.value.observe(element);
        }
    });
}

const itemClasses = (item) => {
    return {
        [`nav-item-${ sanitizeTitle(item.title) }`]: true,
        "item-hidden": hiddenItems.value.includes(sanitizeTitle(item.title)),
    };
};
const disconnectObserver = () => {
    observer.value?.disconnect();
    observer.value = null;
};

onMounted(() => {
    setObserve();
});
onBeforeUnmount(disconnectObserver);

watch(() => props.navList, () => {
    disconnectObserver();
    nextTick(setObserve);
});
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.nav-roll {
    display: grid;
    grid-auto-flow: column;
    box-sizing: border-box;
    width: 100%;

    &__wrapper {
        display: none;

        @include media(M) {
            display: flex;
        }
    }

    :deep(.nav-roll-item) {
        @include font-size--Caption1;
        color: var(--color-text-body);
    }

    &-item__more-button span {
        @include font-size--Caption1;

        padding-left: .5rem;
    }

    &__wrapper {
        height: 100%;
        max-width: min-content;

        overflow-x: clip;
    }

    &-item {
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        transition: color .2s ease;
        cursor: pointer;
        padding: 0;
        margin: 0;
        white-space: nowrap;

        &.item-hidden {
            visibility: hidden;
            order: 999;
        }

        &--soon {
            opacity: .5;

            &-label {
                opacity: 1;
                font-weight: $font-weight--bold;
            }
        }

        &__badge {
            margin-left: .25rem;
        }
    }

    &__dropdown {
        padding: 1rem;
        border-radius: var(--border-radius-s);
        background: $header--background;
        box-shadow: 0 0 1rem var(--color-tertiary-6);

        :deep(.nav-roll-item) {
            visibility: visible;
        }
    }


    :deep(.fe-popup) {
        display: flex;
        justify-content: center;
    }

    :deep(.fe-popup__content) {
        padding-top: .25rem;
    }

    :deep(.fe-popup__wrapper) {
        left: calc(-100% + 4rem);
    }
}
</style>
