<template>
    <div :class="'promo-item-status ' + promoData.status">
        {{ setStatusText }}
        <timer
            v-if="promoData.status !== STATUS_PROMO.ARCHIVE && end_at"
            :dots="dots"
            :end="end_at"
            :text="textTime"
        />
    </div>
</template>

<script>
import Timer from "@components/Timer/Timer.vue";
import { STATUS_PROMO } from "@store/tournaments/constants";
import { useI18n } from "vue-i18n";

/* eslint-disable camelcase */
export default {
    name: "PromoStatus",

    components: {
        Timer,
    },

    props: {
        promoData: {
            type: Object,
        },
        textTime: {
            type: Array,
            default() {
                return [ useI18n().t("TOURNAMENTS.TIMER.DAYS_M"), " ", " ", " " ];
            },
        },

        dots: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            end_at: 0,
            statusText: "",
            STATUS_PROMO,
        };
    },

    computed: {
        setStatusText() {
            if (this.promoData.textTimes) {
                return this.promoData.textTimes;
            }

            switch (this.promoData.status) {
                case STATUS_PROMO.ACTIVE:
                    return this.$t("TOURNAMENTS.TIMER.LEFT_FINISH");
                case STATUS_PROMO.FUTURE:
                    return this.$t("TOURNAMENTS.TIMER.START_IN");
                case STATUS_PROMO.ARCHIVE:
                default:
                    return this.$t("TOURNAMENTS.TIMER.FINISHED_TOURNAMENT");
            }
        },
    },

    watch: {
        promoData() {
            this.setEndDate();
        },
    },

    created() {
        this.setEndDate();
    },

    methods: {
        /* eslint-disable camelcase */
        setEndDate() {
            let date = "";

            switch (this.promoData.status) {
                case "active":
                    date = this.promoData.end_at;
                    break;
                case "future":
                default:
                    date = this.promoData.start_at;
                    break;
            }
            this.end_at = Date.parse(date);
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
