<template>
    <CarouselScroll
        ref="calendarRowContainer"
        class="calendar-row"
        :active-index="activeIndex"
        :show-navigation="false"
        @scroll="handleScroll"
    >
        <CalendarItem
            v-for="item in vipAdventuresStore.calendarConfig"
            ref="calendarItemRefs"
            :key="item.format"
            :class="`calendar-item-${ item.day }-${item.month}`"
            :day-config="item"
            :active-index="activeIndex"
            @choose-day="handleChooseDay"
        />
        <SuperItem
            :day-config="vipAdventuresStore.superConfig"
            :active-index="activeIndex"
            @choose-day="handleChooseDay"
        />
    </CarouselScroll>
</template>

<script setup lang="ts">
import { useVipAdventures } from "@store/user/vipAdventures";

const vipAdventuresStore = useVipAdventures();

import { ref, onMounted, watch } from "vue";
import type { ComponentPublicInstance } from "vue";

import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import CalendarItem from "@modules/VipAdventures/Calendar/CalendarItem.vue";
import SuperItem from "@modules/VipAdventures/Calendar/SuperItem.vue";
import { EventBus, BUS_EVENTS } from "@bus";

const activeIndex = ref<number>(0);

function handleChooseDay(selectData) {
    activeIndex.value = selectData.index;
}

const calendarRowContainer = ref<ComponentPublicInstance<typeof CarouselScroll> | null>(null);
const calendarItemRefs = ref<ComponentPublicInstance<typeof CalendarItem>[] | null>(null);

function handleScroll() {
    if (!calendarRowContainer.value || !calendarItemRefs.value) {
        return;
    }

    for (const refItem of calendarItemRefs.value) {
        const { x: refItemX, width } = refItem.$el.getBoundingClientRect();
        const { x: parentX } = calendarRowContainer.value.$el.getBoundingClientRect();
        const positionEl = refItemX - parentX;

        if (positionEl >= 0) {
            refItem.$el.classList.remove("month-hidden");
        }

        if (positionEl < 0 || positionEl > width) {
            refItem.$el.classList.add("month-hidden");
        }
    }
}

watch(activeIndex, () => {
    EventBus.$emit(BUS_EVENTS.ADVENTURE_CALENDAR_SET_INDEX, activeIndex.value);
});

onMounted(() => {
    const index = vipAdventuresStore.calendarConfig.findIndex((item) => item.today);
    activeIndex.value = index > -1 ? index : vipAdventuresStore.calendarConfig.length;
    handleScroll();
});

</script>

<style scoped lang="scss">
.calendar-row {
    --carousel-item-width: 4rem;
    --carousel-gap: .5rem;
}
</style>
