<template>
    <div class="promo-labels">
        <span
            v-for="item in labels"
            :key="item.title"
            class="promo-labels__item"
            :class="classesLabel(item)"
        >
            <span
                v-if="item.dot"
                class="promo-labels__dot"
                :class="[ 'promo-labels__dot--' + item.dot ]"
            />
            {{ item.title }}</span>
    </div>
</template>

<script>


import { colorsList } from "@ui/lib.js";

export const themes = {
    Ghost: {
        bg: colorsList.ghost,
    },
};

export const dotColor = {
    alert: colorsList.alert,
};

export default {
    name: "PromoLabels",

    props: {
        labels: {
            type: Array,
        },
    },

    methods: {
        classesLabel(item) {
            if (item.accent) {
                return [ "promo-labels__item--accent" ];
            }
            if (item.theme) {
                return {
                    [`promo-labels__item--${ item.theme.bg }`]: item.theme.bg,
                };
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.promo-labels {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    grid-auto-flow: column;

    &__item {
        @include font-size--Caption2;
        padding: 0.25rem 1rem;
        background: var(--color-layer-alt-1);
        border-radius: var(--border-radius-xs);
        color: var(--color-text-alt);

        &--accent {
            color: var(--color-text-on-opposite-back);
            background: var(--color-additional-gradient);
        }

        &--ghost {
            background: var(--color-tertiary-3);
            backdrop-filter: blur(10px);
        }
    }

    &__dot {
        display: inline-block;
        width: .5rem;
        height: .5rem;
        border-radius: .5rem;
        margin-right: .25rem;

        &--alert{
            background: var(--color-error);
        }
    }
}
</style>
