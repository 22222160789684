/* eslint-disable id-length */
export default {
    init(hot_jar_id, hot_jar_sv) {
        window.hj = window.hj || function() {
            (window.hj.q = window.hj.q || []).push(arguments);
        };
        window._hjSettings = { hjid: hot_jar_id, hjsv: hot_jar_sv };
        const a = document.getElementsByTagName("head")[0];
        const r = document.createElement("script");
        r.async = 1;
        r.src = `https://static.hotjar.com/c/hotjar-${ window._hjSettings.hjid }.js?sv=${ window._hjSettings.hjsv }`;
        a.appendChild(r);
    },
};
