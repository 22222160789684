import App from "@layouts/App/App.vue";
import { useRootStore } from "@store/root";
import { createApp, createSSRApp } from "vue";

/*
 * !IMPORTANT ORDER! router > mixins > store
 * mixins need to be after route add to Vue and before $bus applied to Vue,
 * because they using check $bus in Vue instance to apply hooks,
 * while $bus applied inside store in user module
 * */
import { createAppRouter } from "@router";
import { createHead, VueHeadMixin } from "@unhead/vue";

import { createI18n } from "@i18n";
import { localeMixin } from "@src/mixins";

import { EventBus, eventBusPlugin } from "@bus";
import auth from "@controllers/authController";
import stagController from "@controllers/StagController";
import WS from "@controllers/WebsocketController";
import userAgentController from "@front/core/controllers/UserAgentController";
import guestRouterHook from "@hooks/GuestRouter";
import promocodeStorageHook from "@hooks/PromocodeStorageHook";
import modal from "@plugins/Modal";
import pagination from "@plugins/Pagination";
import preloader from "@plugins/Preloader";
import toast from "@plugins/Toast";

import "@components/FormFieldsVee/validationRules/index.js";
import {
    CASHBOX_EVENTS,
    COMMON_EVENTS, FORM_EVENTS,
    GAME_EVENTS, LOGIN_EVENTS, LOOTBOX_WHEEL,
    PERSONAL_INFO_POPUP,
    PLACES,
    REGISTRATION_EVENTS
} from "@controllers/MetriksController/gtmConstants";
import cashboxPopupHook from "@hooks/CashboxPopupHook";
import gtmMainPageHook from "@hooks/gtmMainPageHook";
import languageHook from "@hooks/LanguageHook";
import limitNotificationHook from "@hooks/LimitNotificationHook";
import popupHook from "@hooks/PopupHook";
import profileHook from "@hooks/ProfileHook";
import redirectBettingHook from "@hooks/RedirectBettingHook";
import { beforeMountMixin } from "@mixins/serverPrefetch";
import { addStaticPageToRouter } from "@router/StaticPageRouterController";
import { useCMS } from "@store/CMS";
import { pinia } from "@store/pinia";

export let appInstances = null;

export async function createVue3App({
        headers,
        initialState,
        piniaInitialState,
        routerData,
        baseUrl,
        uaHints
    }) {
    if (piniaInitialState) {
        pinia.state.value = piniaInitialState;
    }

    const uaPlatform = await userAgentController(uaHints);
    let routerProps = routerData;

    const app = import.meta.env.SSR ? createSSRApp(App) : createApp(App);
    app.use(pinia);

    const { setPlatform } = useRootStore();
    setPlatform(uaPlatform);

    if (initialState) {
        routerProps = { ...initialState, ...piniaInitialState };
    }
    WS.init(EventBus);

    const router = createAppRouter(
        routerProps,
        baseUrl,
    );

    promocodeStorageHook(router);
    modal.init(EventBus);
    preloader.init(EventBus);
    toast.init(EventBus);
    pagination.init();
    auth.init(router, headers);
    profileHook(router);
    guestRouterHook(router);
    cashboxPopupHook(router);
    popupHook(router);
    redirectBettingHook(router);
    limitNotificationHook(router);

    router.beforeEach((to, from, next) => {
        const { setNoIndex } = useCMS();
        setNoIndex(to.path.split("/").length > 3);
        next();
    });
    const head = createHead();

    app.config.globalProperties.$isServer = import.meta.env.SSR;
    app.config.globalProperties.$gtmTempData = [];
    app.config.globalProperties.$gtm = {
        eccomerceTrackEvent() {
        },
        customTrackEvent(event) {
            app.config.globalProperties.$gtmTempData.push(event);
        },
        REGISTRATION_EVENTS,
        CASHBOX_EVENTS,
        PLACES,
        LOGIN_EVENTS,
        COMMON_EVENTS,
        LOOTBOX_WHEEL,
        GAME_EVENTS,
        PERSONAL_INFO_POPUP,
        FORM_EVENTS,
    };

    if (!DEV) {
        app.config.errorHandler = (err, vm, info) => {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify({
                label: "GLOBAL_VUE_ERROR_HANDLER",
                message: err.message,
                level_name: "ERROR",
                context: { info },
            }));
        };
        app.config.warnHandler = (msg, vm, info) => {
            // eslint-disable-next-line no-console
            console.error(JSON.stringify({
                label: "GLOBAL_VUE_WARN_HANDLER",
                message: msg,
                level_name: "WARNING",
                context: { info },
            }));
        };
    }

    const i18n = createI18n();
    stagController.init();
    languageHook(router);
    gtmMainPageHook(router);

    app.use(i18n);
    app.mixin(localeMixin);
    app.mixin(beforeMountMixin);
    app.mixin(VueHeadMixin);
    app.use(eventBusPlugin);
    app.use(head);

    await addStaticPageToRouter(router);
    app.use(router);

    appInstances = {
        router,
        pinia,
        i18n,
        WS,
        EventBus,
    };

    return {
        app,
        router,
        WS,
        EventBus,
        root: App,
        pinia,
        head,
    };
}
