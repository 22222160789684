<template>
    <div
        :style="layerGradient"
        class="banner-slide"
    >
        <div
            class="banner-slide-wrapper"
            @click="imgClick"
        >
            <FeImage
                v-if="identity"
                :src="identity"
                class="banner-slide__img-identity"
            />
            <FeImage
                v-if="banner.isSkeleton"
                :class="{'banner-slide__img--is-skeleton': banner.isSkeleton}"
                :lazy="lazy"
                :src="banner.image"
                :type="TypeImage.BANNER"
                class="banner-slide__img"
            />
            <FePicture
                v-else-if="banner.image"
                :alt="altImag"
                :class="{'banner-slide__img--is-skeleton': banner.isSkeleton}"
                :lazy="lazy"
                :src="banner.image"
                :type="TypeImage.BANNER"
                class="banner-slide__img"
            />
            <div class="banner-slide-wrapper-body">
                <div class="banner-slide__content gap-xs">
                    <div class="banner-slide__labels-wrapper">
                        <div v-if="timerData || banner.labels" class="banner-slide__labels">
                            <slot name="status">
                                <PromoStatus
                                    v-if="timerData"
                                    :promo-data="timerData"
                                />
                            </slot>
                            <template v-for="label of banner.labels" :key="label.title">
                                <div
                                    :class="label.type"
                                    class="banner-slide__label"
                                >
                                    {{ label.title }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div>
                        <div
                            v-if="banner.title"
                            :class="banner.title.size"
                            class="banner-slide-title"
                            @click="titleClick"
                            v-html="titleFormatted"
                        />
                        <div
                            v-if="banner.description"
                            :class="banner.description.size"
                            class="banner-slide-description"
                            @click="descriptionClick"
                            v-html="textFormatted"
                        />
                    </div>
                    <slot name="text"/>
                    <div>
                        <slot name="button">
                            <div class="banner-slide__button-wrap gap-column-xs">
                                <template v-if="featureFlags.enableNewUIComponents">
                                    <FeButtonNew
                                        v-if="showBannerButton"
                                        :theme="buttonTheme"
                                        data-class="temMeMore"
                                        v-bind="buttonAttr"
                                        @click.stop.prevent="buttonClick"
                                    >
                                        {{ banner.button.name }}
                                    </FeButtonNew>

                                    <FeButtonNew
                                        v-show="secondButton"
                                        :theme="themes.SecondarySmall"
                                        @click.stop.prevent="handleClickSecondButton"
                                    >
                                        {{ secondButton?.name }}
                                    </FeButtonNew>
                                </template>
                                <template v-else>
                                    <FeButton
                                        v-if="showBannerButton"
                                        :theme="buttonTheme"
                                        data-class="temMeMore"
                                        v-bind="buttonAttr"
                                        @click.stop.prevent="buttonClick"
                                    >
                                        {{ banner.button.name }}
                                    </FeButton>

                                    <FeButton
                                        v-show="secondButton"
                                        :theme="FeButtonConfig.themes.TertiaryExtraSmall"
                                        @click.stop.prevent="handleClickSecondButton"
                                    >
                                        {{ secondButton?.name }}
                                    </FeButton>
                                </template>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import identity1 from "./images/identity1.svg";
import identity2 from "./images/identity2.svg";
import identity3 from "./images/identity3.svg";

import FeImage from "@ui/FeImage/FeImage.vue";
import FePicture from "@ui/FePicture/FePicture.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";

import PromoStatus from "@components/PromoStatus/PromoStatus.vue";
import { STATUS_PROMO } from "@store/tournaments/constants";
import { TypeImage } from "@theme/configs/imgResize";
import { mapState } from "pinia";
import { useLotteriesStore } from "@store/lotteries";
import { defineAsyncComponent } from "vue";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { statusForTournament } from "@helpers/promoHelpers";
import { useUIStore } from "@store/ui";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import { parseContent } from "@helpers/parseContent";

const bannerSlidePopupDetails = defineAsyncComponent(() => {
    return import(
        "./BannerSlidePopupDetails.vue");
});

function isSameHost(url) {
    const link = document.createElement("a");
    link.href = url;
    return link.hostname === location.hostname;
}

export const TYPES_EVENT_CLICK = {
    IMAGE: "IMAGE",
    TITLE: "TITLE",
    DESCRIPTION: "DESCRIPTION",
    BUTTON: "BUTTON",
};

const TYPE_ACTION_BUTTON = {
    TOURNAMENTS: "tournaments",
    LOTTERY: "lottery",
};

function getActionsByStatus(list = [], frontId) {
    return list.sort(({ status }) => {
        switch (status) {
            case STATUS_PROMO.ACTIVE:
                return -1;
            case STATUS_PROMO.FUTURE:
                return 0;
            case STATUS_PROMO.ARCHIVE:
                return 1;

            default:
                return 0;
        }
    }).find(({ frontend_identifier: actionFrontId }) => {
        return actionFrontId === frontId;
    });
}

export default {
    name: "BannerSlide",

    components: {
        FeButton,
        FeImage,
        PromoStatus,
        FePicture,
        FeButtonNew,
    },

    props: {
        banner: {
            type: Object,
            required: true,
        },

        lazy: {
            type: Boolean,
            default: false,
        },

        buttonTheme: {
            type: Object,
            default() {
                return featureFlags.enableNewUIComponents ? themes.PrimaryLarge : FeButtonConfig.themes.PrimaryMedium;
            },
        },

        showSecondButton: {
            type: Boolean,
        },
    },
    emits: [ "click", "clickBanner" ],
    setup() {
        const { setShowModal } = useUIStore();
        return {
            setShowModal,
        };
    },

    data() {
        return {
            TypeImage,
            FeButtonConfig,
            imgIdentity: {
                1: identity1,
                2: identity2,
                3: identity3,
            },
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useLotteriesStore, {
            lotteryList: "getLotteriesList",
        }),
        ...mapState(useTournamentsStore, {
            tournamentsList: "getTournamentsList",
        }),

        hasVariables() {
            return Boolean(this.banner.variables);
        },

        textFormatted() {
            if (!this.banner?.description?.text) {
                return "";
            }

            return this.hasVariables
                ? parseContent(this.banner.description.text, this.banner.variables)
                : this.banner.description.text;
        },

        titleFormatted() {
            if (!this.banner?.title?.text) {
                return "";
            }

            return this.hasVariables
                ? parseContent(this.banner?.title.text, this.banner.variables)
                : this.banner.title.text;
        },

        layerGradient() {
            const {
                colorLeft,
                colorLeftCenter,
                colorRightCenter,
                colorRight,
            } = this.banner.gradient || {};

            return {
                background: `linear-gradient(
                    270deg,
                    ${colorLeft} 0%,
                    ${colorLeft} calc(50% - 1500px),
                    ${colorLeftCenter} calc(50% - 750px),
                    ${colorRightCenter} calc(50% + 750px),
                    ${colorRight} calc(50% + 1500px),
                    ${colorRight} 100%
              )`.replace(/\n/g, ""),
            };
        },
        /* eslint-disable camelcase */
        timerData() {
            if (this.banner.timer) {
                const { start_at, end_at } = this.banner.timer;

                return {
                    start_at,
                    end_at,
                    status: statusForTournament({ start_at, end_at }),
                };
            }
            return null;
        },
        identity() {
            if (this.banner.identity) {
                return this.imgIdentity[this.banner.identity];
            }
            return null;
        },

        showBannerButton() {
            const { button } = this.banner;
            if (button) {
                return Boolean(button.url) || Boolean(button.type);
            }
        },

        buttonAttr() {
            const { button } = this.banner;
            try {
                if (button?.attrs) {
                    return {
                        ...button?.attrs,
                    };
                }
                return {};
            } catch (err) {
                return {};
            }
        },

        getActionUrlByFrontId() {
            if (this.banner.button.type === TYPE_ACTION_BUTTON.TOURNAMENTS) {
                const findAction = getActionsByStatus(this.tournamentsList, this.banner.button.frontendIdentifier);
                return findAction ? `/${TYPE_ACTION_BUTTON.TOURNAMENTS}/${findAction.id}` : null;
            }
            if (this.banner.button.type === TYPE_ACTION_BUTTON.LOTTERY) {
                const findAction = getActionsByStatus(this.lotteryList, this.banner.button.frontendIdentifier);
                return findAction ? `/${TYPE_ACTION_BUTTON.LOTTERY}/${findAction.id}` : null;
            }
            return null;
        },

        secondButton() {
            const { secondButton, button } = this.banner;
            if (!this.showSecondButton || !secondButton) {
                return null;
            }

            return {
                name: secondButton.name,
                popupData: {
                    buttonClickFn: this.defaultEventOnClick,
                    buttonTitle: button?.name || secondButton.name,
                    bannerTitle: this.titleFormatted || this.textFormatted,
                    ...secondButton.popup,
                },
            };
        },

        altImag() {
            return this.banner.title?.text || this.textFormatted;
        },
    },

    methods: {
        defaultEventOnClick() {
            const { button } = this.banner;
            if (button) {
                if (button.type) {
                    return this.routeEvent(this.getActionUrlByFrontId);
                }
                if (button.url === "/deposit") {
                    if (this.isLogged) {
                        this.$gtm.customTrackEvent({
                            ...this.$gtm?.CASHBOX_EVENTS?.DEPOSIT?.NEW?.CLICK_OPEN_DEPOSIT_MAIN,
                            location: "slider-promo-banners",
                        });
                    } else {
                        this.$gtm.customTrackEvent({
                            ...this.$gtm.REGISTRATION_EVENTS.CLICK,
                            location: "slider-promo-banners",
                        });
                    }
                }
                return this.routeEvent(button.url);
            }
        },
        titleClick() {
            this.defaultEventOnClick();
        },
        descriptionClick(banner) {
            this.defaultEventOnClick(banner);
        },

        imgClick() {
            this.defaultEventOnClick();
            this.$emit("clickBanner", this.banner);
        },

        buttonClick() {
            this.defaultEventOnClick();
            this.$emit("clickBanner", this.banner);

            if (this.banner.button.url === "/deposit") {
                this.$gtm.customTrackEvent({
                    ...this.$gtm?.CASHBOX_EVENTS?.DEPOSIT?.NEW?.CLICK_OPEN_DEPOSIT_MAIN,
                    location: "top-index-banner",
                });
            } else {
                this.$gtm.customTrackEvent({
                    ...this.$gtm.COMMON_EVENTS.BANNERS,
                    button_text: this.banner.button && this.banner.button.name,
                    button_url: this.banner.button && this.banner.button.url,
                });
            }
        },

        routeEvent(url) {
            if (isSameHost(url)) {
                this.$router.push(url);
            } else {
                location.href = url;
            }
        },

        async handleClickSecondButton() {
            this.$emit("clickBanner", this.banner);
            this.setShowModal({
                name: "banner-slide-popup-details-modal",
                component: bannerSlidePopupDetails,
                mobileFriendly: true,
                props: {
                    popupData: { ...this.secondButton.popupData, variables: this.banner.variables },
                    layerGradient: this.layerGradient,
                    imageSrc: this.banner.image,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./style.scss"></style>
