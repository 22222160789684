import { routeNames } from "@router/routeNames";
import {
    MODAL_CASHBOX_DASHBOARD,
    MODAL_CASHBOX_STEP_DEPOSIT,
    MODAL_CASHBOX_STEP_WITHDRAW,
    MODAL_CASHBOX_STEP_DEP_ONE_CLICK,
} from "@config/modalsNames";
import { showPreCashboxPopup } from "@helpers/helperPreCashboxPopup";
import { useUserBonusesAndCoupon } from "@store/user/userBonusesAndCoupon";
import { defineAsyncComponent } from "vue";
import { isServer } from "@helpers/ssrHelpers";
import { depositPromocodeStorage, depositPromocodeShowedStorage } from "@helpers/promocodeStorageHelper";
import { useUIStore } from "@store/ui";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";
import { useRootStore } from "@store/root";

const comeFromDashboard = () => {
    const { modals } = storeToRefs(useUIStore());

    return modals.value.some((modal) => {
        return modal.name === MODAL_CASHBOX_DASHBOARD;
    });
};


const cashboxDashboard = defineAsyncComponent(() => {
    return import("@modules/Cashbox/CashboxDashboard/CashboxDashboard.vue");
});

const cashboxStepDeposit = defineAsyncComponent(() => {
    return import("@modules/Cashbox/CashboxStepDeposit/CashboxStepDeposit.vue");
});

const cashboxStepWithdraw = defineAsyncComponent(() => {
    return import("@modules/Cashbox/CashboxStepWithdraw/CashboxStepWithdraw.vue");
});

const cashboxDepositByOneClick = defineAsyncComponent(() => {
    return import("@modules/Cashbox/CashboxDepositByOneClick/CashboxDepositByOneClick.vue");
});

export const showCashboxDashboardPopup = () => {
    const { setShowModal } = useUIStore();
    const { isMobile } = storeToRefs(useRootStore());
    setShowModal({
        name: MODAL_CASHBOX_DASHBOARD,
        component: cashboxDashboard,
        mobileFriendly: true,
        fullScreenMobile: isMobile.value,
    });
};

const showCashboxStepDeposit = (to) => {
    const { setShowModal } = useUIStore();
    const { isMobile } = storeToRefs(useRootStore());
    const isComeFromDash = comeFromDashboard();
    setShowModal({
        name: MODAL_CASHBOX_STEP_DEPOSIT,
        component: cashboxStepDeposit,
        mobileFriendly: true,
        showClose: false,
        fullScreenMobile: isMobile.value,
        props: {
            comeFromDashboard: isComeFromDash,
            ...to.query,
        },
    });
};

const showCashboxStepDepositBonus = () => {
    const { setShowModal } = useUIStore();

    const isComeFromDash = comeFromDashboard();
    const { isMobile } = storeToRefs(useRootStore());
    setShowModal({
        name: MODAL_CASHBOX_STEP_DEPOSIT,
        component: cashboxStepDeposit,
        mobileFriendly: true,
        showClose: false,
        fullScreenMobile: isMobile.value,
        props: {
            comeFromDashboard: isComeFromDash,
        },
    });
};

const showCashboxDepositByOneClick = () => {
    const { setShowModal } = useUIStore();

    setShowModal({
        name: MODAL_CASHBOX_STEP_DEP_ONE_CLICK,
        component: cashboxDepositByOneClick,
        mobileFriendly: true,
    });
};

const showCashboxStepWithdrawPopup = () => {
    const { setShowModal } = useUIStore();
    const { isMobile } = storeToRefs(useRootStore());
    const isComeFromDash = comeFromDashboard();
    setShowModal({
        name: MODAL_CASHBOX_STEP_WITHDRAW,
        component: cashboxStepWithdraw,
        mobileFriendly: true,
        showClose: false,
        fullScreenMobile: isMobile.value,
        props: {
            comeFromDashboard: isComeFromDash,
        },
    });
};

function showPromocodeCashbox() {
    if (isServer) {
        return;
    }

    const depositPromocode = depositPromocodeStorage.get();
    const depositPromocodeShowed = depositPromocodeShowedStorage.get();
    const { getIsLogged } = storeToRefs(useUserInfo());

    if (!depositPromocodeShowed && depositPromocode && getIsLogged.value) {
        const { setDepositBonusCode, deleteDepositBonusCode, useBonuses } = useUserBonusesAndCoupon();

        useBonuses({ can_issue: true }).then(() => {
            setDepositBonusCode(depositPromocode).then((data) => {
                if (data?.promoIsValid === false) {
                    deleteDepositBonusCode().then(() => {
                        showCashboxStepDepositBonus();
                    });
                } else {
                    showCashboxStepDepositBonus();
                }
            });
        });
    }
}

export default (router) => {
    router.beforeEach((to, from, next) => {
        const cashboxRoutes = [
            routeNames.cashboxWithdraw,
            routeNames.cashbox,
            routeNames.cashboxDashboard,
            routeNames.cashboxDeposit,
            routeNames.cashboxDepositOneClick,
            routeNames.cashboxDepositDepositBonus,
        ];


        if (to.name === routeNames.main) {
            showPromocodeCashbox();
        }

        if (cashboxRoutes.includes(to.name)) {
            if ((!from.name || from.name === routeNames.singUpPage)) {
                next({ name: routeNames.main });
            }

            if (showPreCashboxPopup()) {
                return;
            }

            switch (to.name) {
                case routeNames.cashbox:
                case routeNames.cashboxDashboard:
                    showCashboxDashboardPopup();
                    break;
                case routeNames.cashboxDeposit:
                    showCashboxStepDeposit(to);
                    break;
                case routeNames.cashboxDepositDepositBonus:
                    showPromocodeCashbox();
                    break;
                case routeNames.cashboxWithdraw:
                    showCashboxStepWithdrawPopup();
                    break;
                case routeNames.cashboxDepositOneClick:
                    showCashboxDepositByOneClick();
                    break;
                default:
                    showCashboxDashboardPopup();
            }

            return;
        }

        return next();
    });
};
