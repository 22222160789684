<template>
    <div class="producers-block">
        <p class="h2 text--align-center">
            {{ $t("FOOTER.PARTNERS") }}
        </p>
        <Providers class="producers-block__providers-list grid-full-width" :show-title="false"/>
    </div>
</template>

<script>
import Providers from "@modules/GameHall/components/Providers/Providers.vue";

export default {
    name: "ProducersBlock",

    components: {
        Providers,
    },
};
</script>

<style lang="scss" scoped src="./style.scss"></style>
