<template>
    <i18n-t
        :keypath="copyRightKeyPath"
        tag="p"
        class="footer__copyright text--align-center"
    >
        <template #currentYear>{{ currentYear }}</template>
        <template #rocketLink>
            <router-link :to="{ name: routeNames.main }" class="link">
                rocketplay.com
            </router-link>
        </template>
        <template #responsibleGaming>
            <router-link :to="{ path: '/responsible-gaming' }" class="link">
                {{ $t("FOOTER.COPYRIGHT_GEO.EE.RESPONSIBLE_GAMING_LINK") }}
            </router-link>
        </template>
        <template #linkTC>
            <router-link :to="{ path: '/terms-and-conditions' }" class="link">
                {{ $t("FOOTER.COPYRIGHT_GEO.EE.TC_LINK") }}
            </router-link>
        </template>
    </i18n-t>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { routeNames } from "@router/routeNames";
import { useLicense } from "@helpers/license";

const { te } = useI18n();
const licenseApi = useLicense();

const copyRightKeyPath = computed(() => {
    return te(`FOOTER.COPYRIGHT_GEO.${licenseApi.usedLicense.value}.TEXT`) ?
        `FOOTER.COPYRIGHT_GEO.${licenseApi.usedLicense.value}.TEXT` :
        "FOOTER.COPYRIGHT";
});

const currentYear = computed(() => {
    return new Date().getFullYear().toString();
});
</script>

<style lang="scss">
@import "~@theme/styles";

.footer__copyright {
    color: var(--color-text-body);

    &, & a.link {
        @include font-size--Caption2($font-weight--normal);

        max-width: var(--main-layout-width);
        margin: 0 auto;
        padding-bottom: 10rem;
        white-space: pre-line;

        @include media(M) {
            padding-bottom: 3rem;
        }
    }
}
</style>
