import { defineRule, configure } from "vee-validate";
import { integer, max_value as maxValue, min, min_value as minValue, required } from "@vee-validate/rules";
import { number as validatorCardNumber } from "card-validator";
import dayjs from "dayjs";
import i18n from "@i18n";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { useUserStatuses } from "@store/user/userStatuses";
import { isAllowedCardsBin } from "@components/FormFieldsVee/helpers/cardNumber";

dayjs.extend(customParseFormat);

// eslint-disable-next-line max-len
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegEx = /^[0-9a-zA-Z|@#$^&="!№';%:?()_+\-\/,.<>]*$/;
const nicknameRegEx = /^[a-zA-Z\s\d.\-]*$/;

const nicknameRequiredLength = 3;
const nicknameMaxLength = 15;
const personalIdLength = 11;

const nicknameCharRule = (value) => {
    const { t } = i18n.instance || i18n.init();
    return nicknameRegEx.test(value) || t("VALIDATION.NICKNAME.WRONG_CHARTS");
};

const nicknameMinMaxRule = (value) => {
    const { t } = i18n.instance || i18n.init();

    return (!value.length || (value.length >= nicknameRequiredLength && value.length <= nicknameMaxLength)) ||
        t("VALIDATION.NICKNAME.MIN_MAX_LENGTH", {
            min: nicknameRequiredLength,
            max: nicknameMaxLength,
        });
};
export const emailRule = (value) => emailRegEx.test(String(value).toLowerCase());
export const personalIdRule = (value) => value.length === personalIdLength;
const passwordRule = (value) => passwordRegEx.test(value);
const nicknameRule = (value) => nicknameRegEx.test(value);
const minLangPhoneNumberRule = (value) => (/^[+() \-0-9]{8,}$/).test(value);
const checkBalanceRule = (value, [ minVal ]) => Number(value) <= minVal;
const confirmedByRule = (value, [ target ]) => value === target;
const multipleOfRule = (value, [ count ]) => !(Number(value * 1000) % Number(count * 1000));
const birthdayInlineRule = (value, [ day, month ]) => {
    const { t } = i18n.instance || i18n.init();

    const dateValue = dayjs(`${day}/${month}/${value}`, "DD/MM/YYYY", true);
    if (!dateValue.isValid()) {
        return t("VALIDATION.BIRTH_DAY.INVALID");
    }

    const different = dayjs().diff(dateValue, "year", true);
    return different > 18 || t("VALIDATION.BIRTH_DAY.MIN");
};
const cardNumberRule = (value) => validatorCardNumber(value).isValid;
const cardNumberDepBinRule = (value) => !isAllowedCardsBin(value);
const birthDayRule = (value) => Number(value) <= 31 && value.length === 2;
const birthMonthRule = (value) => Number(value) <= 12 && value.length === 2;
const birthYearRule = (value) => {
    const dateValue = dayjs(value, "DD/MM/YYYY", true);
    const different = dayjs().diff(dateValue, "year", true);
    return different > 18;
};
const isValidDateRule = (value) => {
    return dayjs(value, "DD/MM/YYYY", true).isValid();
};

const isExpirationValid = (value) => {
    const formatRegex = /^\d{2}\/\d{2}$/;

    if (!value.match(formatRegex)) {
        return false;
    }

    const [ month, year ] = value.split("/");

    if ((Number(month) <= 12 && Number(month) >= 1) && Number(year) > 0) {
        return true;
    }
};

const isValidCardExpireRule = (value) => {
    return !dayjs(value, "MM/YY", true).isBefore(dayjs().subtract(1, "M"), "month");
};

export const isOnlyEnglishCharacters = (value) => !(/[^a-zA-Z\s]/).test(value);

const ibanValidationLT = (value) => {
    const { t } = i18n.instance;
    const { addUserToGroup } = useUserStatuses();

    if (value.toUpperCase().startsWith("LT")) {
        addUserToGroup(1101);
        return t("VALIDATION.COMMON.IBAN.LT");
    }

    return true;
};

defineRule("integer", integer);
defineRule("minValue", minValue);
defineRule("maxValue", maxValue);
defineRule("required", required);
defineRule("min", min);
defineRule("email", emailRule);
defineRule("personalId", personalIdRule);
defineRule("password", passwordRule);
defineRule("nameCharts", nicknameRule);
defineRule("minLangPhoneNumber", minLangPhoneNumberRule);
defineRule("checkBalance", checkBalanceRule);
defineRule("confirmedBy", confirmedByRule);
defineRule("multiple_of", multipleOfRule);
defineRule("must_checked", (value) => value);
defineRule("birthday_inline", birthdayInlineRule);
defineRule("cardNumber", cardNumberRule);
defineRule("cardNumberDepBin", cardNumberDepBinRule);
defineRule("birthDay", birthDayRule);
defineRule("birthMonth", birthMonthRule);
defineRule("birthYear", birthYearRule);
defineRule("isValidDate", isValidDateRule);
defineRule("isValidCardExpire", isValidCardExpireRule);
defineRule("isExpirationValid", isExpirationValid);
defineRule("onlyEnglishCharacters", isOnlyEnglishCharacters);
defineRule("nicknameCharRule", nicknameCharRule);
defineRule("nicknameMinMaxRule", nicknameMinMaxRule);
defineRule("ibanValidationLT", ibanValidationLT);

configure({
    generateMessage: (context) => {
        const { t } = i18n.instance;

        const { rule, field: fieldName } = context;
        const getMessage = (name) => {
            if (!name) {
                return "";
            }

            const messages = {
                required: t("VALIDATION.COMMON.FIELD_NAME_REQUIRED", { field: fieldName }),
                min: t("VALIDATION.COMMON.FIELD_MIN_LENGTH", { fieldName, length: rule.params[0] }),
                minValue: t("VALIDATION.COMMON.FIELD_MIN", { fieldName, min: rule.params[0] }),
                maxValue: t("VALIDATION.COMMON.FIELD_MAX", { fieldName, max: rule.params[0] }),
                email: t("VALIDATION.EMAIL.INVALID"),
                password: t("VALIDATION.PASSWORD.WRONG_CHARTS"),
                nameCharts: t("VALIDATION.COMMON.FIELD_CONTAIN_INVALID_CARTS", { fieldName }),
                minLangPhoneNumber: t("VALIDATION.COMMON.FIELD_IS_INVALID", { fieldName }),
                checkBalance: t("errors.not_enough_money"),
                confirmedBy: t("VALIDATION.PASSWORD.NOT_MATCH"),
                multiple_of: t("VALIDATION.COMMON.VALUE_MULTIPLE", { count: rule.params[0] }),
                must_checked: t("VALIDATION.COMMON.MUST_BE_CHECKED"),
                cardNumber: t("errors.creditcard.cardnumber.invalid"),
                cardNumberDepBin: t("errors.creditcard.cardnumber.bankNotPossible"),
                birthDay: t("VALIDATION.COMMON.BDAY_INVALID", { fieldName }),
                birthMonth: t("VALIDATION.COMMON.BDAY_INVALID", { fieldName }),
                birthYear: t("VALIDATION.BIRTH_DAY.MIN"),
                isValidDate: t("VALIDATION.BIRTH_DAY.INVALID"),
                isValidCardExpire: t("VALIDATION.COMMON.CARD_EXPIRED"),
                isExpirationValid: t("VALIDATION.COMMON.EXPIRATION_INVALID"),
                onlyEnglishCharacters: t("VALIDATION.COMMON.ONLY_ENGLISH_LETTERS", { fieldName }),
                personalId: t("VALIDATION.COMMON.PERSONAL_ID", { fieldName }),
                integer: t("VALIDATION.COMMON.INTEGER", { fieldName }),
            };

            return messages[name];
        };

        return getMessage(rule?.name);
    },
});
