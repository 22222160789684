<template>
    <div class="table-preference">
        <div
            v-for="(itemList, index) in listToDisplay"
            :key="itemList.label+index"
            class="table-preference__row font__caption-2--normal"
            :class="isLastByIndex(index, listToDisplay) ? 'last-child' : ''"
        >
            <span class="d-flex align-items--center">
                <FeIcon
                    :icon="icons.fill.checkInput"
                    class="check"
                />
                <span>
                    {{ itemList.label }}
                </span>
            </span>
            <strong
                v-if="itemList.data"
                class="table-preference__row-data"
                :class="{ up: itemList.up }"
            >
                {{ itemList.data }}
            </strong>
            <span
                v-if="itemList.icons"
                class="table-preference__socials-icons"
            >
                <template v-for="iconItem in itemList.icons">
                    <img
                        v-if="iconsSocial[iconItem]"
                        :key="iconItem"
                        :alt="iconItem"
                        :src="iconsSocial[iconItem]"
                    >
                </template>
            </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import type { IPreferItem } from "@components/VipClub/types";
import { computed } from "vue";

import whatsappIcon from "./img/social_whatsapp.svg";
import telegramIcon from "./img/social_telegram.svg";
import { isLastByIndex } from "@helpers/lastItem";

interface IProps {
    listPrefer: IPreferItem[];
}

const props = defineProps<IProps>();

const listToDisplay = computed(() => props.listPrefer.filter((item) => item.label));

const iconsSocial = {
    whatsapp: whatsappIcon,
    telegram: telegramIcon,
};

</script>
<style scoped lang="scss">
.table-preference {
    &__row {
        align-items: center;
        padding: .5rem 0;
        display: flex;
        grid-auto-flow: column;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-tertiary-3);
        grid-template-columns: max-content auto;

        &.last-child {
            border-bottom: none;
        }

        &-data {
            text-align: right;

            &.up {
                position: relative;
                margin-left: 1rem;

                &::before {
                    content: url('./img/up.svg');
                    transform: translate(-0.75rem, -1px);
                    position: absolute;
                }
            }
        }

    }

    .check {
        min-width: 1.5rem;
        min-height: 1.5rem;

        display: inline-block;
        margin-right: .5rem;
        color: var(--color-successfully);
    }

    &__socials-icons {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
