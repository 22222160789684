import StaticPage from "@modules/StaticPage/StaticPage.vue";
import { filterStaticPages } from "@helpers/staticPages";

export function generateStaticRoutes(routerData) {
    if (routerData) {
        let staticPagesData = routerData;
        if (routerData?.CMS?.staticPages) {
            staticPagesData = routerData.CMS.staticPages;
        }

        const pages = [];
        filterStaticPages(staticPagesData)
            .forEach((staticPage) => {
                pages.push(
                    {
                        path: staticPage.slug,
                        name: staticPage.slug,
                        component: StaticPage,
                        props: () => {
                            return { ...staticPage };
                        },
                    },
                );
            });

        return pages;
    }
    return [];
}

export function getStaticRoutes(routerData) {
    return [
        {
            path: "/",
            children: generateStaticRoutes(routerData),
        },
    ];
}
