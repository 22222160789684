<template>
    <div class="enter-btns">
        <transition mode="out-in" name="slide">
            <div
                v-if="featureFlags.enablePromoBannerInEnterButton && displayBanner && showBonusBanner"
                class="enter-btns__promo-container"
                @click="signUpAction"
            >
                <PromoBanner :data-test="dataTestAttr.promoBannerWrap"/>
                <div
                    class="enter-btns__close_promo"
                    @click.stop="hidePromoBanner()"
                >
                    <FeIcon :icon="FeIconConfig.icons.fill.close" class="modal__close-btn"/>
                </div>
            </div>
        </transition>
        <template v-if="featureFlags.enableNewUIComponents">
            <FeButtonNew
                :data-test="dataTestAttr.openSignInButton"
                :theme="themes.SecondaryMedium"
                class="enter-btns-log"
                full-width-mobile
                @click="login"
            >
                {{ $t("COMMON.LOGIN") }}
            </FeButtonNew>
            <FeButtonNew
                :data-test="dataTestAttr.openSignUpButton"
                :theme="themes.PrimaryMedium"
                class="enter-btns-reg"
                full-width-mobile
                @click="signUpAction"
            >
                {{ $t("COMMON.REGISTRATION") }}
            </FeButtonNew>
        </template>
        <template v-else>
            <FeButton
                :data-test="dataTestAttr.openSignInButton"
                :theme="FeButtonConfig.themes.SecondarySmall"
                class="enter-btns-log"
                full-width
                @click="login"
            >
                {{ $t("COMMON.LOGIN") }}
            </FeButton>
            <FeButton
                :data-test="dataTestAttr.openSignUpButton"
                :theme="FeButtonConfig.themes.PrimarySmall"
                class="enter-btns-reg"
                full-width
                @click="signUpAction"
            >
                {{ $t("COMMON.REGISTRATION") }}
            </FeButton>
        </template>
    </div>
</template>

<script>
import { routeNames } from "@router/routeNames";
import dataTestAttr from "./data-test-attrs";
import config from "@theme/configs/config";

import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import PromoBanner from "@components/PromoBanner/PromoBanner.vue";
import cookie from "@front/core/controllers/CookieController.js";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import featureFlags from "@theme/configs/featureFlags";
import { generateCaptcha } from "@helpers/recaptchaHelper";
import { useAuth } from "@store/auth/auth";
import { mapActions } from "pinia";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "EnterBtns",

    components: {
        FeButton,
        PromoBanner,
        FeIcon,
        FeButtonNew,
    },

    props: {
        showBonusBanner: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            FeIconConfig,
            FeButtonConfig,
            routeNames,
            displayBanner: true,
            timerId: null,
            dataTestAttr,
            featureFlags,
        };
    },

    computed: {
        themes() {
            return themes;
        },
    },

    mounted() {
        this.setTimerData();
    },

    beforeUnmount() {
        clearTimeout(this.timerId);
    },

    methods: {
        ...mapActions(useAuth, {
            loginAction: "login",
        }),

        async login() {
            this.loginSendActionToGTM();

            if (navigator.credentials) {
                try {
                    const credential = await navigator.credentials.get({ password: true });

                    if (credential) {
                        const captcha = await generateCaptcha("LOGIN");

                        await this.loginAction({
                            email: credential.id,
                            password: credential.password,
                            captcha,
                        });
                    }
                } catch (errors) {
                    return this.$router.push({ name: routeNames.login });
                }
            }

            this.$router.push({ name: routeNames.login });
        },
        setTimerData() {
            const cookieTime = cookie.get("promo_tooltip_timer");
            if (cookieTime && new Date(cookieTime) > new Date()) {
                return;
            }
            const date = new Date();
            const endTime = new Date(date.setMinutes(date.getMinutes() + config.PROMO_TOOLTIP_TIMER_MINUTES));
            cookie.set("promo_tooltip_timer", endTime, { expires: 86400 });
        },
        hidePromoBanner() {
            this.displayBanner = false;
            this.timerId = setTimeout(() => {
                this.displayBanner = true;
            }, 120000);
        },
        signUpAction() {
            this.$router.push({ name: routeNames.registration });
            this.$gtm.customTrackEvent({
                ...this.$gtm.REGISTRATION_EVENTS.CLICK,
                location: this.$parent.$options.name || "header",
            });
        },
        loginSendActionToGTM() {
            this.$gtm.customTrackEvent({
                ...this.$gtm.LOGIN_EVENTS.CLICK_ON_BUTTON_LOGIN,
                location: this.$parent.$options.name || "header",
            });
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
