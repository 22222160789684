<template>
    <div class="dropdown-balance gap-s padding-s">
        <slot name="top">
            <UserBalance show-progress :is-dark="isDark">
                <template v-if="featureFlags.enableNewUIComponents">
                    <FeButtonNew
                        data-test="header-cashbox--button"
                        @click="openCashbox"
                    >
                        {{ $t("HEADER.CASHBOX") }}
                    </FeButtonNew>
                </template>
                <template v-else>
                    <FeButton
                        data-test="header-cashbox--button"
                        @click="openCashbox"
                    >
                        {{ $t("HEADER.CASHBOX") }}
                    </FeButton>
                </template>
            </UserBalance>
        </slot>
        <slot/>
        <div class="dropdown-balance__bottom gap-s">
            <template v-if="featureFlags.enableNewUIComponents">
                <FeButtonNew
                    class="dropdown-balance__profile"
                    :theme="themes.SecondaryMedium"
                    data-test="dropdown-balance__profile-btn"
                    full-width
                    @click="openProfile"
                >
                    {{ $t("PROFILE.GO_TO_PROFILE") }}
                </FeButtonNew>
            </template>
            <template v-else>
                <FeButton
                    class="dropdown-balance__profile"
                    :theme="ProfileBtn"
                    data-test="dropdown-balance__profile-btn"
                    full-width
                    @click="openProfile"
                >
                    {{ $t("PROFILE.GO_TO_PROFILE") }}
                </FeButton>
            </template>
            <slot name="bottom"/>
        </div>
    </div>
</template>

<script>
import { routeNames } from "@router/routeNames";
import UserBalance from "@components/UserBalance/UserBalance.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "DropdownBalance",

    components: {
        UserBalance,
        FeButton,
        FeButtonNew,
    },

    props: {
        closeHandler: {
            type: Function,
            default: () => {
            },
        },

        isDark: {
            type: Boolean,
            default: false,
        },
        routeCashboxButton: {
            type: String,
            default: routeNames.cashbox,
        },
    },

    data() {
        return {
            FeButtonConfig,
            ProfileBtn: FeButtonConfig.themes.SecondarySmall,
            routeNames,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
    },

    methods: {
        openCashbox() {
            this.$router.push({ name: this.routeCashboxButton });
        },
        openProfile() {
            if (this.$route.name === routeNames.profile) {
                this.closeHandler();
            } else {
                this.$router.push({ name: routeNames.profile });
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
