import { routeNames } from "@router/routeNames";
import { isServer } from "@helpers/ssrHelpers";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { getAffiliate } from "@helpers/getAffiliate";
import { useBannerStore } from "@store/banners";

export default function profileHook(router) {
    if (isServer) {
        return;
    }

    router.beforeEach((to, from, next) => {
        const $gtm = useGtm();
        const bannerStore = useBannerStore();
        const VIEW_PROMOTION = "view_promotion";

        if (from.name === routeNames.main && bannerStore.viewedGTMBanners.length) {
            $gtm.customTrackEvent({
                event: VIEW_PROMOTION,
                Affiliate: getAffiliate(router),
                ecommerce: {
                    items: bannerStore.viewedGTMBanners,
                },
            });

            bannerStore.clearViewedGTMBanners();
        }

        next();
    });
}
