import { DOMAIN_FOR_AUSTRALIA, LOCALES } from "@theme/configs/constsLocales";

export const LANG_TO_MIRRORS_FOR_BLOG: { [key: string]: string[] } = {
    "local.rocketplay.com": [ LOCALES.ENGLISH, LOCALES.CANADIAN_ENGLISH ],
    "rocketplay.com": [ LOCALES.ENGLISH, LOCALES.CANADIAN_ENGLISH ],
    "develop.rocketplay.com": [ LOCALES.ENGLISH, LOCALES.CANADIAN_ENGLISH ],
    [DOMAIN_FOR_AUSTRALIA]: [ LOCALES.ENGLISH, LOCALES.AUSTRALIAN_ENGLISH ],
    ["develop." + DOMAIN_FOR_AUSTRALIA]: [ LOCALES.ENGLISH, LOCALES.AUSTRALIAN_ENGLISH ],
    "vue3-launch.rocketplay.wlabel.site": [ LOCALES.ENGLISH, LOCALES.AUSTRALIAN_ENGLISH ],
};
