import type { IVipAdventuresConfig } from "@api/DTO";
import log from "@controllers/Logger";
import type { IStagByReferName, ISurveyConfig } from "@src/types/configs";
import { http } from "../http";

const loadConfig = async <type>(endpoint: string, logError: string) => {
    try {
        const { data } = await http().get<type>(endpoint);
        return data;
    } catch (err) {
        log.error(logError, err);
    }
};

const loadStagByReferNameReq = () => loadConfig<IStagByReferName>("/api/fe/config/stag-by-referrer-name", "LOAD_STAG_BY_REFER_NAME_CONFIG");
const loadSurveyConfigReq = () => loadConfig<ISurveyConfig>("/api/fe/config/survey-config", "LOAD_SURVEY_CONFIG_ERROR");
const loadBettingConfigReq = () => loadConfig<string[]>("/api/fe/config/betting-config", "LOAD_BETTING_CONFIG_ERROR");
const loadVipAdventuresConfigReq = () => loadConfig<IVipAdventuresConfig>("/api/fe/config/vip-adventures", "LOAD_VIP_ADVENTURES_CONFIG_ERROR");

export {
    loadStagByReferNameReq,
    loadSurveyConfigReq,
    loadBettingConfigReq,
    loadVipAdventuresConfigReq
};
