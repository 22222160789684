import { mapState } from "pinia";
import { routeNames } from "@router/routeNames";
import { useGamesProviders } from "@store/games/gamesProviders";

import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import ProducerImage from "@components/ProducerImage/ProducerImage.vue";
import * as ProducerImageConfig from "@components/ProducerImage/ProducerImage.vue";
import { useRootStore } from "@store/root";

export default {
    components: {
        CarouselScroll,
        ProducerImage,
    },

    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            routeNames,
            ProducerImageConfig,
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),

        ...mapState(useGamesProviders, {
            getGamesProviders: "gamesProviders",
        }),

        gamesProviders() {
            return this.getGamesProviders.length ? this.getGamesProviders : [];
        },

    },
};
