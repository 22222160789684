<template>
    <CarouselScroll
        :autoplay="environment.useMocker ? null : 10000"
        :pagination="true"
        class="home-banner"
        @view-banner="trackActiveBanner($event)"
    >
        <BannerSlide
            v-for="(banner, index) in bannersByType"
            :key="index"
            :ref="'banner__' + banner.id"
            :banner="banner"
            :lazy="index !== 0"
            @click-banner="(...args) => clickOnBanner(...args, index)"
        />
    </CarouselScroll>
</template>

<script>
import { mapState, mapActions } from "pinia";
import BannerSlide from "@components/BannerSlide/BannerSlide.vue";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import { BANNERS_CATEGORIES } from "@config/banners";
import { isElementInViewport } from "@helpers/isElementInViewport";
import defaultImg from "@theme/images/BrandImages/default-image.svg";
import { useEnvironments } from "@store/environments";
import { getAffiliate } from "@helpers/getAffiliate";
import { labelToString } from "@helpers/promoHelpers";
import { routeNames } from "@router/routeNames";
import { useBannerStore } from "@store/banners";

export default {
    name: "HomeBanner",

    components: {
        BannerSlide,
        CarouselScroll,
    },

    data() {
        return {
            sliderActiveIndex: 0,
            animationEnabled: true,
            dots: {
                active: "#E0C55D",
                inactive: "#ffffff33",
            },
        };
    },

    computed: {
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),
        ...mapState(useBannerStore, {
            banners: "getBannersData",
            viewedGTMBanners: "viewedGTMBanners",
        }),

        bannersSkeleton() {
            return [
                {
                    categories: [ "slider-index-top" ],
                    description: { text: "", size: "large" },
                    id: Date.now(),
                    groups: [],
                    logged: true,
                    title: { text: "" },
                    identity: 0,
                    gradient: {
                        colorLeft: "#8C51FF",
                        colorLeftCenter: "#712DF4",
                        colorRightCenter: "#2E0F6D",
                        colorRight: "#260764",
                    },
                    labels: [ { title: "" } ],
                    button: { name: "", url: "" },
                    secondButton: {
                        name: "Details",
                        popup: {
                            title: "",
                            secondTitle: "",
                            desc: "",
                        },
                    },
                    image: defaultImg,
                    isSkeleton: true,
                },
            ];
        },

        bannersCollection() {
            return this.banners.length ? this.banners : this.bannersSkeleton;
        },

        bannersByType() {
            return this.bannersCollection.filter((item) => {
                return item.categories.includes(BANNERS_CATEGORIES.INDEX_TOP) ||
                    item.categories.includes(BANNERS_CATEGORIES.INDEX_TOP_JACKPOTS);
            });
        },
    },

    methods: {
        ...mapActions(useBannerStore, {
            setViewedGTMBanners: "setViewedGTMBanners",
        }),
        trackActiveBanner(index) {
            if (this.$route.name === routeNames.main) {
                // if no index passed or banner is already viewed
                if (this.viewedGTMBanners.some((item) => item.location_id === BANNERS_CATEGORIES.INDEX_TOP &&
                item.creative_slot === index + 1)) {
                    return;
                }

                const banner = this.bannersByType[index];
                const { id, labels } = banner;

                const refKeyBanner = this.$refs[`banner__${ id }`];
                if (refKeyBanner) {
                    // setTimeout to wait for banner render
                    const isBannerVisible = isElementInViewport(refKeyBanner[0]?.$el);
                    if (isBannerVisible) {
                        const viewedBanner = {
                            promotion_id: id,
                            promotion_name: labelToString(labels),
                            location_id: BANNERS_CATEGORIES.INDEX_TOP,
                            creative_slot: index + 1,
                        };
                        this.setViewedGTMBanners(viewedBanner);
                    }
                }
            }
        },
        clickOnBanner(banner, index) {
            const { id, categories, labels } = banner;

            this.$gtm.customTrackEvent({
                event: "select_promotion",
                Affiliate: getAffiliate(this.$router),
                ecommerce: {
                    items: [ {
                        promotion_id: id,
                        promotion_name: labelToString(labels),
                        location_id: index + 1,
                        creative_slot: categories[0],
                    } ],
                },
            });
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
