<template>
    <div class="iti-flag-wrapper">
        <div :class="`iti-flag ${code}`" />
    </div>
</template>

<script>
export default {
    props: {
        code: {
            type: String,
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
