<script setup lang="ts">
import { useNoticesStore } from "@store/notices";
import featureFlags from "@theme/configs/featureFlags";
import FeButton, { themes } from "@ui/FeButton/FeButton.vue";
import { icons } from "@theme/configs/icons";
import { EventBus as $bus } from "@bus";
import FeButtonNew from "@ui/New/FeButton.vue";
import { storeToRefs } from "pinia";
import { themes as themeConfig } from "@ui/New/ButtonConfig";

const { enabledNotices } = storeToRefs(useNoticesStore());

function openChat() {
    $bus.$emit("chat.toggle");
}

</script>

<template>
    <div v-if="enabledNotices" class="support-widget-button" @click="openChat">
        <FeButtonNew
            v-if="featureFlags.enableNewUIComponents"
            :theme="themeConfig.SecondaryLarge"
            :icon="icons.outline.support" />
        <FeButton v-else :theme="themes.SecondaryMedium" :icon="icons.outline.support" />
    </div>
</template>

<style scoped lang="scss">
.support-widget-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
