<template>
    <div class="promotion-list__wrap gap-s">
        <div
            v-for="banner in bannersListCompare"
            :key="banner.id"
            :class="[ banner ? banner.size : '' ]"
            class="promotion-list__item"
        >
            <BannerSlide
                :banner="banner"
                :button-theme="featureFlags.enableNewUIComponents ? themes.PrimarySmall : FeButtonConfig.themes.PrimaryExtraSmall"
                show-second-button
            />
        </div>
    </div>
</template>

<script>

import BannerSlide from "@components/BannerSlide/BannerSlide.vue";
import * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "PromotionList",

    components: {
        BannerSlide,
    },

    props: {
        bannersList: {
            type: Array,
        },
    },

    data() {
        return {
            FeButtonConfig,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        bannersListCompare() {
            return this.bannersList.map((item, index) => {
                return {
                    ...item,
                    id: index,
                    identity: 0,
                    gradient: item.gradient ? item.gradient : {
                        colorLeft: "#2F3645",
                        colorLeftCenter: "#2F3645",
                        colorRightCenter: "#2F3645",
                        colorRight: "#2F3645",
                    },
                };
            });
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.promotion-list {
    &__wrap {
        grid-template-columns: 1fr 1fr;
        padding: 1.5rem 0;

        @include media(L) {
            padding: 2rem 0;
        }
    }

    &__item {
        width: 100%;
        grid-column: 1 / 3;

        @include media(L) {
            grid-column: unset;
        }

        &.large {
            grid-column: 1 / 3;
        }
    }
}
</style>
