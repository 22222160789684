import { routeNames } from "@router/routeNames";
import { isServer } from "@helpers/ssrHelpers";
import { giftPromocodeStorage } from "@helpers/promocodeStorageHelper";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";

export default function profileHook(router) {
    if (isServer) {
        return;
    }

    router.beforeEach((to, from, next) => {
        const giftPromocode = giftPromocodeStorage.get();
        const { getIsLogged } = storeToRefs(useUserInfo());

        if (to.name === routeNames.main && giftPromocode && getIsLogged.value) {
            next({ name: routeNames.profileGifts, query: null });
        }

        next();
    });
}
