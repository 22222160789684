<template>
    <div class="footer-menu-links">
        <template v-if="!isMobile">
            <div>
                <p class="footer-menu-links__title h4">
                    {{ $t("FOOTER.INFO_TITLE") }}
                </p>
                <ul class="footer-menu-links--two-colum">
                    <li v-for="page in licenseFilteredInfoPages" :key="page.id">
                        <a
                            v-if="page.url3thPath"
                            :href="page.url3thPath"
                            :rel="page.rel"
                            :target="page.target"
                        >{{ page.title }}</a>
                        <router-link v-else :to="page.url || {name: page.routeName}">
                            {{ page.title }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div>
                <p class="footer-menu-links__title h4">
                    {{ $t("FOOTER.HELP") }}
                </p>
                <ul>
                    <li v-for="page in helpPages" :key="page.id">
                        <a
                            v-if="page.url3thPath"
                            :href="page.url3thPath"
                            :target="page.target"
                        >{{ page.title }}</a>
                        <router-link v-else :to="page.url || {name: page.routeName}">
                            {{ page.title }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </template>

        <template v-else>
            <FeDropdown
                :btn-name="$t('FOOTER.INFO_TITLE')"
                class="footer-menu-links__block-dropdown"
            >
                <ul class="footer-menu-links">
                    <li v-for="page in licenseFilteredInfoPages" :key="page.id">
                        <a
                            v-if="page.url3thPath"
                            :href="page.url3thPath"
                            :rel="page.rel"
                            :target="page.target"
                        >{{ page.title }}</a>
                        <router-link v-else :to="page.url || {name: page.routeName}">
                            {{ page.title }}
                        </router-link>
                    </li>
                </ul>
            </FeDropdown>

            <FeDropdown
                :btn-name="$t('FOOTER.HELP')"
                class="footer-menu-links__block-dropdown "
            >
                <ul class="footer-menu-links">
                    <li v-for="page in helpPages" :key="page.id">
                        <a
                            v-if="page.url3thPath"
                            :href="page.url3thPath"
                            :target="page.target"
                        >{{ page.title }}</a>
                        <router-link v-else :to="page.url || {name: page.routeName}">
                            {{ page.title }}
                        </router-link>
                    </li>
                </ul>
            </FeDropdown>
        </template>
    </div>
</template>

<script>
export default {
    name: "FooterMenu",
};
</script>

<script setup>
import { computed, toRefs } from "vue";
import { routeNames } from "@router/routeNames";
import { getDomainName } from "@helpers/getDomainName";
import { LANG_TO_MIRRORS_FOR_BLOG } from "@theme/configs/blogConfigs";
import FeDropdown from "@ui/FeDropdown/FeDropdown.vue";
import { useI18n } from "vue-i18n";
import { useLicense } from "@helpers/license";

import { BOTTOM_MENU } from "@modules/StaticPage/constants";
import { useCMS } from "@store/CMS";
import { storeToRefs } from "pinia";
import { useRootStore } from "@store/root";

const { t, locale } = useI18n();

const { getUnhiddenStaticPages: pages } = useCMS();

const { isLicenseEE } = toRefs(useLicense());

const informationPages = computed(() => {
    const benefitsCryptoLink = {
        id: routeNames.benefitCrypto,
        hidden: false,
        title: t("FOOTER.LINKS.BENEFITS_CRYPTO"),
        routeName: routeNames.benefitCrypto,
    };
    const affiliatesLink = {
        title: t("FOOTER.LINKS.AFFILIATES"),
        url3thPath: "https://rocketplaypartners.com/",
        target: "_blank",
        rel: "noindex, nofollow",
    };

    const footerMenuPages = pages.filter((page) => {
        return page.categories.includes(BOTTOM_MENU);
    });

    return [
        ...footerMenuPages,
        benefitsCryptoLink,
        affiliatesLink,
    ];
});

const LICENSE_RESTRICTED_PAGES = [ "BenefitCrypto", "sportsbook-terms" ];

const licenseFilteredInfoPages = computed(() => {
    return isLicenseEE.value
        ? informationPages.value.filter(({ id }) => !LICENSE_RESTRICTED_PAGES.includes(id))
        : informationPages.value;
});


const blogLink = computed(() => {
    if (typeof window !== "undefined") {
        const showLangToMirrorForBlog = LANG_TO_MIRRORS_FOR_BLOG[location?.hostname]?.includes(locale.value);
        if (showLangToMirrorForBlog) {
            return `https://blog.${getDomainName()}`;
        }
        return null;
    }
});

const { isMobile } = storeToRefs(useRootStore());

const helpPages = computed(() => {
    const helpMenuCMSPages = pages.filter((page) => {
        return page.categories.includes("bottom-menu-help");
    });
    const hasSupportPage = pages.some((item) => {
        return item.id === "support";
    });
    const supportLink = {
        id: routeNames.supportPage,
        hidden: false,
        title: t("FOOTER.LINKS.SUPPORT"),
        routeName: routeNames.supportPage,
    };

    const blogItem = {
        id: "blog",
        hidden: false,
        title: t("FOOTER.LINKS.BLOG"),
        url3thPath: blogLink.value,
        target: "_blank",
        rel: "noindex, nofollow",
    };

    return [
        ...helpMenuCMSPages,
        ...(hasSupportPage ? [ supportLink ] : []),
        ...(blogLink.value ? [ blogItem ] : []),
    ];
});
</script>

<style lang="scss" src="./style.scss"></style>


