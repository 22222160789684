<template>
    <div>
        <div v-if="showTitle" class="game-row__cover mobile-layout-width">
            <p data-test="game-row__cover-title" class="h2">
                {{ $t("GAME_HALL.PROVIDERS") }}
            </p>
        </div>
        <CarouselScroll class="producers-block" :transparent-border="true">
            <router-link
                v-for="producer in gamesProviders"
                :key="producer.id"
                class="producers-block__link"
                :to="{ name: routeNames.producersGames, params: { slug: producer.slug } }"
            >
                <div class="producers-block__link-wrapper">
                    <ProducerImage
                        :producer="producer.slug"
                        :type="ProducerImageConfig.THEMES.DARK"/>
                </div>
            </router-link>
        </CarouselScroll>
    </div>
</template>

<script>

import mixinProducerRow from "@modules/GameHall/components/Providers/mixinProducerRow.js";

export default {
    name: "Providers",

    mixins: [ mixinProducerRow ],
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
