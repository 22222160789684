<template>
    <div class="cashback-card">
        <div class="cashback-card__info-wrap">
            <div class="cashback-card__img-wrap">
                <FeImage
                    :src="cashbackCardImg"
                    :src-retina="cashbackCardImgX2"
                    class="cashback-card__img"
                />
            </div>
            <div
                v-t="'GAME_HALL.CASHBACK_CARD.DESC'"
                class="cashback-card__des"
            />
        </div>
        <FeButtonNew
            v-if="featureFlags.enableNewUIComponents"
            :theme="themes.PrimarySmall"
            full-width
            data-test="game-hall__cashback-card--button"
            @click="handleClickMore"
        >
            {{ $t("GAME_HALL.CASHBACK_CARD.BUTTON") }}
        </FeButtonNew>
        <FeButton
            v-else
            :theme="FeButtonConfig.themes.PrimaryExtraSmall"
            full-width
            data-test="game-hall__cashback-card--button"
            @click="handleClickMore"
        >
            {{ $t("GAME_HALL.CASHBACK_CARD.BUTTON") }}
        </FeButton>
    </div>
</template>

<script>
import FeImage from "@ui/FeImage/FeImage.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { defineAsyncComponent } from "vue";

import cashbackCardImg from "./img/cashback-card.jpg";
import cashbackCardImgX2 from "./img/cashbak-card@2x.jpg";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { useUIStore } from "@store/ui";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

const cashbackPopup = defineAsyncComponent(() => {
    return import("@modules/GameHall/components/CashbackCard/CashbackPopup.vue");
});
const $gtm = useGtm();

// Mock data for GTM
const itemViewedBanners = {
    creative: "live_casino_all",
    id: "cashback_10",
    name: "cashback_10",
    position: 1,

};

export default {
    name: "CashbackCard",

    components: {
        FeButtonNew,
        FeImage,
        FeButton,
    },

    setup() {
        const { setShowModal } = useUIStore();

        return {
            setShowModal,
        };
    },

    data() {
        return {
            cashbackCardImg,
            cashbackCardImgX2,
            FeButtonConfig,
        };
    },
    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
    },

    mounted() {
        this.handleViewBannerPromo();
    },

    methods: {
        async handleClickMore() {
            this.setShowModal({
                name: "cashback-game-item-popup",
                component: cashbackPopup,
                mobileFriendly: true,
                blockCloseOverlay: true,
            });
            this.handleClickBannerPromo();
        },

        handleClickBannerPromo() {
            $gtm.customTrackEvent({
                event: "ua_select_promo",
                eventContext: "Interactions",
                eventCategory: "banner",
                eventAction: "click",
                eventContent: "",
                ecommerce: {
                    promoClick: {
                        promotions: [ itemViewedBanners ],
                    },
                },
            });

            $gtm.customTrackEvent({
                event: "select_promotion",
                ecommerce: {
                    items: [ {
                        promotion_id: itemViewedBanners.id,
                        promotion_name: itemViewedBanners.name,
                        location_id: itemViewedBanners.creative,
                        creative_slot: itemViewedBanners.position,
                    } ],
                },
            });
        },

        handleViewBannerPromo() {
            $gtm.customTrackEvent({
                event: "ua_view_promo",
                ecommerce: {
                    promoView: {
                        promotions: [ itemViewedBanners ],
                    },
                },
            });

            $gtm.customTrackEvent({
                event: "view_promotion",
                ecommerce: {
                    items: [ itemViewedBanners ],
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.cashback-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background: var(--color-secondary-gradient);
    border-radius: var(--border-radius-s);

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    @include media(M) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    @include media(XL) {
        grid-column-start: 4;
        grid-column-end: 6;
    }

    &__info-wrap {
        height: 100%;
        margin-bottom: .5rem;
    }

    &__img {
        width: 100%;
        margin-bottom: .25rem;
        border-radius: var(--border-radius-xs);
        position: absolute;
        left: 0;
        top: 0;

        &-wrap {
            position: relative;
            width: 100%;
            padding-top: 33%;
        }
    }

    &__des {
        @include font-size--Body($font-weight--bold);
        text-align: center;
    }
}
</style>
