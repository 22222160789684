<template>
    <div
        class="game-item__random"
        @click="handleGoToRandomGame"
    >
        <div class="game-item__random-body">
            <div class="game-item__random-wrapper">
                <div class="game-item__random-container">
                    <FeIcon
                        :icon="FeIconConfig.icons.outline.dice"
                        class="game-item__random-img"
                        :class="{ animation: !environment.useMocker }"
                    />
                    <div class="game-item__random-text">
                        {{ $t("GAME_RANDOM_ITEM.DESC") }}
                    </div>
                </div>
                <FeButtonNew
                    v-if="featureFlags.enableNewUIComponents"
                    :theme="themeNewButton"
                    full-width
                    @click.stop="handleGoToRandomGame"
                >
                    {{ $t("GAME_RANDOM_ITEM.BUTTON") }}
                </FeButtonNew>
                <FeButton
                    v-else
                    :theme="themeButton"
                    class="game-item__random-action"
                    full-width
                    @click.stop="handleGoToRandomGame"
                >
                    {{ $t("GAME_RANDOM_ITEM.BUTTON") }}
                </FeButton>
            </div>
        </div>
    </div>
</template>

<script>
import { routeNames } from "@router/routeNames";

import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { mapActions, mapState } from "pinia";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";
import { useEnvironments } from "@store/environments";
import { useUserInfo } from "@store/user/userInfo";
import { useRootStore } from "@store/root";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import featureFlags from "@theme/configs/featureFlags";


export default {
    name: "GameItemRandom",

    components: {
        FeIcon,
        FeButton,
        FeButtonNew,
    },

    props: {
        category: {
            type: String,
        },
    },

    data() {
        return {
            FeIconConfig,
            FeButtonConfig,
        };
    },

    computed: {
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),

        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),

        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
        ...mapState(useGamesCommon, {
            randomGameByCategory: "getRandomGameByCategory",
        }),

        themeButton() {
            return this.isMobile ? FeButtonConfig.themes.SecondaryExtraSmall : FeButtonConfig.themes.SecondarySmall;
        },
        themeNewButton() {
            return this.isMobile ? themes.SecondarySmall : themes.SecondaryMedium;
        },

    },

    methods: {
        ...mapActions(useGamesCategory, {
            loadCategoryGames: "loadGamesCategory",
        }),

        async handleGoToRandomGame() {
            await this.loadCategoryGames(this.category);

            this.$gtm.customTrackEvent({
                ...this.$gtm.COMMON_EVENTS.RANDOM_CARD,
                category: this.category,
            });

            const randomGame = this.randomGameByCategory(this.category);
            return this.$router.push({
                name: routeNames.gameItem,
                params: {
                    name: randomGame.seo_title,
                    producer: randomGame.provider,
                    demo: !this.isLogged,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.game-item__random {
    position: relative;
    padding-bottom: 140%;
    height: 100%;

    &-body {
        display: grid;
        grid-template-rows: 1fr auto;
        align-items: center;
        justify-items: center;
        width: 100%;
        position: absolute;
        margin: 0;
        border-radius: var(--border-radius-s);
        background: var(--color-layer-alt-2);
        height: calc(100% - 2.5rem);
        box-sizing: border-box;
        text-decoration: none;
        color: var(--color-layer-alt-1);
    }

    &-container {
        margin: auto 0;
    }

    &-wrapper {
        height: 100%;
        position: absolute;
        display: grid;
        grid-template-rows: 1fr auto;
        padding: 1rem;
        grid-row-gap: .5rem;
    }

    &-img {
        width: 4rem;
        height: 4rem;
        margin: 0 auto;
        color: var(--color-text-body);

        &.animation {
            animation: rotatingDice 5s infinite;
            animation-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
            animation-direction: reverse;
        }
    }

    &-text {
        @include font-size--Caption2($weight: $font-weight--normal);
        text-align: center;
        color: var(--color-text-body);
    }

    &-action {
        width: 100%;
    }
}

@keyframes rotatingDice {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(900deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

</style>
