<script setup lang="ts">
import featureFlags from "@theme/configs/featureFlags";
import { getFeatureFlags } from "./featureFlag";
import { setFeatureFlagConfigValue, resetFeatureFlagConfig } from "./helpers/localStorage";
import { ref } from "vue";

const flags = ref<Record<string, boolean>>({
    ...getFeatureFlags(),
});

function goHome() {
    window.location.href = "/";
}

function resetAll() {
    resetFeatureFlagConfig();
    flags.value = { ...featureFlags };
}

function toggleFlag(key: string) {
    setFeatureFlagConfigValue(key, !flags.value[key]);
    flags.value[key] = !flags.value[key];
}
</script>

<template>
    <div class="feature-flags-wrapper">
        <div class="feature-flags">
            <div v-for="(value, key) in flags" :key="key" class="feature-flag">
                <div class="feature-flag__name">
                    {{ key }}
                </div>
                <div class="feature-flag__value" @click="toggleFlag(key)">
                    <div :class="[ 'feature-flag__value-option', {'feature-flag__value-option-active': value} ]">
                        On
                    </div>
                    <div :class="[ 'feature-flag__value-option', {'feature-flag__value-option-active': !value} ]">
                        Off
                    </div>
                </div>
            </div>
        </div>
        <button class="feature-flags-action" @click="resetAll()">
            Reset to defaults
        </button>
        <button class="feature-flags-action" @click="goHome">
            Home
        </button>
    </div>
</template>

<style scoped lang="scss">
.feature-flags-wrapper {
    max-width: 400px;
    min-width: 300px;
    width: 80vw;
    margin: auto;
    padding: 2rem 0;
    display: grid;
    grid-gap: 2rem;
}
.feature-flags {
    display: grid;
    grid-gap: 1rem;
}
.feature-flags-action {
    background: white;
    border: 0;
    padding: 0.5rem 0;
}
.feature-flag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &__name {
        margin-right: 0.5rem;
    }
    &__value {
        display: flex;
        border: 1px solid white;

        &-option {
            padding: 0.2rem;
            position: relative;

            &-active {
                background: #1b861b;
            }
        }
    }
}
</style>
