import { useRouter } from "vue-router";
import Modal from "@plugins/Modal";
import { defineAsyncComponent } from "vue";

const specialGamePopup = defineAsyncComponent(() => {
    return import("@components/SpecialGamePopup/SpecialGamePopup.vue");
});

export const KEY_SHOW_SPECIAL_SLOT_POPUP = "showedSpecialSlotPopup";

export function useClickOnSpecialSlotMenu() {
    const router = useRouter();

    function moveToSlot(linkConfig) {
        return router.push(linkConfig.to || linkConfig.url || { name: linkConfig.routeName });
    }

    async function clickOnSpecialSlotMenu(linkConfig) {
        const showPopup = !localStorage.getItem(KEY_SHOW_SPECIAL_SLOT_POPUP);
        if (showPopup) {
            Modal.show({
                mobileFriendly: true,
                name: "modal-search",
                component: specialGamePopup,
                props: {
                    clickFn: moveToSlot.bind(null, linkConfig),
                },
            });
            return;
        }
        moveToSlot(linkConfig);
    }

    return {
        clickOnSpecialSlotMenu,
    };
}


