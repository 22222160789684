<template>
    <div
        :class="classesCard"
        class="adv-card gap-row-s padding-s"
    >
        <div class="adv-card__top-day-info">
            <p v-if="statusCard !== AdvPrizeStatuses.SUPERB" class="font__caption-2--normal color-text-body">
                <span v-if="isFuturePrize">{{ $t("ADVENTURE.PRIZE_CARD.TIMER_FUTURE_TEXT") }} &nbsp;</span>
                <strong class="text--color--alt"> {{ dayDisplay }}</strong>
            </p>
            <PromoStatus
                v-if="showTimer"
                :dots="false"
                :promo-data="timerData"
                :text-time="[
                    $t('TOURNAMENTS.TIMER.DAYS_M'),
                    $t('TOURNAMENTS.TIMER.HOURS_M'),
                    $t('TOURNAMENTS.TIMER.MINUTES_M'),
                ]"
                class="adv-card__timer"
            />
        </div>

        <div>
            <FeIcon
                v-if="iconByType"
                :icon="iconByType"
                class="adv-card__img"
            />
            <FeImage
                v-else
                :src="imgByType.src"
                :src-retina="imgByType.srcRetina"
                class="adv-card__img"
            />
            <p class="h3 text--align-center">
                {{ prizeText }}
            </p>
        </div>

        <div class="adv-card__footer gap-row-s">
            <div class="text--align-center">
                <span class="text--align-center adv-card__condition-text">{{ condition }}</span>
                <p class="font__caption-2--normal">
                    {{ description }}
                </p>
            </div>
            <div v-if="buttonsCard.length" class="adv-card__actions gap-column-xs">
                <template v-if="featureFlags.enableNewUIComponents">
                    <FeButtonNew
                        v-for="btn in buttonsCard"
                        :key="btn.title"
                        :theme="btn.newTheme"
                        full-width
                        @click="btn.handleClick"
                    >
                        {{ btn.title }}
                    </FeButtonNew>
                </template>
                <template v-else>
                    <FeButton
                        v-for="btn in buttonsCard"
                        :key="btn.title"
                        :theme="btn.theme"
                        full-width
                        @click="btn.handleClick"
                    >
                        {{ btn.title }}
                    </FeButton>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRootStore } from "@store/root";
import featureFlags from "@theme/configs/featureFlags";
import dayjs, { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";
import { computed, defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";

import { routeNames } from "@router/routeNames";
import type { GiftAllItem } from "@api";
import { STATUSES_LOST_GIFT_CANCELED } from "@config/gift";
import { AdvPrizeStatuses, AdvPrizeType, formatDateVipAdv } from "@modules/VipAdventures/consts";

import cashGift from "@modules/VipAdventures/img/cashGift.png";
import cashGiftX2 from "@modules/VipAdventures/img/cashGift@2x.png";
import freespinsGift from "@modules/VipAdventures/img/freespinsGift.png";
import freespinsGiftX2 from "@modules/VipAdventures/img/freespinsGift@2x.png";

import { useI18n } from "vue-i18n";
import type { TranslateResult } from "vue-i18n";
import Modal from "@plugins/Modal";
import FeButton, { themes as themesButton } from "@ui/FeButton/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";

import PromoStatus from "@components/PromoStatus/PromoStatus.vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeImage from "@ui/FeImage/FeImage.vue";
import { EventBus as bus, BUS_EVENTS } from "@bus";

import { parseGiftAdventureTitle } from "@store/user/helpers/vipAdventures";
import { useGiftsStore } from "@store/gifts";
import { useVipAdventures } from "@store/user/vipAdventures";

const detailsPopup = defineAsyncComponent(() => import(
    "@modules/VipAdventures/AdventureCardItemDetailsPopup.vue"
));

interface IProps {
    cardData: {
        day: string;
        isCompleted: boolean;
        condition: {
            minDep: string;
            "wager": string;
            "periodActivationDays": string;
        };
        prize: {
            fs?: number;
            bonus?: string;
        },
        step: number;
        stepTotal: number;
    };
}

const props = defineProps<IProps>();
const $router = useRouter();
const $i18n = useI18n();
const giftsStore = useGiftsStore();
const vipAdventuresStore = useVipAdventures();

const imgCollection = {
    [AdvPrizeType.FREESPIN]: {
        src: freespinsGift,
        srcRetina: freespinsGiftX2,
    },
    [AdvPrizeType.BONUS]: {
        src: cashGift,
        srcRetina: cashGiftX2,
    },
};

const dayFullData = computed(() => dayjs(props.cardData.day, formatDateVipAdv).utc(true));
const todayData = computed(() => vipAdventuresStore.toDay);

const statusCard = computed<AdvPrizeStatuses>(() => {
    if (props.cardData.step === props.cardData.stepTotal + 1) {
        return AdvPrizeStatuses.SUPERB;
    }

    if (props.cardData.isCompleted) {
        return AdvPrizeStatuses.COMPLETED;
    }

    if (dayFullData.value.isSame(todayData.value, "day")) {
        return AdvPrizeStatuses.ACTIVE;
    }

    if (dayFullData.value.isBefore(todayData.value, "day")) {
        return AdvPrizeStatuses.LOST;
    }

    if (dayFullData.value.isAfter(todayData.value, "day")) {
        return AdvPrizeStatuses.FUTURE;
    }

    return AdvPrizeStatuses.LOST;
});

const prizeType = computed<AdvPrizeType>(() => {
    if (statusCard.value === AdvPrizeStatuses.SUPERB) {
        return AdvPrizeType.SUPERB;
    }

    if (props.cardData.prize.bonus) {
        return AdvPrizeType.BONUS;
    }
    if (props.cardData.prize.fs) {
        return AdvPrizeType.FREESPIN;
    }

    return AdvPrizeType.BONUS;
});

const isShowLockIcon = computed(() => {
    return statusCard.value === AdvPrizeStatuses.LOST || statusCard.value === AdvPrizeStatuses.FUTURE;
});

const iconByType = computed(() => {
    if (isShowLockIcon.value) {
        return icons.fill.lock;
    }
    if (statusCard.value === AdvPrizeStatuses.SUPERB) {
        return icons.fill.secretGifts;
    }
});

const { isMobile } = storeToRefs(useRootStore());

const isFuturePrize = computed(() => {
    return statusCard.value === AdvPrizeStatuses.FUTURE || statusCard.value === AdvPrizeStatuses.SUPERB;
});

const dayDisplay = computed(() => {
    return `${dayFullData.value.format("DD")} ${$i18n.tm("CALENDAR.MONTH")[dayFullData.value.get("month")]}`;
});

const isToday = computed<boolean>(() => {
    return dayFullData.value.format(formatDateVipAdv) === vipAdventuresStore.toDay.format(formatDateVipAdv);
});

const condition = computed<TranslateResult>(() => {
    if (statusCard.value === AdvPrizeStatuses.SUPERB) {
        return $i18n.t("ADVENTURE.PRIZE_CARD.CONDITION.SUPPER", {
            countDays: "",
        });
    }

    if (props.cardData.isCompleted) {
        return $i18n.t("ADVENTURE.PRIZE_CARD.STATUS.COMPLETED");
    }

    if (isToday.value) {
        return $i18n.t("ADVENTURE.PRIZE_CARD.CONDITION.TASK", { depCount: props.cardData.condition.minDep });
    }

    switch (statusCard.value) {
        case AdvPrizeStatuses.COMPLETED:
            return $i18n.t("ADVENTURE.PRIZE_CARD.STATUS.COMPLETED");

        case AdvPrizeStatuses.LOST:
            return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.DESC.TASK_LOST");

        case AdvPrizeStatuses.FUTURE:
            return $i18n.t("ADVENTURE.PRIZE_CARD.CONDITION.FUTURE", {
                countDays: Math.ceil(dayFullData.value.diff(vipAdventuresStore.toDay, "h") / 24),
            });

        default:
            return "";
    }
});

type cardPrizeDataType = GiftAllItem & {
    step: number;
    giftTitle: string;
};
const cardPrizeData = computed<cardPrizeDataType | null>(() => {
    switch (statusCard.value) {
        case AdvPrizeStatuses.COMPLETED:
        case AdvPrizeStatuses.ACTIVE:
            for (const giftItem of [ ...giftsStore.giftsAll, ...giftsStore.giftsLost, ...giftsStore.fsGiftsAll ]) {
                const dataPrise = parseGiftAdventureTitle(giftItem.title);
                if (
                    typeof dataPrise === "object" &&
                    "giftTitle" in dataPrise &&
                    props.cardData.step === dataPrise.step &&
                    dataPrise.day === props.cardData.day
                ) {
                    return {
                        ...giftItem,
                        step: dataPrise.step,
                        giftTitle: dataPrise.giftTitle,
                    };
                }
            }
            return null;

        default:
            return null;
    }
});

const showTimer = computed<boolean>(() => {
    return statusCard.value === AdvPrizeStatuses.ACTIVE ||
        (
            statusCard.value === AdvPrizeStatuses.COMPLETED &&
            Boolean(cardPrizeData.value) &&
            cardPrizeData.value?.stage !== STATUSES_LOST_GIFT_CANCELED
        );
});

interface ITimerData {
    start_at: Dayjs | string | null;
    status: string;
    textTimes: TranslateResult;
}

const timerData = computed<ITimerData>(() => {
    const defaultTimerData = {
        start_at: dayjs().utc().add(1, "day").startOf("day"),
        status: "expires",
        textTimes: $i18n.t("ADVENTURE.PRIZE_CARD.STATUS.EXPIRES"),
    };

    if (cardPrizeData.value) {
        defaultTimerData.start_at = cardPrizeData.value.activatable_until;
    }

    return defaultTimerData;
});

const imgByType = computed(() => {
    if (cardPrizeData.value?.type) {
        return imgCollection[cardPrizeData.value.type];
    }

    if (prizeType.value) {
        return imgCollection[prizeType.value];
    }
});

const prizeText = computed(() => {
    if (cardPrizeData.value) {
        return cardPrizeData.value?.giftTitle;
    }

    if (prizeType.value === AdvPrizeType.SUPERB) {
        if (dayFullData.value.isBefore(todayData.value)) {
            return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.SUPPER_LOST");
        }
        return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.SUPPER");
    }

    if (isToday.value ||
        statusCard.value === AdvPrizeStatuses.ACTIVE ||
        statusCard.value === AdvPrizeStatuses.COMPLETED
    ) {
        switch (prizeType.value) {
            case AdvPrizeType.BONUS:
                return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.TODAY_PRIZE_TEXT.BONUS", {
                    textPrize: props.cardData.prize.bonus,
                });
            case AdvPrizeType.FREESPIN:
            default:
                return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.TODAY_PRIZE_TEXT.FREESPIN", {
                    textPrize: props.cardData.prize.fs,
                });
        }
    }


    return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.DAILY");
});

const description = computed<TranslateResult | undefined>(() => {
    if (statusCard.value === AdvPrizeStatuses.SUPERB) {
        return;
    }

    if (isToday.value && cardPrizeData.value) {
        if (!cardPrizeData.value.activatable) {
            return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.TODAY_PRIZE_TEXT.HAS_PRIZE_ACTIVE");
        }

        return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.TODAY_PRIZE_TEXT.HAS_PRIZE_NOT_ACTIVE");
    }

    if (statusCard.value === AdvPrizeStatuses.LOST || (props.cardData.isCompleted && !cardPrizeData.value)) {
        return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.DESC.NOT_RECEIVED");
    }

    if (props.cardData.isCompleted) {
        return $i18n.t("ADVENTURE.PRIZE_CARD.PRIZE_TEXT.DESC.RECEIVED");
    }
});

const classesCard = computed<Record<string, boolean>>(() => {
    return {
        [`adv-card--${statusCard.value}`]: Boolean(statusCard.value),
        "adv-card--with-timer": showTimer.value,
        "adv-card--completed": props.cardData.isCompleted,
    };
});

function handleClickDepositBtn() {
    $router.push({ name: routeNames.cashboxDeposit });
}

const termsForPopup = computed(() => {
    return [
        $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.MIN_DEP", { minDep: props.cardData.condition.minDep }),
        $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.WAGER", { wager: props.cardData.condition.wager }),
        $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.PERIOD_ACTIVATION", {
            periodActivationDays: props.cardData.condition.periodActivationDays,
        }),
    ];
});

async function handleClickDetailsBtn() {
    Modal.show({
        name: "AdventureCardItemDetailsPopup",
        component: detailsPopup,
        mobileFriendly: true,
        props: {
            title: $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.TITLE"),
            prise: $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.DESC", {
                minDep: props.cardData.condition.minDep,
            }),
            terms: termsForPopup.value,
            img: imgByType.value,
            priseText: prizeText.value,
        },
    });
}

function openSupport() {
    bus.$emit(BUS_EVENTS.CHAT_SUPPORT_TOGGLE);
}

function openGiftsPage() {
    $router.push({ name: routeNames.profileGifts });
}

interface IButtonsCard {
    title: TranslateResult;
    theme: {
        color: string;
        size: string;
    },
    handleClick: () => void
}

const buttonsCard = computed<IButtonsCard[]>(() => {
    switch (statusCard.value) {
        case AdvPrizeStatuses.ACTIVE:

            if (props.cardData.isCompleted && cardPrizeData.value) {
                if (!cardPrizeData.value.activatable) {
                    return [];
                }

                return [ {
                    title: $i18n.t("ADVENTURE.PRIZE_CARD.BUTTON_TO_GIFTS"),
                    theme: themesButton.PrimarySmall,
                    newTheme: isMobile.value ? themes.PrimarySmall : themes.PrimaryMedium,
                    handleClick: openGiftsPage,
                } ];
            }

            return [
                {
                    title: $i18n.t("COMMON.DETAILS"),
                    theme: themesButton.SecondarySmall,
                    newTheme: isMobile.value ? themes.SecondarySmall : themes.SecondaryMedium,
                    handleClick: handleClickDetailsBtn,
                },
                {
                    title: $i18n.t("COMMON.DEPOSIT"),
                    theme: themesButton.PrimarySmall,
                    newTheme: isMobile.value ? themes.PrimarySmall : themes.PrimaryMedium,
                    handleClick: handleClickDepositBtn,
                },
            ];
        case AdvPrizeStatuses.COMPLETED:
            if (!cardPrizeData.value) {
                return [ {
                    title: $i18n.t("HEADER.SUPPORT"),
                    theme: themesButton.SecondarySmall,
                    newTheme: isMobile.value ? themes.SecondarySmall : themes.SecondaryMedium,
                    handleClick: openSupport,
                } ];
            }
            return [
                {
                    title: $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.BUTTON_TODAY_TASK"),
                    theme: themesButton.SecondarySmall,
                    newTheme: isMobile.value ? themes.SecondarySmall : themes.SecondaryMedium,
                    handleClick: handleClickDetailsBtn,
                },
            ];

        case AdvPrizeStatuses.FUTURE:
            return [
                {
                    title: $i18n.t("ADVENTURE.PRIZE_CARD.POPUP_TERMS.BUTTON_TODAY_TASK"),
                    theme: themesButton.SecondarySmall,
                    newTheme: isMobile.value ? themes.SecondarySmall : themes.SecondaryMedium,
                    handleClick: handleClickDetailsBtn,
                },
            ];

        default:
            return [];
    }
});

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.adv-card {
    border-radius: 1rem;
    background: var(--color-layer-alt-1);
    width: 16rem;
    height: 19rem;
    flex-direction: column;
    grid-template-rows: auto 1fr auto;
    justify-items: stretch;

    &--lost {
        .adv-card__condition-text {
            @include font-size--Body($font-weight--bold);
            color: var(--color-error);
        }
    }

    .adv-card__top-day-info {
        justify-content: center;
    }

    &--with-timer {
        .adv-card__top-day-info {
            justify-content: space-between;
        }
    }

    &--completed {
        border: 2px solid var(--color-primary-1);

        .adv-card__condition-text {
            @include font-size--Body($font-weight--bold);
            color: var(--color-successfully);
        }
    }

    &--active {
        background: var(--color-secondary-gradient);
    }

    &--future,
    &--superPrize {
        .adv-card__top-day-info {
            justify-content: center;
        }

        .adv-card__condition-text {
            @include font-size--Body($font-weight--bold);
            color: var(--color-primary-2);
        }
    }

    &--superPrize {
        :deep(svg path) {
            fill: url(#a);
        }
    }

    @include media(L) {
        width: 19rem;
        height: 24rem;
    }

    &__top-day-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__timer {
        background: transparent;
        @include font-size--Caption2($font-weight--normal);
        color: var(--color-text-body);
        padding: 0;

        :deep(.promo-item-status .timer) {
            margin: 0 0 0 0.25rem;
        }
    }

    &__img {
        display: block;
        width: 4rem;
        height: 4rem;
        margin: 0 auto 1rem;

        @include media(L) {
            width: 6rem;
            height: 6rem;
        }
    }

    &__actions {
        display: grid;
        grid-column-gap: .5rem;
    }

}
</style>
