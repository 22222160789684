<template>
    <NavRoll
        :nav-list="navList"
        items-location="header"
        left-align-dropdown
        class="header-nav-menu"
    />
</template>

<script>
import NavRoll from "@components/NavRoll/NavRoll.vue";

export default {
    name: "HeaderNavMenu",
    components: {
        NavRoll,
    },

    props: {
        navList: {
            type: Array,
        },
    },
};
</script>


<style scoped lang="scss">
@import "~@theme/styles";

.header-nav-menu {
    :deep(.nav-roll) {

        .nav-roll-item {
            @include link('dark', false);
            @include menu-hover;
            @include font-size--Caption1;
        }


        .nav-roll__wrapper {
            @include media(M) {
                margin-right: 6rem;
            }
        }

        .fe-popup__wrapper {
            left: calc(100% + 1em);
        }

        .fe-popup__content {
            width: auto;
            top: unset;
            left: unset;
            will-change: transform;
            position: fixed;
        }
    }
}

</style>
