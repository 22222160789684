export default {
    birthdayFields: {
        day: "birthday-edit__input--birthday-day",
        month: "birthday-edit__input--birthday-month",
        year: "birthday-edit__input--birthday-year",
    },
    cashboxFields: {
        currencySelect: "cashbox__select-currency",
        amountInput: "cashbox__input-amount",
    },
};
