export const mockLevels = {
    1: {
        position: {
            left: 128,
            top: 189,
        },
        bets: {
            EUR: 0,
        },
    },
    2: {
        position: {
            left: 79,
            top: 273,
        },
        bets: {
            EUR: 400,
        },
    },
    3: {
        position: {
            left: 177,
            top: 274,
        },
        bets: {
            EUR: 1000,
        },
    },
    4: {
        position: {
            left: 128,
            top: 361,
        },
        bets: {
            EUR: 2500,
        },
    },
    5: {
        position: {
            left: 128,
            top: 477,
        },
        bets: {
            EUR: 7500,
        },
    },
    6: {
        position: {
            left: 128,
            top: 660,
        },
        bets: {
            EUR: 10000,
        },
    },
    7: {
        position: {
            left: 79,
            top: 745,
        },
        bets: {
            EUR: 25000,
        },
    },
    8: {
        position: {
            left: 177,
            top: 745,
        },
        bets: {
            EUR: 50000,
        },
    },
    9: {
        position: {
            left: 128,
            top: 832,
        },
        bets: {
            EUR: 75000,
        },
    },
    10: {
        position: {
            left: 128,
            top: 948,
        },
        bets: {
            EUR: 100000,
        },
    },
    11: {
        position: {
            left: 23,
            top: 1118,
        },
        bets: {
            EUR: 150000,
        },
    },
    12: {
        position: {
            left: -10,
            top: 1224,
        },
        bets: {
            EUR: 250000,
        },
    },
    13: {
        position: {
            left: 128,
            top: 1280,
        },
        bets: {
            EUR: 500000,
        },
    },
    14: {
        position: {
            left: 267,
            top: 1224,
        },
        bets: {
            EUR: 750000,
        },
    },
    15: {
        position: {
            left: 236,
            top: 1118,
        },
        bets: {
            EUR: 1000000,
        },
    },
};
