<template>
    <PromoLabels :labels="labels" class="vip-adv__labels" />
</template>

<script lang="ts" setup>
import { computed } from "vue";

import PromoLabels from "@components/PromoLabels/PromoLabels.vue";
import { useVipAdventures } from "@store/user/vipAdventures";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const vipAdvStore = useVipAdventures();

const labels = computed<string[]>(() => {
    return [ {
        title: t("ADVENTURE.PROGRESS", {
            step: vipAdvStore.vipAdventuresDays.filter(({ isCompleted }) => isCompleted).length,
            stepTotal: vipAdvStore.vipAdventuresDays.length,
        }),
        accent: true,
    } ];
});


</script>

<style lang="scss" scoped></style>
