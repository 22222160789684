<template>
    <div class="user-balance gap-row-s" @click.stop>
        <div
            v-if="showSelectCurrency"
            class="user-balance__common"
        >
            <span class="user-balance__label">{{ $t(balanceTitle) }}</span>
            <slot name="selectWallet"/>
        </div>
        <div
            v-else
            class="user-balance__common"
        >
            <div>
                <div class="user-balance__title" :class="userBalanceThemeClass">
                    {{ $t(balanceTitle) }}
                    <Tooltip
                        v-if="bonusBalance"
                        v-click-outside="hideTooltip"
                        bottom
                        :show-on-hover="true"
                        :show="showUserBalanceOnClick && isShowTooltip"
                        :width="225"
                        class="user-balance__tooltip"
                    >
                        <template #activator>
                            <FeIcon
                                class="user-balance__tooltip-info"
                                :icon="FeIconConfig.icons.fill.info1"
                                @click="toggleTooltip"
                            />
                        </template>
                        <div v-html="tooltipText"/>
                    </Tooltip>
                </div>
                <div class="user-balance__value">
                    {{ commonBalance }}
                </div>
            </div>
            <slot/>
        </div>
        <div v-if="bonusBalance" class="user-balance__detailed">
            <div class="user-balance__detailed-real">
                <div class="user-balance__label">
                    {{ $t("PROFILE.BALANCE_REAL_TITLE") }}
                </div>
                <div class="user-balance__value">
                    {{ realBalance }}
                </div>
            </div>
            <div class="user-balance__detailed-bonus">
                <div class="user-balance__label">
                    {{ $t("PROFILE.BALANCE_BONUS_TITLE") }}
                </div>
                <div class="user-balance__value">
                    {{ bonusBalanceNormalize }}
                </div>
            </div>
        </div>
        <ProgressBar
            v-if="showProgress && bonusBalance"
            :progress="percentageWagering"
            class="user-balance__progressbar"
        >
            <template #top>
                <div class="user-balance__progressbar-description">
                    <div class="user-balance__progressbar-text">
                        {{ $t("PROFILE.BALANCE_WAGERING") }}
                    </div>
                    <div class="user-balance__progressbar-wagering">
                        {{ wageredNormalise }}
                        {{ $t("PROFILE.BALANCE_OF") }}
                        {{ totalWageringNormalise }}
                    </div>
                </div>
            </template>
        </ProgressBar>
        <slot name="bottom-info"/>
    </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import Tooltip from "@components/Tooltip/Tooltip.vue";
import ProgressBar from "@components/ProgressBar/ProgressBar.vue";
import { useUserBalance } from "@store/user/userBalance";
import { currencyView } from "@helpers/currencyHelper";
import { useGiftsStore } from "@store/gifts";
import { UsePing } from "@helpers/usePinger";
import { useCommon } from "@store/common";
import { useUserInfo } from "@store/user/userInfo";

export default {
    name: "UserBalance",
    components: {
        FeIcon,
        Tooltip,
        ProgressBar,
    },
    props: {
        showProgress: {
            type: Boolean,
            default: false,
        },
        userBalanceData: {
            type: Object,
            default: () => {
            },
        },
        showSelectCurrency: {
            type: Boolean,
            default: false,
        },
        isDark: {
            type: Boolean,
            default: false,
        },
        showUserBalanceOnClick: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            FeIconConfig,
            isShowTooltip: false,
        };
    },

    computed: {
        ...mapState(useUserInfo, {
            userCurrency: "getUserCurrency",
        }),
        ...mapState(useUserBalance, {
            realBalance: "getUserRealBalanceNormalise",
            bonusBalance: "getUserBonusBalance",
            bonusBalanceNormalize: "getUserBonusBalanceNormalize",
            commonBalance: "getUserCommonBalanceNormalize",
            totalWagering: "getUserTotalWagering",
            wagered: "getUserWager",
        }),

        balanceTitle() {
            return this.bonusBalance ? "PROFILE.BALANCE_COMMON_TITLE" : "PROFILE.BALANCE_TITLE";
        },
        percentageWagering() {
            const PERCENTS = 100;
            return Math.floor((this.wagered / this.totalWagering) * PERCENTS);
        },
        tooltipText() {
            return this.$t("PROFILE.BALANCE_TOOLTIP_TEXT", { link: "/bonus-balance" });
        },
        userBalanceThemeClass() {
            return this.isDark ? "theme-dark" : "theme-light";
        },
        totalWageringNormalise() {
            return currencyView(
                this.totalWagering,
                this.userCurrency,
                null,
                this.subUnits(this.userCurrency),
            );
        },
        wageredNormalise() {
            return currencyView(
                this.wagered,
                this.userCurrency,
                null,
                this.subUnits(this.userCurrency),
            );
        },
        pingBonusBalance() {
            return new UsePing(this.loadGiftsData, 10000);
        },
    },

    mounted() {
        this.pingBonusBalance.runPing();
    },

    beforeUnmount() {
        this.pingBonusBalance.clearPing();
    },

    methods: {
        ...mapActions(useGiftsStore, [
            "loadGiftsData",
            "loadFSGiftsData",
        ]),
        ...mapActions(useCommon, {
            subUnits: "getSubunitsToUnitsByCode",
        }),
        toggleTooltip() {
            this.isShowTooltip = !this.isShowTooltip;
        },
        hideTooltip() {
            this.isShowTooltip = false;
        },
    },
};
</script>
<style scoped lang="scss" src="./style.scss"/>
