let bus: IBus | undefined;

export default {
    init($bus: IBus) {
        bus = $bus;
    },

    show(options: object, id?: string) {
        bus?.$emit("preloader.show", options, id);
    },

    close(options: string, id?: string) {
        bus?.$emit("preloader.close", options, id);
    },
};
