<template>
    <BookmarksItem
        v-click-outside="closeBookmark"
        :image="iconBookmark"
        :class="classes"
        class="quest-bookmark"
        @bookmark-click="openBookmark"
    >
        <div class="quest-bookmark__info">
            <div class="quest-bookmark__info-data">
                <p
                    class="quest-bookmark__title h3"
                    @click="moveToQuest"
                >
                    {{ questData.title }}
                </p>
                <QuestProgressBar
                    v-if="pointForNextLevel"
                    :current-position="pointLevel"
                    :total-award-places="totalLevelsQuest"
                    :user-points="currentUserBets"
                    :point-for-next-level="pointForNextLevel"
                    :show-title="false"
                />
            </div>
            <div class="quest-bookmark__icon-wrap">
                <FeIcon
                    :icon="icons.fill.arrowNext"
                    class="quest-bookmark__open-icon"
                    @click.stop="closeBookmark"
                />
            </div>
        </div>
    </BookmarksItem>
</template>

<script>
import { mapState } from "pinia";
import BookmarksItem from "@modules/BookmarksList/item.vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import QuestProgressBar from "@modules/Quest/components/QuestProgressBar.vue";

import ufoSmall from "@modules/Quest/images/ufo-small.svg";
import robotMock from "@modules/Quest/images/robot-mock.svg";

import { routeNames } from "@router/routeNames";
import { questSlugById } from "@helpers/questHelpers";
import getQuestConfig from "@modules/Quest/constants";
import { useQuestStore } from "@store/quest/questStore";
import { useEnvironments } from "@store/environments";

export default {
    name: "QuestBookmark",

    components: {
        BookmarksItem,
        FeIcon,
        QuestProgressBar,
    },

    props: {
        questData: {
            type: Object,
        },
    },

    data() {
        return {
            icons,
            open: false,
        };
    },

    computed: {
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),
        ...mapState(useQuestStore, {
            listQuestLevelPoints: "getListQuestsLevelPoint",
            listNextLevelPoints: "getNextLevelPointByIdQuest",
            userBetsInQuestById: "getUserBetsInQuestById",
            pointsInQuestById: "getPointsInQuestById",
        }),

        currentLevelPoint() {
            return this.listQuestLevelPoints[this.questData.id];
        },

        pointLevel() {
            return Number(this.currentLevelPoint[0]);
        },

        pointForNextLevel() {
            return this.nextLevelPoint[1]?.bets[this.questData?.currency];
        },

        nextLevelPoint() {
            return this.listNextLevelPoints(this.questData.id);
        },

        currentUserBets() {
            return this.userBetsInQuestById(this.questData.id);
        },

        totalLevelsQuest() {
            return this.pointsInQuestById(this.questData.id).length;
        },

        classes() {
            return {
                "quest-bookmark--open": this.open,
                "quest-bookmark--logout": !this.isLogged,
                [`quest-bookmark--${ this.questData.questSize }`]: this.questData.questSize,
            };
        },

        iconBookmark() {
            if (this.environment.useMocker) {
                return robotMock;
            }

            if (this.guestConfig.imageConfig.bookmarkIcon) {
                return this.guestConfig.imageConfig.bookmarkIcon;
            }

            return ufoSmall;
        },

        guestConfig() {
            return getQuestConfig(this.questData.questSize);
        },
    },

    methods: {
        closeBookmark() {
            this.open = false;
        },
        openBookmark() {
            if (!this.open) {
                setTimeout(() => {
                    this.open = true;
                }, 0);
            } else {
                this.moveToQuest();
            }
        },
        moveToQuest() {
            if (this.open) {
                this.$router.push({
                    name: routeNames.questPage,
                    params: {
                        slug: questSlugById(this.questData.frontend_identifier),
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

$info-wrap--width--m: 15rem;
$info-wrap--width--l: 22rem;


.quest-bookmark {
    @include blur-bg;
    white-space: nowrap;
    transition: all .5s;
    right: 0;
    position: relative;

    &--m {
        background: var(--color-secondary-2);
    }

    &--s {
        background: var(--color-secondary-gradient);
    }

    &__info {
        align-items: center;
        display: flex;
        transition: all .2s;
        justify-content: space-between;
        margin-right: 0;
        opacity: 1;
        width: $info-wrap--width--m;
        padding-left: 1rem;

        @include media(XM) {
            padding-left: 1.5rem;
            width: $info-wrap--width--l;
        }

        &-data {
            flex-grow: 1;
            overflow: hidden;
        }
    }

    $parent: &;

    &--open {
        right: #{$info-wrap--width--m};

        @include media(XM) {
            right: #{$info-wrap--width--l};
        }
    }

    &__progress {
        width: 12rem;
        @include media(XM) {
            width: 15rem;
        }
    }

    &__title {
        @include font-size--Body($font-weight--bold);
        color: var(--color-text-menu-active);
        cursor: pointer;

        @include media(XM) {
            margin-bottom: .5rem;
        }
    }


    &__open-icon {
        cursor: pointer;
        width: 1.5rem;

        @include media(XM) {
            width: 2rem;
        }
    }

    &__icon-wrap {
        padding: .5rem;
        margin-left: 1rem;
    }

    :deep(.quest-progress__data) {
        margin-bottom: 0;

        @include media(M) {
            margin-bottom: .5rem;
        }
    }
}

</style>
