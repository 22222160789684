import topGame from "@theme/icons/badges/icon-top.svg";
import newGame from "@theme/icons/badges/icon-new.svg";
import jackpotGame from "@theme/icons/badges/icon-jackpot.svg";
import wagerGame from "@theme/icons/badges/icon-wager.svg";
import jackpotCustom from "@theme/icons/badges/icon-jackpot-custom.svg";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";

export const ICON_BADGES_BY_TYPE = {
    [SlugCategoriesGames.SLUG_CATEGORY_NEW]: newGame,
    [SlugCategoriesGames.SLUG_CATEGORY_TOP]: topGame,
    [SlugCategoriesGames.SLUG_CATEGORY_JACKPOT]: jackpotGame,
    [SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING]: wagerGame,
    [SlugCategoriesGames.SLUG_CATEGORY_MYSTIC_JACKPOTS]: jackpotCustom,
};
