const STATUS = {
    RECEIVED: "received",
    ACTIVATED: "activated",
    EXPIRED: "expired",
    USED: "used",
    ERASED_BY_WITHDRAW: "erased_by_withdraw",
};

const TYPES = {
    Cashback: "Cashback",
    Bonus: "Deposit bonus",
    Cash: "Cash",
    Spin: "Giftspin",
    Group: "Group",
    BooongoFreebet: "Booongo Freebet",
    Custom: "Custom",
    BooongoFeature: "Booongo Feature Freespins",
    PlaysonFeature: "Playson Feature Trigger",
    XP: "XP",
    SoftGaming: "SoftGaming Gift",
    FreeSpins: "NonDeposit",
};

export const TYPE_GIFT_BONUS = "bonus";
export const TYPE_GIFT_DEPOSIT = "deposit";
export const TYPE_GIFT_REGISTRATION = "registration";
export const TYPE_GIFT_FS = "fs";


export const STATUSES_LOST_GIFT_CANCELED = "canceled";
export const STATUSES_LOST_GIFT = [ "finished", STATUSES_LOST_GIFT_CANCELED, "expired", "wager_done", "lost" ];


export {
    STATUS,
    TYPES,
};
