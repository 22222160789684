<template>
    <div class="time-wrapper">

        <SessionTime/>
        <div class="time">
            {{ $t("CALENDAR.DATE_DISPLAY", { date }) }}
        </div>
    </div>
</template>

<script setup>
import { timeUTC } from "@helpers/formatDate";
import { useI18n } from "vue-i18n";
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import SessionTime from "@components/SessionTime/SessionTime.vue";
import { useEnvironments } from "@store/environments";

const { t } = useI18n();

const { useMocker } = useEnvironments();
const date = ref(timeUTC(undefined, t));
const timerId = ref(null);

function updateDate() {
    timerId.value = setTimeout(() => {
        date.value = timeUTC(undefined, t);
        updateDate();
    }, 15000);
}

onBeforeMount(() => {
    if (useMocker) {
        date.value = timeUTC(new Date("2000"), t);
    }
});

onMounted(() => {
    if (!useMocker) {
        updateDate();
    }
});

onBeforeUnmount(() => {
    clearTimeout(timerId.value);
});


</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.time-wrapper {
    color: var(--color-text-alt);
    background: var(--color-tertiary-6);
    backdrop-filter: blur(10px);
    width: max-content;
    padding: .5rem 1rem;
    border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
    display: flex;
    flex-direction: column;
}

.local-time, .session-time {
    @include font-size--Caption2($font-weight--normal);

}
</style>
