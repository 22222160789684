export const mockLevels = {
    1: {
        position: {
            left: 129,
            top: 201,
        },
        bets: {
            EUR: 0,
        },
    },
    2: {
        position: {
            left: 254,
            top: 306,
        },
        bets: {
            EUR: 350,
        },
    },
    3: {
        position: {
            left: 99,
            top: 492,
        },
        bets: {
            EUR: 1000,
        },
    },
    4: {
        position: {
            left: 237,
            top: 669,
        },
        bets: {
            EUR: 2000,
        },
    },
    5: {
        position: {
            left: 237,
            top: 869,
        },
        bets: {
            EUR: 5000,
        },
    },
    6: {
        position: {
            left: 145,
            top: 922,
        },
        bets: {
            EUR: 10000,
        },
    },
    7: {
        position: {
            left: 145,
            top: 1029,
        },
        bets: {
            EUR: 15000,
        },
    },
    8: {
        position: {
            left: 237,
            top: 1082,
        },
        bets: {
            EUR: 25000,
        },
    },
    9: {
        position: {
            left: 145,
            top: 1135,
        },
        bets: {
            EUR: 35000,
        },
    },
    10: {
        position: {
            left: 53,
            top: 1189,
        },
        bets: {
            EUR: 70000,
        },
    },
    11: {
        position: {
            left: 145,
            top: 1241,
        },
        bets: {
            EUR: 120000,
        },
    },
    12: {
        position: {
            left: 145,
            top: 1343,
        },
        bets: {
            EUR: 170000,
        },
    },
    13: {
        position: {
            left: 38,
            top: 1405,
        },
        bets: {
            EUR: 220000,
        },
    },
    14: {
        position: {
            left: 191,
            top: 1566,
        },
        bets: {
            EUR: 300000,
        },
    },
    15: {
        position: {
            left: 99,
            top: 1618,
        },
        bets: {
            EUR: 400000,
        },
    },
    16: {
        position: {
            left: 22,
            top: 1840,
        },
        bets: {
            EUR: 500000,
        },
    },
    17: {
        position: {
            left: 177,
            top: 1840,
        },
        bets: {
            EUR: 600000,
        },

    },
    18: {
        position: {
            left: 177,
            top: 1929,
        },
        bets: {
            EUR: 800000,
        },
    },
    19: {
        position: {
            left: 177,
            top: 2018,
        },
        bets: {
            EUR: 1000000,
        },
    },
    20: {
        position: {
            left: 7,
            top: 2221,
        },
        bets: {
            EUR: 1200000,
        },
    },
    21: {
        position: {
            left: 147,
            top: 2372,
        },
        bets: {
            EUR: 1400000,
        },
    },
    22: {
        position: {
            left: 147,
            top: 2461,
        },
        bets: {
            EUR: 1600000,
        },
    },
    23: {
        position: {
            left: 38,
            top: 2523,
        },
        bets: {
            EUR: 1900000,
        },
    },
    24: {
        position: {
            left: 191,
            top: 2683,
        },
        bets: {
            EUR: 2200000,
        },
    },
    25: {
        position: {
            left: 38,
            top: 2840,
        },
        bets: {
            EUR: 2500000,
        },
    },
};
