import { useMultilang } from "@store/multilang";
import { useSettings } from "@store/settings";
import fetchAdapter from "@vespaiach/axios-fetch-adapter";
import axios from "axios";
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

import authService from "@controllers/authController";
import log from "@controllers/Logger";
import { isServer } from "@helpers/ssrHelpers";
import { routeNames } from "@router/routeNames";
import { COOKIE_BY_LOCALE } from "@theme/configs/constsLocales";
import { storeToRefs } from "pinia";

const SERVER_TIMEOUT = 8000;
const CLIENT_TIMEOUT = 30000;
const RETRY_COUNT = 6;
const URL_PART_BEFORE_QUERY = 0;

const RETRY_CONDITION = {
    "Network Error": true,
    [`timeout of ${CLIENT_TIMEOUT}ms exceeded`]: true,
    "timeout of 1ms exceeded": true,
};

const timeout = isServer ? SERVER_TIMEOUT : CLIENT_TIMEOUT;

interface IHttpParams {
    apiUrl?: string;
    headers?: Record<string, string>;
}

export function http({ apiUrl, headers }: IHttpParams = {}): AxiosInstance {
    const settings = useSettings();
    if (!settings) {
        throw Error("No store");
    }

    const params: AxiosRequestConfig = {
        baseURL: apiUrl || settings.apiUrl,
        timeout,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            ...(headers || {}),
        },
        adapter: fetchAdapter,

    };

    if (isServer) {
        const { getUserLocale } = storeToRefs(useMultilang());
        params.headers = {
            ...params.headers,
            Cookie: `locale=${ COOKIE_BY_LOCALE[getUserLocale.value] };`,
        };
    }

    const client: AxiosInstance = axios.create(params);
    client.defaults.transitional = {
        silentJSONParsing: true,
        forcedJSONParsing: true,
        clarifyTimeoutError: false,
    };
    client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response?.status === 401) {
            authService.resetAuthData(routeNames.login);
        }

        if (error?.config?.url) {
            let apiLabel = error.config.url.replace("/api/", "").replace(/\//g, "_").toUpperCase();
            if (apiLabel.indexOf("?") > -1) {
                apiLabel = apiLabel.split("?")[URL_PART_BEFORE_QUERY];
            }
            log.error(`LOAD_${apiLabel}_ERROR`, error);
        }

        return Promise.reject(error);
    });

    if (!isServer) {
        axiosRetry(client, {
            retries: RETRY_COUNT,
            retryCondition: (error) => Object.hasOwnProperty.call(RETRY_CONDITION, error.message),
            retryDelay: axiosRetry.exponentialDelay,
            shouldResetTimeout: true,
        });
    }

    return client;
}
