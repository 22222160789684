<template>
    <LanguageSelect
        v-model="language"
        data-test="language-select-switch"
        @change="setLocale"/>
</template>

<script>
import LanguageSelect from "@components/FormFieldsVee/LanguageSelect.vue";
import { mapActions, mapState } from "pinia";
import { useMultilang } from "@store/multilang";
import { loadLanguageAsync } from "@i18n/loadLanguageAsync";

export default {
    name: "LanguageForm",

    components: {
        LanguageSelect,
    },

    data() {
        return {
            language: "",
        };
    },

    computed: {
        ...mapState(useMultilang, {
            userLocale: "getUserLocale",
        }),
    },

    mounted() {
        this.language = this.userLocale;
    },

    methods: {
        ...mapActions(useMultilang, {
            updateLocale: "updateLocale",
        }),

        setLocale(newLocale) {
            loadLanguageAsync({ lang: newLocale, route: this.$route }, this.updateLocale);
        },
    },
};
</script>

