<template>
    <div
        class="promo-banner padding-xs"
        :class="[ classPromoBanner, size ]"
        v-bind="$attrs"
    >
        <div v-if="showTimer" class="promo-banner__timer">
            <PromoTimer class="promo-banner-timer"/>
        </div>

        <div class="promo-banner__container gap-column-xs">
            <FeImage
                :src="imageForBanner.src"
                :src-retina="imageForBanner.srcRetina"
                class="promo-banner__icon"
            />
            <div>
                <div class="promo-banner__desc">
                    <slot name="description">
                        {{ $t("PROMO_BANNER.TEXT") }}
                    </slot>
                </div>
                <div class="promo-banner__title">
                    <slot name="title">
                        {{ $t("PROMO_BANNER.TITLE") }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PromoBannerTheme } from "@components/PromoBanner/config";
import FeImage from "@ui/FeImage/FeImage.vue";
import bonusNewPlayer from "@theme/images/BrandImages/bonus-new-player@2x.png";
import bonusNewPlayerX2 from "@theme/images/BrandImages/bonus-new-player.png";
import PromoTimer from "@components/PromoTimer/PromoTimer.vue";
import { computed } from "vue";

interface IImageConfig {
    src: string,
    srcRetina: string
}

interface IProps {
    side?: boolean;
    showTimer?: boolean;
    size?: string;
    theme?: string;
    image?: IImageConfig;
}


const props = withDefaults(defineProps<IProps>(), {
    theme: PromoBannerTheme.Outline,
});

const classPromoBanner = computed(() => {
    return {
        "promo-banner--side": props.side,
        ...(props.theme ? { [props.theme]: props.theme } : {}),
    };
});

const imageForBanner = computed<IImageConfig>(() => {
    if (props.image) {
        return props.image;
    }
    return {
        src: bonusNewPlayer,
        srcRetina: bonusNewPlayerX2,
    };
});

</script>

<style lang="scss" src="./style.scss"></style>
