export const mockLevels = {
    1: {
        position: {
            left: 132,
            top: 151,
        },
        bets: {
            EUR: 0,
        },
    },
    2: {
        position: {
            left: 50,
            top: 218,
        },
        bets: {
            EUR: 150,
        },
    },
    3: {
        position: {
            left: 83,
            top: 311,
        },
        bets: {
            EUR: 500,
        },
    },
    4: {
        position: {
            left: 198,
            top: 308,
        },
        bets: {
            EUR: 1000,
        },
    },
    5: {
        position: {
            left: 218,
            top: 502,
        },
        bets: {
            EUR: 5000,
        },
    },
    6: {
        position: {
            left: 154,
            top: 674,
        },
        bets: {
            EUR: 15000,
        },
    },
};
