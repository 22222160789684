import { Buffer } from "buffer";
import cookieController from "@front/core/controllers/CookieController.js";

const KEY_LOCALE = "locale";

export function getLocale() {
    const localeInCookie = cookieController.get(KEY_LOCALE)?.split("==")[0];
    if (localeInCookie) {
        return Buffer.from(localeInCookie, "base64").toString("ascii").replace(/\"/g, "");
    }
    return;
}
