<template>
    <button
        :disabled="disabled"
        class="additional-button"
        :class="classes"
    >
        <FeIcon
            v-if="icon"
            :icon="icon"
            class="additional-button__icon"
        />
        <span
            class="additional-button__title"
            v-html="title"
        />
    </button>
</template>

<script setup lang="ts">
import FeIcon from "@ui/FeIcon/FeIcon.vue";
import { computed } from "vue";

interface IProps {
    icon?: string;
    disabled?: boolean;
    title?: string;
    fullWidth?: boolean;
    fullWidthMobile?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
    fullWidth: false,
    fullWidthMobile: false,
});

const classes = computed(() => ({
    "additional-button--full-width": props.fullWidth,
    "additional-button--full-width-mobile": props.fullWidthMobile,
}));

</script>

<style scoped lang="scss">
@import "~@theme/styles";

.additional-button {
    padding: .5rem;
    border-radius: var(--border-radius-s);
    border: 2px solid var(--color-tertiary-3);
    background: transparent;
    color: var(--color-text-alt);
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;


    &:hover {
        background: var(--color-tertiary-2);
        border-color: transparent;
    }

    &:active {
        background: var(--color-tertiary-4);
        border-color: transparent;
    }

    @include btn-disabled;

    &__icon {
        margin-bottom: .25rem;
    }

    &__title {
        @include font-size--SmallCaption;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    &--full-width {
        width: 100%;
        justify-content: center;
    }

    &--full-width-mobile {
        width: 100%;
        justify-content: center;

        @include media(M) {
            width: auto;
        }
    }

}
</style>
