import { http } from "@api/http";
import log from "@controllers/Logger";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type { IJackpotWinner } from "./types/winner";

export const useJackpotWinners = defineStore("winnersJackpot",
    () => {
        const winnersData = ref<IJackpotWinner[]>([]);
        const biggestWinnersData = ref<IJackpotWinner[]>([]);
        const latestWinnersData = ref<IJackpotWinner[]>([]);

        async function getJackpotWinners() {
            try {
                // @ts-ignore
                const { data } = await http().get("/api/jackpot_wins");
                winnersData.value = data;
            } catch (err) {
                log.error("GET_JACKPOT_WINNERS_ERROR", err);
            }
        }

        const sortBiggestWinnersList = computed(() => {
            biggestWinnersData.value = winnersData.value.slice();
            return biggestWinnersData.value.sort((a, b) => b.amount_cents - a.amount_cents);
        });

        const sortLatestWinnersList = computed(() => {
            return  winnersData.value;
        });

        return {
            getJackpotWinners,
            sortBiggestWinnersList,
            sortLatestWinnersList
        };
    });
