/* eslint camelcase: ["error", {allow: ["level_name"]}] */
import {
    createRouter,
    NavigationFailureType,
    isNavigationFailure,
    createWebHistory,
    createMemoryHistory,
} from "vue-router";
import baseRouter from "@router/defaultRouter";
import routerDiff from "@theme/configs/router";
import service from "@router/RouterService";
import { getStaticRoutes } from "@controllers/RouterController";
import { wait } from "@front/core/helpers/functionsHelper";
import log from "@controllers/Logger";
import { isServer } from "@helpers/ssrHelpers";
import { removeTrailingSlashPlugin } from "@router/plugins";

async function tryScrollToAnchor(hash, timeout = 5000, delay = 200) {
    try {
        const el = document.querySelector(hash);
        if (timeout < 0) {
            return;
        }

        if (el) {
            await wait(delay);
            el.scrollIntoView({ behavior: "smooth" });
            return;
        }
        await wait(delay);
        await tryScrollToAnchor(hash, timeout - delay);
    } catch (err) {
        log.error("TRY_SCROLL_ERROR", err);
    }
}

async function scrollBehavior(to: { hash: string; }, from: any, savedPosition: any) {
    if (to.hash) {
        await tryScrollToAnchor(to.hash);
        return;
    }
    if (savedPosition) {
        return savedPosition;
    }
    return { left: 0, top: 0 };
}

const router: Record<"instance", Router | null> = { instance: null };

export default router;

export function createAppRouter(routerData?: Record<string, any>, baseUrl?: string) {
    let mergedRoutes = service.mergeRoutes(baseRouter, routerDiff);
    const staticRoutes = getStaticRoutes(routerData);
    const hasSupportPage = staticRoutes[0].children.some((item) => {
        return item.name === "support";
    });
    if (hasSupportPage) {
        staticRoutes[0].children = staticRoutes[0].children.filter((item) => {
            return item.name !== "support";
        });
    } else {
        const indexRootInRoutes = mergedRoutes.findIndex((item) => {
            return item.path === "/";
        });
        mergedRoutes[indexRootInRoutes].children = mergedRoutes[indexRootInRoutes].children.filter((item) => {
            return item.path !== "support";
        });
    }
    mergedRoutes = service.mergeRoutes(mergedRoutes, staticRoutes);

    const historyUrl = routerData?.guest ? "/guest/" : baseUrl ?? "/";
    const history = isServer ? createMemoryHistory(historyUrl) : createWebHistory(historyUrl);

    router.instance = createRouter({
        history,
        scrollBehavior,
        routes: mergedRoutes,
    });

    router.instance.onError((err) => {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify({
            label: "VUE_ROUTER",
            message: err.message,
            level_name: "ERROR",
        }));
        // eslint-disable-next-line no-console
        console.error(JSON.stringify({
            label: "VUE_ROUTER",
            message: err.stack,
            level_name: "ERROR",
        }));
    });

    const originalPush = router.instance.push;
    router.instance.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalPush.call(this, location, onResolve, onReject);
        }

        return originalPush.call(this, location).catch((err) => {
            if (isNavigationFailure(err, NavigationFailureType.duplicated) ||
                isNavigationFailure(err, NavigationFailureType.aborted)) {
                return;
            }
            log.error("ROUTER_PUSH_ERROR", err);
        });
    };

    router.instance.beforeEach((to, from, next) => {
        if (to.path !== "/" && to.path.endsWith("/")) {
            const correctedPath = to.path.slice(0, -1);
            next({ ...to, path: correctedPath });
        } else {
            next();
        }
    });

    removeTrailingSlashPlugin(router.instance);

    return router.instance;
}
