export function parseAdventuresTitleDayConfig(title: string): {
    day: string;
    step: number;
    stepTotal: number;
} {
    const [ __, stepInfo, day ] = title.split("__");
    const [ step, stepTotal ] = stepInfo.split("/");
    return {
        day,
        step: Number(step),
        stepTotal: Number(stepTotal),
    };
}

export function parseGiftAdventureTitle(title): {
    step: number;
    giftTitle: string;
    day: string
} | string {
    if (title.includes("__")) {
        const [ __, step, day, giftTitle ] = title.split("__");
        return {
            step: Number(step),
            day,
            giftTitle,
        };

    }

    return title;
}
