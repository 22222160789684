<template>
    <div v-if="showTimer" class="promo-timer">
        <Timer
            class="promo-timer__timer"
            :end="endTime"
            :text="[ null, null, ' ', ' ' ]"
            dots
            @end="timerEndHandler"
        />
    </div>
</template>

<script>
import cookie from "@front/core/controllers/CookieController.js";

import Timer from "@components/Timer/Timer.vue";

import config from "@theme/configs/config";

export default {
    name: "PromoTimer",

    components: {
        Timer,
    },
    emits: [ "hideTimer", "showTimer" ],

    data() {
        return {
            showTimer: false,
            endTime: 0,
        };
    },

    mounted() {
        const cookieTime = cookie.get("promo_tooltip_timer");
        if (cookieTime) {
            this.endTime = new Date(cookieTime);
        } else {
            const endTime = this.getEndTimerTime();
            this.endTime = endTime;
            cookie.set("promo_tooltip_timer", endTime, { expires: 86400 });
        }
        if (this.endTime > new Date()) {
            this.showTimer = true;
            this.$emit("showTimer");
        }
    },

    methods: {
        timerEndHandler() {
            this.showTimer = false;
            this.$emit("hideTimer");
        },
        getEndTimerTime() {
            const date = new Date();
            date.setMinutes(date.getMinutes() + config.PROMO_TOOLTIP_TIMER_MINUTES);
            return date;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
