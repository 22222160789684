<template>
    <div
        class="menu-item"
        :class="{'active': active}"
        @click="clickHandler"
    >
        <div class="menu-item__content">
            <FeIcon
                v-if="icon"
                class="menu-item__icon"
                :icon="icon"
            />
            <span class="menu-item__text" v-html="title" />
            <span
                v-if="count"
                class="menu-item__pin"
            >
                {{ count }}
            </span>
            <FeNavBadge
                v-else-if="badge"
                class="menu-item__badge"
                :badge="badge"
            />
        </div>
    </div>
</template>

<script>
import FeIcon from "@ui/FeIcon/FeIcon.vue";
import FeNavBadge from "@ui/FeNavBadge/FeNavBadge.vue";
import iconArrow from "@theme/images/icon-arrow-down.svg";


export default {
    name: "SidebarMenuItem",

    components: {
        FeNavBadge,
        FeIcon,
    },
    props: {
        active: {
            type: Boolean,
        },
        linkto: {
            type: [
                Object,
                String,
            ],
        },
        externalUrl: {
            type: [
                Object,
                String,
            ],
        },
        icon: {
            type: String,
        },
        title: {
            type: String,
        },
        count: {
            type: Number,
        },
        hasChild: {
            type: Boolean,
        },
        showChild: {
            type: Boolean,
        },
        badge: {
            type: Object,
        },
        clickFn: {
            type: Function,
        },
    },
    emits: [ "click" ],

    data() {
        return {
            iconArrow,
        };
    },
    computed: {
        arrowStyles() {
            return {
                "menu-item__arrow": true,
                "menu-item__arrow--rotated": this.showChild,
            };
        },
    },

    methods: {
        clickHandler($event) {
            if (this.externalUrl) {
                window.location = this.externalUrl;
            }
            if (this.clickFn) {
                return this.clickFn({ to: this.linkto });
            }

            if (!this.active && this.linkto) {
                this.$router.push(this.linkto);
            } else {
                this.$emit("click", $event);
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "./style.scss";

.menu-item {
    @include font-size--Body($weight: $font-weight--bold);
    @include link("dark", true);
    color: var(--color-text-body);

    background: transparent;
    border: none;
    outline: none;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    width: 100%;
    padding: 1rem;
    position: relative;
    transition: color .2s ease;
    cursor: pointer;
    box-sizing: border-box;

    &.active {
        background: var(--color-layer-alt-4);
    }

    &__content {
        display: grid;
        grid-template-columns: auto 1fr auto;
    }

    &__text {
        position: relative;
    }

    .sidebar__submenu & {
        text-transform: none;
        font-weight: $sidebar__menu-item-btn-submenu--font-weight;
        font-size: $sidebar__menu-item-btn-submenu--font-size;
        padding: $sidebar__menu-item-btn-submenu--padding;
        transition: color .2s ease;
    }

    &__icon {
        margin-right: 1rem;
    }

    &__pin {
        @include pin;
        @include font-size--SmallCaption($weight: $font-weight--bold);
        background: var(--color-primary-1);
        height: 1rem;
        min-width: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__badge {
        align-self: center;
    }

    &__arrow {
        width: 24px;
        height: auto;
        transition: transform 0.2s ease-in-out;
        color: #fff;

        &--rotated {
            transform: rotate(180deg);
        }
    }
}
</style>
