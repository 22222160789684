<template>
    <CarouselScroll class="promo-slider">
        <BannerSlide
            v-for="(banner, index) in bannersByType"
            :key="banner.id"
            :ref="`slide--` + index"
            :banner="banner"
            :button-theme="featureFlags.enableNewUIComponents ? themes.PrimarySmall : FeButtonConfig.themes.PrimaryExtraSmall"
            :lazy="true"
            class="promo-slider__banner-slide"
            show-second-button
            @click-banner="(propItem) => $emit('clickBanner', propItem, index)"
        />
    </CarouselScroll>
</template>

<script>
import BannerSlide from "@components/BannerSlide/BannerSlide.vue";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import * as FeButtonConfig from "@ui/FeButton/FeButton.vue";

import { BANNERS_CATEGORIES } from "@config/banners";
import { mapState } from "pinia";
import { useBannerStore } from "@store/banners";
import { themes } from "@ui/New/ButtonConfig";
import featureFlags from "@theme/configs/featureFlags";

export default {
    name: "PromoSlider",

    components: {
        BannerSlide,
        CarouselScroll,
    },

    props: {
        categoryBanners: {
            type: String,

            validator(value) {
                return [ BANNERS_CATEGORIES.PROMO, BANNERS_CATEGORIES.TOURNAMENTS ].includes(value);
            },
            default: "slider-tournaments-banners",
        },
    },
    emits: [ "clickBanner", "viewBanner" ],

    data() {
        return {
            FeButtonConfig,
            bannerWasViewed: {},
        };
    },

    computed: {
        featureFlags() {
            return featureFlags;
        },
        themes() {
            return themes;
        },
        ...mapState(useBannerStore, {
            banners: "getBannersData",
        }),

        bannersByType() {
            return this.banners.filter((item) => {
                return item.categories.includes(this.categoryBanners);
            });
        },


        keysRefsForObserve() {
            return this.bannersByType.map((item, index) => {
                return { refKey: `slide--${ index }`, item };
            });
        },
    },

    mounted() {
        this.setObserve();
    },

    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },

    methods: {
        setObserve() {
            const options = {
                rootMargin: "0px",
                threshold: 1,
            };
            const callback = (entriesArray) => {
                entriesArray.forEach((entries) => {
                    if (entries.isIntersecting) {
                        const entriesKey = entries.target.dataset.keyItem;
                        const indexViewedBanner = this.keysRefsForObserve.findIndex(({ refKey }) => {
                            return refKey === entriesKey;
                        });
                        if (this.bannerWasViewed[entriesKey]) {
                            return;
                        }
                        this.$emit(
                            "viewBanner",
                            this.keysRefsForObserve[indexViewedBanner].item,
                            indexViewedBanner,
                        );

                        this.bannerWasViewed[entriesKey] = true;
                    }
                });
            };

            this.observer = new IntersectionObserver(callback, options);
            for (const { refKey } of this.keysRefsForObserve) {
                if (this.$refs[refKey] && this.$refs[refKey][0]) {
                    this.$refs[refKey][0].$el.dataset.keyItem = refKey;
                    this.observer.observe(this.$refs[refKey][0].$el);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.promo-slider {

    --carousel-item-width: 16rem;
    --carousel-gap: 1rem;

    @include media(S) {
        --carousel-item-width: 24rem;
    }
    @include media(M) {
        --carousel-item-width: 32rem;
        --carousel-gap: 1.5rem;
    }

    @include media(XM) {
        --carousel-item-width: 38rem;
    }

    &__banner-slide {
        height: 20rem;

        :deep(.banner-slide-description) {
            @include font-size--Header1;

            &.large {
                @include font-size--Header1;
            }
        }

    }
}
</style>
