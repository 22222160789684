<template>
    <div
        :class="classes"
        class="search-list gap-row-m"
        @click="closeHandler">
        <div class="search-list__input-wrap" @click.stop>
            <div class="search-list__input">
                <FeIcon
                    :icon="FeIconConfig.icons.outline.search"
                    class="search-list__input-search-icon"
                />
                <GameSearch
                    ref="input"
                    :placeholder="$t('SEARCH_POPUP.PLACEHOLDER')"
                    class="search-list__input-input"
                    @input="debounceSearchInputHandler"
                />
                <div v-if="searchInput.length > 0" class="search-list__input-close-icon">
                    <CloseButton :icon="FeIconConfig.icons.fill.closeInput" @click="handleClearSearch"/>
                </div>
            </div>
            <div class="search-list__input-close-popup" @click="closeHandler">
                <FeIcon :icon="FeIconConfig.icons.fill.close"/>
            </div>
        </div>

        <FeLoader v-if="loadingGames" class="search-list__pre-loader"/>
        <div
            v-else
            class="search-list__list gap-row-m"
            @click.stop>
            <div
                v-if="showEmptyListText"
                class="search-list__list-empty"
            >
                <div class="game-hall__no-results text--align-center">
                    <div class="game-hall__empty-title">
                        {{ $t("SEARCH_POPUP.LIST_EMPTY") }}
                    </div>
                    <div class="game-hall__empty-description">
                        {{ $t("SEARCH_POPUP.LIST_EMPTY_SPECIFY") }}
                    </div>
                </div>
            </div>
            <div class="gap-row-s">
                <p
                    v-show="foundGamesList.length === 0"
                    v-t="'SEARCH_POPUP.RECOMMENDED'"
                    class="search-list__list-title"
                />
                <GameItems
                    :games="gamesList"
                    class="search-list__list-body"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useSearchPopupMixin } from "@components/GamesListToolbarSearch/searchPopupMixin.js";
import FeLoader from "@ui/FeLoader/FeLoader.vue";
import FeIcon from "@ui/FeIcon/FeIcon.vue";
import GameSearch from "@modules/GameSearch/GameSearch.vue";
import CloseButton from "@modules/Auth/components/CloseButton/CloseButton.vue";
import GameItems from "@modules/GameHall/components/GameItems/GameItems.vue";
import { ref, onMounted } from "vue";
import { useGamesCategory } from "@store/games/gamesCategory";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";

const props = defineProps({
    closeHandler: {
        type: Function,
    },
});

const input = ref(null);

const {
    FeIconConfig,
    searchInput,
    gamesList,
    showEmptyListText,
    classes,
    handleClearSearch,
    loadingGames,
    debounceSearchInputHandler,
    foundGamesList,
} = useSearchPopupMixin(props.closeHandler, input);

const { loadGamesCategory } = useGamesCategory();

onMounted(() => {
    input.value.$el.focus();
    const fakeInput = document.getElementById("fakeInput");
    if (fakeInput) {
        fakeInput.remove();
    }
    loadGamesCategory(SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED);
});
</script>

<style lang="scss" src="./style.scss"></style>
