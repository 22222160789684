export const BANNERS_CATEGORIES = {
    PROMO: "slider-promo-banners",
    TOURNAMENTS: "slider-tournaments-banners",
    INDEX_TOP: "slider-index-top",
    INDEX_TOP_JACKPOTS: "jackpot-top-banners",
};

export const BANNERS_CATEGORIES_ENABLE = {
    [BANNERS_CATEGORIES.PROMO]: true,
    [BANNERS_CATEGORIES.TOURNAMENTS]: true,
    [BANNERS_CATEGORIES.INDEX_TOP]: true,
    [BANNERS_CATEGORIES.INDEX_TOP_JACKPOTS]: true,
};

export const BANNER_CATEGORY_CRYPTO_REG = "crypto-reg";
export const BANNER_CATEGORY_CRYPTO_REG_NO = "crypto-noreg";
export const BANNER_CATEGORY_FIAT_REG = "fiat-reg";
export const BANNER_CATEGORY_FIAT_REG_NO = "fiat-noreg";
export const BANNER_CATEGORY_JACKPOTS = "jackpot-top-banners";
export const BANNER_CATEGORY_131811_SHOW = "131811--show";
export const BANNER_CATEGORY_131811__HIDE = "131811--hide";
export const BANNER_CATEGORY_TERMS_CONDITIONS = "terms-and-conditions";
