<template>
    <CarouselScroll
        class="adv-cards__carousel"
        :active-index="activeIndex"
    >
        <AdventureCardItem
            v-for="item in vipAdventuresStore.vipAdventuresDays"
            :key="item.day"
            :card-data="item"
        />
        <AdventureCardItem
            v-if="vipAdventuresStore.superDay"
            key="superb"
            :card-data="vipAdventuresStore.superDay"
        />
    </carouselscroll>
</template>
<script setup lang="ts">
import { EventBus, BUS_EVENTS } from "@bus";
import { useVipAdventures } from "@store/user/vipAdventures";
import { onBeforeUnmount, onMounted, ref } from "vue";

import AdventureCardItem from "@modules/VipAdventures/AdventureCardItem.vue";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";

const vipAdventuresStore = useVipAdventures();

const activeIndex = ref<number>(0);

onMounted(() => {
    EventBus.$on(BUS_EVENTS.ADVENTURE_CALENDAR_SET_INDEX, (index) => {
        activeIndex.value = index;
    });
});

onBeforeUnmount(() => {
    EventBus.$off(BUS_EVENTS.ADVENTURE_CALENDAR_SET_INDEX);
});

</script>


<style scoped lang="scss">
@import "~@theme/styles";

.adv-cards {
    &__carousel {
        --carousel-item-width: 16rem;
        --carousel-gap: 1rem;

        @include media(L) {
            --carousel-item-width: 19rem;
            --carousel-gap: 1.5rem;
        }
    }


}
</style>
