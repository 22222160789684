import { IndexedDBNames, IndexedDBStoreNames } from "@controllers/indexedDB/consts";
import IndexedDBController from "@controllers/indexedDB/indexedDBCreator";
import { isServer } from "@helpers/ssrHelpers";
import type { INotification } from "@src/types/WSnotices";

export function useNotificationDB() {
    let notificationDB: IndexedDBController<INotification> | undefined;
    if (!isServer) {
        notificationDB = new IndexedDBController<INotification>(
            IndexedDBNames.notifications,
            IndexedDBStoreNames.notifyList,
        );
    }

    return {
        notificationDB,
    };
}
