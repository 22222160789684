import { routeNames } from "@router/routeNames.js";
import { mainPageJsonLD } from "./mainPage.js";
import { DOMAIN_FOR_AUSTRALIA, DOMAINS_FOR_AUSTRALIA } from "@theme/configs/constsLocales";

export const jsonLD = ({ hostname, routname }) => {
    let allowedHostnames = [
        DOMAINS_FOR_AUSTRALIA.r13,
        DOMAINS_FOR_AUSTRALIA.r14,
        DOMAINS_FOR_AUSTRALIA.r15,
        DOMAIN_FOR_AUSTRALIA,
        "local.rocketplay.com",
        "staging.rocketplay.wlabel.site",
    ];
    let textContent = null;

    if (allowedHostnames.includes(hostname)) {
        // Main page
        if (routname === routeNames.main) {
            textContent = mainPageJsonLD({ hostname });
        }
    }

    if (textContent) {
        return {
            hid: "json-ld",
            type: "application/ld+json",
            textContent,
        };
    }
};
