<template>
    <div class="vip-status-goals--wrapper gap-xs">
        <div class="vip-status-goals--deposit-header">
            {{ t("VIP_CLUB.PROGRESS.DEPOSIT_HEADER", { currency: currencyToDisplay }) }}
        </div>
        <div class="vip-status-goals--bets-header">
            {{ t("VIP_CLUB.PROGRESS.BETS_HEADER", { currency: currencyToDisplay }) }}
        </div>
        <div class="vip-status-goals--deposit-goal">
            <span class="dep-current">{{ goals.depCurrent }} /&nbsp;</span><span>{{ goals.depGoal }}</span>
        </div>
        <div class="vip-status-goals--bets-goal">
            <span class="bets-current">{{ goals.betsCurrent }} /&nbsp;</span><span>{{ goals.betsGoal }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { VIP_PROGRESS_CURRENCIES } from "@components/VipClub/constsNew";
import { useCommon } from "@store/common";
import { useVipAdventures } from "@store/user/vipAdventures";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { sanitizeNumber } from "@helpers/currencyHelper";

interface IProps {
    depositAmountCents: number,
    depositThresholdCents: number,
    betSumCents: number,
    betSumThresholdCents: number,
    currency: string
}

const props = defineProps<IProps>();

const { t } = useI18n();
const { userVipStatusProgress } = storeToRefs(useVipAdventures());

const { getSubunitsToUnitsByCode } = useCommon();

const currencyToDisplay = computed(() => VIP_PROGRESS_CURRENCIES[userVipStatusProgress.value?.currency]);
const formatSum = (value: number) => sanitizeNumber(value / getSubunitsToUnitsByCode(props.currency), " ");

const goals = computed(() => ({
    depCurrent: props.depositAmountCents > props.depositThresholdCents
        ? formatSum(props.depositThresholdCents)
        : formatSum(props.depositAmountCents),
    depGoal: formatSum(props.depositThresholdCents),
    betsCurrent: props.betSumCents > props.betSumThresholdCents
        ? formatSum(props.betSumThresholdCents)
        : formatSum(props.betSumCents),
    betsGoal: formatSum(props.betSumThresholdCents),
}));
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.vip-status-goals {
    &--wrapper {

        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid var(--color-tertiary-3);
        color: var(--color-text-body);

        .dep-current, .bets-current {
            color: var(--color-tertiary-1)
        }
    }

    &--deposit-goal, &--bets-goal {
        display: flex;
        flex-direction: column;

        @include media(L) {
            flex-direction: row;
        }
    }
}
</style>
