let bus: IBus | undefined;

export default {
    init($bus: IBus) {
        bus = $bus;
    },

    show(options: object) {
        bus?.$emit("toast.show", options);
    },

    close(options: string) {
        bus?.$emit("toast.close", options);
    },
};
