<template>
    <div class="status-block vip-club-status__card text--color--alt padding-s">
        <div class="vip-club-status__main-block gap-xs">
            <div class="gap-xs">
                <div class="vip-club-status card-block card-block--bg-custom">
                    <div class="vip-club-status__user-level">
                        <FeImage
                            v-if="imageStatusWithRegular.src"
                            :src="imageStatusWithRegular.src"
                            :src-retina="imageStatusWithRegular.srcRetina"
                            class="vip-club-status__user-img"
                        />

                        <div class="vip-club-status--info gap-xs">
                            <div class="vip-club-status--wrapper">
                                <div class="vip-club-status--status-details">
                                    <h3 v-text="nameStatus"/>
                                    <div
                                        v-if="isVip"
                                        v-t="'VIP_CLUB.COMPARES.CARD.MY_STATUS'"
                                        class="vip-club-status__my-status"
                                    />
                                </div>
                                <span
                                    v-if="isDiamond"
                                    v-t="'VIP_CLUB.PROGRESS.DIAMOND.LIFE_LONG'"
                                    class="font__caption-2--normal life-long"/>
                            </div>
                            <p v-if="!isDiamond && isVip" class="vip-club-status--info-text">
                                <span>{{ $t("GIFT.LABEL.EXPIRES") }}:</span> <strong>{{ textLastDayQuarter }}</strong>
                                <Tooltip :width="tooltipWidth" :top-left="isMobile">
                                    <template #activator>
                                        <FeIcon :icon="icons.fill.info1" class="comp-points__icon-info"/>
                                    </template>
                                    <span v-t="'VIP_CLUB.STATUS_CARD.TOOLTIP_TEXT'" class="tooltip-text"/>
                                </Tooltip>
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    v-if="!!vipStatusWithRegular&& !isDiamond"
                    class="vip-club-status__next-info gap-xs"
                >
                    <p><strong v-text="rulesTitle"/></p>
                    <span class="label__days-left">{{ $t("VIP_CLUB.PROMO_STATUS", { daysLeft }) }}</span>
                    <p>{{ descriptionText }}</p>
                </div>
                <div v-if="isDiamond" class="vip-club-status__diamond-info">
                    <p v-t="'VIP_CLUB.PROGRESS.DIAMOND.CAPTION'" class="font__body--bold diamond-info--caption"/>
                    <span v-t="'VIP_CLUB.PROGRESS.DIAMOND.DESCRIPTION'"/>
                </div>
            </div>
        </div>
        <div v-if="userExists" class="vip-club-status__vip-progress">
            <VipClubProgress v-if="!isDiamond"/>
            <VipClubItemTablePreference v-else :list-prefer="diamondBenefits"/>
        </div>
        <div class="text--align-center gap-row-xs vip-club-status__bottom-section">
            <hr>
            <a v-t="'VIP_CLUB.STATUS_CARD.LINK_TO_COMPARE'" class="color-primary" href="#vip-page-compare"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useUserStatuses } from "@store/user/userStatuses";
import { useVipAdventures } from "@store/user/vipAdventures";
import { computed } from "vue";
import Tooltip from "@components/Tooltip/Tooltip.vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon.vue";
import dayjs from "dayjs";
import VipClubProgress from "@components/VipClub/VipClubProgress.vue";
import {
    userVipClubStatus,
    imageStatusWithRegular,
    nextQuarterDays,
} from "@components/VipClub/vipClubHelpers";
import FeImage from "@ui/FeImage/FeImage.vue";
import { useI18n } from "vue-i18n";
import type { TranslateResult } from "vue-i18n";
import { useCommon } from "@store/common";
import { storeToRefs } from "pinia";
import { DIAMOND_STATUS, VIP_CLUB_IDS } from "@components/VipClub/consts";
import { useUserInfo } from "@store/user/userInfo";
import VipClubItemTablePreference from "@components/VipClub/VipClubItemTablePreference.vue";

const { t, te, tm } = useI18n();
const { isMobile } = storeToRefs(useCommon());
const daysLeft = computed(() => nextQuarterDays.value.lastDayCurrentQuarter.diff(dayjs(), "days"));
const { getUserInfo: userInfo } = storeToRefs(useUserInfo());
const { userVipStatusProgress } = storeToRefs(useVipAdventures());
const { isVip } = storeToRefs(useUserStatuses());

const diamondBenefits = tm("VIP_CLUB.COMPARES.DATA.VIP_USERS")[4].benefits;

const userExists = computed(() => Boolean(userInfo.value.id));

const tooltipWidth = computed<number>(() => isMobile.value ? 246 : 200);

const textLastDayQuarter = `${
    nextQuarterDays.value.lastDayCurrentQuarter.date()
} ${
    t(`CALENDAR.MONTH[${nextQuarterDays.value.lastDayCurrentQuarter.month()}]`)
} ${
    nextQuarterDays.value.lastDayCurrentQuarter.year()}`;

const vipStatusWithRegular = computed(() => userVipClubStatus.value || VIP_CLUB_IDS.REGULAR);

const nameStatus = computed<TranslateResult>(() => {
    return t(`VIP_CLUB.STATUS_CARD.NAMES.${vipStatusWithRegular.value}`);
});

const rulesTitle = computed(() => {
    const key = `VIP_CLUB.STATUS_CARD.CONDITION.${vipStatusWithRegular.value}.TITLE`;
    return te(key) && `${t(key)} ${textLastDayQuarter}`;
});

const descriptionText = computed(() => {
    const key = `VIP_CLUB.STATUS_CARD.CONDITION.${vipStatusWithRegular.value}.DESCRIPTION`;
    return t(key, { currency: userVipStatusProgress.value?.currency });
});

const isDiamond = computed(() => nameStatus.value?.toLowerCase() === DIAMOND_STATUS);

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.vip-club-status {
    grid-auto-flow: row;
    grid-template-rows: auto max-content;
    border: 1px solid var(--color-tertiary-2);

    margin: 0 auto;

    width: stretch;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media(M) {
        grid-auto-flow: column;
        grid-template-columns: auto max-content;
        grid-template-rows: unset;
    }

    &.card-block {
        margin: -1rem;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: left;
        padding: 0 1rem;

        @include media(M) {
            margin: 0;
            justify-content: center;
            padding: 0;
        }
    }

    &__user-level {
        padding: 1rem;
        display: grid;
        grid-gap: 1rem;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        position: relative;

        @include media(L) {
            height: 100%;
            grid-auto-flow: row;
            align-content: center;
            justify-items: center;
            text-align: center;
        }
    }

    &__user-img {
        width: 4rem;
        height: 4rem;

        @include media(M) {
            width: 6rem;
            height: 6rem;
        }
    }

    &__card {
        display: block;
        width: 100%;
        grid-gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            "top-left top-right"
            "bottom bottom";

        @include media(M) {
            display: grid;
            grid-gap: 2rem;
        }

        :deep(.promo-item-status) {
            background: var(--color-tertiary-4);
            @include font-size--Caption2($font-weight--normal);
        }
    }

    &__main-block {
        display: grid;
        grid-area: top-left;
        margin-bottom: 1rem;

        @include media(L) {
            margin-bottom: 0;
            grid-auto-flow: column;
        }
    }

    &__vip-progress {
        display: grid;
        grid-area: top-right;
    }

    &__bottom-section {
        grid-area: bottom;
    }

    &--info {
        grid-gap: .5rem;
        align-content: center;

        &-text {
            @include font-size--Caption2;
            font-weight: 400;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: .25rem;

            & span {
                color: var(--color-tertiary-1);
            }

            :deep(.tooltip) {
                position: relative;
            }
        }
    }

    &--wrapper {
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 0.5rem;
        flex-direction: column;

        @include media(M) {
            justify-content: center;
        }

        .life-long {
            color: var(--color-text-body);
        }
    }

    &--status-details {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__next-info {
        display: grid;
        grid-gap: 1rem;
        align-content: baseline;

        ul {
            padding-left: 1.75rem;
        }

        .label__days-left {
            @include font-size--Caption2($font-weight--normal);
            color: var(--color-text-body)
        }
    }

    &__diamond-info {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .diamond-info--caption {
            max-width: 20rem;
        }

    }

    &__my-status {
        border-radius: 0.5rem;
        background: var(--color-additional-gradient);
        display: flex;
        padding: 0 0.5rem;
        align-items: center;
        color: var(--color-text-on-opposite-back);
        @include font-size--SmallCaption($font-weight--bold);
        height: max-content;
    }

}
</style>
