<template>
    <div class="comp-points__count-wrap">
        <span class="text--upper">{{ $t("COMP_PINTS.MY_COINS") }}</span>
        <Tooltip :[positionTooltip]="positionTooltip" :width="200" class="comp-points__tooltip">
            <template #activator>
                <FeIcon :icon="FeIconConfig.icons.fill.info1" class="comp-points__icon-info" />
            </template>
            <span>{{ $t("COMP_PINTS.INFO_TEXT") }}</span>
        </Tooltip>
        <div class="comp-points-informer">
            <FeImage
                :src="iconCoin"
                :src-retina="iconCoinX2"
                class="comp-points-informer__icon"
            />
            {{ userComPointBalance || 0 }}
        </div>
    </div>
</template>

<script>
import FeImage from "@ui/FeImage/FeImage.vue";
import iconCoin from "@modules/CompPoints/images/icon-coin.png";
import iconCoinX2 from "@modules/CompPoints/images/icon-coin@2.png";
import Tooltip from "@components/Tooltip/Tooltip.vue";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import * as FeTooltipConfig from "@ui/FeTooltip/FeTooltip";
import { useCompPointsStore } from "@store/compPoints/compPointsStore";
import { mapState } from "pinia";

export default {
    name: "CompPointBalanceInformer",

    components: {
        FeImage,
        Tooltip,
        FeIcon,
    },

    props: {
        positionTooltip: {
            type: String,
            default: FeTooltipConfig.position.Bottom,
        },
    },

    data() {
        return {
            iconCoin,
            iconCoinX2,
            FeIconConfig,
        };
    },

    computed: {
        ...mapState(useCompPointsStore, {
            userComPointBalance: "getUserCompPoints",
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.comp-points {
    &-informer {
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight--bold;
        color: var(--color-text-alt);
        background-color: var(--color-tertiary-6);
        border-radius: $container--border-radius;

        &__icon {
            width: 1rem;
            height: 1rem;
            margin-right: .5rem;
        }
    }


    &__count-wrap {
        @include font-size--Caption1($font-weight--normal);
        color: var(--color-text-body);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-s);
        background: var(--color-tertiary-6);
        padding: .5rem 1rem;
    }

    &__tooltip {
        position: relative;
        margin: 0 1.5rem 0 .5rem;
        font-weight: $font-weight--normal;
        text-transform: initial;
    }

    &__icon-info {
        width: 1rem;
        color: var(--color-text-alt);
    }
}

</style>
