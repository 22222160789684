<template>
    <FeImage v-if="showImgCheckMock" class="mock-server-check-img" :src="mockServerImg" />
</template>

<script setup lang="ts">
import { useEnvironments } from "@store/environments";
import { nextTick, ref } from "vue";

import mockServerImg from "./img/mockServerImg.png";
import FeImage from "@ui/FeImage/FeImage.vue";


const showImgCheckMock = ref<boolean>(false);

const { useMocker } = useEnvironments();

nextTick(() => {
    if (useMocker) {
        showImgCheckMock.value = true;
    }
});
</script>

<style scoped lang="scss">
.mock-server-check-img {
    display: block;
    width: 10rem;
    margin: 0 auto;
}
</style>
