import type { ComponentOptionsMixin } from "vue";
import type { NavigationGuard } from "vue-router";

export const beforeMountMixin: ComponentOptionsMixin = {
    beforeMount() {
        const { serverPrefetch } = this.$options;
        if (serverPrefetch) {
            // присваиваем операцию загрузки к Promise
            // чтобы в компонентах мы могли делать так `this.dataPromise.then(...)`
            // для выполнения других задач после готовности данных
            this.$options.dataPromise = serverPrefetch.call(this);
        }
    }
};

export const beforeRouteUpdate: NavigationGuard = function(to, from, next) {
    this.$options.serverPrefetch
        .call({
            ...this,
            $route: to,
            $store: this.$store,
        })
        .finally(next);
};
