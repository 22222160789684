import log from "@controllers/Logger";
import { useContextStore } from "@store/context";

const oneSignalKeyByHost = {
    "rocketplay.com": "9a0c7b8e-86c3-4369-baf9-e64e0dc15abf",
    "rocketplay1.com": "042e65a4-75a6-4a6f-8669-7e7394d37c2b",
    "rocketplay2.com": "fd397f0b-00a2-4378-b105-7afbdecf68a5",
    "rocketplay3.com": "1786b14f-5a2e-47ab-9e77-63a502a9d435",
    "local.rocketplay1.com": "2119766a-10d7-4376-b759-d71df1a3bb66",
    "develop.rocketplay.com": "f643bbc9-891e-448f-81fe-f2eeadce2be6",
    "develop.rocketplay1.com": "2e76b242-dca2-4eab-bf12-53890fcadbeb",
};

function registerFetchWorker() {
    navigator.serviceWorker.register(`/sw.js?${ Math.random().toString().replace(".", "") }`)
        .then(() => {
            log.info("SW: Registration succeeded.");
        }).catch((error) => {
            log.error(`SW: Registration failed with ${ error }`);
        });
}

export function initSw() {
    const { isBotUA: isBot } = useContextStore();
    if (isBot) {
        return;
    }

    if (oneSignalKeyByHost[window.location.hostname]) {
        if (!window.OneSignal) {
            window.OneSignal = window.OneSignal || [];
            OneSignal.push(() => {
                OneSignal.init({
                    appId: oneSignalKeyByHost[window.location.hostname],
                });
            });

            const script = document.createElement("script");
            script.async = true;
            script.type = "text/javascript";
            script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";

            document.head.appendChild(script);
        }
    } else if ("serviceWorker" in navigator) {
        registerFetchWorker();
    }
}

