<template>
    <div v-show="showModal" class="modal">
        <transition-group :name="isMobile ? 'modal-mobile' : 'modal'" tag="div">
            <Modal
                v-for="modal in modals"
                :key="modal.name"
                :bottom-side="modal.bottomSide"
                :mobile-friendly="modal.mobileFriendly"
                :show-close="modal.showClose"
                :full-screen="modal.fullScreen"
                :full-screen-mobile="modal.fullScreenMobile"
                :has-overflow-visible="modal.hasOverflowVisible"
                @close="({immediate, clickOnOverlay, clickOnCloseButton}) => {
                    close(modal.name, immediate, clickOnOverlay, modal, clickOnCloseButton)}"
            >
                <template #default="{ closeHandler }">
                    <component
                        :is="modal.component"
                        :class="[ modal.component.name, modal.name ]"
                        :close-handler="closeHandler"
                        :need-confirm="needConfirm"
                        :show-confirm="showConfirm"
                        v-bind="modal.props"
                        @change-confirm-closing="setNeedConfirm"
                    />
                </template>
            </Modal>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from "pinia";

import Modal from "@modules/Modal/Modal.vue";
import { useUIStore } from "@store/ui";
import { useRootStore } from "@store/root";

export default {
    name: "ModalController",

    components: {
        Modal,
    },

    setup() {
        const { setShowModal: show, closeModal: closeFn } = useUIStore();

        return {
            show,
            closeFn,
        };
    },

    data() {
        return {
            needConfirm: false,
            showConfirm: false,
        };
    },

    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useUIStore, [
            "modals",
            "showModal",
        ]),
    },

    created() {
        if (!this.$isServer) {
            this.$bus.$on("modal.show", this.show);
        }
    },

    mounted() {
        this.$bus.$on("modal.close", this.close);
    },

    beforeUnmount() {
        this.$bus.$off("modal.show", this.show);
        this.$bus.$off("modal.close", this.close);
    },

    methods: {
        close(name, immediate = false, clickOnOverlay, modal, clickOnCloseButton) {
            // Block close modal if clicked on overlay
            if (clickOnOverlay && modal.blockCloseOverlay) {
                return;
            }
            if (this.needConfirm && !immediate) {
                this.showConfirm = true;
            } else {
                this.closeFn({
                    name,
                    immediate,
                    clickOnOverlay,
                    clickOnCloseButton,
                });
            }
        },
        setNeedConfirm({ need } = { need: false }) {
            this.needConfirm = need;

            if (need) {
                // Покидаем этап подтверждения
                this.showConfirm = false;
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
