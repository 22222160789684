import { EventBus as bus } from "@bus";
import i18n from "@i18n";

import toast from "@plugins/Toast";
import type { IUserInfo } from "@src/types/user";
import type { IConfigNotice } from "@src/types/WSnotices";
import { useNoticesStore } from "@store/notices";
import { useUserInfo } from "@store/user/userInfo";
import { v4 as uuid } from "uuid";
import { computed } from "vue";

export const useEmailConfirmation = () => {
    const { t } = i18n.init();

    const userInfo = computed<IUserInfo>(() => {
        const storeUserInfo = useUserInfo();
        return storeUserInfo.getUserInfo;
    });

    // tslint:disable-next-line:no-empty
    async function sendConfirmEmail(captcha, closeHandler = () => {
    }): Promise<any> {
        try {
            const { confirmEmailResend } = useUserInfo();
            const resp = await confirmEmailResend(captcha);
            toast.show({
                image: "message",
                text: t("MODAL_EMAIL_CONFIRM.EMAIL_WAS_SEND", { email: userInfo.value.email }),
                id: uuid(),
                url: null,
                type: "message",
            });
            return resp;
        } catch (error: any) {
            const { response } = error;
            if (response.status === 422) {
                const message = Object.values(response.data.errors.email)?.pop();
                if (!message) {
                    return;
                }
                toast.show({
                    image: "message",
                    text: message,
                    id: Date.now(),
                    url: null,
                    type: "message",
                });
            }
        } finally {
            closeHandler();
        }
    }

    const configNotice = computed<IConfigNotice>(() => {
        return {
            id: "notices-header__email-confirm",
            message: t("EMAIL_VERIFY.NOTICE.MESSAGE"),
            closeBtn: false,
            link: {
                text: t("EMAIL_VERIFY.NOTICE.LINK"),
                fn: sendConfirmEmail,
            },
        };
    });

    function checkUserConfirmEmail() {
        if (!userInfo.value.confirmed_at && userInfo.value.id) {
            const noticesStore = useNoticesStore();
            noticesStore.addHeaderNoticeConfig(configNotice.value);
        }
    }

    function hideHeaderNotice() {
        if (!userInfo.value.confirmed_at) {
            const noticesStore = useNoticesStore();
            noticesStore.deleteHeaderNotice(configNotice.value.id);
        }
    }

    function addUserBus() {
        bus.$on("user.login", checkUserConfirmEmail);
        bus.$on("user.logout", hideHeaderNotice);
    }

    function deleteUserBus() {
        bus.$off("user.login", checkUserConfirmEmail);
        bus.$off("user.logout", hideHeaderNotice);
    }

    return {
        sendConfirmEmail,
        addUserBus,
        deleteUserBus,
    };
};
