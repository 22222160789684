import * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";
import { routeNames } from "@router/routeNames.js";
import { useGamesSearch } from "@store/games/gamesSearch";
import { debounce } from "@helpers/DebounceHelper";
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";

const MIN_LENGTH_SEARCH_STRING = 2;

export function useSearchPopupMixin(closeHandler, input) {
    const searchInput = ref("");
    const foundGamesList = ref([]);
    const loadingGames = ref(false);
    const wasRequestToServer = ref(false);

    const { getGameCategoryNameBySlug } = useGamesCommon();
    const { getCollection: getCategoryGames } = useGamesCategory();

    const gamesToRow = (slug) => {
        return getCategoryGames(slug, 1);
    };

    const recommendGames = computed(() => {
        return gamesToRow(SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED);
    });

    const gamesList = computed(() => {
        return foundGamesList.value.length > 0 ? foundGamesList.value : recommendGames.value;
    });

    const hasMinCharactersInSearch = computed(() => {
        return searchInput.value.length > MIN_LENGTH_SEARCH_STRING;
    });

    const showEmptyListText = computed(() => {
        return hasMinCharactersInSearch.value &&
            wasRequestToServer.value &&
            foundGamesList.value.length === 0;
    });

    const classes = computed(() => {
        return {
            error: hasMinCharactersInSearch.value && gamesList.value.length === 0,
            open: hasMinCharactersInSearch.value && gamesList.value.length !== 0,
        };
    });

    const route = useRoute();

    watch(route, () => {
        closeHandler();
    });

    const { loadFoundGames } = useGamesSearch();

    const searchInputHandler = async({ target }) => {
        const { value } = target;
        searchInput.value = value;
        if (value.length > MIN_LENGTH_SEARCH_STRING) {
            loadingGames.value = true;
            wasRequestToServer.value = true;
            foundGamesList.value = await loadFoundGames(value);
            loadingGames.value = false;
        }
    };

    const debounceSearchInputHandler = ref(debounce(searchInputHandler, 300));

    const getCategoryName = (slug) => {
        return getGameCategoryNameBySlug(slug);
    };

    const handleClearSearch = () => {
        input.value.clear();
        searchInput.value = "";
        foundGamesList.value = [];
    };

    return {
        FeIconConfig,
        searchInput,
        gamesList,
        hasMinCharactersInSearch,
        showEmptyListText,
        classes,
        searchInputHandler,
        handleClearSearch,
        getCategoryName,
        recommendGames,
        loadingGames,
        getGameCategoryNameBySlug,
        getCategoryGames,
        routeNames,
        debounceSearchInputHandler,
        foundGamesList,
    };
}
