<template>
    <div v-if="progressData" class="status-wrapper">
        <div
            v-for="(status, key) in statuses"
            :key="key"
            class="vip-status__block d-flex flex-direction--column"
            :class="{ 'status-current': status.isActive }"
        >
            <div class="vip-status__block-details d-flex height-fit-content">

                <div class="vip-status--progress-block">
                    <div class="status-image--wrapper" :class="{shown: status.isActive}">
                        <FeImage
                            v-if="status.img.src"
                            :src="status.img.src"
                            :src-retina="status.img.srcRetina"
                            class="status-image"
                            :class="{ 'status-image--current': status.isActive }"
                        />
                    </div>
                    <div class="vip-status--progress-bar-backing" :class="status.backingClassList"></div>
                    <div class="vip-status--progress-bar" :class="status.progressBarClassList"></div>
                </div>
                <div
                    class="vip-status--progress-header d-flex align-items-start justify-content--space-between flex-wrap">
                    <span class="vip-status-name">{{ t(`VIP_CLUB.STATUS_CARD.NAMES.${status.id}`) }}</span>
                    <div
                        v-if="status.isActive && !status.isRegular"
                        class="vip-status--achieved gap-column-xxs align-items-center">
                        <span>{{ achievedStatusText }}</span>
                        <FeIcon
                            :icon="FeIconConfig.icons.fill.check"
                        />
                    </div>
                    <div v-if="isNext(status.order)" class="vip-status--next">
                        <span>{{ nextStatusText }}</span>
                    </div>
                    <div v-if="closedStatuses.includes(status.order) && !status.isNext">
                        <FeIcon
                            class="status-locked"
                            :icon="FeIconConfig.icons.fill.lock"
                        />
                    </div>
                    <div v-if="status.isActive" class="vip-status--active-details gap-xs">
                        <p class="vip-status--instructions">
                            {{ t("VIP_CLUB.PROGRESS.NEXT_STATUS_INSTRUCTIONS", { nextStatus: nextStatus.name }) }}</p>
                        <VipClubStatusGoals
                            :deposit-amount-cents="progressData.depositAmountCents"
                            :deposit-threshold-cents="progressData.depositThresholdCents"
                            :bet-sum-cents="progressData.betSumCents"
                            :bet-sum-threshold-cents="progressData.betSumThresholdCents"
                            :currency="progressData.currency"/>
                    </div>
                    <div
                        v-if="
                            isNext(status.order)"
                        class="vip-status--next-details">
                        <p class="vip-status--instructions">
                            {{ t("VIP_CLUB.PROGRESS.EARN_GIFT", { currency: progressData.currency }) }}</p>
                    </div>
                    <div v-if="status.isDiamond" class="life-long-status text-uppercase">
                        <span>
                            {{ t("VIP_CLUB.PROGRESS.DIAMOND.LIFE_LONG") }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useUserBalance } from "@store/user/userBalance";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import { storeToRefs } from "pinia";
import { onMounted, ref, computed } from "vue";
import { type IStatus, MOCKED_PROGRESS_SUM, STATUSES } from "@components/VipClub/constsNew";
import FeImage from "@ui/FeImage/FeImage.vue";
import { useVipAdventures } from "@store/user/vipAdventures";
import { useI18n } from "vue-i18n";
import { userVipStatusWithRegular } from "@components/VipClub/vipClubHelpers";
import VipClubStatusGoals from "@components/VipClub/VipClubStatusGoals.vue";

const { t } = useI18n();

const { loadVipProgress } = useVipAdventures();

const progressData = ref(null);

const isActive = (status: string) => status === progressData.value.activeStatus;
const isDiamond = (groupId: number) => groupId === 759;
const isRegular = (groupId: number) => groupId === 1117;
const activeStatus = computed(() => STATUSES[progressData.value.activeStatus]);

const nextStatus = computed(() => {
    return Object.values(STATUSES).find((status: IStatus) => status.order === activeStatus.value.order + 1);
});

const isNext = (statusOrder: number) => nextStatus.value?.order === statusOrder;
const backingClassList = (status: IStatus) => {
    return {
        "vip-status--progress-bar": true,
        "backing": true,
        "hidden": isDiamond(status.id),
    };
};

const progressBarClassList = (status: IStatus) => {
    return {
        "vip-status--progress-bar": true,
        "status-achieved": status.order < STATUSES[progressData.value.activeStatus].order,
        "status-current": status.order === STATUSES[progressData.value.activeStatus].order,
        "hidden": isDiamond(status.id),
    };
};

const statuses = computed(() => {
    return Object.fromEntries(Object.entries(STATUSES).map(([ statusName, status ]) => {
        return ([ statusName, {
            ...status,
            isActive: isActive(statusName),
            isDiamond: isDiamond(status.id),
            isNext: isNext(status.order),
            isRegular: isRegular(status.id),
            backingClassList: backingClassList(status),
            progressBarClassList: progressBarClassList(status),
        } ]);
    }));
});

const achievedStatusText = computed(() => t(`VIP_CLUB.STATUS_CARD.CONDITION.${userVipStatusWithRegular.value}.STATUS_ACHIEVED`));
const nextStatusText = computed(() => t(`VIP_CLUB.STATUS_CARD.CONDITION.${userVipStatusWithRegular.value}.IN_PROGRESS`));

const closedStatuses = computed(() => {
    return Object.values(STATUSES).filter((status: IStatus) => status.order > activeStatus.value.order).map(({ order }) => order);
});

const { userCurrency } = storeToRefs(useUserBalance());

onMounted(async () => {
    try {
        progressData.value = await loadVipProgress();
    } catch {
        progressData.value = {
            currency: userCurrency.value,
            currentStatus: "REGULAR",
            activeStatus: "REGULAR",
            nextStatus: "BRONZE",
            depositThresholdCents: MOCKED_PROGRESS_SUM[userCurrency.value].depSum || 900000,
            depositAmountCents: 0,
            betSumCents: 0,
            betSumThresholdCents: MOCKED_PROGRESS_SUM[userCurrency.value].betSum || 9000000,
            overallProgress: 0.0,
        };
    }
});

</script>

<style scoped lang="scss">
@import "~@theme/styles";

.vip-status__block {
    min-height: 4rem;
    position: relative;
    overflow: hidden;

    .status-image {
        background: var(--color-tertiary-4);
        padding: 0.25rem;
        border-radius: var(--border-radius-xs);
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        z-index: 9999;
        position: relative;

        &-current {
            border: 2px solid var(--color-additional-gradient);
        }
    }

    .vip-status-name {
        @include font-size--Body($font-weight--bold);
        align-self: start;
        min-width: 30%;
    }
}

.vip-status--progress-bar {
    width: 0.25rem;
    height: 100%;
    position: absolute;
    left: calc(1.25rem - 2px);

    &-backing {
        background: var(--color-tertiary-3);
    }

    &.status-current {
        background: var(--color-additional-gradient);
        height: 25%;
        border-bottom-left-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
    }

    &.status-achieved {
        background: var(--color-additional-gradient);
    }

    &.hidden {
        display: none;
    }
}

.vip-status {
    &--active-details {
        padding-bottom: 1rem;
    }

    &--next-details {
        text-transform: uppercase;
        width: 100%;
    }

    &--instructions {
        @include font-size--SmallCaption($font-weight--normal);
        color: var(--color-text-body);
        text-transform: none;
    }

    &--progress-header {
        width: 100%;

        .status-locked {
            color: var(--color-text-body);
        }
    }

    &--achieved {
        color: var(--color-successfully);
        @include font-size--SmallCaption($font-weight--bold);
    }

    &--next {
        color: var(--color-warning);
        @include font-size--SmallCaption($font-weight--bold);
    }

    &--progress-block {
        .status-image--wrapper {
            display: contents;

            &.shown {
                background: var(--color-tertiary-4);
                z-index: 1;
                display: block;
                position: relative;
                border-radius: var(--border-radius-xs);
                max-width: 2.5rem;
                margin-right: 1rem;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: inherit;
                    padding: 2px;
                    background: var(--color-additional-gradient);
                    -webkit-mask: var(--color-gradient-mask);
                    -webkit-mask-composite: destination-out;
                    mask-composite: exclude;
                    z-index: -1;
                }
            }
        }
    }
}

.life-long-status {
    @include font-size--SmallCaption($font-weight--normal);
    color: var(--color-text-body);
    width: 100%;
}


</style>
