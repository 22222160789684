<template>
    <BookmarksItem
        v-if="showBookmark"
        :image="bookmarkImage"
        @bookmark-click="handleClickBookmark"
    />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useEnvironments } from "@store/environments";
import { useUserStatuses } from "@store/user/userStatuses";

import BookmarksItem from "@modules/BookmarksList/item.vue";
import { VIP_ADV_GROUP } from "@modules/VipAdventures/consts";

import widgetAdventure from "./img/Widget_Adventure.svg";
import widgetAdventureMock from "./img/Widget_Adventure_Mock.svg";

const { useMocker } = useEnvironments();

const bookmarkImage = computed(() => {
    return useMocker ? widgetAdventureMock : widgetAdventure;
});

const userStatuses = useUserStatuses();
const ADVENTURE_PATH = "/rocketplay-adventure";
const $useRoute = useRoute();
const showBookmark = computed<boolean>(() => {
    return $useRoute.path !== ADVENTURE_PATH &&
        (userStatuses.isUserTester || userStatuses.getUserGroups.includes(VIP_ADV_GROUP));
});

const $router = useRouter();

function handleClickBookmark() {
    $router.push(ADVENTURE_PATH);
}
</script>
