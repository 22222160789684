<template>
    <div class="tooltip" :class="classes">
        <div class="tooltip__activator" @click.once="toCorrectAbroadAxisHorizontal">
            <slot name="activator"/>
        </div>
        <div class="tooltip__body" :style="styles">
            <div ref="tooltipContent" class="tooltip__content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",

    props: {
        top: {
            type: Boolean,
            default: true,
        },
        left: {
            type: Boolean,
        },
        right: {
            type: Boolean,
        },
        bottom: {
            type: Boolean,
        },
        topLeft: {
            type: Boolean,
        },
        width: {
            type: Number,
            default: 180,
        },
        showOnHover: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,

        },
    },

    computed: {
        styles() {
            return {
                width: `${ this.width }px`,
            };
        },
        classes() {
            const classes = [];

            // @TODO: rework with position prop
            if (this.topLeft) {
                classes.push("tooltip--position-top-left");
            } else if (this.left) {
                classes.push("tooltip--position-left");
            } else if (this.right) {
                classes.push("tooltip--position-right");
            } else if (this.bottom) {
                classes.push("tooltip--position-bottom");
            } else if (this.top) {
                classes.push("tooltip--position-top");
            }

            if (this.show) {
                classes.push("tooltip--show");
            }

            if (this.showOnHover) {
                classes.push("tooltip--hover");
            }

            return classes;
        },
    },

    methods: {
        toCorrectAbroadAxisHorizontal() {
            const tooltipContent = this.$refs.tooltipContent;
            // eslint-disable-next-line id-length
            const { x: offsetX, width } = tooltipContent.getBoundingClientRect();

            if (offsetX < 0) {
                tooltipContent.style.marginLeft = `${ Math.abs(offsetX) }px`;
                return;
            }

            const diffAbroad = offsetX + width - document.body.offsetWidth;
            if (diffAbroad > 0) {
                tooltipContent.style.marginLeft = `-${ diffAbroad }px`;
                return;
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
