import { getBettingUrl } from "../helpers/bettingHelpers";

export default (router) => {
    router.beforeEach((to, from, next) => {
        if (to.hash === "#sport") {
            window.location.href = getBettingUrl();
        }

        if (to.hash.startsWith("#sport_")) {
            const sportPath = to.hash.replace("#sport_", "");
            window.location.href = `${ getBettingUrl() }/${ sportPath }`;
        }

        next();
    });
};
