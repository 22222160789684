<template>
    <div
        class="jackpot-leaderboard-item"
    >
        <div v-if="renderNumberInsteadImage">
            <FeText
                :align="FeTextConfig.align.Center"
                :size="FeTextConfig.size.H2"
                :theme="FeTextConfig.themes.White"
                class="jackpot-leaderboard-item__place h2 text--align-center"
            >
                {{ props.index + 1 }}
            </FeText>
        </div>
        <div v-else>
            <FeImage
                class="jackpot-leaderboard-item__img"
                :src="iconItem.img"
                :src-retina="iconItem.imgRetina"
            />
        </div>
        <div class="jackpot-leaderboard-item__info">
            <FeText class="jackpot-leaderboard-item__name">
                {{ usernamePrepare(props.data.user_name) }}
            </FeText>
            <FeText class="jackpot-leaderboard-item__time">
                {{ timeFromNow(props.data.created_at) }}
            </FeText>
        </div>
        <FeText
            :align="FeTextConfig.align.Right"
            class="jackpot-leaderboard-item__win"
        >
            {{ winningAmount }} {{ props.data.jackpot_original_currency }}
        </FeText>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import FeText, * as FeTextConfig from "@ui/FeText/FeText.vue";
import { mapWinnersIcons } from "@components/JackpotsCustom/jackpotsConfig";
import type { IJackpotWinner } from "@store/jackpots/types/winner";
import { nicknameReplace } from "@helpers/textFilters";
import { timeFromNow } from "@helpers/formatDate";
import i18nInit from "@i18n/index";
import type { IMapImages } from "@src/types/common";

interface Props {
    data: IJackpotWinner;
    index: number;
    blockTitle: string;
}

const props = defineProps<Props>();
const renderNumberInsteadImage = computed(() => {
    return props.blockTitle === i18nInit.instance.t("JACKPOTS.LEADERBOARD.TITLES.BIGGEST") &&
        props.index > 2;
});
const winningAmount = computed(() => {
    return (props.data.jackpot_original_amount_cents / 100).toFixed(2);
});
const iconItem = computed<IMapImages>(() => {
    if (props.blockTitle === i18nInit.instance.t("JACKPOTS.LEADERBOARD.TITLES.BIGGEST")) {
        return mapWinnersIcons[`place${ props.index + 1 }`];
    }
    return mapWinnersIcons[props.data.jackpot_level_name];
});

function usernamePrepare(username) {
    return nicknameReplace(username);
}
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.jackpot-leaderboard-item {
    display: grid;
    grid-template-columns: 2.5rem 1fr 1fr;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    background: var(--color-tertiary-4);
    border-radius: var(--border-radius-xs);

    &__place {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        border-radius: 50%;
        background: var(--color-tertiary-3);
    }

    &__img {
        width: 2.5rem;
        height: 2.5rem;
    }

    &__name {
        @include font-size--Caption2($font-weight--normal);
    }

    &__time {
        @include font-size--Caption2($font-weight--normal);
        color: var(--color-text-body);
    }

    &__win {
        @include font-size--Caption1($font-weight--bold);
        color: var(--color-primary-1);
    }
}
</style>
