<template>
    <div class="quest-progress">
        <p v-if="showTitle" class="quest-progress-title text--align-center">
            {{ $t("QUEST.FAQ.TIMER.PROGRESS_TITLE") }}
        </p>
        <div class="quest-progress__data gap-column-s">
            <div>
                {{
                    $t("QUEST.FAQ.TIMER.PROGRESS_LEVELS", { currentPosition: currentPosition, total: totalAwardPlaces })
                }}
            </div>
            <div class="last-child">
                {{
                    $t("QUEST.FAQ.TIMER.PROGRESS_POINTS", { currentPosition: userPoints, total: pointForNextLevel })
                }}
            </div>
        </div>
        <ProgressBar :progress="progressForNextLevel"/>
    </div>
</template>

<script>
import ProgressBar from "@components/ProgressBar/ProgressBar.vue";

export default {
    name: "QuestProgressBar",

    components: {
        ProgressBar,
    },

    props: {
        currentPosition: {
            type: Number,
        },

        totalAwardPlaces: {
            type: Number,
        },

        userPoints: {
            type: Number,
        },

        pointForNextLevel: {
            type: Number,
        },

        showTitle: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        progressForNextLevel() {
            return this.userPoints / this.pointForNextLevel * 100;
        },
    },

};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.quest {
    &-progress {
        &-title {
            @include font-size--Caption2($font-weight--normal);
            margin-bottom: .5rem;
        }

        &__data {
            @include font-size--SmallCaption($font-weight--bold);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: .5rem;

            @include media(M) {
                @include font-size--Caption1;
            }

            div.last-child {
                text-align: right;
            }
        }
    }
}
</style>
