import { createI18n as _createI18n } from "vue-i18n";
import config from "./config";
import { useMultilang } from "@store/multilang";
import { storeToRefs } from "pinia";

export const createI18n = () => {
    const { getUserLocale } = storeToRefs(useMultilang());

    const oldLocale = getUserLocale.value;

    return _createI18n({
        ...config(oldLocale, oldLocale),
        legacy: false,
        allowComposition: true,
        warnHtmlMessage: false,
    });
};

const i18n = {
    instance: null,
    init() {
        const _i18n = createI18n();
        this.instance = _i18n.global;
        return this.instance;
    },
};

export default i18n;
