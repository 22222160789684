import type { IJackpotItem } from "@api/DTO/jackpot";
import { currencyView } from "@helpers/currencyHelper";

export function jackpotPrizePool(jackpotData: IJackpotItem, subUntilFn: (currency: string) => number): string {
    if (jackpotData) {
        const prizeSumCent = jackpotData.levels.reduce((accum, levelData) => {
            return accum + levelData.amount_cents;
        }, 0);

        return currencyView(
            prizeSumCent,
            jackpotData.currency,
            null,
            subUntilFn(jackpotData.currency),
        );
    }

    return "0";
}
