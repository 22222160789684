<template>
    <div :class="[ 'vs-notify', group ]" :style="styles">
        <transition-group :name="trans" mode="out-in">
            <div
                v-for="item in list"
                :key="item.id"
                :class="item.type"
                class="toast">
                <slot name="body" :class="item.type" :item="item">
                    <template v-if="item.image">
                        <FeIcon
                            :icon="item.image"
                            class="toast__thumb"
                            @click="clickHandler(item)"
                        />
                    </template>
                    <div class="toast__content" @click="clickHandler(item)">
                        <p v-if="item.title" class="toast__title h3" v-html="item.title" />
                        <p class="toast__text" v-html="item.text" />
                    </div>
                    <div class="toast__close" @click.stop="closeHandler(item)">
                        <FeIcon :icon="FeIconConfig.icons.fill.close" />
                    </div>
                </slot>
            </div>
        </transition-group>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";

const icons = {
    message: FeIconConfig.icons.fill.info1,
    successfully: FeIconConfig.icons.fill.checkInput,
    warning: FeIconConfig.icons.fill.attention2,
    reject: FeIconConfig.icons.fill.closeInput,
};

export default {
    name: "Toast",

    components: {
        FeIcon,
    },

    data() {
        return {
            FeIconConfig,
            group: "basic",
            image: "",
            styles: "",
            type: "",
            text: "default",
            list: [],
            trans: "ntf-fade",
        };
    },

    mounted() {
        this.$bus.$on("toast.show", this.show);
        this.$bus.$on("toast.close", this.close);
    },

    beforeUnmount() {
        this.$bus.$off("toast.show", this.show);
        this.$bus.$off("toast.close", this.close);
    },

    methods: {
        show(toast) {
            if (toast.time !== 0 && toast.time) {
                this.setTimer(toast.time);
            }

            if (toast.image) {
                toast.image = icons[toast.image];
            }

            if (!Object.prototype.hasOwnProperty.call(toast, "id")) {
                toast.id = Math.random();
            }

            this.setTimer(toast);
            this.list.push(toast);
        },

        close(toast) {
            this.remove(toast.id);
        },

        remove(id) {
            this.list = this.list.filter((item) => {
                return item.id !== id;
            });
        },

        setTimer(toast) {
            let timer = toast.time === 0 ? 0 : 10000;

            if (timer > 0) {
                setTimeout(() => {
                    this.remove(toast.id);
                }, timer);
            }
        },

        clickHandler(item) {
            if (item.url) {
                // this.$router.push(item.url); // переходы нужно обсудить так как пути не соответсвуют текущим роутам
            } else if (item.callback) {
                item.callback();
            }
        },

        closeHandler(item) {
            if (item.callback) {
                item.callback();
            }
            this.remove(item.id);
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
