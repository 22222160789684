import { routeNames } from "@router/routeNames";
import { useUserLimits } from "@store/user/userLimits";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";

export default (router) => {
    router.beforeEach((to, __, next) => {
        const { getIsLogged } = storeToRefs(useUserInfo());

        if (!getIsLogged.value || to.name !== routeNames.gameItem) {
            return next();
        }

        const { loadUserLimits, isOneLimitReached } = useUserLimits();
        loadUserLimits().then(() => {
            if (isOneLimitReached) {
                return next({ name: routeNames.profileLimits });
            }
            return next();
        });
    });
};
