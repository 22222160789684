<template>
    <div class="comp-points-rate__wrap">
        <div class="comp-points-rate__label">
            {{ label }}
        </div>
        <div class="comp-points-rate">
            {{ rateCompPointsCount }}
            <FeImage
                :src="iconCoin"
                :src-retina="iconCoinX2"
                class="comp-points-rate__icon"
            />
            = {{ calculateRate }}
        </div>
    </div>
</template>

<script>
import { currencyView } from "@helpers/currencyHelper";
import FeImage from "@ui/FeImage/FeImage.vue";
import iconCoin from "@modules/CompPoints/images/icon-coin.png";
import iconCoinX2 from "@modules/CompPoints/images/icon-coin@2.png";
import { mapActions, mapState } from "pinia";
import { useCompPointsStore } from "@store/compPoints/compPointsStore";
import { useCommon } from "@store/common";

export default {
    name: "CompPointRateInformer",

    components: {
        FeImage,

    },

    props: {
        label: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            iconCoin,
            iconCoinX2,
        };
    },

    computed: {
        ...mapState(useCompPointsStore, {
            userRateCompPoints: "getCompPointsRate",
        }),

        rateCompPointsCount() {
            return this.userRateCompPoints?.points * 100;
        },

        calculateRate() {
            const { amount_cents: amountCents, currency } = this.userRateCompPoints;
            return currencyView(amountCents * 100, currency, null, this.subUnits(currency));
        },
    },
    methods: {
        ...mapActions(useCommon, {
            subUnits: "getSubunitsToUnitsByCode",
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.comp-points-rate {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight--bold;
    background: var(--color-tertiary-6);
    border-radius: $container--border-radius;
    padding: .5rem 1rem;

    &__icon {
        width: 2rem;
        margin: 0 .5rem;
    }

    &__label {
        @include font-size--Caption2($font-weight--normal);
        text-align: center;
        color: var(--color-primary-3);
    }

    &__wrap {
        display: grid;
        justify-items: center;
    }
}

</style>
