<template>
    <div class="footer-social">
        <p class="h5 footer-social-description">
            {{ $t("FOOTER.SOCIAL") }}
        </p>
        <div class="footer-social__list gap-column-xs">
            <a
                v-for="social in list"
                :key="social.name"
                class="footer-social__item"
                :alt="social.name"
                :href="social.url"
                target="_blank"
                rel="noindex, nofollow"
            >
                <FeIcon class="footer-social__item-icon" :icon="social.icon"/>
            </a>
        </div>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import config from "@theme/configs/config";

export default {
    name: "FooterSocial",

    components: {
        FeIcon,
    },

    data() {
        return {
            FeIconConfig,
            list: config.socials || [],
        };
    },
};
</script>

<style lang="scss" src="./style.scss"></style>

