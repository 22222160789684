export const SLUG_CATEGORY_ALL = "all";

export enum SlugCategoriesGames {
    SLUG_CATEGORY_NEW = "new",
    SLUG_CATEGORY_BONUS_WAGERING = "bonus-wagering",
    SLUG_CATEGORY_MEGAWAYS = "megaways",
    SLUG_CATEGORY_BONUS_BUY = "bonus-buy",
    SLUG_CATEGORY_BLACKJACK = "blackjack",
    SLUG_CATEGORY_ROULETTE = "roulette",
    SLUG_CATEGORY_BACCARAT = "baccarat",
    SLUG_CATEGORY_POKER = "poker",
    SLUG_CATEGORY_TABLE = "table",
    SLUG_CATEGORY_BTC_GAMES = "btc-games", // cryptoGame
    SLUG_CATEGORY_GAME_SHOWS = "game-shows",
    SLUG_CATEGORY_MYSTIC_JACKPOTS = "mystic-jackpots",
    SLUG_CATEGORY_BNG_TOURNAMENT = "bng-tournament",
    SLUG_CATEGORY_JACKPOT = "jackpot",
    SLUG_CATEGORY_PLS_TOURNAMENT = "pls-tournament",
    SLUG_CATEGORY_TOP = "top",
    SLUG_CATEGORY_RECOMMENDED = "recommended",
    SLUG_CATEGORY_EGYPT_GAMES = "egypt-games",
    SLUG_CATEGORY_FRUITS_GAMES = "fruit-games",
    SLUG_CATEGORY_BOOKS_GAMES = "book-games",
    SLUG_CATEGORY_CRASH_GAMES = "crash-games",
    SLUG_CATEGORY_LOTTERY_GAMES = "lottery-games",
    SLUG_CATEGORY_FISHING_GAMES = "fishing-games",
    SLUG_CATEGORY_HIGH_VOLATILITY = "high-volatility",
    SLUG_CATEGORY_LOW_VOLATILITY = "low-volatility",
    SLUG_CATEGORY_SPECIAL = "special",
    SLUG_CATEGORY_TOUR_GAMES = "tour_games",
    SLUG_CATEGORY_ALL = "all",
    SLUG_CATEGORY_LIVE = "live",
    SLUG_CATEGORY_POKIES = "pokies",
    SLUG_CATEGORY_WINN_WEEK = "winning-week",
    SLUG_CATEGORY_WIN_WEEK_TOUR = "winning-week-tournament",
    SLUG_CATEGORY_COINS = "coin",
    SLUG_CATEGORY_LIVE_ALL = "live_all",
}

export enum MenuCollectionsMame {
    MAIN_PAGE_CATEGORIES = "MAIN_PAGE_CATEGORIES",
    POKIES_PAGE_CATEGORIES = "POKIES_PAGE_CATEGORIES",
    LIVE_PAGE_CATEGORIES = "LIVE_PAGE_CATEGORIES",
    CATEGORY_PAGE_CATEGORIES = "CATEGORY_PAGE_CATEGORIES",
    PRODUCER_PAGE_CATEGORIES = "PRODUCER_PAGE_CATEGORIES",
    FAVORITE_PAGE_CATEGORIES = "FAVORITE_PAGE_CATEGORIES"
}

export const MAIN_PAGE_CATEGORIES: SlugCategoriesGames[] = [
    SlugCategoriesGames.SLUG_CATEGORY_TOP,
    SlugCategoriesGames.SLUG_CATEGORY_NEW,
    SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED,
    SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING,
    SlugCategoriesGames.SLUG_CATEGORY_COINS,
    SlugCategoriesGames.SLUG_CATEGORY_JACKPOT,
    SlugCategoriesGames.SLUG_CATEGORY_TABLE,
    SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_EGYPT_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_FRUITS_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_BOOKS_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_CRASH_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_LOTTERY_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_FISHING_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_ALL,
];

export const POKIES_PAGE_CATEGORIES: SlugCategoriesGames[] = [
    SlugCategoriesGames.SLUG_CATEGORY_TOP,
    SlugCategoriesGames.SLUG_CATEGORY_NEW,
    SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED,
    SlugCategoriesGames.SLUG_CATEGORY_MYSTIC_JACKPOTS,
    SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING,
    SlugCategoriesGames.SLUG_CATEGORY_COINS,
    SlugCategoriesGames.SLUG_CATEGORY_BONUS_BUY,
    SlugCategoriesGames.SLUG_CATEGORY_MEGAWAYS,
    SlugCategoriesGames.SLUG_CATEGORY_PLS_TOURNAMENT,
    SlugCategoriesGames.SLUG_CATEGORY_HIGH_VOLATILITY,
    SlugCategoriesGames.SLUG_CATEGORY_LOW_VOLATILITY,
    SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_SPECIAL,
    SlugCategoriesGames.SLUG_CATEGORY_BNG_TOURNAMENT,
    SlugCategoriesGames.SLUG_CATEGORY_EGYPT_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_FRUITS_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_BOOKS_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_CRASH_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_LOTTERY_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_FISHING_GAMES,
];

export const LIVE_PAGE_CATEGORIES: SlugCategoriesGames[] = [
    SlugCategoriesGames.SLUG_CATEGORY_BLACKJACK,
    SlugCategoriesGames.SLUG_CATEGORY_ROULETTE,
    SlugCategoriesGames.SLUG_CATEGORY_GAME_SHOWS,
    SlugCategoriesGames.SLUG_CATEGORY_BACCARAT,
    SlugCategoriesGames.SLUG_CATEGORY_POKER,
    SlugCategoriesGames.SLUG_CATEGORY_LIVE_ALL,
];

export const CATEGORY_PAGE_CATEGORIES: SlugCategoriesGames[] = [
    SlugCategoriesGames.SLUG_CATEGORY_TOP,
    SlugCategoriesGames.SLUG_CATEGORY_NEW,
    SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED,
    SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING,
    SlugCategoriesGames.SLUG_CATEGORY_JACKPOT,
    SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_TABLE,
    SlugCategoriesGames.SLUG_CATEGORY_ALL,
];

export const FAVORITE_PAGE_CATEGORIES: SlugCategoriesGames[] = [
    SlugCategoriesGames.SLUG_CATEGORY_TOP,
    SlugCategoriesGames.SLUG_CATEGORY_NEW,
    SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED,
    SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING,
    SlugCategoriesGames.SLUG_CATEGORY_JACKPOT,
    SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES,
    SlugCategoriesGames.SLUG_CATEGORY_TABLE,
    SlugCategoriesGames.SLUG_CATEGORY_ALL,
];

export const PRODUCER_PAGE_CATEGORIES: SlugCategoriesGames[] = POKIES_PAGE_CATEGORIES;

export const CONFIG_DEFAULT_COLLECTIONS_MENU_SLUGS: Record<MenuCollectionsMame, SlugCategoriesGames[]> = {
    MAIN_PAGE_CATEGORIES,
    POKIES_PAGE_CATEGORIES,
    LIVE_PAGE_CATEGORIES,
    CATEGORY_PAGE_CATEGORIES,
    FAVORITE_PAGE_CATEGORIES,
    PRODUCER_PAGE_CATEGORIES,
};
