<template>
    <div
        class="games-list-toolbar-search"
        data-test="open-search-button"
        @click="openHandler"
    >
        <template v-if="additionalButton">
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SlabMedium"
                :slab-icon="FeIconConfig.icons.outline.search"
                full-width
            >
                {{ $t('GAME_HALL.SEARCH_BUTTON') }}
            </FeButtonNew>
            <AdditionalButton
                v-else
                :icon="FeIconConfig.icons.outline.search"
                full-width
                :title="$t('GAME_HALL.SEARCH_BUTTON')"
            />
        </template>
        <FeIcon
            v-else
            :icon="FeIconConfig.icons.outline.search"
            class="games-list-toolbar-search__icon"
        />
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import Modal from "@plugins/Modal";

import iconSearch from "@theme/images/icon-search.svg";
import SearchList from "@components/GamesListToolbarSearch/components/SearchList/SearchList.vue";
import AdditionalButton from "@components/AdditionalButton/AdditionalButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import { mapState } from "pinia";
import { useRootStore } from "@store/root";

export default {
    name: "GamesListToolbarSearch",

    components: {
        FeIcon,
        AdditionalButton,
        FeButtonNew,
    },

    props: {
        additionalButton: {
            type: Boolean,
        },
    },
    emits: [ "open" ],

    data() {
        return {
            iconSearch,
            FeIconConfig,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
    },

    watch: {
        "$route"() {
            Modal.close("modal-search");
        },
    },

    methods: {
        openHandler() {
            if (this.isMobile) {
                // create invisible input to receive the focus first (fixing showing keyboard at iOS)
                const fakeInput = document.createElement("input");
                fakeInput.id = "fakeInput";
                fakeInput.setAttribute("type", "text");
                fakeInput.style.position = "absolute";
                fakeInput.style.opacity = "0";
                fakeInput.style.height = "0";
                fakeInput.style.fontSize = "16px";
                document.body.prepend(fakeInput);
                fakeInput.focus();

                Modal.show({
                    mobileFriendly: true,
                    name: "modal-search",
                    component: SearchList,
                });
            } else {
                this.$emit("open");
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
