<template>
    <FeImage
        v-if="withoutLink"
        :src="logoSrc"
        class="logo__img"
        :alt="title"
    />
    <router-link
        v-else
        :class="logoClass"
        :title="title"
        :to="{name: 'Main'}"
        class="logo">
        <FeImage :src="logoSrc" class="logo__img" :alt="title"/>
    </router-link>
</template>

<script setup lang="ts">
import { useRootStore } from "@store/root";
import { useLogoStore } from "@store/logoStore";
import FeImage from "@ui/FeImage/FeImage.vue";

import manifest from "@theme/manifest.json";
import { useUserInfo } from "@store/user/userInfo";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const props = withDefaults(defineProps<{
    full: boolean,
    useMobileLogo: boolean,
    withoutLink: boolean
}>(), {
    full: false,
    useMobileLogo: false,
    withoutLink: false,
});

const { isMobile } = storeToRefs(useRootStore());
const title = ref(manifest.name);
const { getMobileLogoSrc, getFullLogoSrc, getLogoSrc } = storeToRefs(useLogoStore());
const { getIsLogged: isLogged } = storeToRefs(useUserInfo());

const logoSrc = computed(() => {
    if (props.useMobileLogo) {
        return getMobileLogoSrc.value;
    }

    if (props.full) {
        return getFullLogoSrc.value;
    }

    return getLogoSrc.value;
});
const logoClass = computed(() => {
    if (props.full) {
        return "";
    }

    return isMobile.value && isLogged.value ? "logo--mobile" : "";
});
</script>

<style lang="scss" src="./style.scss"></style>
