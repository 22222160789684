<template>
    <div class="header" :class="classes">
        <SessionTime v-if="isMobile" class="session-time"/>
        <HeaderNotice/>
        <div class="header__wrapper">
            <div class="header__left">
                <!--required for grid mirroring-->
            </div>
            <div class="header__center">
                <div class="header__logo">
                    <logo/>
                </div>

                <HeaderNavMenu :nav-list="topNavList"/>

                <div v-if="!isLogged" class="header__auth">
                    <EnterBtns class="header__only-desktop"/>
                    <SearchGames/>
                    <Notify v-if="enabledNotices" class="header__only-desktop"/>
                    <SupportButton v-else class="header__only-desktop"/>
                    <LanguageForm class="header-select-lang"/>
                </div>
                <div v-else-if="userIsLoaded" class="header__profile">
                    <div class="header__cashbox">
                        <FeButtonNew
                            v-if="featureFlags.enableNewUIComponents"
                            :data-test="dataTestAttrs.header.headerDepositButton"
                            @click="openCashbox"
                        >
                            {{ $t("COMMON.DEPOSIT") }}
                        </FeButtonNew>
                        <FeButton
                            v-else
                            :data-test="dataTestAttrs.header.headerDepositButton"
                            @click="openCashbox"
                        >
                            {{ $t("COMMON.DEPOSIT") }}
                        </FeButton>
                    </div>
                    <div class="header__user gap-xs">
                        <div class="header__user--container">
                            <UserPicName
                                :image="iconLevel"
                                :linkto="{ name : 'Profile'}"
                                class="header__user-name"
                            />
                            <Popup
                                width-auto
                                activator-fixed
                                bottom
                                right
                                offset-x
                                :use-backdrop="isMobile"
                            >
                                <template #activator="{ toggleHandler: toggleDropdownHandler, isShow }">
                                    <div
                                        class="header__user-info"
                                        :data-test="dataTestAttrs.header.headerDropdownUserInfo"
                                        @click.stop="toggleDropdownHandler"
                                    >
                                        <div class="header__user-status">
                                            <span
                                                :class="userNameClassList"
                                                :data-test="dataTestAttrs.header.headerUserNicknameContainer"
                                            >{{ userNickname }}
                                            </span>
                                            <FeImage
                                                v-if="isVip"
                                                :src="VipBadge"
                                            />
                                        </div>
                                        <span
                                            class="header__user-balance"
                                            :class="{'header__user-balance--open': isShow}"
                                        >
                                            <span>{{ userCommonBalance }}</span>
                                            <FeIcon
                                                :icon="FeIconConfig.icons.fill.dropdownDown"
                                                class="header__user-balance-arrow theme-light"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #default="{ hideHandler: closeDropdownBalance }">
                                    <DropdownBalance
                                        :is-dark="true"
                                        :route-cashbox-button="routeNames.cashboxDashboard"
                                        :close-handler="closeDropdownBalance"
                                    >
                                        <CompPointBalance v-if="featureFlags.enableConpoints"/>
                                        <LotteryTicketsBalance/>
                                    </DropdownBalance>
                                </template>
                            </Popup>
                        </div>
                        <div class="header__user__buttons gap-column-xs">
                            <SearchGames/>
                            <GiftButton :count="giftsCounter"/>
                            <Notify v-if="enabledNotices"/>
                            <SupportButton v-else/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomMenu
            :nav-menu-list="bottomNavList"
            :cashbox-title="$t('COMMON.DEPOSIT')"
            :path-for-deposit-button="routeNames.cashboxDeposit"
        />
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";
import { useJackpots } from "@store/jackpots";
import { routeNames } from "@router/routeNames";

import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import Logo from "@components/Logo/Logo.vue";
import FeButton from "@ui/FeButton/FeButton.vue";
import SupportButton from "./components/SupportButton/SupportButton.vue";
import EnterBtns from "@components/EnterBtns/EnterBtns.vue";
import UserPicName from "@components/UserPicName/UserPicName.vue";
import DropdownBalance from "@components/DropdownBalance/DropdownBalance.vue";

import Popup from "@components/Popup/Popup.vue";
import { TOP_MENU_TYPE } from "@modules/StaticPage/constants.js";

import HeaderNavMenu from "@components/HeaderNavMenu/HeaderNavMenu.vue";
import BottomMenu from "@components/BottomMenu/BottomMenu.vue";
import GiftButton from "@components/GiftButton/GiftButton.vue";
import LotteryTicketsBalance
    from "@modules/Lottery/LotteryPage/components/LotteryTicketsBalance/LotteryTicketsBalance.vue";
import CompPointBalance from "@modules/CompPoints/components/CompPointBalance.vue";
import LanguageForm from "@modules/Profile/components/LanguageForm/LanguageForm.vue";
import HeaderNotice from "@components/HeaderNotice/HeaderNotice.vue";
import SearchGames from "@components/SearchGames/SearchGames.vue";
import Notify from "@modules/Notify/Notify.vue";

import dataTestAttrs from "./data-test-attrs.js";
import { getBettingUrl, showBettingLink } from "@helpers/bettingHelpers";
import featureFlags from "@theme/configs/featureFlags";
import { useGiftsStore } from "@store/gifts";
import { useUserBalance } from "@store/user/userBalance";
import { useNoticesStore } from "@store/notices";
import { SPECIAL_GAME_LINK } from "@theme/configs/games";
import { getTitleFromIdentifier } from "@helpers/getTitleFromIdentifier";
import { useClickOnSpecialSlotMenu } from "@helpers/specialLinkMenuHelper";
import { BADGE_COLORS } from "@ui/FeNavBadge/badgeColorsConsts";
import { useLootboxesStore } from "@store/lootboxes";
import { useLevelsStore } from "@store/levels/levelsStore";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { useI18n } from "vue-i18n";
import { useCompPointsStore } from "@store/compPoints/compPointsStore";
import SessionTime from "@components/SessionTime/SessionTime.vue";
import { useCMS } from "@store/CMS";
import { useUserInfo } from "@store/user/userInfo";
import FeImage from "@ui/FeImage/FeImage.vue";
import VipBadge from "@modules/Header/img/vip_badge.svg";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import { useRootStore } from "@store/root";

export default {
    name: "HeaderSection",

    components: {
        FeImage,
        SessionTime,
        Popup,
        FeIcon,
        EnterBtns,
        FeButton,
        SupportButton,
        Logo,
        UserPicName,
        HeaderNavMenu,
        BottomMenu,
        GiftButton,
        DropdownBalance,
        LotteryTicketsBalance,
        CompPointBalance,
        SearchGames,
        LanguageForm,
        HeaderNotice,
        Notify,
        FeButtonNew,
    },

    setup() {
        return { ...useI18n() };
    },

    data() {
        return {
            FeIconConfig,
            VipBadge,
            featureFlags,
            routeNames,
            dataTestAttrs,
            showBettingLink: false,
            itemLobby: {
                title: this.$t("HEADER.GAMES"),
                icon: FeIconConfig.icons.outline.bottomBarCasino,
                routeName: routeNames.main,
                action: () => {
                    this.$router.push({ name: this.main });
                },
            },
            itemPokies: {
                title: this.$t("HEADER.POKIES"),
                routeName: routeNames.categoryPokiesAll,
                icon: FeIconConfig.icons.outline.bottomBarPokies,
                to: { name: routeNames.categoryPokiesAll },
            },
            itemLive: {
                title: this.$t("HEADER.LIVE"),
                routeName: routeNames.categoryLiveAll,
                icon: FeIconConfig.icons.outline.bottomBarTable,
                to: { name: routeNames.categoryLiveAll },
            },
            itemTournaments: {
                title: this.$t("HEADER.TOURNAMENTS"),
                routeName: routeNames.tournamentsAll,
                to: { name: routeNames.tournamentsAll },
            },
            itemSpinWheelPage: {
                title: this.$t("HEADER.ROCKET_WHEEL"),
                routeName: routeNames.spinWheelPage,
                to: { name: routeNames.spinWheelPage },
            },
            itemBetting: {
                title: this.$t("HEADER.SPORTS"),
                icon: FeIconConfig.icons.outline.betting,
                externalUrl: getBettingUrl(),
            },
            itemAchievements: {
                title: this.$t("HEADER.ACHIEVEMENTS"),
                routeName: routeNames.achievements,
                to: { name: routeNames.achievements },
            },
            itemVipClub: {
                title: this.$t("HEADER.VIP_CLUB"),
                routeName: routeNames.vipClub,
                to: { name: routeNames.vipClub },
            },
        };
    },

    computed: {
        themes() {
            return themes;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
            userCurrency: "getUserCurrency",
            userIsLoaded: "getDataIsLoaded",
            userNickname: "getUserNickName",
        }),
        ...mapState(useLevelsStore, [ "getLevelImageById" ]),
        ...mapState(useUserStatuses, {
            userLevel: "getUserLevelInfo",
            isUserTester: "isUserTester",
            isVip: "isVip",
        }),
        ...mapState(useGiftsStore, {
            giftsCounter: "giftsCounter",
            gifts: "giftsAll",
        }),
        ...mapState(useCompPointsStore, {
            userComPointBalance: "getUserCompPoints",
        }),
        ...mapState(useUserBalance, {
            userCommonBalance: "getUserCommonBalanceNormalize",
        }),
        ...mapState(useJackpots, {
            activeJackpots: "jackpotsActiveList",
        }),
        ...mapState(useNoticesStore, {
            headerNotices: "headerNotices",
            enabledNotices: "enabledNotices",
        }),
        ...mapState(useLootboxesStore, [ "isUserIncludeInLootbox" ]),
        ...mapState(useTournamentsStore, {
            tournamentsList: "getTournamentsList",
        }),
        ...mapState(useCMS, {
            pages: "getUnhiddenStaticPages",
        }),
        userNameClassList() {
            return {
                "header__user-nickname": true,
                "header__user-nickname-short": this.isVip,
            };
        },
        iconLevel() {
            return this.getLevelImageById(this.userLevel);
        },
        classes() {
            return {
                "header--login": this.isLogged,
                "header--logout": !this.isLogged,
                "header--notice__show": this.headerNotices?.length,
            };
        },
        topMenuPages() {
            return this.pages.filter((page) => {
                return page.categories.includes(TOP_MENU_TYPE);
            });
        },
        itemsJackpots() {
            return this.activeJackpots.map((item) => {
                return {
                    title: getTitleFromIdentifier(item.identifier),
                    badge: {
                        background: BADGE_COLORS.ERROR,
                        color: BADGE_COLORS.WHITE,
                        text: this.$t("HEADER.BADGE.NEW"),
                    },
                    to: `/${item.identifier}`,
                };
            });
        },
        topNavList() {
            return [ ...[
                ...(!this.showBettingLink ? [ this.itemLobby ] : []),
                this.itemPokies,
                this.itemLive,
                ...(this.showBettingLink ? [ this.itemBetting ] : []),
                ...[ this.specialSlotLink ],
                this.itemTournaments,
                ...(featureFlags.enableMysticJackpots && this.itemsJackpots || []),
                ...(this.isUserIncludeInLootbox && [ this.itemSpinWheelPage ] || []),
                ...[ this.itemAchievements ],
                this.itemVipClub,
                ...this.topMenuPages,
            ] ];
        },
        bottomNavList() {
            return [
                ...(!this.showBettingLink ? [ this.itemLobby ] : []),
                this.itemPokies,
                this.itemLive,
                ...(this.showBettingLink ? [ this.itemBetting ] : []),
            ];
        },

        specialSlotLink() {
            const { clickOnSpecialSlotMenu } = useClickOnSpecialSlotMenu();
            return {
                title: this.$t("HEADER.SPECIAL_GAME_NAME"),
                routeName: routeNames.achievements,
                badge: {
                    background: BADGE_COLORS.RRIMARY,
                    color: BADGE_COLORS.BLACK,
                    text: this.$t("HEADER.BADGE.SPECIAL_GAME"),
                },
                to: SPECIAL_GAME_LINK,
                clickFn: clickOnSpecialSlotMenu,
            };
        },
    },

    async mounted() {
        this.$bus.$on("shouldBounceTournaments", this.shouldBounceTournaments);
        this.$bus.$on("shouldBouncePresents", this.shouldBouncePresents);
        this.showBettingLink = await showBettingLink();
    },

    beforeUnmount() {
        this.$bus.$off("shouldBounceTournaments", this.shouldBounceTournaments);
        this.$bus.$off("shouldBouncePresents", this.shouldBouncePresents);
    },

    methods: {
        openCashbox() {
            this.$gtm.customTrackEvent(this.$gtm.CASHBOX_EVENTS.DEPOSIT.DEPOSIT_MAIN);
            this.$gtm.customTrackEvent({
                ...this.$gtm.CASHBOX_EVENTS.DEPOSIT.NEW.CLICK_OPEN_DEPOSIT_MAIN,
                location: "header",
            });
            this.$router.push({ name: routeNames.cashboxDeposit });
        },
        getActiveClass(item, isActive) {
            let active = isActive;
            if (item.routeName === routeNames.main) {
                active = this.$router.currentRoute.fullPath === "/" ||
                    this.$router.currentRoute.fullPath.startsWith("/categories");
            }

            if (item.routeName === routeNames.categoryPokiesAll) {
                active = this.$router.currentRoute.fullPath.startsWith("/pokies");
            }

            if (item.routeName === routeNames.categoryLiveAll) {
                active = this.$router.currentRoute.fullPath.startsWith("/live");
            }

            return { active: active };
        },

        openSideBar() {
            this.$bus.$emit("sidebar.open");
        },

        shouldBounceTournaments(param) {
            if (this.$isServer || param) {
                this.$forceUpdate();
                return false;
            }

            let buffer = this.tournamentsList.filter((item) => {
                return item.status === "active";
            }).length;

            let amountInStore = window.localStorage.getItem("shouldBounceTournaments");
            return Number(amountInStore) !== buffer;
        },

        shouldBouncePresents(param) {
            if (this.$isServer || param) {
                this.$forceUpdate();
                return false;
            }
            let amountInStore = window.localStorage.getItem("shouldBouncePresents");
            return Number(amountInStore) !== this.giftsCounter;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
