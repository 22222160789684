/* eslint-disable max-len */
export const mainPageJsonLD = ({
    hostname = "rocketplay.com",
    description = "RocketPlay is a trusted Australian online casino with wide selection of the best pokies from well-known providers and huge bonuses for new players",
}) => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "RocketPlay",
        "description": description,
        "email": "support@rocketplay.com",
        "url": `https://${hostname}`,
        "logo": `https://${hostname}/dist/logo.svg?768785cd71776b80e63d2360b5204e01`,
        "foundingDate": "2020",
        "sameAs": [
            "https://www.instagram.com/rocketplaycasino_/ ",
            "https://www.facebook.com/rocketplaycasino",
            "https://whatsapp.com/channel/0029Va6O4v311ulJb5xnqB3Q ",
            "https://twitter.com/RocketPlay5 ",
        ],
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "contactType": "customer support",
                "email": "support@rocketplay.com",
                "url": `https://${hostname}`,
            },
        ],
    };

    return JSON.stringify(schema);
};
