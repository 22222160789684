export function removeTrailingSlashPlugin(router) {
    router.beforeEach((to, from, next) => {
        if (to.fullPath.endsWith("/") && to.fullPath !== "/") {
            const correctedPath = to.fullPath.slice(0, -1);
            next({ path: correctedPath, query: to.query, hash: to.hash });
        } else {
            next();
        }
    });
}
