import type { IGame } from "@src/types/game";
import { useGamesFavorite } from "@store/games/gamesFavorite";
import { useUserBalance } from "@store/user/userBalance";
import { storeToRefs } from "pinia";
import { computed } from "vue";

export function useGameFavoriteHelper(game: IGame) {
    const { gamesFavoriteID } = storeToRefs(useGamesFavorite());
    const { userCurrency } = useUserBalance();

    const favoriteGameId = computed<number | undefined>(() => {
        return gamesFavoriteID.value.find((idGame: number) => {
            return Object.values(game?.currencies || {})
                .some(({ id }: { id: number }) => id === idGame);
        });
    });

    const isFavoriteGame = computed<boolean>(() => {
        return Boolean(favoriteGameId.value);
    });

    const gameIdByCurrency = computed<number | undefined>(() => {
        return Object.values(game.currencies).find(({ id }: { id: number }) => {
            return gamesFavoriteID.value.includes(id);
        })?.id || game.currencies[userCurrency]?.id;
    });

    return {
        gamesFavoriteID,
        isFavoriteGame,
        favoriteGameId,
        gameIdByCurrency,
    };
}
