<template>
    <FeButtonNew
        v-if="featureFlags.enableNewUIComponents"
        class="live-chat-button"
        :class="{'mobile-layout-width': mobileLayoutWidth}"
        :theme="theme"
        :full-width-mobile="fullWidthMobile"
        :full-width="fullWidth"
        data-test="support__live-chat--button"
        @click="openChat"
    >
        {{ $t("SUPPORT.CONTACT_INFO.LIVE_CHAT") }}
    </FeButtonNew>
    <FeButton
        v-else
        class="live-chat-button"
        :class="{'mobile-layout-width': mobileLayoutWidth}"
        :theme="theme"
        :full-width-mobile="fullWidthMobile"
        data-test="support__live-chat--button"
        @click="openChat"
    >
        {{ $t("SUPPORT.CONTACT_INFO.LIVE_CHAT") }}
    </FeButton>
</template>

<script>
import FeButton from "@ui/FeButton/FeButton.vue";
import { EventBus as $bus } from "@bus";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes as themesConfig } from "@ui/New/ButtonConfig";

export default {
    name: "LiveChatButton",

    components: {
        FeButton,
        FeButtonNew,
    },

    props: {
        theme: {
            type: Object,
            default() {
                return featureFlags.enableNewUIComponents ? themesConfig.TertiaryMedium : themesConfig.TertiarySmall;
            },
        },
        mobileLayoutWidth: {
            type: Boolean,
        },

        fullWidthMobile: {
            type: Boolean,
        },
        fullWidth: {
            type: Boolean,
        },
    },
    computed: {
        featureFlags() {
            return featureFlags;
        },
    },

    methods: {
        openChat() {
            $bus.$emit("chat.toggle");
        },
    },
};
</script>

