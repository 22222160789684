export enum AdvPrizeStatuses {
    LOST = "lost",
    COMPLETED = "completed",
    FUTURE = "future",
    ACTIVE = "active",
    SUPERB = "superPrize",
}

export enum AdvPrizeType {
    FREESPIN= "fs",
    BONUS= "bonus",
    SUPERB= "superPrize",
}

export const formatDateVipAdv = "DD/MM/YYYY";

export const VIP_ADV_GROUP = 791;
