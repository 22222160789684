export const DEPOSIT_METHOD = "deposit";
export const WITHDRAWAL_METHOD = "withdrawal";

export const paymentsMethods = [
    {
        id: "visa",
        type: "Credit Card LOCAL",
        processingTime: "Instant",
        fee: "free",
        currency: null,
        [DEPOSIT_METHOD]: {
            min: "PAYMENTS_PAGE.VISA.DEPOSIT_METHOD.MIN",
            max: "PAYMENTS_PAGE.VISA.DEPOSIT_METHOD.MAX",
        },
        [WITHDRAWAL_METHOD]: {
            min: "PAYMENTS_PAGE.VISA.WITHDRAWAL_METHOD.MIN",
            max: "PAYMENTS_PAGE.VISA.WITHDRAWAL_METHOD.MAX",
        },
    },
    {
        id: "maestro",
        type: "Credit Card LOCAL",
        processingTime: "Instant",
        fee: "free",
        currency: null,
        [DEPOSIT_METHOD]: {
            min: "PAYMENTS_PAGE.MAESTRO.DEPOSIT_METHOD.MIN",
            max: "PAYMENTS_PAGE.MAESTRO.DEPOSIT_METHOD.MAX",
        },
        [WITHDRAWAL_METHOD]: {
            min: "PAYMENTS_PAGE.MAESTRO.WITHDRAWAL_METHOD.MIN",
            max: "PAYMENTS_PAGE.MAESTRO.WITHDRAWAL_METHOD.MAX",
        },
    },
    {
        id: "mastercard",
        type: "Credit Card LOCAL",
        processingTime: "Instant",
        fee: "free",
        currency: null,
        [DEPOSIT_METHOD]: {
            min: "PAYMENTS_PAGE.MASTERCARD.DEPOSIT_METHOD.MIN",
            max: "PAYMENTS_PAGE.MASTERCARD.DEPOSIT_METHOD.MAX",
        },
        [WITHDRAWAL_METHOD]: {
            min: "PAYMENTS_PAGE.MASTERCARD.WITHDRAWAL_METHOD.MIN",
            max: "PAYMENTS_PAGE.MASTERCARD.WITHDRAWAL_METHOD.MAX",
        },
    },
    {
        id: "ecopayz",
        type: "Credit Card",
        processingTime: "Instant",
        fee: "free",
        currency: null,
        [DEPOSIT_METHOD]: {
            min: "PAYMENTS_PAGE.ECOPAYZ.DEPOSIT_METHOD.MIN",
            max: "PAYMENTS_PAGE.ECOPAYZ.DEPOSIT_METHOD.MAX",
        },
        [WITHDRAWAL_METHOD]: {
            min: "PAYMENTS_PAGE.ECOPAYZ.WITHDRAWAL_METHOD.MIN",
            max: "PAYMENTS_PAGE.ECOPAYZ.WITHDRAWAL_METHOD.MAX",
        },
    },
    {
        id: "bitcoin",
        type: "Bitcoin",
        processingTime: "Instant",
        fee: "free",
        currency: "BTC",
        [DEPOSIT_METHOD]: {
            min: 0.0001,
        },
        [WITHDRAWAL_METHOD]: {
            min: 0.0001,
            max: 0.4,
        },
    },
    {
        id: "bitcoin-cash",
        type: "Bitcoin Cash",
        processingTime: "Instant",
        fee: "free",
        currency: "BTH",
        [DEPOSIT_METHOD]: {
            min: 0.0001,
        },
        [WITHDRAWAL_METHOD]: {
            min: 0.0001,
            max: 0.4,
        },
    },
    {
        id: "litecoin",
        type: "Lite Coin",
        processingTime: "Instant",
        fee: "free",
        currency: "LTC",
        [DEPOSIT_METHOD]: {
            min: 0.01,
        },
        [WITHDRAWAL_METHOD]: {
            min: 0.0001,
            max: 60,
        },
    },
    {
        id: "ethereum",
        type: "Ethereum",
        processingTime: "Instant",
        fee: "free",
        currency: "ETH",
        [DEPOSIT_METHOD]: {
            min: 0.01,
        },
        [WITHDRAWAL_METHOD]: {
            min: 0.0001,
            max: 12,
        },
    },
    {
        id: "dogecoin",
        type: "Dogecoin",
        processingTime: "Instant",
        fee: "free",
        currency: "DOG",
        [DEPOSIT_METHOD]: {
            min: 0.01,
        },
        [WITHDRAWAL_METHOD]: {
            min: 0.0001,
            max: "1 600 000",
        },
    },
    {
        id: "tether",
        type: "Tether",
        processingTime: "Instant",
        fee: "free",
        currency: "USDT",
        [DEPOSIT_METHOD]: {
            min: 20,
        },
        [WITHDRAWAL_METHOD]: {
            min: 25,
            max: 4000,
        },
    },
];

export const paymentsMethodsForBenefitCrypto = [
    {
        id: "bitcoin",
        currencyMethod: "Bitcoin (BTC)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.BITCOIN",
        network: "Bitcoin",

    },
    {
        id: "bitcoin-cash",
        currencyMethod: "Bitcoin Cash (BCH)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.BITCOIN_CASH",
        network: "BCH Bitcoin Cash",
    },
    {
        id: "litecoin",
        currencyMethod: "Litecoin (LTC)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.LITECOIN",
        network: "LTC Litecoin",
    },
    {
        id: "dogecoin",
        currencyMethod: "Dogecoin (DOGE)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.DOGECOIN",
        network: "DOGE",
    },
    {
        id: "ethereum",
        currencyMethod: "Ethereum (ETH)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.ETHEREUM",
        network: "ERC20",
    },
    {
        id: "tether",
        currencyMethod: "Tetheh (USDT)",
        minDep: "PAYMENTS_PAGE.MIN_DEP_CRYPTO.TETHER",
        network: "ERC20",
    },
];
