<template>
    <div class="notify-message gap-column-s">
        <div class="notify-message__icon">
            <slot name="icon">
                <FeImage v-bind="image"/>
            </slot>
        </div>
        <div class="gap-s">
            <div class="notify-message__context">
                <div v-if="$slots.title" class="h3 text--capitalize">
                    <slot name="title"/>
                </div>
                <div class="text-color--body">
                    <slot/>
                </div>
            </div>
            <slot name="button"/>
        </div>

        <FeIcon
            class="notify-message__icon-trash"
            :icon="icons.fill.trash"
            @click.stop="handleDeleteNotice"
        />
    </div>
</template>

<script setup lang="ts">
import { useNoticesStore } from "@store/notices";
import type { INotification } from "@src/types/WSnotices";
import { WSNotificationName } from "@src/types/WSnotices";
import { computed } from "vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeImage from "@ui/FeImage/FeImage.vue";

import notificationIcon from "./image/notification-icon.png";
import notificationIconX2 from "./image/notification-icon@2x.png";
import noticeIconBonus from "./image/notice-icon-bonus.png";
import noticeIconBonus2x from "./image/notice-icon-bonus@2x.png";
import noticeIconFS from "./image/notice-icon-fs.png";
import noticeIconFS2x from "./image/notice-icon-fs@2x.png";


interface IProps {
    id: INotification["id"];
    type: INotification["type"];
}

const props = defineProps<IProps>();
const noticesStore = useNoticesStore();

const iconByType = {
    [WSNotificationName.BONUSES_CHANGES]: {
        src: noticeIconBonus,
        srcRetina: noticeIconBonus2x,
    },
    [WSNotificationName.FREESPINS_CHANGES]: {
        src: noticeIconFS,
        srcRetina: noticeIconFS2x,
    },
};

const image = computed(() => {
    if (iconByType[props.type]) {
        return iconByType[props.type];
    }

    return {
        src: notificationIcon,
        srcRetina: notificationIconX2,
    };
});

function handleDeleteNotice() {
    noticesStore.deleteNotification(props.id);
}
</script>


<style scoped lang="scss">
@import "~@theme/styles";

.notify-message {
    grid-template-columns: 2rem 1fr 1.5rem;
    padding: 1rem;
    border-bottom: 1px solid var(--color-layer-alt-4);
    column-gap: 1rem;

    &__icon-trash {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        padding: .25rem;
        color: var(--color-text-body);
        cursor: pointer;

        &:hover {
            color: var(--color-text-alt);
        }
    }

    &__icon {
        width: 2rem;
        height: 2rem;

        img {
            display: block;
            width: 100%;
        }
    }

    :deep(a) {
        @include link("light", true);
        text-decoration: none;
    }
}
</style>
