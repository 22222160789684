export const mockLevels = {
    1: {
        position: {
            left: 129,
            top: 201,
        },
        bets: {
            EUR: 0,
        },
    },
    2: {
        position: {
            left: 254,
            top: 306,
        },
        bets: {
            EUR: 350,
        },
    },
    3: {
        position: {
            left: 99,
            top: 492,
        },
        bets: {
            EUR: 1000,
        },
    },
    4: {
        position: {
            left: 237,
            top: 669,
        },
        bets: {
            EUR: 2000,
        },
    },
    5: {
        position: {
            left: 237,
            top: 869,
        },
        bets: {
            EUR: 5000,
        },
    },
    6: {
        position: {
            left: 145,
            top: 922,
        },
        bets: {
            EUR: 10000,
        },
    },
    7: {
        position: {
            left: 145,
            top: 1029,
        },
        bets: {
            EUR: 15000,
        },
    },
    8: {
        position: {
            left: 237,
            top: 1082,
        },
        bets: {
            EUR: 25000,
        },
    },
    9: {
        position: {
            left: 145,
            top: 1135,
        },
        bets: {
            EUR: 35000,
        },
    },
    10: {
        position: {
            left: 53,
            top: 1189,
        },
        bets: {
            EUR: 70000,
        },
    },
    11: {
        position: {
            left: 145,
            top: 1241,
        },
        bets: {
            EUR: 130000,
        },
    },
    12: {
        position: {
            left: 145,
            top: 1343,
        },
        bets: {
            EUR: 220000,
        },
    },
    13: {
        position: {
            left: 38,
            top: 1405,
        },
        bets: {
            EUR: 300000,
        },
    },
    14: {
        position: {
            left: 191,
            top: 1566,
        },
        bets: {
            EUR: 500000,
        },
    },
    15: {
        position: {
            left: 40,
            top: 1725,
        },
        bets: {
            EUR: 800000,
        },
    },
};
