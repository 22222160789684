import { storeToRefs } from "pinia";

const MESSAGES_NOTIFICATION = {
    Registration: "Registration restricted in your country",
    Login: "Login not available in your country",
};

import toast from "@plugins/Toast";
import { useRootStore } from "@store/root";

export default (router) => {
    router.beforeEach((to, __, next) => {
        const { isGuest } = storeToRefs(useRootStore());
        if (!isGuest.value) {
            return next();
        }

        if (to.meta && Boolean(to.meta.notAllowedFor451)) {
            if (location.pathname.replace("/guest", "") === to.fullPath) {
                return next({ name: "Main" });
            }

            toast.show({
                image: "warning",
                type: "warning",
                text: MESSAGES_NOTIFICATION[to.name],
                id: Math.random(),
            });

            return next(false);
        }

        return next();
    });
};
