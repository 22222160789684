import { IMG_STATUSES, VIP_CLUB_IDS, VIP_CLUB_STATUSES } from "@components/VipClub/consts";
import * as newConsts from "@components/VipClub/constsNew";
import { useUserStatuses } from "@store/user/userStatuses";
import dayjs from "dayjs";
import { computed } from "vue";

export const userVipClubStatus = computed<number | undefined>(() => {
    const userStatuses = useUserStatuses();

    const vipClubStatuses = Object.keys(VIP_CLUB_STATUSES).map((id) => Number(id));
    return userStatuses.getUserGroups.find((groupId) => {
        return vipClubStatuses.includes(Number(groupId));
    }) as number;
});

export const userVipStatusWithRegular = computed(() => userVipClubStatus.value || VIP_CLUB_IDS.REGULAR);

export const imageStatus = computed<{ src: string; srcRetina: string }>(() => {
    if (!userVipClubStatus.value) {
        return {
            src: "",
            srcRetina: "",
        };
    }

    return {
        src: IMG_STATUSES[userVipClubStatus.value].src,
        srcRetina: IMG_STATUSES[userVipClubStatus.value].srcRetina,
    };
});

export const imageStatusWithRegular = computed<{ src: string; srcRetina: string }>(() => {
    if (!userVipStatusWithRegular.value) {
        return {
            src: "",
            srcRetina: "",
        };
    }

    return {
        src: newConsts.IMG_STATUSES[userVipStatusWithRegular.value].src,
        srcRetina: newConsts.IMG_STATUSES[userVipStatusWithRegular.value].srcRetina,
    };
});

export const nextQuarterDays = computed(() => {
    const currentDate = dayjs();
    const quarterKey = "quarter";

    const firstDate = currentDate.add(1, quarterKey).startOf(quarterKey);
    const lastDate = currentDate.add(1, quarterKey).endOf(quarterKey);
    const lastDayCurrentQuarter = currentDate.add(0, quarterKey).endOf(quarterKey);

    return {
        firstDate,
        firstMonth: firstDate.month(),
        lastDate,
        lastMonth: lastDate.month(),
        lastDayCurrentQuarter,
    };
});

