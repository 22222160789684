import dayjs from "dayjs";
import utsPlugin from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import relativeTime from "dayjs/plugin/relativeTime";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(utsPlugin);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);

export function formatDate(date, format = "YYYY/MM/DD HH:mm") {
    return dayjs(date).format(format);
}

export function formatDateL(date, format = "YYYY-MM-DD, HH:mm:ss") {
    return dayjs(date).format(format);
}

export function formatDateUS(date, format = "YYYY/MM/DD hh:mm A") {
    return dayjs(date).format(format);
}

export function dayMonthYear(date, format = "YYYY/MM/DD") {
    return dayjs(date).format(format);
}

export function timeUTC(date, t) {
    const dateOfWeek = t(`CALENDAR.WEEK_DAYS.${ [ dayjs(date).utc().day() ] }`);
    return `${ dateOfWeek }, ${ dayjs(date).utc().format("MMM, D, HH:mm") }`;
}

export function timeFromNow(date) {
    return dayjs(date).fromNow();
}
