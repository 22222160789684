<template>
    <div class="comp-points-table-rates">
        <div
            v-for="rateItem in dataForTable"
            :key="rateItem.id"
            :class="{'comp-points-table-rates__row--user': rateItem.isUserLevel}"
            class="comp-points-table-rates__row align-items-center"
        >
            <div class="comp-points-table-rates__row-left">
                <FeImage
                    :src="rateItem.image"
                    class="comp-points-table-rates__icon"
                />

                <span>{{ rateItem.name }}</span>
            </div>
            <div class="comp-points-table-rates__row-right">
                {{
                    $t("COMP_PINTS.FAQ.TABLE_RATES.BET_COUNT",
                       {
                           countCurrency: rateItem.rate.bet_amount,
                           currency: rateItem.currency,
                           coinCount: 1,
                       })
                }}
            </div>
        </div>

        <FeButtonNew
            v-if="featureFlags.enableNewUIComponents"
            :theme="themes.SecondaryMedium"
            class="comp-points-table-rates__action"
            full-width
            @click="handleClickGoToLevels"
        >
            {{ $t("COMP_PINTS.FAQ.TABLE_RATES.BUTTON_GO_TO_LEVEL") }}
        </FeButtonNew>
        <FeButton
            v-else
            :theme="FeButtonConfig.themes.SecondarySmall"
            class="comp-points-table-rates__action"
            full-width
            @click="handleClickGoToLevels"
        >
            {{ $t("COMP_PINTS.FAQ.TABLE_RATES.BUTTON_GO_TO_LEVEL") }}
        </FeButton>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";
import { routeNames } from "@router/routeNames";

import FeImage from "@ui/FeImage/FeImage.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { useLevelsStore } from "@store/levels/levelsStore";
import { useCommon } from "@store/common";
import { useUserInfo } from "@store/user/userInfo";
import FeButtonNew from "@ui/New/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "CompPointsTableRates",

    components: {
        FeButtonNew,
        FeImage,
        FeButton,
    },

    props: {
        ratesData: {
            type: Array,
        },
    },

    data() {
        return {
            FeButtonConfig,
            routeNames,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useUserStatuses, {
            userLevel: "getUserLevelInfo",
        }),
        ...mapState(useLevelsStore, {
            levelById: "getLevelsById",
        }),
        ...mapState(useUserInfo, {
            userCurrency:"getUserCurrency",
            isLogged: "getIsLogged",
        }),
        ...mapState(useCommon, {
            defaultCurrency: "getDefaultCurrency",
        }),

        dataForTable() {
            return this.ratesData.map(({ id, image, rate }) => {
                const { name } = this.levelById(id);

                const rateByCurrency = {
                    rate: rate[this.userCurrency] || rate[this.defaultCurrency] || rate[Object.keys(rate)[0]],
                    currency:
                        (rate[this.userCurrency] && this.userCurrency) ||
                        (rate[this.defaultCurrency] && this.defaultCurrency) ||
                        Object.keys(rate)[0],
                };

                return {
                    id,
                    image,
                    name,
                    ...rateByCurrency,
                    isUserLevel: this.userLevel.id === id,
                };
            });
        },
    },

    methods: {
        handleClickGoToLevels() {
            this.$router.push({ name: routeNames.profileLevels });

            if (!this.isLogged) {
                this.$gtm.customTrackEvent({
                    ...this.$gtm.REGISTRATION_EVENTS.CLICK,
                    location: "CompPointsTableRates",
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.comp-points-table-rates {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
        @include font-size--Caption2;
        padding: .5rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &-left {
            display: flex;
            align-items: center;
        }

        &-right {
            text-align: right;
            color: var(--color-text-menu-active);
        }

        &--user {
            background: var(--color-additional-gradient);
            border-radius: var(--border-radius-s);

            .comp-points-table-rates__row-right {
                color: var(--color-text-alt);
            }
        }
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;
    }

    &__action {
        margin: 1rem auto 0;
    }
}
</style>
