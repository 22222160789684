<template>
    <input
        ref="search"
        v-model="input"
        :placeholder="placeholder"
        autocomplete="off"
        class="game-search"
        type="text"
        @focus="$emit('focus')"
        @blur="(e) => $emit('blur', e)"
    >
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
    minSearchLength: {
        type: Number,
        default: 3,
    },
    placeholder: {
        type: String,
        default: "Search",
    },
    autofocus: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits([ "input:modelValue", "focus", "blur" ]);
const input = ref("");
const timer = ref(null);

function search() {
    if (input.value.length < props.minSearchLength) {
        return;
    }
}

function delaySearch() {
    if (timer.value) {
        clearTimeout(timer.value);
    }

    timer.value = setTimeout(() => {
        search();
    }, 300);
}

function searchHandler() {
    if (input.value.length >= props.minSearchLength) {
        delaySearch();
    }
}


function clear() {
    input.value = "";
}


watch(input, () => {
    emit("input:modelValue", input.value);
    searchHandler();
});

defineExpose({
    clear,
});
</script>

<style lang="scss" src="./style.scss"></style>
