export const routeNames = {
    mainLayout: "MainLayout",
    main: "Main",
    categoryGames: "CategoryGames",
    categoryPokiesAll: "CategoryPokiesAll",
    categoryPokies: "CategoryPokies",
    categoryLiveAll: "categoryLiveAll",
    categoryTable: "CategoryTable",
    categoryGamesByPage: "CategoryGamesByPage",
    producersGames: "ProducersGames",
    producersGamesByPage: "ProducersGamesByPage",
    action: "Action",
    arena: "Arena",
    promoPage: "PromoPage",
    achievements: "Achievements",
    tournaments: "Tournaments",
    tournamentsAll: "TournamentsAll",
    tournamentsProducers: "TournamentsProducers",
    tournamentsLottery: "TournamentsLottery",
    tournamentsAchievements: "TournamentsAchievements",
    tournamentsAction: "TournamentsAction",
    tournamentsArchive: "Archive",
    tournamentPage: "TournamentPage",
    lotteryPage: "LotteryPage",
    tournamentGamesByPage: "TournamentGamesByPage",
    tournamentLeaderboard: "Leaderboard",
    profile: "Profile",
    profileLevels: "ProfileLevels",
    profileGifts: "ProfileGifts",
    profileVerification: "ProfileVerification",
    profileLimits: "ProfileLimits",
    profileSecurity: "ProfileSecurity",
    profileGamesHistory: "ProfileGamesHistory",
    profilePromocode: "profilePromocode",
    gameBase: "GameBase",
    gameItem: "GameItem",
    gameItemSFU: "GameItemSFU",
    remind: "Remind",
    newPassword: "NewPassword",
    emailVerify: "EmailVerify",
    emailConfirmation: "EmailConfirmation",
    login: "Login",
    registration: "Registration",
    cashbox: "Cashbox",
    cashboxWithdraw: "CashboxWithdraw",
    cashboxDeposit: "CashboxDeposit",
    cashboxDepositOneClick: "CashboxDepositOneClick",
    cashboxDepositDepositBonus: "CashboxDepositDepositBonus",
    cashboxDashboard: "cashboxDashboard",
    namedPopup: "NamedPopup",
    singleBonusPage: "SingleBonusPage",
    vipPage: "VipPage",
    newsList: "NewsList",
    newsPage: "NewsPage",
    userLimitConfirm: "UserLimitConfirm",
    paymentsPage: "PaymentsPage",
    supportPage: "SupportPage",
    oopsPage: "OopsPage",
    favoriteGames: "FavoriteGames",
    questPage: "QuestPage",
    benefitCrypto: "BenefitCrypto",
    spinWheelPage: "SpinWheelPage",
    exchangeCompoints: "ExchangeCompoints",
    bettingMain: "bettingMain",
    bettingReg: "bettingReg",
    bettingLogin: "bettingLogin",
    singUpPage: "singUpPage",
    loginPage: "loginPage",
    notFoundPage: "NotFoundPage",
    vipClub: "VipClub",
    featureFlagConfiguration: "FeatureFlagConfiguration",
};
