<template>
    <div class="footer-payments">
        <p class="footer-payments__title text--align-center h2">
            {{ $t("FOOTER.PAYMENTS") }}
        </p>
        <CarouselScroll
            class="footer-payments-сarousel"
            :transparent-border="true"
        >
            <div
                v-for="payment in paymentsCollection"
                :key="payment"
                class="footer-payments__link"
                @click="$router.push({ name: routeNames.cashboxDashboard })"
            >
                <div
                    class="footer-payments__link-wrapper"
                    :data-test="dataTestAttrs.footerPayments.itemWrap"
                >
                    <PaymentsLogo
                        class="footer-payments__image"
                        :payment="payment"
                    />
                </div>
            </div>
        </CarouselScroll>
    </div>
</template>

<script setup lang="ts">
import config from "@theme/configs/config";
import PaymentsLogo from "@modules/Payments/components/PaymentsLogo/PaymentsLogo.vue";
import { routeNames } from "@router/routeNames";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import dataTestAttrs from "@modules/Footer/dataTestAttrs.js";
import { useLicense } from "@helpers/license";
import { computed } from "vue";

const { isLicenseEE } = useLicense();

const paymentsCollection = computed(() => {
    return isLicenseEE.value ? config.paymentsEE : config.payments;
});

</script>

<style lang="scss" src="./style.scss"></style>
