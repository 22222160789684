let listener = null;
let isLoading = false;

function getDocHeight() {
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
    );
}

function addScrollListener() {
    if (typeof window !== "undefined") {
        window.addEventListener("scroll", () => {
            if (!listener) {
                return;
            }

            let bottomElement = window["pagination-end"];

            const height = bottomElement && bottomElement.offsetTop || getDocHeight();
            if (window.scrollY + window.innerHeight > height - 500) {
                if (listener) {
                    listener();
                }
            }
        }, true);
    }
}

export default {
    init() {
        addScrollListener();
    },
    addListener(handlerPagination) {
        listener = () => {
            if (isLoading) {
                return;
            }

            if (typeof handlerPagination === "function") {
                isLoading = true;

                handlerPagination.call(this).finally(() => {
                    isLoading = false;
                });
            } else {
                throw new Error("handlerPaginationName is not a function");
            }
        };
    },
    removeListener() {
        listener = null;
        isLoading = false;
    },
};
