<template>
    <div class="user-buttons">
        <router-link :to="{ name: routeNames.profileGifts }" class="user-buttons__gift">
            <FeIcon :icon="FeIconConfig.icons.outline.giftBox" alt="Gifts" class="user-buttons__gift-icon" />
            <div v-if="count" class="user-buttons__gift-counter" :class="classes">
                {{ count }}
            </div>
        </router-link>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";

import { routeNames } from "@router/routeNames";
import { TYPES as GiftTypes } from "@config/gift";
import { useGiftsStore } from "@store/gifts";


export default {
    name: "GiftButton",
    components: {
        FeIcon,
    },
    props: {
        count: {
            type: Number,
        },
    },

    data() {
        return {
            FeIconConfig,
            routeNames,
        };
    },

    computed: {
        classes() {
            return {
                "user-buttons__gift-counter--pulsing": this.hasFeatureGift,
            };
        },
        hasFeatureGift() {
            const giftsStore = useGiftsStore();

            return giftsStore.giftsActual.some((gift) => {
                return gift.type === GiftTypes.BooongoFeature || gift.type === GiftTypes.PlaysonFeature;
            });
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
