<template>
    <div
        class="modal__wrapper"
        :class="classes"
        @click.self.prevent="closeHandler({clickOnOverlay: true})"
    >
        <div
            class="modal__container"
            :class="containerClasses"
        >
            <slot
                :full-screen="fullScreen"
                :close-handler="closeHandler"
            >
                <div class="modal__body">
                    default body
                </div>
            </slot>
            <slot v-if="showClose && !$route.meta.popup" name="close" :close-handler="closeHandler">
                <div class="modal__close" @click="closeHandler({clickOnCloseButton: true})">
                    <FeIcon class="modal__close-btn" :icon="FeIconConfig.icons.fill.close"/>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import bettingClose from "@helpers/bettingCloseHelper";
import { isBettingLayout } from "@helpers/bettingHelpers";
import { mapState } from "pinia";
import { useRootStore } from "@store/root";

export default {
    name: "Modal",

    components: {
        FeIcon,
    },

    provide() {
        return {
            closeHandler: this.closeHandler,
            fullScreen: this.fullScreen,
            fullScreenMobile: this.fullScreenMobile,
            hasOverflowVisible: this.hasOverflowVisible,
        };
    },

    props: {
        mobileFriendly: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: false,
        },
        bottomSide: {
            type: Boolean,
            default: false,
        },
        fullScreen: {
            type: Boolean,
        },
        fullScreenMobile: {
            type: Boolean,
        },
        hasOverflowVisible: {
            type: Boolean,
            default: false,
        },
    },

    emits: [ "close" ],

    data() {
        return {
            FeIconConfig,
        };
    },
    computed: {
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        classes() {
            return {
                "modal__wrapper--mobile-friendly": this.mobileFriendly,
                "modal__wrapper--bottom": this.bottomSide,
                "modal__wrapper--full-screen": this.fullScreen,
                "modal__wrapper--full-screen-mobile": this.fullScreenMobile && this.isMobile,
            };
        },
        containerClasses() {
            return {
                "modal__container--has-overflow-visible": this.hasOverflowVisible,
            };
        },
    },
    methods: {
        closeHandler({ immediate, clickOnOverlay, clickOnCloseButton } = { immediate: false, clickOnOverlay: false }) {
            this.$emit("close", { immediate, clickOnOverlay, clickOnCloseButton });

            if (clickOnOverlay && isBettingLayout(this.$route)) {
                bettingClose(this.$router);
            }

            if (clickOnCloseButton) {
                this.$bus.$emit("clickHandleCloseButton");
            }
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
