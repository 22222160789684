<template>
    <Popup
        bottom
        full-width
        offset-x
        offset-y
        popup-wrapper-fixed
        right
        use-backdrop
    >
        <template #activator>
            <NotifyButton/>
        </template>

        <template #default="{ hideHandler, contentShow }">
            <NotifyBar :is-content-show="contentShow" :hide-handler="hideHandler"/>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { EventBus as bus } from "@bus";
import Popup from "@components/Popup/Popup.vue";
import NotifyButton from "./NotifyButton.vue";
import NotifyBar from "./NotifyBar.vue";

import { onMounted, onBeforeUnmount } from "vue";
import { useNoticesStore } from "@store/notices";
import { WSNotificationName } from "@src/types/WSnotices";

const { addRealTimeNotification } = useNoticesStore();

function handlerPersonalNotifications(data) {
    addRealTimeNotification(data, WSNotificationName.PERSONAL_NOTIFICATIONS);
}

function handlerBonusesChanges(data) {
    addRealTimeNotification(data, WSNotificationName.BONUSES_CHANGES);
}

function handlerFreespinsChanges(data) {
    addRealTimeNotification(data, WSNotificationName.FREESPINS_CHANGES);
}

onMounted(() => {
    bus.$on("websocket.personal_notifications", handlerPersonalNotifications);
    bus.$on("websocket.bonuses_changes", handlerBonusesChanges);
    bus.$on("websocket.freespins_changes", handlerFreespinsChanges);

    /* bus.$on("websocket.tournament_notifications",
        (data) => addRealTimeNotification(data, WSNotificationName.TOURNAMENT_NOTIFICATIONS));
    bus.$on("websocket.payments_changes",
        (data) => addRealTimeNotification(data, WSNotificationName.PAYMENTS_CHANGES)); */
});

onBeforeUnmount(() => {
    bus.$off("websocket.personal_notifications", handlerPersonalNotifications);
    bus.$off("websocket.bonuses_changes", handlerBonusesChanges);
    bus.$off("websocket.freespins_changes", handlerFreespinsChanges);

    /*   bus.$off("websocket.tournament_notifications");
      bus.$off("websocket.payments_changes"); */
});
</script>

<style scoped lang="scss">
</style>
