import i18n from "@src/i18n";
import i18nMessages from "@api/i18nMessages";

export async function loadLanguageAsync({ lang, route }, updateUserLocale = () => {}) {
    const $i18n = i18n.instance || i18n.init();

    if ($i18n.instance?.messages[lang]) {
        return Promise.resolve(updateUserLocale({ lang, route }));
    }

    // Если локализация ещё не была загружена
    const messages = await i18nMessages.loadMessages(lang);
    $i18n.setLocaleMessage(lang, messages);
    return updateUserLocale({ lang, route });
}
