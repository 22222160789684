import {
    ALLOW_DOMAINS,
    COUNTRY_BY_HOST,
    DEFAULT_LOCALE_BY_COUNTRY,
    EXCEPTION_DOMAIN_LANG_ALLOW,
} from "@theme/configs/constsLocales";

export function metaHrefLangsLink(routePath) {
    const allDomainsHrefLangs = [];

    ALLOW_DOMAINS.forEach((domain) => {
        allDomainsHrefLangs.push({
            rel: "alternate",
            hreflang: DEFAULT_LOCALE_BY_COUNTRY[COUNTRY_BY_HOST[domain]],
            href: `https://${ domain }${ routePath }`,
        });

        if (EXCEPTION_DOMAIN_LANG_ALLOW[domain]) {
            EXCEPTION_DOMAIN_LANG_ALLOW[domain].forEach((local) => {
                allDomainsHrefLangs.push({
                    rel: "alternate",
                    hreflang: local,
                    href: `https://${ domain }/${ local }${ routePath }`,
                });
            });
        }
    });
    return allDomainsHrefLangs;
}
