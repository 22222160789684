<script setup lang="ts">
import { computed } from "vue";

import NotifyMessage from "@modules/Notify/componentsByTypes/NotifyMessagePersonal.vue";
import NotifyMessageTournament from "@modules/Notify/componentsByTypes/NotifyMessageTournament.vue";

import type { INotification } from "@src/types/WSnotices";

interface IProps {
    data: INotification;
}

const props = defineProps<IProps>();

const componentByType = computed(() => {
    if (props.data.identifier) {
        return NotifyMessageTournament;
    }
    return NotifyMessage;
});

</script>

<template>
    <component :is="componentByType" v-bind="props" />
</template>
