import { routeNames } from "@router/routeNames";
import { isServer } from "@helpers/ssrHelpers";
import {
    depositPromocodeStorage,
    depositPromocodeShowedStorage,
    giftPromocodeStorage,
    clearAllDepositePromocodeStorage,
} from "@helpers/promocodeStorageHelper";
import { storeToRefs } from "pinia";
import { useUserInfo } from "@store/user/userInfo";

export default (router) => {
    if (isServer) {
        return;
    }

    router.beforeEach((to, from, next) => {
        const { getIsLogged } = storeToRefs(useUserInfo());

        if (to.query.depositPromocode) {
            depositPromocodeStorage.set(to.query.depositPromocode);
            depositPromocodeShowedStorage.remove();
            router.replace({ ...to, query: null });
        }

        if (to.query.giftPromocode) {
            giftPromocodeStorage.set(to.query.giftPromocode);
            router.replace({ ...to, query: null });
        }

        if (to.name === routeNames.main && !getIsLogged.value) {
            if (depositPromocodeStorage.get()) {
                clearAllDepositePromocodeStorage();
            }

            if (giftPromocodeStorage.get()) {
                giftPromocodeStorage.remove();
            }
        }

        next();
    });
};
