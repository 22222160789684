import { mapState } from "pinia";
import { useEnvironments } from "@store/environments";

export default {

    /**
     * Props for this component.
     *
     * @param {number} end - parsed date in milliseconds.
     * @param {boolean} dots - show or hidden ":" symbol.
     * @param {array} text - display custom description. For example:
     * a) display full timer: ['days','hours','months','seconds']
     * b) display only seconds: [,,,'sec']
     */

    props: {
        end: {
            type: [
                Number,
                Date,
            ],
            required: true,
        },
        dots: {
            type: Boolean,
        },
        text: {
            type: Array,
            default: () => {
                return [
                    "д",
                    "ч",
                    "м",
                    "с",
                ];
            },
        },
    },

    data() {
        return {
            now: null,
            dummy: 0,
            diff: 0,
            interval: null,
            ms: 1000,
        };
    },

    mounted() {
        this.start();
    },

    computed: {
        seconds() {
            return this.dateFormat(Math.trunc(this.diff) % 60) || null;
        },
        minutes() {
            return this.dateFormat(Math.trunc(this.diff / 60) % 60) || null;
        },
        hours() {
            return this.dateFormat(Math.trunc(this.diff / 60 / 60) % 24) || null;
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24) || null;
        },
        date() {
            return Math.trunc(this.end / this.ms);
        },
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),
    },

    methods: {
        start() {
            if (this.environment.useMocker) {
                this.diff = 556133;

                return;
            }

            this.now = Math.trunc((new Date()).getTime() / this.ms);

            this.interval = setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / this.ms);
            }, this.ms);
        },

        dateFormat(value) {
            return value < 10 ? `0${ value }` : value;
        },
    },

    watch: {
        now() {
            this.diff = this.date - this.now;

            if (this.diff <= 0) {
                this.diff = 0;
                clearInterval(this.interval);
                this.$emit("end");
            }
        },

        end() {
            this.start();
        },
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },
};
