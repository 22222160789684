<template>
    <div
        class="jackpot-leaderboard-block"
    >
        <FeText
            v-if="!isMobile"
            :align="FeTextConfig.align.Center"
            :size="FeTextConfig.size.H2"
            class="jackpot-leaderboard-block__title"
        >
            {{ props.block.title }}
        </FeText>
        <div
            class="jackpot-leaderboard-block__wrapper gap-row-xs"
        >
            <JackpotLeaderBoardItem
                v-for="(item, index) in props.block.data"
                :key="item.created_at"
                :data="item"
                :index="index"
                :block-title="props.block.title"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRootStore } from "@store/root";
import { storeToRefs } from "pinia";
import FeText, * as FeTextConfig from "@ui/FeText/FeText.vue";
import JackpotLeaderBoardItem
    from "@components/JackpotsCustom/JackpotRow/JackpotLeaderBoard/JackpotLeaderBoardItem.vue";
import type { IJackpotWinner } from "@store/jackpots/types/winner";

interface Props {
    block: {
        title: string;
        data: IJackpotWinner[];
    };
}

const props = defineProps<Props>();
const { isMobile } = storeToRefs(useRootStore());
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.jackpot-leaderboard-block {
    padding: 0;
    background: var(--color-layer-alt-1);
    border-radius: var(--border-radius-s);
    @include media(M) {
        padding: 1rem;
    }
    @include media(L) {
        padding: 1.5rem;
    }

    &__wrapper {
        @include media(M) {
            gap: 1rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
    }
}
</style>
