<template>
    <div
        :class="classesCard"
        class="status-prefer-item padding-s"
    >
        <div class="status-prefer-item__top-s-info">
            <div>
                <div
                    v-if="textForLabel"
                    class="status-prefer-item__my-status"
                    v-text="textForLabel"
                />
            </div>

            <div>
                <div
                    v-if="!isDiamondCard && textForTimer"
                    class="font__caption-2--normal status-prefer-item__top-s-info-date"
                >
                    <span
                        class="text-color--body"
                        v-text="textForTimer.label"
                    />
                    <strong> {{ textForTimer.data }} </strong>
                    <FeTooltip
                        :position="FeTooltipPosition.Bottom"
                        :theme="FeTooltipThemes.Secondary"
                    >
                        <template #activator>
                            <FeIcon :icon="icons.fill.info1"/>
                        </template>
                        <span>{{ textForTimer.tooltip }}</span>
                    </FeTooltip>
                </div>
                <FeIcon v-else-if="isLocked" :icon="icons.fill.notAvailable"/>
            </div>
        </div>

        <div class="text--align-center gap-row-xs">
            <FeImage
                v-if="imageByStatus.src"
                :src="imageByStatus.src"
                :src-retina="imageByStatus.srcRetina"
                class="status-prefer-item__img-status"
            />

            <span class="h3 text--capitalize">{{ stepData.step }}</span>
        </div>

        <VipClubItemTablePreference
            v-if="isShowBenefits && stepData.benefits"
            :list-prefer="stepData.benefits"
        />
        <p v-else class="text-color--body">
            {{ stepData.condition }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

import { DIAMOND_STATUS, IMG_BY_NAME_STATUSES, VIP_CLUB_STATUSES } from "@components/VipClub/consts";
import type { IStatusStepItem } from "@components/VipClub/types";
import { nextQuarterDays, userVipClubStatus } from "@components/VipClub/vipClubHelpers";
import VipClubItemTablePreference from "@components/VipClub/VipClubItemTablePreference.vue";
import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import FeImage from "@ui/FeImage/FeImage.vue";
import FeTooltip, { position as FeTooltipPosition, themes as FeTooltipThemes } from "@ui/FeTooltip/FeTooltip";
import { useI18n } from "vue-i18n";
import type { TranslateResult } from "vue-i18n";

interface IProps {
    stepData: IStatusStepItem;
    allSteps: IStatusStepItem[];
}

const props = defineProps<IProps>();
const { t } = useI18n();

const imageByStatus = computed(() => {
    return IMG_BY_NAME_STATUSES[props.stepData.step];
});

const levelCard = computed(() => {
    return VIP_CLUB_STATUSES[userVipClubStatus.value];
});

const isDiamondCard = computed<boolean>(() => levelCard.value.toLowerCase() === DIAMOND_STATUS);

// eslint-disable-next-line no-shadow
enum StatesCards {
    STATE_CURRENT = "current",
    STATE_FUTURE = "future",
    STATE_BEFORE = "before",
    STATE_LOCK = "lock",
}

const positionCardInCollection = computed(() => ({
    cardPosition: props.allSteps.findIndex(({ step }) => step === props.stepData.step),
    currentLevelPosition: props.allSteps.findIndex(({ step }) => step === levelCard.value),
}));

const isCurrent = computed<boolean>(() => levelCard.value === props.stepData.step);

const isBefore = computed<boolean>(() => {
    return positionCardInCollection.value.cardPosition < positionCardInCollection.value.currentLevelPosition;
});

const isFuture = computed<boolean>(() => {
    return positionCardInCollection.value.cardPosition === positionCardInCollection.value.currentLevelPosition + 1;
});

const isLocked = computed<boolean>(() => {
    return positionCardInCollection.value.cardPosition > positionCardInCollection.value.currentLevelPosition + 1;
});

const stateCard = computed<StatesCards>(() => {
    if (isCurrent.value) {
        return StatesCards.STATE_CURRENT;
    }

    if (isBefore.value) {
        return StatesCards.STATE_BEFORE;
    }

    if (isFuture.value) {
        return StatesCards.STATE_FUTURE;
    }

    return StatesCards.STATE_LOCK;
});

const classesCard = computed(() => {
    return {
        [`status-prefer-item--${stateCard.value}`]: stateCard,
    };
});

const isShowBenefits = computed<boolean>(() => {
    switch (stateCard.value) {
        case StatesCards.STATE_BEFORE:
        case StatesCards.STATE_CURRENT:
        case StatesCards.STATE_FUTURE:
            return true;
        default:
            return false;
    }
});

const textForLabel = computed<TranslateResult | false>(() => {
    switch (stateCard.value) {
        case StatesCards.STATE_CURRENT:
            return t("VIP_CLUB.COMPARES.CARD.MY_STATUS");
        case StatesCards.STATE_FUTURE:
            return t("VIP_CLUB.COMPARES.CARD.LABEL_NEX_STATUS");
        default:
            return false;
    }
});

const textForTimer = computed<{
    label: TranslateResult,
    data: string | number,
    tooltip: TranslateResult
} | false>(() => {
    switch (stateCard.value) {
        case StatesCards.STATE_CURRENT:
            return {
                label: t("VIP_CLUB.COMPARES.CARD.DATE_EXPIRES"),
                data: nextQuarterDays.value.lastDayCurrentQuarter?.format("DD.MM.YYYY"),
                tooltip: t("VIP_CLUB.COMPARES.CARD.CURRENT_STAT_TOOLTIP_TEXT"),
            };
        case StatesCards.STATE_FUTURE:
            return {
                label: t("VIP_CLUB.COMPARES.CARD.LEFT_DAYS"),
                data: nextQuarterDays.value.lastDayCurrentQuarter?.diff(dayjs(), "day"),
                tooltip: t("VIP_CLUB.COMPARES.CARD.FUTURE_STAT_TOOLTIP_TEXT"),
            };
        default:
            return false;
    }
});

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.status-prefer-item {
    display: grid;
    grid-gap: 1.5rem;
    align-content: baseline;
    width: 100%;
    max-width: 21rem;
    height: 100%;

    border-radius: 1rem;
    background: var(--color-tertiary-4);

    &--lock {
        background: transparent;
        border-radius: 1rem;
        border: 1px solid var(--color-layer-alt-4);
    }

    &__top-s-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 1.5rem;
    }

    &__top-s-info-date {
        display: grid;
        grid-auto-flow: column;
        grid-gap: .25rem;
        align-items: center;
    }

    &__my-status {
        border-radius: 0.25rem;
        background: var(--color-additional-gradient);
        display: flex;
        padding: 0 0.5rem;
        align-items: center;
        color: var(--color-text-on-opposite-back);
        @include font-size--SmallCaption($font-weight--bold);
    }

    :deep(.fe-tooltip__body) {
        width: 15rem;
        left: -13rem;
    }

    &__img-status {
        display: block;
        width: 5rem;
        height: 5rem;
        margin: 0 auto;

        @include media(L) {
            width: 7rem;
            height: 7rem;
        }
    }

    &--before {
        :deep(.table-preference .check) {
            color: var(--color-layer-alt-3);
        }
    }

    &--future {
        :deep(.table-preference .check) {
            color: var(--color-layer-alt-3);
        }

        :deep(.table-preference__row-data) {
            position: relative;

            &.up:before {
                content: "";
                position: absolute;
                border: .25rem solid transparent;
                border-bottom: .5rem solid var(--color-successfully);
            }
        }

        .status-prefer-item__my-status {
            background: var(--color-secondary-2);
            color: var(--color-text-alt);
        }
    }
}
</style>
