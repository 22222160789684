<template>
    <CalendarRow v-if="calendarConfig.length > 0"/>
</template>

<script setup lang="ts">
import { onMounted, onServerPrefetch } from "vue";

import CalendarRow from "@modules/VipAdventures/Calendar/CalendarRow.vue";

import { useVipAdventures } from "@store/user/vipAdventures";
import { storeToRefs } from "pinia";

const { loadVipAdventuresConfig } = useVipAdventures();
const { vipAdventuresConfigFile, calendarConfig } = storeToRefs(useVipAdventures());

onMounted(async() => {
    // undefined check because it could be an [] which is a correct behaviour
    if (vipAdventuresConfigFile.value === undefined || !calendarConfig.value) {
        await loadVipAdventuresConfig();
    }
});

onServerPrefetch(async() => {
    return await loadVipAdventuresConfig();
});

</script>
