<script lang="ts" setup>
import { computed } from "vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import FeNavBadge from "@ui/FeNavBadge/FeNavBadge.vue";
import { useGetActiveClass } from "@helpers/getActiveClassNavMenu";
import type { IItemMenu } from "@helpers/getActiveClassNavMenu";
import { useRouter } from "vue-router";

interface IProps {
    item: IItemMenu;
    hiddenItems: Array<any>;
    classes?: string[];
}

const props = defineProps<IProps>();

const { getActiveClass } = useGetActiveClass();
const $router = useRouter();

function handleClick(item) {
    if (item.externalUrl) {
        window.location = item.externalUrl;
    }

    if (item.clickFn) {
        return item.clickFn(item);
    }
    $router.push(item.to || item.url || { name: item.routeName });
}

const routerLinkTo = computed(() => {
    return props.item.externalUrl ? props.item.externalUrl : props.item.to || props.item.url || { name: props.item.routeName };
});

</script>

<template>
    <router-link
        :key="item.title"
        v-slot="{ href, isActive }"
        :to="routerLinkTo"
        class="nav-roll-item"
        custom
    >
        <a
            :class="[ getActiveClass(item, isActive, hiddenItems), ...(classes || []) ]"
            :href="props.item.externalUrl || href"
            class="nav-roll-item"
            v-bind="$attrs"
            @click.prevent="handleClick(item)"
        >
            <FeImage
                v-if="item.image"
                :src="item.image"
                :alt="item.title"
                class="nav-roll-item__img"
            />
            <span class="" v-html="item.title"/>
            <FeNavBadge
                v-show="item.badge"
                :badge="item.badge || {}"
                class="nav-roll-item__badge"
            />
        </a>
    </router-link>
</template>

<style lang="scss" scoped>
@import "~@theme/styles";

.nav-roll {
    &-item {
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        transition: color .2s ease;
        cursor: pointer;
        padding: 0;
        margin: 0;
        white-space: nowrap;

        &.item-hidden {
            visibility: hidden;
            order: 999;
        }

        &--soon {
            opacity: .5;

            &-label {
                opacity: 1;
                font-weight: $font-weight--bold;
            }
        }

        &__badge {
            margin-left: .25rem;
        }

        &__img {
            margin-right: .5rem;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__dropdown-item {
        margin: 0 0 1rem 0;

        &:last-child {
            margin-bottom: 0;
        }

        &.active,
        &:hover {
            &:after {
                display: none;
            }
        }
    }
}
</style>
