<template>
    <div id="main" class="main">
        <HeaderSection/>
        <HomeBanner v-if="showBanner"/>
        <div
            class="body__wrapper"
            :class="{'body__wrapper--full-width': fullWidth}"
        >
            <router-view v-slot="{ Component }">
                <transition :name="transitionName" mode="out-in">
                    <component :is="Component"/>
                </transition>
            </router-view>
            <LocalTime v-if="!isMobile" class="local-time"/>
            <BookmarksList/>
        </div>
        <FooterSection/>
        <Sidebar/>
    </div>
</template>
<script setup lang="ts">
import HeaderSection from "@modules/Header/Header.vue";
import HomeBanner from "@components/HomeBanner/HomeBanner.vue";
import FooterSection from "@modules/Footer/Footer.vue";
import Sidebar from "@modules/SideBar/SideBar.vue";

import LocalTime from "@components/LocalTime/LocalTime.vue";
import BookmarksList from "@modules/BookmarksList/BookmarksList.vue";

import { useRootStore } from "@store/root";

interface IProps {
    showBanner: boolean;
    fullWidth: boolean;
}

withDefaults(defineProps<IProps>(), {
    showBanner: false,
    fullWidth: false,
});

const { transitionName, isMobile } = useRootStore();

</script>

<style lang="scss" src="./style.scss"></style>
