<template>
    <div class="comp-points-faq">
        <div class="comp-points-faq-steps__wrap">
            <div class="comp-points-faq-steps">
                <FeImage
                    :src="iconCoin"
                    :src-retina="iconCoinX2"
                    class="comp-points-faq-steps__icon"
                    :lazy="true"
                />
                <div class="comp-points-faq-steps__content">
                    <p class="h3">
                        {{ $t("COMP_PINTS.FAQ.STEPS.FIRST.TITLE") }}
                    </p>
                    <p>{{ $t("COMP_PINTS.FAQ.STEPS.FIRST.DESC") }}</p>
                </div>
            </div>

            <div class="comp-points-faq-steps">
                <FeImage
                    :src="iconCoin"
                    :src-retina="iconCoinX2"
                    class="comp-points-faq-steps__icon"
                />
                <div class="comp-points-faq-steps__content">
                    <p class="h3">
                        {{ $t("COMP_PINTS.FAQ.STEPS.SECOND.TITLE") }}
                    </p>
                    <p>{{ $t("COMP_PINTS.FAQ.STEPS.SECOND.DESC") }}</p>
                </div>
            </div>

            <div class="comp-points-faq-steps">
                <FeImage
                    :src="iconCoin"
                    :src-retina="iconCoinX2"
                    class="comp-points-faq-steps__icon"
                />
                <div class="comp-points-faq-steps__content">
                    <p class="h3">
                        {{ $t("COMP_PINTS.FAQ.STEPS.THIRD.TITLE") }}
                    </p>
                    <p>{{ $t("COMP_PINTS.FAQ.STEPS.THIRD.DESC") }}</p>
                </div>
            </div>
        </div>

        <div class="comp-points-rates gap-s">
            <p class="text--align-center h3">
                {{ $t("COMP_PINTS.FAQ.TITLE") }}
            </p>

            <div class="comp-points-rates__user-info">
                <div v-if="isLogged" class="gap-xs justify-items-center">
                    <FeImage
                        v-if="userLevel.name"
                        :src="iconLevel"
                        class="comp-points-rates__user-info-icon"
                    />
                    <p
                        v-if="userLevel.name"
                        v-html="$t('COMP_PINTS.FAQ.USER_INFO.LEVEL', {level: userLevel.name})"
                    />
                    <CompPointRateInformer :label="$t('COMP_PINTS.FAQ.USER_INFO.EX_RATE')"/>
                </div>
                <FeImage
                    v-else
                    :src="goldLevelImage"
                    class="comp-points-rates__user-info-icon"
                />
            </div>

            <div class="comp-points-table">
                <p class="text--align-center">
                    {{ $t("COMP_PINTS.FAQ.TABLE_RATES.TITLE") }}
                </p>
            </div>

            <CompPointsTableRates :rates-data="ratesData"/>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";

import FeImage from "@ui/FeImage/FeImage.vue";
import iconCoin from "@modules/CompPoints/images/icon-coin.png";
import iconCoinX2 from "@modules/CompPoints/images/icon-coin@2.png";
import goldLevelImage from "@theme/images/BrandImages/Levels/level_4.png";
import CompPointRateInformer from "@modules/CompPoints/components/CompPointRateInformer.vue";
import CompPointsTableRates from "@modules/CompPoints/components/CompPointsTableRates.vue";
import { useLevelsStore } from "@store/levels/levelsStore";
import { useUserInfo } from "@store/user/userInfo";

export default {
    name: "CompPointsFAQ",

    components: {
        FeImage,
        CompPointRateInformer,
        CompPointsTableRates,
    },

    props: {
        contentData: {
            type: Object,
        },
    },

    data() {
        return {
            iconCoin,
            iconCoinX2,
            goldLevelImage,
        };
    },

    computed: {
        ...mapState(useUserStatuses, {
            userLevel: "getUserLevelInfo",
        }),
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
        ...mapState(useLevelsStore, [ "getLevelImageById" ]),
        iconLevel() {
            return this.getLevelImageById(this.userLevel);
        },
        ratesData() {
            return Object.entries(this.contentData).map(([ id, { rate, image } ]) => {
                return {
                    id,
                    rate,
                    image,
                };
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.comp-points-faq {
    &-steps {
        display: flex;

        &__wrap {
            display: grid;
            grid-gap: 2rem;
            margin-bottom: 2rem;

            @include media(M) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__icon {
            margin-right: 1rem;
            width: 2rem;
            height: 2rem;
        }

        &__content {
            h3 {
                @include font-size--Header3;
                margin-bottom: .5rem;
            }

            p {
                color: var(--color-text-body);
            }
        }
    }
}

.comp-points-rates {
    background: var(--color-layer-alt-1);
    border-radius: var(--border-radius-s);
    padding: 1rem;
    max-width: 27rem;
    margin: 0 auto;

    @include media(M) {
        padding: 1.5rem;
    }

    h3 {
        @include font-size--Body($font-weight--bold);
        text-transform: inherit;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-icon {
            width: 5rem;
            height: 5rem;
        }

        p {
            @include font-size--Header3;
            color: var(--color-text-body);

            :deep(strong) {
                color: var(--color-text-menu-active);
            }
        }
    }
}

.comp-points-table p {
    color: var(--color-text-body);
}
</style>
