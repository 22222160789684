<template>
    <div
        class="bonus-card__container"
    >
        <div
            v-for="(bonusItem, index) in mockBonusListWithImage"
            :key="index"
            class="bonus-card__wrapper"
        >
            <SuperGift :gift="bonusItem"/>
        </div>
    </div>
</template>

<script>
import SuperGift from "@modules/Gifts/components/SuperGift/SuperGift.vue";

export default {
    name: "DepositGiftsList",

    components: {
        SuperGift,
    },

    props: {
        mockBonusListWithImage: {
            type: Array,
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.bonus-card {
    &__container {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 0 auto;
    }

    &__wrapper {
        width: calc(100vw - 2rem);
        margin: 0 0 1rem;

        @include media(S) {
            width: 25rem;
        }

        @include media(M) {
            margin: 0 1rem 1rem 0;
        }
    }


    &__title {
        @include font-size--h4;
    }

    &__description {
        @include font-size--h3;
    }

    &__parameter {
        @include font-size--main;
    }

    &__circles {
        position: absolute;
        display: flex;
        width: calc(100% + 2rem);
        justify-content: space-between;
        align-items: center;
        background: transparent;
        margin: -1rem -1rem 0 -1rem;
    }

    &__coupon {
        display: flex;
        align-items: center;
        background: var(--color-text-alt);
        padding: 1rem;

        &-icon {
            margin-right: 0.75rem;

            &--used {
                display: none;
            }
        }

        &-description p {
            font-size: 1rem;
            line-height: 1.5rem;
            text-align-last: left;
        }

        &-title {
            font-weight: $font-weight--bold;
        }
    }
}
</style>
