<template>
    <div class="grid-content">
        <div
            ref="contentRef"
            class="static-page mobile-layout-width grid-center-position gap-m"
        >
            <div
                v-if="customPage"
                ref="containerCustomPage"
                :class="classes"
                class="container-custom-page gap-row-m"
                v-html="preparedContent.html"
            />
            <div
                v-else-if="currentPage"
                class="static-page__container gap-m"
                v-html="currentPage.content"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useLicense } from "@helpers/license";
import { useCMS } from "@store/CMS";
import { storeToRefs } from "pinia";
import {
    computed,
    onMounted,
    getCurrentInstance,
    onServerPrefetch,
    ref,
    toRefs,
    watch,
    h,
    onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import { createI18n } from "@i18n";
import { CUSTOM_PAGE_TYPE } from "@modules/StaticPage/constants";
import log from "@controllers/Logger";

import type { IWaitingShowWelcomePack } from "@controllers/useWelcomePack";
import type { IStaticPage } from "@store/types/cms";

import { useWelcomePack } from "@controllers/useWelcomePack";

import "@theme/icons/social_whatsapp.svg";
import "@theme/icons/social_telegram.svg";
import "@theme/icons/icon-lock.svg";
import "@theme/icons/star.svg";
import "@modules/Footer/components/TrustBlock/img/trastpilot.svg";
import "@modules/Footer/components/TrustBlock/img/lcd.png";
import "@modules/Footer/components/TrustBlock/img/casino-guru.png";

import PaymentsMethods from "@modules/Payments/components/PaymentsMethods.vue";
import DepositGiftsList from "@modules/Bonus/components/DepositGiftsList.vue";
import LevelsListSlider from "@modules/Bonus/VipPage/components/LevelsListSlider.vue";
import JackpotSlider from "@components/JackpotsCustom/JackpotSlider.vue";
import JackpotLeaderBoard from "@components/JackpotsCustom/JackpotRow/JackpotLeaderBoard/JackpotLeaderBoard.vue";
import JackpotsPerks from "@components/JackpotsCustom/JackpotsPerks.vue";
import JackpotCatchInfo from "@components/JackpotsCustom/JackpotCatchInfo.vue";
import LiveChatButton from "@modules/Support/components/LiveChatButton.vue";
import SingUpDepositButton from "@components/EnterBtns/SingUpDepositButton.vue";
import PromoSlider from "@components/PromoSlider/PromoSlider.vue";
import PromotionList from "@components/PromotionList/PromotionList.vue";
import GameRow from "@modules/GameHall/components/GameRow/GameRow.vue";
import TermsAndConditionsDownload from "@components/TermsAndConditionsDownload/TermsAndConditionsDownload.vue";
import CompPointsFAQ from "@modules/CompPoints/components/CompPointsFAQ.vue";
import AdventureCardsCarousel from "@modules/VipAdventures/AdventureCardsCarousel.vue";
import AdventureCalendar from "@modules/VipAdventures/AdventureCalendar.vue";
import AdventuresLabelUserPlace from "@modules/VipAdventures/AdventuresLabelUserPlace.vue";
import VipAdventuresBadges from "@modules/VipAdventures/VipAdventuresLabels.vue";
import AdventureBanner from "@modules/VipAdventures/AdventureBanner.vue";
import VipClubBannerContent from "@components/VipClub/VipClubBannerContent.vue";
import VipClubCompares from "@components/VipClub/VipClubCompares.vue";
import ArticlesLists from "@components/Blog/ArticlesLists.vue";
import { userVipClubStatus } from "@components/VipClub/vipClubHelpers";
import { createApp } from "vue";
import { useGtm } from "@controllers/MetriksController/GTMController";
import { useUserInfo } from "@store/user/userInfo";

const currentApp = getCurrentInstance();

const pathToComponents = {
    PaymentsMethods,
    DepositGiftsList,
    LevelsListSlider,
    JackpotSlider,
    JackpotLeaderBoard,
    JackpotsPerks,
    JackpotCatchInfo,
    LiveChatButton,
    SingUpDepositButton,
    PromoSlider,
    PromotionList,
    GameRow,
    TermsAndConditionsDownload,
    CompPointsFAQ, // ??
    AdventureCardsCarousel,
    AdventuresLabelUserPlace,
    VipAdventuresBadges,
    AdventureCalendar,
    AdventureBanner,
    VipClubBannerContent,
    VipClubCompares,
    ArticlesLists,
};

const { getIsLogged: isLogged } = storeToRefs(useUserInfo());
const $route = useRoute();
const $router = useRouter();

interface IProps {
    categories?: [];
    slugStaticPage: string;
}

const props = withDefaults(defineProps<IProps>(), {
    categories: () => [],
});

const { categories, slugStaticPage } = toRefs(props);
const { waitingShowWelcomePack } = useWelcomePack();
const { getStaticPageBySlug, loadCurrentStaticPage } = useCMS();
const { currentStaticPage: currentPage } = storeToRefs(useCMS());

const welcomePackId = ref<IWaitingShowWelcomePack | null>(null);
const { usedLicense } = toRefs(useLicense());

const staticPageInfo = computed<IStaticPage>(() => {
    const staticPageSlugForWelcomePack = `${welcomePackId.value}/${slugStaticPage.value || $route.name}`;
    const staticPageSlugByGeo = `${$route.name}/${usedLicense.value}`;

    return getStaticPageBySlug(staticPageSlugByGeo) ||
        getStaticPageBySlug(staticPageSlugForWelcomePack) ||
        getStaticPageBySlug($route.name);
});
const customPage = computed<boolean>(() => {
    return categories.value.includes(CUSTOM_PAGE_TYPE);
});

type PropsDynamicComponents = Record<string, unknown>;

interface IComponentInCollection<C> {
    Component: C,
    componentName: string,
    props?: PropsDynamicComponents,
    idElement: string,
}

const preparedContent = computed(() => {
    const components: IComponentInCollection<any> [] = [];
    if (!currentPage.value) {
        return {
            html: "",
            components,
        };
    }

    let descriptionData = {};
    try {
        descriptionData = currentPage.value?.meta.metaKeywords ? JSON.parse(currentPage.value.meta.metaKeywords) : {};
    } catch (err) {
        log.warn("STATIC_PAGE_DESCRIPTION_DATA_METHODS_ERROR", err);
        descriptionData = {};
    }

    const regex = /#([^#\[\]]+)(?:\[([^\[\]]+)])?#/g;

    const html = currentPage.value.content.replace(regex, (match, componentName, propsString, indexPlace) => {
        const Component = pathToComponents[componentName];
        if (Component) {
            const propsPreparing = propsString?.split(";").reduce((result, propString) => {
                const [ keyProp, dataProp ] = propString.split("=");

                if (dataProp === "description") {
                    result[keyProp] = descriptionData;
                    return result;
                }

                try {
                    result[keyProp] = JSON.parse(dataProp);
                } catch (err) {
                    log.warn("STATIC_PAGE_PROPS_PARSE", err);
                    result[keyProp] = dataProp;
                }

                return result;
            }, {});

            const idElement = `${componentName}-${indexPlace}`;

            components.push({
                Component,
                componentName,
                props: propsPreparing,
                idElement,
            } as IComponentInCollection<InstanceType<typeof Component>>);

            return `<div id="${idElement}"></div>`;
        }
        return match;
    });

    return {
        components,
        html,
    };
});

const contentRef = ref<HTMLElement | null>(null);

function openLContentByHash() {
    if (typeof window === "undefined") {
        return;
    }

    const hash = $route.hash;
    const element = hash ? contentRef.value?.querySelector(hash) : null;

    if (element instanceof HTMLInputElement) {
        element.checked = true;
    }
}

const isLoadingPage = ref<boolean>(false);

async function fetchStaticPage() {
    welcomePackId.value = await waitingShowWelcomePack();

    try {
        if (isLoadingPage.value || !staticPageInfo.value) {
            return;
        }
        isLoadingPage.value = true;

        await loadCurrentStaticPage(staticPageInfo.value.slug);
        openLContentByHash();
    } catch (err) {
        log.warn("STATIC_PAGE_PROPS_PARSE_WARN", err);
    } finally {
        isLoadingPage.value = false;
    }
}

const containerCustomPage = ref<HTMLElement | null>(null);
const customComponents = ref([]);

const $gtm = useGtm();

function renderComponents() {
    preparedContent.value.components.forEach(({ idElement, Component, props: internalProps }) => {
        const el = containerCustomPage.value?.querySelector(`#${idElement}`);
        if (el) {
            const app = createApp({
                name: Component.__name,
                render: () => h(Component, internalProps),
            });
            el.style.display = "contents";

            app.config.globalProperties.$gtm = $gtm;

            app.use($router);
            app.use(currentApp.appContext.config.globalProperties.$pinia);

            app.use(createI18n());
            // @TODO: it solves the problem with displaying static components at vue devtools, but it adds an outer div to html
            app.mount(el);
            customComponents.value.push(app);
        }
    });
}

onBeforeUnmount(() => {
    customComponents.value.forEach((app) => {
        app.unmount();
    });
});

watch(
    () => $route.name,
    fetchStaticPage,
);

const loadStaticPageData = async () => {
    try {
        if (staticPageInfo.value) {
            return await fetchStaticPage();
        }

        return Promise.resolve();
    } catch (err) {
        log.error("Async data in static body:", err);
    }
};

onServerPrefetch(loadStaticPageData);

onMounted(loadStaticPageData);

const classes = computed(() => {
    return {
        auth: isLogged.value,
        vip: userVipClubStatus.value,
    };
});

watch(
    () => isLogged.value,
    fetchStaticPage,
);

watch(
    () => preparedContent.value.components,
    (val) => {
        if (val.length) {
            setTimeout(renderComponents, 0);
        }
    },
);
</script>

<style lang="scss" src="./style.scss"></style>
