import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";
import { POINT_STATUS_ACTIVE, POINT_STATUS_COMPLETED, POINT_STATUS_LOCKED } from "@modules/Quest/constants/PointStatuses";

// @ts-ignore
import questXSMobLogo from "@modules/Quest/constants/xs/img/quest--xs--logo.png";
// @ts-ignore
import questXSMobLogoX2 from "@modules/Quest/constants/xs/img/quest--xs--logo@2x.png";
// @ts-ignore
import questXSDesktopX2 from "@modules/Quest/constants/xs/img/questXS-desktop-2x.jpg";
// @ts-ignore
import questXSDesktop from "@modules/Quest/constants/xs/img/questXS-desktop.jpg";
// @ts-ignore
import questXSMobX2 from "@modules/Quest/constants/xs/img/questXS-mob-2x.jpg";
// @ts-ignore
import questXSMob from "@modules/Quest/constants/xs/img/questXS-mob.jpg";
import { mockLevels } from "@modules/Quest/constants/xs/mock";
import pointActive from "@modules/Quest/images/point_active_1x.png";
import pointActiveL from "@modules/Quest/images/point_active_2x.png";
import pointLocked from "@modules/Quest/images/point_locked_1x.png";
import pointLockedL from "@modules/Quest/images/point_locked_2x.png";
import pointUnlocked from "@modules/Quest/images/point_unlocked_1x.png";
import pointUnlockedL from "@modules/Quest/images/point_unlocked_2x.png";

const config: IBaseQuestConfig = {
    mockLevels,
    imageConfig: {
        logo: {
            main: questXSMobLogo,
            retina: questXSMobLogoX2,
        },
        desktop: {
            main: questXSDesktop,
            retina: questXSDesktopX2,
        },
        mobile: {
            main: questXSMob,
            retina: questXSMobX2,
        },
        points: {
            [POINT_STATUS_ACTIVE]: {
                sm: pointActive,
                lg: pointActiveL,
            },
            [POINT_STATUS_COMPLETED]: {
                sm: pointUnlocked,
                lg: pointUnlockedL,
            },
            [POINT_STATUS_LOCKED]: {
                sm: pointLocked,
                lg: pointLockedL,
            },
        },
    }
};

export default config;
