<template>
    <div
        class="notify-bar"
        :class="classes"
    >
        <div class="notify-bar__header">
            <span v-t="'NOTIFY_BAR.HEADER.TITLE'"/>
            <close-button @click="hideHandler"/>
        </div>
        <div v-if="getAllNotifications.length" class="notify-bar__container">
            <NotifyMessageSupper
                v-for="(item, index) in getAllNotifications"
                :key="item.id"
                class="notify-bar__mess"
                :class="notificationsClasses[index]"
                :data="item"
            />
        </div>

        <div
            v-else
            class="gap-row-s justify-items-center padding-s"
        >
            <FeIcon
                :icon="icons.outline.noNotify"
                class="notify-bar__icon-empty"
            />
            <p
                v-t="'NOTIFY_BAR.MESSAGES.EMPTY_TEXT'"
                class="text--align-center color-text-body"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

import bodyDisableScroll from "@front/core/mixins/bodyDisableScroll";

import FeIcon, { icons } from "@ui/FeIcon/FeIcon";
import CloseButton from "@modules/Auth/components/CloseButton/CloseButton.vue";
import NotifyMessageSupper from "@modules/Notify/NotifyMessageSupper.vue";
import { useNoticesStore } from "@store/notices";
import { isLastByIndex } from "@helpers/lastItem";

interface IProps {
    isContentShow: boolean;
    hideHandler: () => void;
}

const props = defineProps<IProps>();
const { getAllNotifications } = storeToRefs(useNoticesStore());

const isOpen = ref<boolean>(false);

const classes = computed(() => {
    return {
        "notify-bar--opened": isOpen.value,
    };
});

const notificationsClasses = computed(() => {
    return getAllNotifications.value.map((item, index) => isLastByIndex(index, getAllNotifications.value) ? "last-child" : "");
});

onMounted(() => {
    bodyDisableScroll(true);
    setTimeout(() => {
        isOpen.value = props.isContentShow;
    }, 0);
});

onBeforeUnmount(() => {
    bodyDisableScroll(false);
});

</script>

<style scoped lang="scss">
@import "~@theme/styles";

.notify-bar {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: var(--color-layer-alt-1);
    height: 100vh;
    width: 80vw;
    max-width: 24rem;
    left: 100%;
    top: 0;
    overflow: hidden;
    transform: translateX(0px);
    transition: all .25s;
    border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
    padding-bottom: 1rem;

    &--opened {
        transform: translateX(-100%);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }

    @include media(M) {
        border-radius: var(--border-radius-m) 0 0 var(--border-radius-m);
    }

    &__icon-empty {
        width: 4rem;
        height: 4rem;
    }

    &__container .notify-message.last-message {
        border-bottom: none;
    }
}
</style>
