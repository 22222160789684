import type { IBaseQuestConfig } from "@modules/Quest/constants/BaseQuestConfig";
import { POINT_STATUS_ACTIVE, POINT_STATUS_COMPLETED, POINT_STATUS_LOCKED } from "@modules/Quest/constants/PointStatuses";
import { mockLevels } from "@modules/Quest/constants/s/mock";

import pointActiveL from "./img/point_active--s--х2.png";
import pointActive from "./img/point_active--s.png";
import pointLockedL from "./img/point_locked--s--х2.png";
import pointLocked from "./img/point_locked--s.png";
import pointUnlockedL from "./img/point_unlocked--s--х2.png";
import pointUnlocked from "./img/point_unlocked--s.png";

import questSMobLogo from "./img/quest--s--logo-mobile.png";
import questSMobLogoX2 from "./img/quest--s--logo-mobile@2x.png";
import questSDescLogo from "./img/quest--s--logo.png";
import questSDescLogoX2 from "./img/quest--s--logo@2x.png";
import questSDesktopX2 from "./img/questS-desctop-2x.jpg";
import questSDesktop from "./img/questS-desktop.jpg";
import questSMobX2 from "./img/questS-mob-2x.jpg";
import questSMob from "./img/questS-mob.jpg";

import bookmarkIcon from "./img/bookmark-icon--s.svg";
import character from "./img/Character--s--x1.png";
import characterX2 from "./img/Character--s--x2.png";

const config: IBaseQuestConfig = {
    mockLevels,
    imageConfig: {
        logo: {
            main: questSDescLogo,
            retina: questSDescLogoX2,
        },
        logoSecondary: {
            main: questSMobLogo,
            retina: questSMobLogoX2,
        },
        desktop: {
            main: questSDesktop,
            retina: questSDesktopX2,
        },
        mobile: {
            main: questSMob,
            retina: questSMobX2,
        },
        points: {
            [POINT_STATUS_ACTIVE]: {
                sm: pointActive,
                lg: pointActiveL,
            },
            [POINT_STATUS_COMPLETED]: {
                sm: pointUnlocked,
                lg: pointUnlockedL,
            },
            [POINT_STATUS_LOCKED]: {
                sm: pointLocked,
                lg: pointLockedL,
            },
        },
        character: {
            main: character,
            retina: characterX2,
        },
        bookmarkIcon,
    },
};

export default config;
