export const LIMIT_STATUS_ACTIVE = "active";
export const LIMIT_STATUS_CONFIRMED = "confirmed";
export const LIMIT_STATUS_CONFIRMED_DISABLE = "confirmed_disable";
// eslint-disable-next-line id-length
export const LIMIT_STATUS_UN_CONFIRMED_DISABLE = "unconfirmed_disable";
export const LIMIT_STATUS_UN_CONFIRMED = "unconfirmed";

export const LIMIT_TYPE_LOSS = "loss";
export const LIMIT_TYPE_WAGER = "wager";
export const LIMIT_TYPE_DEPOSIT = "deposit";
export const LIMIT_TYPE_COOLING_OFF = "coolingoff";
export const LIMIT_TYPE_SELF_EXCLUSION = "selfexclusion";
export const LIMIT_TYPE_SESSION = "session";

export const LIMIT_PERIOD_DAY = "day";
export const LIMIT_PERIOD_WEEK = "week";
export const LIMIT_PERIOD_MONTH = "month";

export const LIMIT_TYPE_PERIOD_DAY = "day";
export const LIMIT_TYPE_PERIOD_DAY_3 = "day_3";
export const LIMIT_TYPE_PERIOD_WEEK = "week";
export const LIMIT_TYPE_PERIOD_MONTH = "month";
export const LIMIT_TYPE_PERIOD_MONTH_3 = "month_3";
export const LIMIT_TYPE_PERIOD_MONTH_6 = "month_6";
export const LIMIT_TYPE_PERIOD_MONTH_9 = "month_9";
export const LIMIT_TYPE_PERIOD_MONTH_12 = "month_12";
export const LIMIT_TYPE_PERIOD_FOREVER = "forever";


export const LIMIT_ORDERS_PERIOD = {
    [LIMIT_PERIOD_DAY]: 1,
    [LIMIT_PERIOD_WEEK]: 2,
    [LIMIT_PERIOD_MONTH]: 3,
};
