import * as PromiseMixin from "@src/mixins/promiseMixin";
import { useRoute } from "vue-router";
import { mapState } from "pinia";
import { useUserInfo } from "../store/user/userInfo";

export const localeMixin = {
    computed: {
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
    },

    methods: {
        promiseAll: PromiseMixin.promiseAll,

        loadStartData: PromiseMixin.loadStartData,

        popupClose() {
            const route = useRoute();
            this.$bus.$emit("popup.close", route.value.name);
        },
    },
};
