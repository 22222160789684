<template>
    <transition-group
        name="slide"
        mode="out-in"
        class="message-container"
        tag="div"
    >
        <ConfirmEMPCard
            v-if="showPopupEMP"
            key="ConfirmEMPCard"
            class="confirm-emp-card"
        />
        <CookiesAccepted
            v-if="showCookies"
            key="CookiesAccepted"
            class="cookies-accepted"
        />
    </transition-group>
</template>

<script>
export default {
    name: "AcceptedMessages",
};
</script>

<script setup>
import { ref, onMounted } from "vue";

import ConfirmEMPCard from "@modules/ErrorEMP/ConfirmEMPCard.vue";
import CookiesAccepted from "@modules/CookiesAccepted/CookiesAccepted.vue";

defineProps({
    showPopupEMP: {
        type: Boolean,
        default: true,
    },
});

const showCookies = ref(false);
onMounted(() => {
    setTimeout(() => {
        showCookies.value = true;
    }, 1500);
});


</script>

<style scoped lang="scss">
@import "~@theme/styles";

$message-container-confirm-emp-card--margin-bottom: 1.5rem;
$message-container--padding: 0;
$message-container-cookies-accepted--margin-bottom: 1.5rem;

.message-container {
    position: fixed;
    top: 0;
    z-index: 9997;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: $message-container--padding;
    @include media(M) {
        top: unset;
        bottom: 0;
    }

    :deep(.message-container) {
        .confirm-emp-card {
            margin-bottom: $message-container-confirm-emp-card--margin-bottom;
            order: 1;
        }

        .cookies-accepted {
            order: 0;
            margin-bottom: $message-container-cookies-accepted--margin-bottom;

            @include media(M) {
                order: 2;
                margin-bottom: 0;
            }
        }
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 1.5s;
}

.slide-enter {
    @include media(M) {
        transform: translate(0, -100%);

    }
}

</style>
