<template>
    <PromoLabels :labels="labels" class="vip-adv__labels" />
</template>

<script lang="ts" setup>
import { useRootStore } from "@store/root";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { formatDateVipAdv } from "@modules/VipAdventures/consts.ts";
import PromoLabels, { dotColor, themes } from "@components/PromoLabels/PromoLabels.vue";
import { useVipAdventures } from "@store/user/vipAdventures";
import { useI18n } from "vue-i18n";

interface IProps {
    isFinishDay: boolean;
}

const props = defineProps<IProps>();

const { t } = useI18n();
const vipAdvStore = useVipAdventures();

const rangeDateAdventures = computed<string>(() => {
    if (!vipAdvStore.vipAdventuresDays.length) {
        return "";
    }
    const firstDay = dayjs(vipAdvStore.vipAdventuresDays[0].day, formatDateVipAdv);
    const lastDay = dayjs(vipAdvStore.vipAdventuresDays[vipAdvStore.vipAdventuresDays.length - 1].day, formatDateVipAdv);
    const startDisplayData = `${ firstDay.date() } ${ t(`CALENDAR.MONTH[${ firstDay.month() }]`) }`;
    const finishDisplayData = `${ lastDay.date() } ${ t(`CALENDAR.MONTH[${ lastDay.month() }]`) }`;

    const { isMobile } = storeToRefs(useRootStore());
    return `${ startDisplayData } - ${ finishDisplayData } ${ isMobile.value ? "" : lastDay.year() }`;
});

interface ILabels {
    title: string;
    dot: string;
    theme?: string;
}

const labels = computed<ILabels[]>(() => {
    const themeLabels = props.isFinishDay ? { theme: themes.Ghost } : {};

    return [
        {
            title: t("ADVENTURE.LABEL_NAME_ACTION"),
            ...themeLabels,

        },
        ...(
            rangeDateAdventures.value && !props.isFinishDay ?
                [ {
                    title: rangeDateAdventures.value,
                    dot: dotColor.alert,
                    ...themeLabels,
                } ] :
                [ {
                    title: t("ADVENTURE.BANNER_FINISHED.LABEL_IS_OVER"),
                    dot: dotColor.alert,
                    ...themeLabels,
                } ]
        ),
    ];
});

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.vip-adv {
    &__tags {
        display: grid;
        gap: .5rem;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
    }

    &__labels {
        justify-content: center;

        @include media(M) {
            justify-content: flex-start;
        }
    }
}

</style>
