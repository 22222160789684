/* eslint-disable max-len */
export const metaDataSSR = (locale: string): {
    title: string,
    description: string,
    content?: string
} => {
    try {
        return require(`./ssr/${locale}.json`);
    } catch {
        return require("./ssr/en-AU.json");
    }
};

export const metaDataNoSSR = ({ hostname = "rocketplay.com", locale = "", lastSlug = "" }: { hostname: string, locale: string, lastSlug: string }) => {
    const transfromLastSlug = lastSlug ? lastSlug[0].toUpperCase() + lastSlug.slice(1).replace(/-/g, " ") : "";

    return {
        title: `${transfromLastSlug || hostname} - high quality experience`,
        description: `Discover ${hostname} for high quality gambling experience. Fast payouts, a lot of payment systems available, 24/7 support center`,
        template: () => {
            try {
                return require(`./no_ssr/${locale}.json`);
            } catch {
                return "";
            }
        },
    };
};
