import { http } from "@api/http";
import log from "@controllers/Logger";

let listener = null;

function getDocHeight() {
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight,
    );
}

if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
        if (!listener) {
            return;
        }

        let bottomElement = window["pagination-end"];

        const height = bottomElement && bottomElement.offsetTop || getDocHeight();
        if (window.scrollY + window.innerHeight > height - 500) {
            if (listener) {
                listener();
            }
        }
    }, true);
}

export function defaultCollection() {
    return {
        items: [],
        isLoaded: false,
    };
}

export const DEFAULT_COLLECTION_NAME = "default";

export function paginationModule(name, url, limit) {
    return {
        namespaced: true,

        state: {
            collection: {},
        },

        actions: {
            RELOAD_DATA({ commit, dispatch }, { slug, page = 1 } = {}) {
                commit("RESET_DATA", { slug });

                dispatch("LOAD_DATA", { slug, page });
            },

            LOAD_DATA({ getters, commit }, { slug, page = 1 } = {}) {
                // eslint-disable-next-line new-cap
                if (getters.IS_LOADED(slug || DEFAULT_COLLECTION_NAME, page)) {
                    // eslint-disable-next-line new-cap
                    return Promise.resolve(getters.GET_COLLECTION(slug, page));
                }

                const param = slug ? `/${ slug }` : "";

                return http()
                    .get(`${ url }${ param }?page=${ page }&limit=${ limit }`)
                    .then((response) => {
                        commit("SET_DATA", {
                            data: response.data,
                            slug: slug || DEFAULT_COLLECTION_NAME,
                            page,
                        });
                        return response.data.data ? response.data.data : response.data;
                    }).catch((err) => {
                        log.error(`LOAD_DATA_${ name }`, err);
                    });
            },
        },

        getters: {
            GET_COLLECTION(store) {
                return (slug = DEFAULT_COLLECTION_NAME, page = 1) => {
                    let item = store.collection[slug];
                    if (!item) {
                        return [];
                    }

                    return item.items.slice(0, (page || 1) * limit);
                };
            },

            IS_LOADED(store, getters) {
                return (slug, page) => {
                    const item = store.collection[slug];

                    if (item && item.isLoaded) {
                        return true;
                    }

                    // eslint-disable-next-line new-cap
                    const itemsCount = getters.GET_COLLECTION(slug, page).length;
                    return itemsCount > 0 && itemsCount >= limit * page;
                };
            },
        },

        mutations: {
            SET_DATA(store, { data, slug, page, isLoaded }) {
                const collection = data.data ? data.data : data;
                let item = store.collection[slug];

                if (!item || page === 1) {
                    store.collection[slug] = defaultCollection();
                    item = store.collection[slug];
                }
                item.isLoaded = isLoaded || collection.length < limit;
                item.items.push(...collection);
            },

            RESET_DATA(store, { slug = DEFAULT_COLLECTION_NAME }) {
                store.collection[slug] = defaultCollection();
            },

            INIT_COLLECTION(store, data) {
                if (!data) {
                    return;
                }

                data.forEach((item) => {
                    if (store.collection[item.slug]) {
                        return;
                    }

                    store.collection[item.slug] = defaultCollection();
                });
            },
        },
    };
}
